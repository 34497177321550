import { clearStatus } from "./status";

const types = {
  LOGOUT: 'LOGOUT',
  LOGIN: 'LOGIN_SUCCESS',
  RENEW_TOKEN: 'RENEW_TOKEN',
};


export const actions = {
  login: (user, token) => {
    return {type: types.LOGIN, user, token};
  },
  logout() {
    return (dispatch, getState) => {
      dispatch({ type: types.LOGOUT })
      dispatch(clearStatus())
    }
  },
};


export function renewToken(token){
  return {type: types.RENEW_TOKEN, token}
}


const initialState = {
  isLogged: false,
  user: null,
  token: null,
};

export const reducer = (state = initialState, action) => {
  const {type, user} = action;

  switch (type) {
    case types.LOGOUT:
      return Object.assign({}, initialState);

    case types.LOGIN:
      return Object.assign({}, state, {
        token: action.token,
        user: action.user,
        isLogged: true,
      });


    case types.RENEW_TOKEN:
      return Object.assign({}, state, {
        token: action.token,
      });
    
    default:
      return state;
  }
};
  