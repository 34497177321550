import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import api from "../../../../utils/api";

export const controlSignals = {
  local_up: "home_goal+",
  local_down: "home_goal-",
  local_foul: "home_foul+",
  local_foul_down: "home_foul-",
  visit_up: "visit_goal+",
  visit_down: "visit_goal-",
  visit_foul: "visit_foul+",
  visit_foul_down: "visit_foul-",
  start: "time_start",
  pause: "time_pause",
  program: "program",
  horn: "horn",
  f1: "f1",
  f2: "f2",

  // sequences
  add_minute: "minute+",
  substract_minute: "minute-",
  reset_board: "reset_board",
  set_names: "set_names",
}

export default function useRemoteControl(fieldId) {
  const [isLoading, setIsLoading] = useState(false);
  const selectedStadiumId = useSelector((state) => state.status?.selectedStadiumId);

  function sendSignal(signal) {
    const data = {
      signal,
    }
    console.log("posting event", JSON.stringify(data))

    setIsLoading(true)

    api.post(`/stadiums/${selectedStadiumId}/remotes/${fieldId}/signal`, data)
      .then(({ data }) => {
        setIsLoading(false)
      })
      .catch((error) => {
        // console.log("sendSignal error", error);
        alert("error al mandar señal")
      })
  }

  return {
    isLoading,
    sendSignal
  }
}