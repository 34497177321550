import React, { useState, useEffect } from 'react';
import { View, Image } from "react-native"


import tw from '../../styles/tailwind';
import Text from '../../Components/Text';
import LottieView from 'lottie-react-native';
import GamifyBaseModal from '../../Components/Gamification/GamifyBaseModal';

import TeamBadge from '../../Components/Teams/TeamBadge';
import TeamXPBar from '../../Components/Gamification/TeamXPBar';
import TeamXPBadge from '../../Components/Gamification/XPBadge';
import XPCoin from '../../Components/Gamification/XPCoin';
import PlayerXPBar from '../../Components/Gamification/PlayerXPBar';
import PlayerLevel from '../../Components/Gamification/PlayerLevel';
import PlayerPicture from '../../Components/Players/PlayerPicture';
import { useSelector } from 'react-redux';

const _Ach = {
  name: "Hat-Trick",
  image_url: "https://d1crkphdqubgyh.cloudfront.net/gamification/achievements/test/5.png?r",
  description: "Anota 3 goles en un partido",
  xp: 40,
}

export default function PersonalMatchSummary({ onClose, player, actions, matchResult, prevXP, newXP }){
  const [currentXP, setCurrentXP] = useState(prevXP);

  const fm_league = useSelector(state => state.status.fm_league)
  const currentLevel = fm_league?.levels?.player?.find(e => currentXP >= e.range_low && currentXP <= e.range_high)

  useEffect(() => {
    setTimeout(() => {
      setCurrentXP(newXP)
    }, 1000)
  }, []);


  const renderAction = (action, amount, totalXP) => {
    return (
      <View style={tw`row justify-between my-2`} key={action}>
        <Text style={tw`text-white`}>{action}{amount > 1 ? ` (${amount})` : ''}</Text>

        <View style={tw`row`}>
          <XPCoin size={18} />
          <Text style={tw`text-primary ml-2`}>+{totalXP}</Text>
        </View>
      </View>
    )
  }

  return (
    <GamifyBaseModal onClose={onClose} bgColor={"#003879"}>
      <View style={tw`flex-1 justify-around`}>
        <Text style={tw`text-center text-white text-xl font-800 mb-6`}>Fin del partido</Text>
        {
          matchResult == "won" &&
            <Image source={require('./assets/won.png')} style={tw`w-80 h-28 self-center mb-6`} resizeMode={'contain'} />
        }
        {
          matchResult == "lost" &&
            <Image source={require('./assets/lost.png')} style={tw`w-80 h-28 self-center mb-6`} resizeMode={'contain'} />
        }
        {
          matchResult == "draw" &&
            <Image source={require('./assets/draw.png')} style={tw`w-80 h-28 self-center mb-6`} resizeMode={'contain'} />
        }

        {/* */}
        <View style={tw`items-center`}>
          <PlayerPicture player={player} />
          <PlayerLevel size={70} style={tw`-mt-9`} level={currentLevel?.level} />

          <PlayerXPBar
            style={{ container: `w-full mt-4`}}
            current={currentXP}
            level={currentLevel?.level}
            highLimit={currentLevel?.range_high}
          />
        </View>
        

        {/*  */}
        <View style={tw``}>
          <Text style={tw`text-yellow-200 text-lg text-center mb-5`}>Recompensas personales</Text>

          <View style={tw`bg-black/40 rounded-lg -mx-6 px-5 py-3`}>
            {
              actions.map(action => renderAction(action.action.name, action.amount, action.xp_total))
            }
          </View>

        </View>
      </View>
    </GamifyBaseModal>
  )  
}


