import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, Share, Linking, Platform, Image } from "react-native"

import { useSelector } from 'react-redux';

import tw from '../../../../styles/tailwind';
import Text from '../../../../Components/Text';

import TabletModal from '../../Matches/TabletComponents/TabletModal';

import Input from '../../../../Components/Input';
import { useForm } from 'react-hook-form';
import Button from '../../../../Components/Button';
import api from '../../../../utils/api';
import { Ionicons } from "@expo/vector-icons"


function SMSForm({ player, leagueTeamId }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [smsSent, setSmsSent] = useState(false);

  const { control, handleSubmit, setValue, reset, setFocus } = useForm();


  function sendSMSInvitation(data) {
    const _data = {
      player_id: player.uuid,
      phone_number: data.phone_number,
    }

    setIsSubmitting(true)

    api.post(`/league-teams/${leagueTeamId}/invite-player`, _data)
      .then(({ data }) => {
        setSmsSent(true)
      })
      .catch(({ response }) => {
        setIsSubmitting(false)

        toast.show("No se pudo generar la liga de invitación :S", {
          type: "warning",
        })
      })
  }

  return (
    <View style={tw`mt-8`}>
      <Input
        control={control}
        name="phone_number"
        keyboardType='phone-pad'
        maxLength={10}
        placeholder="# Teléfono (10 dígitos)"
        rules={{ required: "Número de teléfono requerido", pattern: { value: /^[0-9]{10}$/, message: "Formato inválido" } }}
      />

      <Button
        label={smsSent ? `SMS Enviado!` : (!isSubmitting ? `Enviar SMS` : `Enviando`)}
        disabled={isSubmitting || smsSent}
        onPress={handleSubmit(sendSMSInvitation)}
        style={{ container: tw`mb-0 mt-0 px-2` }}
      />
    </View>
  )
}


export default function ShareInvitationModal({ player, visible, leagueTeamId, onRequestClose }) {
  const [invitationURL, setInvitationURL] = useState<null|string>(null);
  const [showLinkCopied, setShowLinkCopied] = useState(false);

  const [showSMSForm, setShowSMSForm] = useState(false);
  const [smsNumber, setSmsNumber] = useState();

  const roles = useSelector(state => state.status.roles)
  const isAdmin = !!roles?.find(role => role == "admin")

  useEffect(() => {
    if (visible) {
      generateInvitation()
    }
  }, [visible]);


  async function generateInvitation() {
    api.post(`/league-teams/${leagueTeamId}/invite-player`, { player_id: player.uuid })
      .then(({ data }) => {
        setInvitationURL(data.invitation_url)
        // const invitation_url = qr ? data.invitation_url.replace("/futmaster", "/api.futmaster") : data.invitation_url
      })
      .catch(({ response }) => {
        toast.show("No se pudo generar la liga de invitación :S", {
          type: "warning",
        })
      })
  }

  async function shareUrl(url) {
    try {
      const result = await Share.share({
        message: url,
      });

      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      // alert(error.message);
    }
  }

  function sendWhats() {
    const text = "Tu liga de invitación a Fut Master"
    const message = encodeURIComponent(text) + " - " + encodeURIComponent(invitationURL);
    const whatsapp_url = "whatsapp://send?text=" + message;

    Linking.openURL(whatsapp_url)
    // window.location.href = whatsapp_url;
  }

  function copyToClipboard(url:string) {
    if (Platform.OS == "web") {
      navigator?.clipboard?.writeText(url)
    }

    setShowLinkCopied(true)

    setTimeout(() => {
      setShowLinkCopied(false)
    }, 5000)
  }

  return (
    <TabletModal
      visible={visible}
      onRequestClose={onRequestClose}
      onDismiss={onRequestClose}
      style={{ wrapper: tw`w-80` }}
      transparent
      showCloseButton
    >
      <Text style={tw`text-center text-lg`}>Enviar invitación a:</Text>
      <Text style={tw`text-center text-lg`}>{player.name}</Text>


      {
        invitationURL &&
          <Image
            source={{ uri: `https://chart.googleapis.com/chart?cht=qr&chl=${invitationURL}&chs=380x380&choe=UTF-8&chld=L|2` }}
            style={tw`w-30 h-30 self-center mt-3 mb-3`}
          />
      }

      <Input value={invitationURL} uncontrolled disabled selection={{ start: 0, end: 0 }} />

      {
        (Platform.OS == "web") ?
          <View style={tw`row justify-around`}>
            <TouchableOpacity onPress={() => copyToClipboard(invitationURL)}>
              <Ionicons name="copy-outline" size={30} />
            </TouchableOpacity>

            <TouchableOpacity onPress={sendWhats}>
              <Ionicons name="logo-whatsapp" size={30} color={"#4fce5d"} />
            </TouchableOpacity>

            <TouchableOpacity
              style={tw`w-8 h-8 border rounded-full items-center justify-center`}
              onPress={() => setShowSMSForm(!showSMSForm)}
            >
              <Text>SMS</Text>
            </TouchableOpacity>
          </View>
          :
          <TouchableOpacity
            onPress={(ev) => shareUrl(invitationURL)}
            style={tw`row justify-between px-2 mt-2 w-full bg-primary py-3 rounded-lg px-4`}
          >
            <Text style={tw`text-center text-md text-secondary flex-1`}>Enviar invitación</Text>
          </TouchableOpacity>
      }


      {
        (isAdmin && !player.is_registered) &&
        <TouchableOpacity
          onPress={(ev) => shareUrl(invitationURL?.replace("/futmaster", "/api.futmaster"))}
          style={tw`row justify-between px-2 mt-2 w-full bg-primary py-3 rounded-lg px-4`}
        >
          <Text style={tw`text-center text-md text-secondary flex-1`}>Copiar link ticket QR</Text>
        </TouchableOpacity>
      }

      {
        showLinkCopied && <Text style={tw`text-center mt-3`}>Liga copiada al portapapeles</Text>
      }

      {
        showSMSForm &&
        <SMSForm player={player} leagueTeamId={leagueTeamId} />
      }
    </TabletModal>
  )
}