import api from "../../utils/api";

const types = {
  STATUS_UPDATED: 'STATUS_UPDATED',
  SELECTED_STADIUM: 'SELECTED_STADIUM',
};



export function updateStatus(){
  return (dispatch, getState) => {
    return api.get(`/status`)
      .then(({ data }) => {
        dispatch(statusUpdated(data))
      })
      .catch(error => { })
  }
}

export function clearStatus(){
  return { type: types.STATUS_UPDATED, status: initialState }
}

export function statusUpdated(status){
  return { type: types.STATUS_UPDATED, status }
}

export function setSelectedStadiumId(stadiumId:string){
  return { type: types.SELECTED_STADIUM, stadiumId }
}


const initialState = {
  roles: [],
  stadiums: [],
  league_teams: [],
  upcoming_matches: [],
  past_matches: [],
  player: null,
  selectedStadiumId: null,
  gamify_notifications: null,
  fm_league: null,
};



export const reducer = (state = initialState, action) => {
  const {type, user} = action;

  switch (type) {
    case types.SELECTED_STADIUM:
      return {
        ...state,
        selectedStadiumId: action.stadiumId,
      }
      
    case types.STATUS_UPDATED:
      return Object.assign({}, state, {
        roles: action.status.roles,
        stadiums: action.status.stadiums,
        player: action.status.player,
        league_teams: action.status.league_teams,
        upcoming_matches: action.status.upcoming_matches,
        past_matches: action.status.past_matches,
        gamify_notifications: action.status.gamify_notifications,
        fm_league: action.status.fm_league,
      });

    default:
      return state;
  }
};
  