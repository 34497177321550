import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity } from "react-native"


import tw from '../../../../styles/tailwind';
import Text from '../../../../Components/Text';
import TabletModal from '../../Matches/TabletComponents/TabletModal';
import Button from '../../../../Components/Button';
import {Ionicons} from '@expo/vector-icons'
import api from '../../../../utils/api';
import { useForm } from 'react-hook-form';
import Input from '../../../../Components/Input';
import LabelSwitch from '../../../../Components/LabelSwitch';


export default function EditRoundModal({ visible, onRequestClose, round, onRoundSaved }:{matches:Array<Match>}) {
  const { control, handleSubmit, formState, formState: { errors }, setError, setValue, watch } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (visible){
      setValue("name", round.name)
      setValue("exhibition_round", round.exhibition_round)
    }else{
    }
  }, [visible]);


  function saveRound(data){
    setIsLoading(true)

    // const data = {
    //   // matches: hiddenMatches,
    // }

    console.log(data);

    api.post(`/rounds/${round.uuid}`, data)
    .then(({data}) => {
      // setIsLoading(false)
      onRoundSaved?.()
    })
    .catch(({response})=> {
      setIsLoading(false)
      console.log("HideMatchesModal error - ", JSON.stringify(response));
    })
  }

  if(!visible) return null

  return (
    <TabletModal visible={visible} onRequestClose={onRequestClose} style={{wrapper: tw`w-90%`}}>
      <Text style={tw`text-lg text-bold text-center mb-3`}>Editar jornada</Text>

      <Input
        name="name"
        control={control}
        placeholder="Nombre de la jornada"
        rules={{required:true}}
        style={{
          // container: tw`mb-5 mt-8`,
          // input: { marginBottom: 0 },
          // label: tw.style(`font-normal bg-transparent`),
        }}
      />

      <LabelSwitch
        label={"Puntos de jornada cuentan para tabla"}
        name="exhibition_round"
        control={control}
        offValue={true}
        onValue={false}
        style={{ container: tw`bg-gray-100`, label: tw`text-gray-500` }}
      />

      <Button label={isLoading ? "Guardando" : "Guardar"} disabled={isLoading} onPress={handleSubmit(saveRound)} />
    </TabletModal>
  )
}

