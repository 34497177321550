import React, { useEffect, useState } from "react";
import { TouchableOpacity, View, Image, ScrollView, Platform, Alert } from "react-native";

import tw from "../../../../styles/tailwind";
import Text from '../../../../Components/Text';
import Button from "../../../../Components/Button";
import BottomSheet from "../../../../Components/BottomSheet";
import api from "../../../../utils/api";
import FMIcons from "../../../../Components/FMIcon"
import {Ionicons} from "@expo/vector-icons"
import { updateMatch } from "../../../../store/Reducers/matches";
import { useDispatch, useSelector } from "react-redux";

interface MatchSelectFieldProps{
  visible: boolean,
  match: Match,
  onRequestClose: () => {},
  onFieldChanged?: () => {},
}


export default function MatchSelectFieldBottomSheet({ visible, onRequestClose, match, onFieldChanged } : MatchSelectFieldProps) {

  // TODO: SELECTED_STADIUM_STATE
  const selectedStadiumId = useSelector((state) => state.status?.selectedStadiumId);
  const stadium = useSelector((state) => state.status?.stadiums?.find(e => e.uuid == selectedStadiumId));


  function setMatchField(field){
    const data = {
      field_id: field.uuid,
    }

    api.post(`/matches/${match.uuid}/field`, data)
      .then(({ data }) => {
        // setLeagueCalendar(data)
        // console.log(data);
        onFieldChanged?.()
      })
      .catch(error => {
        console.log(error?.response?.data);
      })
  }


  return (
    <BottomSheet
      visible={visible}
      onRequestClose={() => {
        onRequestClose && onRequestClose()
      }}
      height={200}
      style={{ container: tw`px-3 bg-fm-background rounded-tl-xl rounded-tr-xl` }}
    >
      <ScrollView style={tw`flex-1 px-4 pt-5`} contentContainerStyle={tw`pb-14`}>
        <Text style={tw`font-bold mb-3 text-lg text-center`}>Elige la cancha</Text>

        <View style={tw`mt-2`}>
          {
            stadium.fields?.map(field =>
              <TouchableOpacity
                key={field.uuid}
                style={tw`rounded-lg border border-gray-200 mb-3 px-4 py-2`}
                onPress={() => {
                  setMatchField(field)
                }}
              >
                <Text style={tw`text-gray-300`}>{field.name}</Text>
              </TouchableOpacity>
            )
          }
          
        </View>
      </ScrollView>
    </BottomSheet>
  );
}
