import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity } from "react-native"


import tw from '../../../styles/tailwind';
import Text from '../../../Components/Text';


import { Image } from "react-native"
import PlayerXPBar from '../../../Components/Gamification/PlayerXPBar';



import { LinearGradient } from "expo-linear-gradient";
import TeamBadge from '../../../Components/Teams/TeamBadge';
import TeamXPBar, { TeamLevel } from '../../../Components/Gamification/TeamXPBar';
import FMIcons from '../../../Components/FMIcon';
import GradientSeparator from '../../../Components/GradientSeparator';
import { useSelector } from 'react-redux';
import ClockAnimation from '../../../Components/Gamification/ClockAnimation';

import useFMLeagueEndsAt from '../../Gamification/useFMLeagueEndsAt'

function FMLeagueInfo({ leagueTeam }){
  const fm_league = useSelector(state => state.status.fm_league)
  
  const { timeStr, timeSeconds } = useFMLeagueEndsAt()

  return (
    <View style={tw`bg-black/20 px-2 py-2 rounded-lg mx-2`}>
      <Text style={tw`text-white text-center font-600`}>TEMPORADA FUT MASTER</Text>
      <GradientSeparator style={tw`mt-1`} />

      <View style={tw`row`}>
        {/* Master points */}
        <View style={tw`row flex-1 justify-center border-r border-gray-200`}>
          <FMIcons name={"masterpoints"} size={28} color={tw.color(`primary`)} />
          <Text style={tw.style(`font-600 text-white ml-3`, { fontSize: 30 })}>{leagueTeam.fm_league?.trophies ?? 0}</Text>
        </View>


        {/* Escudo equipo / Posicion */}
        <View style={tw`row flex-1 justify-center`}>
          <FMIcons name={"posiciones"} size={28} color={tw.color(`gray-200`)} />
          <TeamBadge width={26} height={26} style={tw`mx-2`} />
          <Text style={tw.style(`font-600 text-white`, { fontSize: 30 })}>{leagueTeam.fm_league?.rank ?? '-'}</Text>
        </View>
      </View>

      <Text style={tw`text-gray-200 text-center px-5 mt-2`}>Los <Text style={tw`text-primary`}>Trofeos de Temporada</Text> se reinician al final de la temporada</Text>

      {/* Termina en x tiempo */}
      <View style={tw`row mt-3 justify-center py-[6px]`}>
        <LinearGradient
          colors={['#00000000', "#00000077", "#00000099", "#00000077", "#00000000"]}
          start={{ x: 0, y: 0.5 }}
          end={{ x: 1, y: 0.5 }}
          style={tw`absolute right-0 top-0 left-0 bottom-0`}
        />

        {
          timeSeconds >= 0 ?
            <>
              <ClockAnimation size={22} />
              <Text style={tw`text-primary ml-2`}>Termina en</Text>
              <Text style={tw`text-white ml-2`}>{timeStr}</Text>
            </>
          :
            <Text style={tw`text-primary ml-2`}>Temporada Finalizada</Text>
        }
      </View>
    </View>
  )
}


export default function PlayerTeamLevels({ leagueTeam, style }:{leagueTeam:LeagueTeam}) {
  const teamXP = leagueTeam.gamify?.xp ?? 0
  const teamLevel = leagueTeam.gamify?.level?.level ?? 1

  const fm_league = useSelector(state => state.status.fm_league)
  const currentLevel = fm_league?.levels?.team?.find(e => e.level === teamLevel)


  return (
    <View style={tw.style(`rounded-lg overflow-hidden  pt-4 pb-2`, style)}>
      <LinearGradient
        colors={['#52374F', "#996698"]}
        start={{ x: 0, y: 1 }}
        end={{ x: 1, y: 0 }}
        style={tw`absolute right-0 top-0 left-0 bottom-0`}
      />

      <View style={tw`px-5 mb-3`}>
        <View style={tw`row mb-4`}>
          <TeamLevel level={teamLevel} size={80} />

          <View style={tw`ml-3`}>
            <Text style={tw`text-white font-600 text-md -mt-1 mb-1`}>NIVEL {teamLevel}</Text>

            <View style={tw`row`}>
              <TeamBadge team={leagueTeam.team} width={40} height={40} />

              <View style={tw`ml-1`}>
                <Text style={tw`text-white text-lg`}>{leagueTeam.name}</Text>
                {/* <Text style={tw`text-gray-200`}>11 jugadores</Text> */}
              </View>
            </View>
          </View>
        </View>

        {/*  */}
        <TeamXPBar current={teamXP} highLimit={currentLevel?.range_high} />

        {/* Foto liga - nombre - posicion */}
        <View style={tw`row mb-3 mt-5`}>
          <Image source={{ uri: leagueTeam.league.image_url }} style={tw`h-7 w-7 rounded-lg`} />
              
          <Text style={tw`text-white ml-2`}>{leagueTeam.league.name}</Text>
          <Text style={tw`text-gray-200 ml-2`}>• Posición <Text style={tw`text-white`}>{leagueTeam?.stats?.position}</Text></Text>
        </View>

        {/* Cancha - dias de juego */}
        <View style={tw`row`}>
          <FMIcons name={"cancha"} size={25} color={tw.color('gray-200')} style={tw`mr-1`} />
          <Text style={tw`text-white ml-2`}>{leagueTeam.league.stadium?.name}</Text>
          <Text style={tw`text-gray-200 ml-2`}>• {leagueTeam.league.match_days_str}</Text>
        </View>

      </View>

      {/*  */}
      <FMLeagueInfo leagueTeam={leagueTeam}/>
    </View>
  )
}

