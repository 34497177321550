import React, { useEffect, useState } from "react";
import { ScrollView, View, Image, TouchableOpacity } from "react-native";
import { StatusBar } from "expo-status-bar";

import Text from '../../Components/Text';
import tw from "../../styles/tailwind";

import { SafeAreaView } from "react-native-safe-area-context";

import GradientSeparator from "../../Components/GradientSeparator";

import FMTeamsLeaderboard from "./FMTeamsLeaderboard";
import FMGoalsLeaderboard from "./FMGoalsLeaderboard";

import TimeBg from './assets/header_time_bg.svg'
import FMIcons from "../../Components/FMIcon";
import ClockAnimation from "../../Components/Gamification/ClockAnimation";
import useFMLeagueEndsAt from "../Gamification/useFMLeagueEndsAt";
import api from "../../utils/api";
import { useSelector } from "react-redux";


export default function FMLeagueScreen({ navigation }) {
  const [FMLeague, setFMLeague] = useState();
  const {timeStr, timeSeconds} = useFMLeagueEndsAt()

  useEffect(() => {
    loadFMLeague();
  }, []);

  function loadFMLeague() {
    api.get(`/gamification/fm-league`)
      .then(({ data }) => {
        setFMLeague(data)
      })
      .catch(({ response }) => {

      })
  }

  useEffect(() => {
    navigation.setOptions({
      headerShown: false,
    })
  }, []);


  return (
    <ScrollView>
      {/* <StatusBar  style="auto" /> */}
      <SafeAreaView style={tw`pb-20`}>

        {/* Header */}
        <Image source={{ uri: "https://cdn.futmaster.app/gamification/fm_league/banner-T2.png"}} style={tw`absolute h-[300px] w-full`} />

        {
          timeSeconds >= 60 &&
            <View>
              {/* Termina en: */}
              <View style={tw`h-[280px]`}>
                <View style={tw`absolute bottom-0 left-0 right-0 items-center`}>
                  <TimeBg height={50} width={300} style={tw`absolute top-0`}/>

                  <View style={tw`row`}>
                    <ClockAnimation size={30} />
                    
                    <View style={tw`ml-3`}>
                      <Text style={tw.style(`text-white text-sm mb-1`)}>Termina en</Text>
                      <Text style={tw.style(`text-white font-bold -mt-[12px] -mb-[3px]`, { fontSize: 28 })}>{timeStr}</Text>
                    </View>
                  </View>
                  
                </View>
              </View>

              {/*  */}
              <View style={tw`px-3 py-3`}>
                <Text style={tw.style(`text-white text-center`, { fontSize: 20 })}>¡Ya está aquí la <Text style={tw`text-yellow-200`}>Temporada 2</Text> <Text style={tw`text-primary`}>Fut Master</Text>!</Text>

                {/* Patrocinadores */}
                {/* <View style={tw`row justify-around mt-2`}>
                  <Image source={require('./assets/powerade.png')} style={tw`h-10 w-30`} resizeMode={"contain"} />
                  <Image source={require('./assets/ws7.png')} style={tw`h-15 w-15`} resizeMode={"contain"} />
                </View> */}

                <GradientSeparator />

                <Text style={tw`text-yellow-200 text-center px-3`}>Juega tus partidos con tu equipo, acumula <FMIcons name="masterpoints" size={18} color={"#F4E0A4"} /> <Text style={tw`text-primary`}>Trofeos de Temporada</Text> y recibe premios por tu esfuerzo</Text>

                {/* Tabla explicación puntos */}
                <View style={tw`bg-gray-400 mx-3 py-3 pl-5 pr-3 rounded-lg mt-3`}>
                  <View style={tw`row justify-between mb-2`}>
                    <Text style={tw`text-white`}>Partido Jugado</Text>

                    <View style={tw`row`}>
                      <FMIcons name="masterpoints" size={18} color={"#F4E0A4"} />
                      <Text style={tw`text-yellow-200 w-7 ml-2`}>+5</Text>
                    </View>
                  </View>

                  <View style={tw`row justify-between mb-2`}>
                    <Text style={tw`text-white`}>Partido ganado</Text>

                    <View style={tw`row`}>
                      <FMIcons name="masterpoints" size={18} color={"#F4E0A4"} />
                      <Text style={tw`text-yellow-200 w-7 ml-2`}>+10</Text>
                    </View>
                  </View>

                  <View style={tw`row justify-between`}>
                    <Text style={tw`text-white`}>Partido perdido</Text>

                    <View style={tw`row`}>
                      <FMIcons name="masterpoints" size={18} color={"#F4E0A4"} />
                      <Text style={tw`text-yellow-200 w-7 ml-2`}>-10</Text>
                    </View>
                  </View>
                </View>

                {/* <View style={tw`row mx-10 items-center`}>
                  <FMIcons name="masterpoints" size={18} color={"#F4E0A4"} />

                  <Text style={tw`text-yellow-200`}>Los <Text>MasterPoints</Text> se reinician al final de la temporada</Text>
                </View> */}
              </View>




              <FMTeamsLeaderboard teamsRanking={FMLeague?.teams} />

              <View style={tw`mt-7`}>
                <FMGoalsLeaderboard playersRanking={FMLeague?.scorers} />
              </View>
            </View>
        }


        {/* Pantalla torneo terminado  bg-red-200 */}
        {
          timeSeconds < 60 &&
            <View style={tw`flex-1`}>
              <View style={tw`h-[240px]`} />

              {/* Patrocinadores */}
              <View style={tw`row justify-around mt-2`}>
                <Image source={require('./assets/powerade.png')} style={tw`h-10 w-30`} resizeMode={"contain"} />
                <Image source={require('./assets/ws7.png')} style={tw`h-15 w-15`} resizeMode={"contain"} />
              </View>

              {/* Texto */}
              <View style={tw`px-4 mt-6 mb-0`}>
                <Text style={tw.style(`text-white text-center`, { fontSize: 20 })}>Ha finalizado la <Text style={tw`text-yellow-200`}>Temporada 1</Text> <Text style={tw`text-primary`}>Fut Master</Text></Text>
                <Text style={tw.style(`text-white text-center mt-2`, { fontSize: 18 })}>¡Muchas felicidades a todxs por su esfuerzo!</Text>
              </View>

              {/* Siguiente temporada empieza en: */}
              <View style={tw`mt-0 pb-3`}>
                {/* <GradientSeparator /> */}

                <Text style={tw.style(`text-white text-center mb-4 mt-3`, { fontSize: 18 })}>Ahora a disfrutar de un merecido descanso, la <Text style={tw`text-yellow-200`}>Temporada 2</Text> <Text style={tw`text-primary`}>Fut Master</Text> comienza en:</Text>

                <NextSeasonTimer />
              </View>

              {/* Imagen final */}
              <Image
                source={{ uri: "https://cdn.futmaster.app/gamification/fm_league/T1_final.png"}}
                style={tw`self-center w-[380px] h-[780px] flex-1`}
                resizeMode="contain"
              />

              <Text style={tw.style(`text-gray-200 text-center mb-2 mt-5 px-10`, { fontSize: 12 })}>Estaremos notificando a los ganadores acerca de la entrega de sus premios.</Text>

            </View>
        }

      </SafeAreaView>
    </ScrollView>
  );
}


function NextSeasonTimer(){
  const fm_league = useSelector(state => state.status.fm_league)
  const startsAt = new Date(fm_league?.starts_at)
  // const startsAt = new Date("2023-01-06T00:00:00.000000Z")
  const now = new Date
  const diffInSecs = (startsAt - now) / 1000

  const daysSecs = 24 * 60 * 60
  const days = Math.floor(diffInSecs / daysSecs)
  const hoursLeft = ((diffInSecs / daysSecs) - days) * 24
  const hours = Math.floor(hoursLeft)
  const minutes = Math.floor((hoursLeft - hours) * 60)

  const timerStr = `${days} días`
  // const timerStr = `${days}${short ? 'd' : ' días'} ${hours} hrs`

  return (
    <View style={tw`h-[50px] items-center`}>
      <TimeBg height={50} width={290} style={tw`absolute top-0`} />

      <View style={tw`row items-center mt-[9px]`}>
        <ClockAnimation size={30} />

        <View style={tw`ml-3 mt-[5px]`}>
          <Text style={tw.style(`text-white font-bold -mt-[12px] -mb-[3px]`, { fontSize: 28 })}>{timerStr}</Text>
        </View>
      </View>
    </View>
  )
}


// const LeagueFinalImg = ({  desiredWidth }) => {
//   const uri = 
//   const [desiredHeight, setDesiredHeight] = React.useState(0)

//   Image.getSize(uri, (width, height) => {
//     setDesiredHeight(desiredWidth / width * height)
//   })

//   return (
//     <Image
//       source={{ uri }}
//       style={{
//         borderWidth: 1,
//         width: desiredWidth,
//         height: desiredHeight
//       }}
//     />
//   )
// }