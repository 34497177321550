import React, { useEffect, useRef, useState } from "react";

import { theme } from "../styles/common.style";


import { useSelector, useDispatch } from "react-redux";
import { updateStatus } from "../store/Reducers/status";



// Implementacion del navigator, este se puede customizar si se desea otro tipo de stack
// http...
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { NavigationContainer, DefaultTheme, useNavigationContainerRef } from "@react-navigation/native";
const Stack = createNativeStackNavigator();

import * as Analytics from 'expo-firebase-analytics';



// Configuración de las URL
// https://reactnavigation.org/docs/deep-linking/
import * as Linking from "expo-linking";
const prefix = Linking.createURL("/");



const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    // primary: 'rgb(255, 45, 85)',
    background: theme.BACKGROUND_COLOR,
    border: "transparent",
  },
};



// Import de las Pantallas

/*Stack de auth */
import LoginScreen from './Auth/LoginScreen'
import SingupScreen from "./Auth/SignupScreen";

/*Rutas públicas */
import Giveaway from './Giveaway'
import PollScreen from "./Giveaway/PollScreen";
import Index from "./Index";


/*Rutas de Admin*/
import AdminTeamDetail from './Admin/Teams/Team.Detail';
import AdminNewPlayerScreen from './Admin/Teams/Players/NewPlayerFormScreen';
import AdminPlayerDetailScreen from './Admin/Teams/Players/PlayerDetailScreen';
import AssociatePlayerToTeamScreen from './Admin/Teams/Players/AssociatePlayerToTeamScreen';

import AdminMatchDetail from './Admin/Matches/Match.Detail';
import ArbitrationCertificate from './Admin/Matches/Match.ArbitrationCertificate';
import ArbitrationTablet from './Admin/Matches/Match.ArbitrationTablet';

import AdminStageNewTable from './Admin/Leagues/Stages/NewTableScreen';
import AdminRefereesScreen from "./Admin/Admin.RefereesScreen";


/*Rutas Ligas*/
import AdminLeagueHome from './Admin/Leagues/League.Home';
import AdminLeagueNew from './Admin/Leagues/League.New';



//---------- Jugadores

import PlayerHome from './Player/Player.Home';
import PlayerNew from './Player/Player.New';

// Ligas
import LeagueTeamHome from './Team/LeagueTeam.Home';
import LeagueHome from './League/League.Home';

// Partidos
import MatchDetail from './Match/Match.Home';

import ToSPrivacy from './ToS/PrivacyScreen'
import SupportPage from './Support/SupportScreen'





/*Tab Navigator*/
import AdminStack from "./AdminStack";
import PlayerStack from "./PlayerStack";

//
import InvitationsScreen from "./Invitations";



// Nombres de ruta de react Navigation https://reactnavigation.org/docs/configuring-links
const screensNameConfig = {
  screens: {
    "Index": '',

    "Giveaway": 'giveaway',
    "Giveaway.Poll": 'giveaway/encuesta',

    "Invitation.Index": 'invite/:token',
    
    "Auth.Login": "auth/login",
    "Auth.ResetPassword": "auth/reset-password",
    
    "Auth.Signup": "auth/singup",

    "Admin.Home": {
      screens: {
        "Home": "admin"
      }
    },
    "Admin.TeamDetail": "admin/ligas/:leagueUid/equipos/:teamUid",
    // adminMatch
    "Admin.MatchDetail": "admin/partidos/:matchId",
    "Admin.ArbitrationCertificate": "admin/partidos/captura-cedula-arbitral",
    "Admin.ArbitrationTablet": "admin/partidos/tablet",
    "Admin.League.Home": "admin/ligas/:leagueUid",
    "Admin.League.New": "admin/ligas/crear",

    "Admin.Stage.NewTable": "admin/ligas/:leagueUid/stages/crear-tabla",

    "Admin.Player.New": "admin/ligas/:leagueUid/equipos/:teamUid/agregar-jugador",


    "League.Home": "liga/:leagueId/:selectedTab?",
    "LeagueTeam.Home": "liga/:leagueId/equipo/:teamId",

    "Match.Detail": "partido/:matchId",

    "Player.New": "jugador/nuevo-jugador",


    "Player.Detail": "jugador/:playerId",


    "ToS.Privacy": 'privacidad',
    "Support.Index": 'soporte',

  },
};

import { OverflowMenuProvider } from 'react-navigation-header-buttons';

import { Platform, View } from "react-native";
import tw from "../styles/tailwind";
import GuestIndexScreen from "./Index/GuestIndex";
import ResetPasswordScreen from "./Auth/ResetPasswordScreen";

function WebWindow({children}){
  if(Platform.OS != "web") return children

  return (
    <View style={tw`bg-background flex-1 lg:items-center`}>
      <View style={tw`flex-1 lg:w-[600px]`}>
        {children}
      </View>
    </View>
  )

}


// Get the current screen from the navigation state
function getActiveRouteName(navigationState) {
  if (!navigationState) return null;
  const route = navigationState.routes[navigationState.index];
  // Parse the nested navigators
  if (route.routes) return getActiveRouteName(route);
  return route.routeName;
}



export default function Routes() {
  const auth = useSelector((state) => state.auth);
  const status = useSelector((state) => state.status);
  const dispatch = useDispatch()

  // para conectar los analytics
  const navigationRef = useNavigationContainerRef();
  const routeNameRef = useRef();

  const linking = {
    prefixes: [prefix],
    config: screensNameConfig,
  };

  // Despachamos la accion para actualizar el status de la app
  useEffect(() => {
    dispatch(updateStatus())
  }, [auth.isLogged]);


  return (
    <NavigationContainer
      linking={linking}
      fallback={<View style={{ flex: 1, backgroundColor: MyTheme.colors.background}}></View>}
      theme={MyTheme}

      // conexion de analitics
      // unamezcla de https://reactnavigation.org/docs/screen-tracking/
      // con https://docs.expo.dev/versions/latest/sdk/firebase-analytics/#react-navigation
      ref={navigationRef}
      onReady={() => {
        routeNameRef.current = navigationRef.getCurrentRoute()?.name;
      }}
      onStateChange={async () => {
        // En web evitamos esto
        // if(Platform.OS == "web") return

        const previousRouteName = routeNameRef.current;
        const currentRouteName = navigationRef.getCurrentRoute()?.name;

        if (previousRouteName !== currentRouteName) {
          await Analytics.logEvent('screen_view', {
            screen: currentRouteName,
            prevScreen: previousRouteName,
            // params: JSON.stringify(navigationRef.getCurrentRoute()?.params),
            // path: navigationRef.getCurrentRoute()?.path,
          });
        }

        // Save the current route name for later comparison
        routeNameRef.current = currentRouteName;
        // console.log("SCREEN_CHANGE", { currentRouteName, previousRouteName, cRoute: navigationRef.getCurrentRoute() });
      }}
    >
      <OverflowMenuProvider>
        <WebWindow>
          <Stack.Navigator
            screenOptions={{
              headerStyle: {
                backgroundColor: MyTheme.colors.background,
              },
              headerTintColor: 'white',
            }}
            // onNavigationStateChange={(prevState, currentState) => {
              
            // }}
          >
            {auth.isLogged ? (
              <>
                {/*  */}
                <Stack.Screen
                  name="Index"
                  component={PlayerStack}
                  options={{ headerShown: false }}
                />

                {/*

                  Pantallas Admin

                **/}
                <Stack.Screen
                  name="Admin.Home"
                  component={AdminStack}
                  options={{ title: "Administrador", headerShown: false }}
                />

                <Stack.Screen
                  name="Admin.RefereesScreen"
                  component={AdminRefereesScreen}
                  options={{ headerShown: false }}
                />

                <Stack.Screen
                  name="Admin.TeamDetail"
                  component={AdminTeamDetail}
                  options={{ title: "" }}
                />

                {/* Partido */}
                <Stack.Screen
                  name="Admin.MatchDetail"
                  component={AdminMatchDetail}
                  options={{ title: "" }}
                />

                <Stack.Screen
                  name="Admin.ArbitrationCertificate"
                  component={ArbitrationCertificate}
                  options={{ title: "Captura de Cédula Arbitral" }}
                />
                <Stack.Screen
                  name="Admin.ArbitrationTablet"
                  component={ArbitrationTablet}
                  options={{ headerShown: false }}
                />


                <Stack.Screen
                  name="Admin.Stage.NewTable"
                  component={AdminStageNewTable}
                  options={{ title: "Nueva Tabla" }}
                />

                <Stack.Screen
                  name="Admin.Player.New"
                  component={AdminNewPlayerScreen}
                />

                <Stack.Screen
                  name="Admin.Player.Detail"
                  component={AdminPlayerDetailScreen}
                />

                <Stack.Screen
                  name="Admin.Player.Associate"
                  component={AssociatePlayerToTeamScreen}
                  options={{ presentation: 'modal'}}
                />

                {/* League */}
                <Stack.Screen
                  name="Admin.League.Home"
                  component={AdminLeagueHome}
                  options={{ title: "" }}
                />

                <Stack.Screen
                  name="Admin.League.New"
                  component={AdminLeagueNew}
                  options={{ title: "Nuevo Torneo" }}
                />





                {/*

                  Pantallas Jugador

                **/}

                {/*
                  Mi perfil fantasía
                */}
                <Stack.Screen
                  name="Player.New"
                  component={PlayerNew}
                  options={{ title: "Nuevo Jugador" }}
                />



              </>
            ) : (
              <>
                {/* El stack de Auth */}
                <Stack.Screen
                  name="Auth.Login"
                  component={LoginScreen}
                    options={{ title: "Iniciar sesión", headerShown: false }}
                />

                <Stack.Screen
                  name="Auth.Signup"
                  component={SingupScreen}
                  options={{ title: "Registrarse" }}
                />

                <Stack.Screen
                  name="Auth.ResetPassword"
                  component={ResetPasswordScreen}
                  options={{ title: "Generar contraseña", headerShown: false }}
                />
              </>
            )}



            {/* Acá se declaran las rutas públicas */}

            {
              (Platform.OS === "web" && ! auth.isLogged)   &&
                <Stack.Screen
                  name="Index"
                  component={GuestIndexScreen}
                  options={{ headerShown: false }}
                />
            }

            <Stack.Screen
              name="Giveaway"
              component={Giveaway}
              options={{ title: "Gana unos Tenis con FutMaster", headerShown: false }}
            />

            <Stack.Screen
              name="Giveaway.Poll"
              component={PollScreen}
              options={{ title: "Gana unos Tenis con FutMaster", headerShown: false }}
            />



            {/* League */}
            <Stack.Screen
              name="League.Home"
              component={LeagueHome}
              options={{ title: "Nuevo Jugador", headerShown: false }}
            />


            <Stack.Screen
              name="LeagueTeam.Home"
              component={LeagueTeamHome}
              options={{ title: "Equipos" }}
            />

            {/* Partido */}
            <Stack.Screen
              name="Match.Detail"
              component={MatchDetail}
              options={{ headerShown: false }}
            />



            <Stack.Screen
              name="Player.Detail"
              component={PlayerHome}
              options={{ title: "Jugador" }}
            />

            <Stack.Screen
              name="Invitation.Index"
              component={InvitationsScreen}
              options={{ headerShown: false }}
            />

            <Stack.Screen
              name="ToS.Privacy"
              component={ToSPrivacy}
              options={{ title: "Privacidad" }}
            />

            <Stack.Screen
              name="Support.Index"
              component={SupportPage}
              options={{ title: "Soporte" }}
            />

          </Stack.Navigator>
        </WebWindow>

      </OverflowMenuProvider>
    </NavigationContainer>
  );
}
