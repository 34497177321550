import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { View } from "react-native";

import StepIndicator from "../../../Components/StepIndicator";
import Text from "../../../Components/Text";
import RNListSlider from '../../../Components/react-native-list-slider'
import OptionsSelect from "../../../Components/OptionsSelect";
import Button from "../../../Components/Button";

import { Ionicons } from '@expo/vector-icons';
import tw from "../../../styles/tailwind";



export default function NewPlayer_Step3({ onSubmit, handleBack, style }) {
  const { control, handleSubmit, formState, formState: { errors }, setError } = useForm();

  const [weight, setWeight] = useState(40);
  const [height, setHeight] = useState(40);
  const [selectedPosition, setSelectedPosition] = useState();

  const returnStep = () => {
    handleBack()
  }

  const submitStep3 = () => {
    const data = {
      // ...lodelformulario,
      weight,
      height,
    }
    onSubmit(data)
  }

  return (
    <View style={tw.style(``, style?.container)}>

      <StepIndicator
        style={{ container: tw`mb-6` }}
        currentStep={3}
      />

      {/* Espacio para lottie */}
      <View style={tw.style(`bg-gray-100 flex-1 h-32 my-12 rounded-xl self-center`)} />


      {/* Contenedor de selectores de peso y altura */}
      <View style={tw`flex-col mb-8`}>

        {/* Selector peso */}
        <View style={tw`mb-8`}>

          {/* Header titulos  */}
          <View style={tw`flex-row items-center justify-between`}>
            <Text>Peso</Text>

            <View style={tw`flex-row items-center`}>
              <Text style={tw`text-4xl`}>{weight}</Text>
              <Text style={tw``}>kg</Text>
            </View>

            <Text style={tw`self-center`}>i</Text>
          </View>


          {/* Slider */}
          <RNListSlider
            value={weight}
            onValueChange={value => setWeight(value)}
            multiplicity={1}
            arrayLength={130}
          />
        </View>



        {/* Selector altura */}
        <View style={tw``}>
          {/* Header titulos  */}
          <View style={tw`flex-row items-center justify-between`}>
            <Text>Altura</Text>

            <View style={tw`flex-row items-center`}>
              <Text style={tw`text-4xl`}>{(height / 100).toFixed(2)}</Text>
              <Text style={tw``}>m</Text>
            </View>

            <Text style={tw`self-center`}>i</Text>
          </View>


          {/* Slider */}
          <RNListSlider
            value={height}
            onValueChange={value => setHeight(value)}
            multiplicity={1}
            arrayLength={220}
          />
        </View>

      </View>


      {/* Selector de estilo de p */}
      <OptionsSelect
        style={{ container: tw`mb-12` }}
        control={control}
        name="kick"
        options={[
          {
            label: <View style={tw`items-center `}>
              <Ionicons name="return-up-back" size={35} color="gray" />
              <Text style={tw.style(`text-primary mt-3 text-xs`)}>Izquierda</Text>
            </View>, value: "left"
          },
          {
            label: <View style={tw`items-center `}>
              <Ionicons name="swap-horizontal" size={35} color="gray" />
              <Text style={tw.style(`text-primary mt-3 text-xs`)}>Ambos</Text>
            </View>, value: "both"
          },
          {
            label: <View style={tw`items-center `}>
              <Ionicons name="return-up-forward" size={35} color="gray" />
              <Text style={tw.style(`text-primary mt-3 text-xs`)}>Derecha</Text>
            </View>, value: "right"
          },
        ]}
      />

      {/* Botones */}
      <View style={tw`flex-row justify-around`}>
        <Button
          label={"Anterior"}
          onPress={() => { returnStep() }}
          style={{ container: tw`px-8  py-3 bg-black-100`, label: tw`text-white` }}

        />

        <Button
          label={"Siguiente"}
          onPress={(handleSubmit(submitStep3))}
          style={{ container: tw`px-8  py-3` }}
        />
      </View>
    </View>
  )
}
