import 'expo-dev-client';

import React, { useEffect, useState } from "react";
import Routes from "./Screens/Routes";
import * as SplashScreen from 'expo-splash-screen';
import { ActionSheetProvider } from "@expo/react-native-action-sheet";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { LogBox, Platform, View } from "react-native";
import * as Application from 'expo-application';
import { ReduxNetworkProvider } from 'react-native-offline';

import * as Notifications from 'expo-notifications';
import * as Linking from 'expo-linking';

import * as serviceWorkerRegistration from "./web/serviceWorkerRegistration";

//-------------------------Configuración redux-------------------------------------------//

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import configureStore from "./store/configureStore";

const { store, persistor } = configureStore();
import {actions as auth} from "./store/Reducers/auth"

//--------------------------------------------------------------------------------------//

LogBox.ignoreLogs(['ViewPropTypes will be removed from React Native'])


//-------------------------Configuración de API-------------------------------------------//
import api from "./utils/api";

api.defaults.baseURL = process.env.API_URL

console.log(process.env.API_URL)
console.log(process.env.API_URL)
console.log(process.env.API_URL)

// Para inyectar el token en las peticiones
api.interceptors.request.use( config => {
  const { auth } = store.getState()

  config.headers = {
    'Accept': 'application/json',
  }
  if (auth.isLogged) {
    config.headers.Authorization = `Bearer ${auth.token}`
  }

  return config;
}, error => {
  return Promise.reject(error);
});

import axios from "axios";

// Response interceptor
api.interceptors.response.use(response => {
  // Cualquier http 2xx
  return response;
}, function (error) {
  // API_GLOBAL_ERROR_TOAST
  if (error?.response?.status === 401){
    if (error.response.data?.message == "Unauthenticated."){
      // disph
      store.dispatch(auth.logout())
    }

    // toast.show(error.response.data?.message, {
    //   type: "warning",
    // })
  }

  if (! axios.isCancel(error) && error && !error.response) {
    console.log(error)
    toast.show("Error de red", {
      type: "warning",
    })
  }

  if (error?.response?.status >= 500) {
    toast.show("Error en nuestros servidores :(. Intenta de nuevo.", {
      type: "warning",
    })
  }

  return Promise.reject(error);
})
//--------------------------------------------------------------------------------------//



import tw from "./styles/tailwind";
import { useDeviceContext } from 'twrnc'

import {
  useFonts,
  WorkSans_100Thin,
  WorkSans_200ExtraLight,
  WorkSans_300Light,
  WorkSans_400Regular,
  WorkSans_500Medium,
  WorkSans_600SemiBold,
  WorkSans_700Bold,
  WorkSans_800ExtraBold,
  WorkSans_900Black,
  // WorkSans_100Thin_Italic,
  // WorkSans_200ExtraLight_Italic,
  // WorkSans_300Light_Italic,
  // WorkSans_400Regular_Italic,
  // WorkSans_500Medium_Italic,
  // WorkSans_600SemiBold_Italic,
  // WorkSans_700Bold_Italic,
  // WorkSans_800ExtraBold_Italic,
  // WorkSans_900Black_Italic,
} from '@expo-google-fonts/work-sans';

import { StatusBar } from "expo-status-bar";


// OTA_AUTOMATIC_RELOAD 
import * as Updates from 'expo-updates';

// GLOBAL_TOAST
import Toast, { ToastProvider } from 'react-native-toast-notifications'
import ToastComponent from "./Components/Toast";



export default function App() {
  useDeviceContext(tw);

  let [fontsLoaded] = useFonts({
    WorkSans_100Thin,
    WorkSans_200ExtraLight,
    WorkSans_300Light,
    WorkSans_400Regular,
    WorkSans_500Medium,
    WorkSans_600SemiBold,
    WorkSans_700Bold,
    WorkSans_800ExtraBold,
    WorkSans_900Black,
    // WorkSans_100Thin_Italic,
    // WorkSans_200ExtraLight_Italic,
    // WorkSans_300Light_Italic,
    // WorkSans_400Regular_Italic,
    // WorkSans_500Medium_Italic,
    // WorkSans_600SemiBold_Italic,
    // WorkSans_700Bold_Italic,
    // WorkSans_800ExtraBold_Italic,
    // WorkSans_900Black_Italic,
    IcoMoon: require('./assets/icomoon/fonts/icomoon.ttf'),
    AgencyFB : require('./assets/fonts/agencyfb.ttf'),
  });

  // OTA_AUTOMATIC_RELOAD v1
  async function checkAppReload() {
    try {
      const update = await Updates.checkForUpdateAsync();
      
      if (update.isAvailable) {
        await Updates.fetchUpdateAsync();
        // ... notify user of update ...
        await Updates.reloadAsync()
      }
    } catch (e) {
      // handle or log error
    }
  }

  useEffect(() => {
    checkAppReload()
  }, [])
  ///


  useEffect(() => {
    async function prepare() {
      try {
        // Keep the splash screen visible while we fetch resources
        await SplashScreen.preventAutoHideAsync();
        // Pre-load fonts, make any API calls you need to do here
      } catch (e) {
        // console.warn(e);
      } finally {
        // Tell the application to render
        // setAppIsReady(true);
      }
    }

    prepare();
  }, []);

 
  // TEST: Referrer
  useEffect(() => {
    async function getReferrerInfo() {
      if(Platform.OS !== "android") return

      const referrer = await Application.getInstallReferrerAsync();

      console.log(`[TEST] nativeBuildVersion ${Application.nativeBuildVersion} - nativeApplicationVersion ${Application.nativeApplicationVersion} - referrer ${referrer}`)
    }

    getReferrerInfo()
  }, [])


  useEffect(() => {
    async function hideSplash() {
      await SplashScreen.hideAsync()
    }

    if(fontsLoaded){
      hideSplash()
    }
  }, [fontsLoaded])


  // recibir push notifications
  // esta mejor alg tipo https://medium.com/cybermonkey/deep-linking-push-notifications-with-react-navigation-5fce260ccca2
  useEffect(() => {
    const _handleNotification = notification => {
      console.log("handleNotification", notification.request.content)

      if (notification.request.content?.data?.path){
        const url = Linking.createURL(notification.request.content?.data?.path)
        Linking.openURL(url)
      }
    };

    const _handleNotificationResponse = response => {
      console.log("_handleNotificationResponse", response.notification.request.content)

      if (response.notification.request.content?.data?.path) {
        const url = Linking.createURL(response.notification.request.content.data.path)

        setTimeout(() => {
          Linking.openURL(url)
        }, 800)
      }
    };

    Notifications.addNotificationReceivedListener(_handleNotification);

    Notifications.addNotificationResponseReceivedListener(_handleNotificationResponse);
  }, [])


  function renderLoadingScreen(){
    return (
      <View style={tw`bg-background flex-1`}></View>
    )
  }


  if (!fontsLoaded) return renderLoadingScreen()

  // pingInterval={5000} pingTimeout={5000} pingServerUrl={"https://futmaster.app/"}
  return (
    <Provider store={store}>
      <PersistGate loading={renderLoadingScreen()} persistor={persistor}>
        <GestureHandlerRootView style={{ flex: 1 }}>
          <ReduxNetworkProvider>
            <ActionSheetProvider>
              <ToastProvider offsetTop={80}>
                <StatusBar style="light" />

            
                <Routes />

                <Toast ref={(ref) => global['toast'] = ref} />
              </ToastProvider>
            </ActionSheetProvider>
          </ReduxNetworkProvider>
        </GestureHandlerRootView>
      </PersistGate>
    </Provider>
  );
}


serviceWorkerRegistration.register();
