import React, { useState, useEffect, useRef } from "react";
import { ScrollView, TouchableOpacity, View, } from "react-native";
import { StatusBar } from "expo-status-bar";

import { useForm } from "react-hook-form";

import tw from "../../../styles/tailwind";

import api from '../../../utils/api'

import NewLeague_Step1 from "./League.New.Steps/NewLeague_Step1";
import NewLeague_Step2 from "./League.New.Steps/NewLeague_Step2";
import NewLeague_Step3 from "./League.New.Steps/NewLeague_Step3";

export default function NewLeagueScreen({ route, navigation }) {
  const { control, handleSubmit, formState, formState: { errors }, setError, setValue, watch } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});
  const scrollRef = useRef();

  useEffect(() => {
    navigation.setOptions({

      title: "Nuevo Torneo",
      headerStyle: {
        backgroundColor: '#ebebeb',
      },
      headerTintColor: 'black',
    })
  }, []);

  const stadiumID = route.params?.stadiumId;


  function createLeague(data) {
    setIsSubmitting(true)

    const _data = {
      ...data,
      min_players: data.min_players[0],
      max_players: data.min_players[1],
    }

    console.log("Creating league createLeague", _data)

    api.post(`stadiums/${stadiumID}/league`, _data)
      .then(({ data }) => {
        navigation.navigate("Admin.League.Home", {leagueUid: data.uuid})
        // navigation.navigate("Admin.Home")
      }).catch((data) => {
        console.log("response error", data);
        
        if (data.response.status == 422) {
          console.log("validation errors", data.response.data);
          
          Object.keys(data.response.data.errors).forEach(errorKey => {
            setError(errorKey, { type: "manual", message: data.response.data.errors[errorKey][0] })
          })

          setIsSubmitting(false)
        }
      })
  }

  function scrollToTop(){
    scrollRef.current?.scrollTo({
      y: 0,
      animated: true,
    });
  }

  return (
    <ScrollView ref={scrollRef} style={tw`flex-1 bg-gray pt-5`} >

      {
        step == 1 &&
        <NewLeague_Step1
          control={control} handleSubmit={handleSubmit}
          onSubmit={data => {
            console.log("step1 data", data)

            setFormData({
              ...formData,
              ...data
            })

            setStep(step + 1)

            scrollToTop()
          }} />
      }

      {
        step == 2 &&
        <NewLeague_Step2
          watch={watch}
          control={control}
          handleSubmit={handleSubmit}
          onSubmit={data => {
            console.log("step2 data", data)

            setFormData({
              ...formData,
              ...data
            })

            setStep(step + 1)

            scrollToTop()
          }}

          handleBack={() => {
            setStep(step - 1)

            scrollToTop()
          }} />
      }

      {
        step == 3 &&
        <NewLeague_Step3
          control={control}
          handleSubmit={handleSubmit}
          onSubmit={data => {
            console.log("step3 data", data)

            const _data = {
              ...formData,
              ...data
            }

            createLeague(_data)
          }}

          handleBack={() => {
            setStep(step - 1)
            scrollToTop()
          }}
        />
      }

    </ScrollView>
  );
}

