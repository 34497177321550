import React, { useState, useEffect, useRef } from 'react';
import { View, TouchableOpacity, ScrollView } from "react-native"

import { useForm } from 'react-hook-form';

import Button from '../../../../Components/Button';
import Text from '../../../../Components/Text';
import tw from '../../../../styles/tailwind';
import TeamBadge from '../../../../Components/Teams/TeamBadge';
import api from '../../../../utils/api';
import TabletModal from '../../Matches/TabletComponents/TabletModal';
import Input from '../../../../Components/Input';


export default function ChangeTeamNameModal({ onRequestClose, onNameChanged, visible, leagueTeam, leagueUid }: { leagueTeam: LeagueTeam }) {
  const [isSubmitting, setIsSubmitting] = useState(false);


  const { handleSubmit, watch, errors, setValue, control } = useForm()
  const newName = watch('name')


  useEffect(() => {
    if(visible){
      setValue("name", leagueTeam.name)
      setIsSubmitting(false)
    }
  }, [visible]);


  function changeLeagueTeamName(formData) {
    setIsSubmitting(true)

    const data = {
      ...formData
    }


    api.post(`/leagues/${leagueUid}/teams/${leagueTeam.team.uuid}`, data)
      .then(({ data }) => {
        setIsSubmitting(false)
        console.log("ok");
        onNameChanged?.();
      })
      .catch(({ response }) => {
        console.log(response);
        
        setIsSubmitting(false)
      })
  }




  return (
    <TabletModal
      visible={visible}
      onRequestClose={onRequestClose}
      showCloseButton
    >
      <Text style={tw`font-bold text-xl text-center`}>Cambiar nombre</Text>

      <Input
        control={control}
        name={"name"}
        rules={{ required: "Nombre del equipo requerido" }}
      />

      <Button
        disabled={isSubmitting || (newName == leagueTeam.name)}
        label={isSubmitting ? "Cambiando..." : "Cambiar"}
        onPress={handleSubmit(changeLeagueTeamName)}
        style={{ container: tw`mt-0` }}
      />
    </TabletModal>
  )
}

