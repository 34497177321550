import React, { useEffect, useState } from "react";
import { View, ScrollView, TouchableOpacity, Image } from "react-native";
import Text from "../../Components/Text";

import tw from '../../styles/tailwind';

import * as Linking from 'expo-linking';
import api from "../../utils/api";
import Loading from "../../Components/Loading";
import TeamBadge from "../../Components/Teams/TeamBadge";
import Button from "../../Components/Button";
import FMIcons from "../../Components/FMIcon";


const getMobileOS = () => {
  const ua = navigator?.userAgent
  if (/android/i.test(ua)) {
    return "Android"
  }
  else if ((/iPad|iPhone|iPod/.test(ua))
     || (navigator?.platform === 'MacIntel' && navigator?.maxTouchPoints > 1)){
    return "iOS"
  }
  return "Other"
}


export default function InvitationScreenWeb({ route, navigation }) {
  const [invitation, setInvitation] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [invalidInvitation, setInvalidInvitation] = useState(false);

  const token = route.params?.token;

  useEffect(() => {
    api.get(`/invitation/${token}`)
      .then(({ data }) => {
        setisLoading(false)

        setInvitation(data)
      })
      .catch(error => {
        // console.log("api error", error)
        // navigation.navigate("Home")
      })
  }, [token]);



  function goToAppStore(){
    const appUrl = {
      ios: "https://apps.apple.com/us/app/futmaster/id1623310602",
      android: "https://play.google.com/store/apps/details?id=app.futmaster",
    }

    if (getMobileOS() === "Android"){
      // Linking.openURL(appUrl.android)
      window.open(appUrl.android, '_blank');
    }

    if (getMobileOS() === "iOS"){
      Linking.openURL(appUrl.ios)
      // window.open(appUrl.ios, '_blank');
    }
  }

  function openAppLink(){
    // No sería mejor traer esto del server?
    Linking.openURL(`futmaster://${route.path}`)
  }

  return (
    <ScrollView style={tw`pt-4 bg-white/30 flex-1 px-3`}>
      <Loading visible={isLoading} />


      {
        invitation  &&
          <View style={tw``}>
            {/* logo FM */}
            <Image
              source={require("../../assets/logo_black.png")}
              style={tw.style(`h-20 mb-8`, {tintColor: tw.color('primary')})}
              resizeMode="contain"
            />

            <Text style={tw`text-white font-bold text-center mb-6 text-xl`}>Bienvenido <Text style={tw`text-primary font-bold`}>{invitation.player_name}</Text>,{"\n"}a partir de hoy</Text>

            <Image
              source={require("./vive_fut.svg")}
              style={tw.style(`h-30 mb-12`, { tintColor: tw.color('primary') })}
              resizeMode="contain"
            />


            {/*  */}
            <View style={tw`bg-gray-800/75 rounded-xl px-5 py-6 mb-3`}>

              <View style={tw`items-center mb-5 row px-4 items-center justify-center`}>
                <TeamBadge team={invitation.league_team.team} width={50} height={50} />
                <Text style={tw`ml-2 text-white text-lg font-bold`}>{invitation.league_team.name}</Text>
              </View>

              <Text style={tw`mb-10 text-white text-center font-bold`}>Te ha invitado a formar parte de su equipo</Text>


              {/* Paso 1 */}
              <Text style={tw`mb-4 text-white text-center font-bold`}><Text style={tw`text-primary`}>Paso 1: </Text>Descarga la aplicación</Text>

              <TouchableOpacity
                onPress={goToAppStore}
                style={tw`bg-primary row justify-between px-3 py-2 rounded-lg`}
              >
                <Text>Instala FutMaster{"\n"}para {getMobileOS() === "iOS" ? "iOS" : "Android"}</Text>

                <View>
                  <FMIcons name="fm" size={30} />
                </View>
              </TouchableOpacity>



              {/* Paso 2 */}
              <Text style={tw`mt-6 mb-4 text-white text-center font-bold`}><Text style={tw`text-primary`}>Paso 2: </Text>Acepta la invitación</Text>

              <Button label="Aceptar invitación" onPress={openAppLink} style={{ container: tw` bg-primary`}} />
            </View>
          </View>
      }
    </ScrollView>
  );
}