import React, { useState, useEffect } from 'react';
import { View, Platform, TouchableOpacity, Image } from 'react-native'

import Text from './Text';

import * as ImagePicker from 'expo-image-picker';
import FMIcons from './FMIcon';


import tw from '../styles/tailwind';
import api from '../utils/api';

// https://docs.expo.dev/versions/latest/sdk/imagepicker/
// https://github.com/expo/examples/blob/master/with-aws-storage-upload/App.js
// https://docs.expo.dev/versions/latest/sdk/filesystem/
// https://dev.to/naderalfakesh/uploading-files-in-react-native-470m
// onUploadProgress en axios  https://stackoverflow.com/a/41088162

export default function ImagesPicker(props) {

  const [selectedImage, setSelectedImage] = useState(null);
  const [status, requestPermission] = ImagePicker.useMediaLibraryPermissions();
  const [uploadPercentage, setUploadPercentage] = useState(null);
  const [uploading, setUploading] = useState(false);

  const [imageUrl, setImageUrl] = useState(props.initialUrl);


  // useEffect(() => {
  //   (async () => {
  //     if (Platform.OS !== 'web') {
  //       const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
  //       // const { status } = await ImagePicker.getMediaLibraryPermissionsAsync();

  //       console.log("ImagesPicker requestMediaLibraryPermissionsAsync", {status}) // denied :thingin_face:
  //       // if (status !== 'granted') {
  //       //   alert('Necesitamos el permiso a la galería!');
  //       // }
  //     }
  //   })();
  // }, []);

  const openImagePickerAsync = async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();


    if (status !== "granted") {
      alert("Necesitamos permiso para acceder a tu galería")
    }

    const result = await ImagePicker.launchImageLibraryAsync({
      // mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      // aspect: [4, 4],
      quality: 1,
      base64: true,
    });

    handleImagePicked(result)
  };

  const cleanImage = () => {
    setSelectedImage(null);
    props.onImageChanged && props.onImageChanged(null)
  }


  const handleImagePicked = async (pickerResult) => {
    try {
      if (pickerResult.cancelled) {
        // alert("Upload cancelled");
        return;
      } else {
        setSelectedImage(pickerResult);
        setUploadPercentage(0);
        // console.log("Paso 1 subir", pickerResult)
        // const img = await fetchImageFromUri(pickerResult.uri);
        const serverImage = await uploadImage(pickerResult);
        // downloadImage(uploadUrl);
      }
    } catch (e) {
      console.log(e);
      alert("Falló la actualización de la imagen, intenta de nuevo.");
    }
  };

  const fetchImageFromUri = async (uri) => {
    const response = await fetch(uri);
    const blob = await response.blob();
    return blob;
  };


  const uploadImage = async (pickerResult) => {
    const formData = new FormData();

    if (Platform.OS === "web") {
      const image = await fetchImageFromUri(pickerResult.uri)

      formData.append('file', image, 'image.jpg');

      // console.log("paso 3 subir file (web", {
      //   name: "image.jpg",
      //   image,
      // })
    } else {
      const localUri = Platform.OS === 'ios' ? pickerResult.uri.replace('file://', '') : pickerResult.uri
      const filename = localUri.split('/').pop();

      // Infer the type of the image
      const match = /\.(\w+)$/.exec(filename);
      const type = match ? `image/${match[1]}` : `image/jpeg`;

      formData.append('file', {
        name: "image.jpg",
        type: type,
        uri: localUri,
      });

      // console.log("paso 3 subir file", {
      //   name: "image.jpg",
      //   type: type,
      //   uri: localUri,
      // })
    }

    formData.append('target', 'Player');




    const config = {
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

        setUploadPercentage(percentCompleted)
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
      timeout: 30000,
    }


    setUploading(true)

    return api.post("/images/upload", formData, config)
      .then((response) => {
        setUploading(true)
        console.log("Upload succeded", response.data);

        setImageUrl(response.data.url)
        setUploading(false)
        setSelectedImage(null)

        props.onImageChanged && props.onImageChanged(response.data)

        return response.data;
      })
      .catch((error) => {
        console.log("Upload error", error);
        setUploading(false)
      });
  };

  //props.image64(selectedImageBase64);

  return (
    <View style={tw.style(`flex items-center`, props.style?.container)}>
      {/* // Imagen en subicion */}
      {
        selectedImage &&
          <View style={tw``}>
            <TouchableOpacity onPress={openImagePickerAsync}
              style={
                tw.style(
                  `flex-row items-center w-26 h-26 overflow-hidden rounded-xl bg-transparent border-2 border-primary rounded-xl  overflow-hidden`,
                  props.style?.buttonWrapperFill,
                )
              }
            >
              <Image
                source={{ uri: selectedImage.uri }}
                style={tw.style(`w-26 h-26`, { resizeMode: "cover" })}
              />

              <Text style={tw`absolute top-0 left-2 text-primary text-xl`}>{uploadPercentage}</Text>

              {/* {
                props.changeable == true ?
                  <View style={tw`items-center ml-12`}>
                    <FMIcons name={"cargar"} size={33} color={"#706967"} />
                    <Text style={tw`text-gray-300 text-md mt-1`}>Cambiar</Text>
                  </View>
                  :
                  null
              } */}
            </TouchableOpacity>

            {props.deletable === false ?
              null
              :
              <TouchableOpacity onPress={cleanImage}
                style={tw`absolute top-0 right-0 rounded-full h-6 w-6 bg-red items-center justify-center`}>
                <FMIcons name={"cerrar"} size={10} color={"#ebebeb"} />
              </TouchableOpacity>

            }
          </View>
      }
      {
        !selectedImage &&
        <TouchableOpacity onPress={openImagePickerAsync}
          style={
            tw.style(
              `flex-col items-center justify-center w-26 h-26 bg-transparent border-2 border-primary rounded-xl overflow-hidden `,
              props.style?.buttonWrapper
            )
          }
        >
          {
            imageUrl &&
              <Image
                source={{uri: imageUrl}}
                style={tw.style(`absolute left-0 top-0 right-0 bottom-0`, { resizeMode: "cover" })}
                // style={tw`absolute left-0 top-0`}
              />
          }

          
          <View style={tw.style(`flex-row absolute bottom-0 left-0 right-0 items-center justify-center bg-black/40 py-2`, props.style?.labelContainer)}>
            {
              props.icon &&
                <FMIcons name={props.icon} color={'#706967'} size={28} style={tw`mr-2`} />
            }
            <Text style={tw.style(`text-gray-300 text-center text-sm`, props.style?.label)}>{props.label}</Text>
          </View>

          {
            props.description &&
              <Text style={tw`text-gray-300 text-center text-sm`}>{props.description}</Text>
          }
        </TouchableOpacity>
      }
    </View>
  )
}