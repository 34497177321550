import React, { useState, useEffect } from "react";
import { ScrollView, View } from "react-native";

import OptionsSelect from "../../../../Components/OptionsSelect";
import Text from "../../../../Components/Text";
import LabelSwitch from "../../../../Components/LabelSwitch";
import Select from "../../../../Components/Select";
import NumberPicker from "../../../../Components/NumberPicker";
import Slider from "../../../../Components/Slider";
import Button from "../../../../Components/Button";

import FMIcons from "../../../../Components/FMIcon";
import tw from "../../../../styles/tailwind";


export default function NewLeague_Step2({ control, handleSubmit, onSubmit, handleBack, watch, style }) {
  const selectedCategory = watch("category")

  const [ageUpperLimit, setAgeUpperLimit] = useState([]);
  const [ageLowerLimit, setAgeLowerLimit] = useState([]);

  const lowerLimit = (upperLimit) => {

    const anio = upperLimit[0].value

    const newanio = []

    for (var i = (anio); i > (anio - 45); i--) {
      newanio.push({ label: `${i}`, value: i })
    }

    setAgeLowerLimit(newanio)

  }

  useEffect(() => {
    const thisYear = (new Date).getFullYear()

    const upperLimit = []

    for (var i = (thisYear - 6); i > (thisYear - 20); i--) {
      upperLimit.push({ label: `${i}`, value: i })
    }

    setAgeUpperLimit(upperLimit)
    lowerLimit(upperLimit)
  }, []);


  const submitStep2 = data => {

    onSubmit(data)
  }

  const returnStep = () => {
    handleBack()
  }

  return (

    <View style={tw.style(`flex-1 px-3 mb-8`, style?.container)}>

      <OptionsSelect
        control={control}
        name={"gender"}
        options={[
          {
            icon: "feminil",
            iconlabel: "Femenil",
            value: "female"
          },
          {
            icon: "varonil",
            iconlabel: "Varonil",
            value: "male"
          },
          {
            icon: "mixto",
            iconlabel: "Mixto",
            value: "mixed"
          },
        ]}
        rules={{ required: true }}
        style={{
          container: tw`mb-5`,
          option: tw`flex-1 py-4 px-4 border-0`,
        }}

      />


      {/* Categoría / límite de edad */}
      <LabelSwitch
        label={"Límite de edad"}
        name="category"
        control={control}
        offValue={"free"}
        onValue={"age_limit"}
        style={{ container: tw`rounded-tr-xl rounded-tl-xl` }}
      />

      {/* Límites de edad */}
      {
        selectedCategory == "age_limit" &&
        <View style={tw`flex-row items-center justify-around pb-3 rounded-br-xl rounded-bl-xl bg-gray-500`}>

          {/* Limite superior */}
          <View style={tw`flex-1 flex-col pl-3 pr-1`}>
            <Text style={tw`ml-1 mb-1 text-gray`}>Superior</Text>
            <View style={tw`flex-1 bg-gray-50 flex-row items-center rounded-xl p-1`}>
              <View style={tw`flex-1`}>
                <Select
                  name="max_age_year"
                  items={ageUpperLimit}
                  placeholder={{ label: "Seleccionar", value: "" }}
                  control={control}
                  style={{ input: { borderWidth: 0, paddingVertical: 8 } }}
                />
              </View>
            </View>
          </View>


          {/* Limite inferior */}
          <View style={tw`flex-1 flex-col pr-3 pl-1`}>
            <Text style={tw`ml-4 mb-1 text-gray`}>Inferior</Text>
            <View style={tw`flex-1 h-14 flex-row items-center bg-gray-50 rounded-xl p-1`}>
              <View style={tw`flex-1`}>
                <Select
                  name="min_age_year"
                  items={ageLowerLimit}
                  placeholder={{ label: "Seleccionar", value: "" }}
                  control={control}
                  style={{ input: { borderWidth: 0, paddingVertical: 8 } }}
                />
              </View>
            </View>
          </View>
        </View>
      }
      

      <Text style={tw.style(`text-xl mt-5 mb-5`)}>Equipos</Text>
      <NumberPicker
        control={control}
        name="max_teams"
        label={"Número de equipos"}
        style={{
          container: tw`mb-8`,
          button: tw`bg-primary rounded-xl w-10 h-10`,
          wrapperButton: tw`flex-1`,
          wrapperIconText: tw`mr-3`,
          number: tw`mx-2`,
          buttonText: tw`text-black text-xl font-normal`,
        }}
        min={3}
        icon={"equipos"}
      />


      {/* Jugadores por equipo / Slider */}
      <View style={tw`mb-6 bg-gray-50 p-2 rounded-xl`}>

        <View style={tw`flex-row items-center mb-3`}>
          <FMIcons name={"jersey"} size={35} color={"#706967"} />
          <Text style={tw.style(`text-md text-gray-500 ml-3`)}>Jugadores por equipo</Text>
        </View>

        <Slider
          min={5}
          max={20}
          initialValue={[5, 14]}
          maxLabel="máximo"
          minLabel="mínimo"
          control={control}
          name="min_players"
        />
      </View>


      {/* Máximo de altas y bajas */}
      <NumberPicker
        control={control}
        name="max_players_substitutions"
        min={0}
        value={5}
        label={"Máximo de altas/bajas"}
        icon={"altas-bajas"}
        style={{
          container: tw`mb-8`,
          button: tw`bg-primary rounded-xl w-10 h-10`,
          wrapperButton: tw`flex-1`,
          wrapperIconText: tw`mr-3`,
          number: tw`mx-2`,
          buttonText: tw`text-black text-xl font-normal`,
        }}
      />


      {/* Botones */}
      <View style={tw`flex-row justify-around`}>
        <Button
          label={"Anterior"}
          onPress={() => { returnStep() }}
          style={{ container: tw`px-8  py-3 bg-secondary`, label: tw`text-white` }}
        />

        <Button
          label={"Siguiente paso"}
          onPress={(handleSubmit(submitStep2))}
          style={{ container: tw`px-8 py-2` }}
        />
      </View>
    </View>
  )
}