import React, { useEffect, useState } from "react";
import { View } from "react-native";

import tw from "../../styles/tailwind";

import { LinearGradient } from "expo-linear-gradient";

export default function TeamBgGradient() {
  return (
    <LinearGradient
      colors={['#093634', "#206f8f"]}
      start={{ x: 0, y: 1 }}
      end={{ x: 1, y: 0 }}
      style={tw`absolute right-0 top-0 left-0 bottom-0`}
    />
  )
}