import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity } from "react-native"


import tw from '../../../../styles/tailwind';
import Text from '../../../../Components/Text';
import api from '../../../../utils/api';
import MatchCard from '../../Components/MatchCard';
import { useNavigation } from '@react-navigation/native';
import MatchRow from '../../Components/MatchRow';
import Loading from '../../../../Components/Loading';

export default function TeamMatches({ leagueTeam, leagueId }) {
  const [matches, setMatches] = useState();
  const [loading, setLoading] = useState(false);

  const navigation = useNavigation()

  useEffect(() => {
    loadMatches()
  }, []);


  function loadMatches() {
    setLoading(true)

    api.get(`/league-teams/${leagueTeam.uuid}/matches`)
      .then(({ data }) => {
        setMatches(data)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        console.log(error);
      })
  }

  return (
    <View style={tw.style()}>
      <Loading visible={loading} />
      
      {
        matches?.map((match: Match) =>
          <MatchRow
            match={match}
            key={match.uuid}
            // showDate={true}
            showLeague={false}
            showRound={true}
            style={{
              container: `mb-5`
            }}
            onPress={() => navigation.navigate("Admin.MatchDetail", { matchId: match.uuid })}
          />
        )
      }
    </View>
  )
}

