
import React, { useEffect, useState } from "react";
import { TouchableOpacity, View } from "react-native";

import { useNavigation, useRoute } from "@react-navigation/native";


import Text from "../../../../Components/Text";
import TeamBadge from "../../../../Components/Teams/TeamBadge";
import tw from "../../../../styles/tailwind";


export default function MatchTeams({ match, style, reverseAssistance, onTeamPressed, homeScore, awayScore }: { match: Match }) {

  const route = useRoute()
  const navigation = useNavigation()


  return (
    <View style={tw.style(`row justify-center`, reverseAssistance && { flexDirection: 'row-reverse' }, style?.container)}>

      {/*
        Equipo local
      */}
      <TouchableOpacity
        onPress={() => {
          onTeamPressed && onTeamPressed(match.home_team)
        }}
        style={tw.style(`flex-1 row`, reverseAssistance && { flexDirection: 'row-reverse' } )}
      >
        <TeamBadge
          team={match.home_team?.team}
          width={60}
          height={60}
        />

        <Text
          style={tw.style(
            `text-gray text-md text-center mt-1 ml-8 flex-1`,
            { fontSize: 35 },
          )}
        >{match.home_team?.name}</Text>
      </TouchableOpacity>



      {/* VS */}
      <View style={tw`row h-18`}>
        {
          ! reverseAssistance ?
            <Text style={tw.style(`text-xl text-gray text-center`, { fontSize: 90, lineHeight: 95 })}>{homeScore ?? ""} - {awayScore ?? "" }</Text>
          :
            <Text style={tw.style(`text-xl text-gray text-center`, { fontSize: 90, lineHeight: 95 })}>{awayScore ?? ""} - {homeScore ?? "" }</Text>
        }
      </View>




      {/*
        Equipo Visitante
      */}
      <TouchableOpacity
        onPress={() => {
          onTeamPressed && onTeamPressed(match.away_team)
        }}
        style={tw.style(`flex-1 row justify-end`, reverseAssistance && { flexDirection: 'row-reverse' })}
      >
        <Text
          style={tw.style(
            `text-gray text-md text-center mt-1 mr-8 flex-1`,
            {fontSize: 35},
          )}
        >{match.away_team?.name}</Text>

        <TeamBadge
          team={match.away_team?.team}
          width={60}
          height={60}
        />
      </TouchableOpacity>
      
    </View>
  )
}