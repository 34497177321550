import React, { useEffect } from 'react';
import { Platform, View, StyleSheet, Modal as ReactModal, Pressable, ScrollView } from 'react-native'

import tw from '../styles/tailwind';



export default function Modal(params) {
  function renderModal(){
    return (
      <View style={[styles.modalWrapper, { position: 'absolute' }, params.style?.wrapper]}>
        {params.children}
      </View>
    )
  }

  // if (Platform.OS == "web"){
  //   if( ! params.visible ) return null;

  //   return (
  //     <View style={[{ position: 'fixed', left: 0, right: 0, top: 0, bottom: 0, zIndex:1 }]}>
  //       <Pressable
  //         style={[styles.modalOverlay, { backgroundColor: 'rgba(0, 0, 0, 0.7)', flex: 1 }]}
  //         onPress={() => { params.onRequestClose && params.onRequestClose() }}
  //       ></Pressable>

  //       { renderModal() }
  //     </View>
  //   );
  // }

  
  return <ReactModal {...params}>
    <View style={{flex: 1}}>
      <Pressable
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)', flex: 1 }}
        onPress={() => { params.onRequestClose && params.onRequestClose() }}
      ></Pressable>

      { renderModal() }
    </View>
    
  </ReactModal>
}



const styles = StyleSheet.create({
  modalOverlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)', flex: 1,
  },
  modalWrapper: {
    top: 50,
    left: 25,
    backgroundColor: 'white',
    right: 25,
    bottom: 40,
    position: 'absolute',
    paddingVertical: 14,
    paddingHorizontal: 14,
    borderRadius: 12,
    overflow: 'hidden',
  }
})