import React, {useState, useEffect} from 'react';
import {Dimensions, ScrollView, Image, View, TouchableOpacity, Linking} from 'react-native'
import {Ionicons} from '@expo/vector-icons';
import api from "../../utils/api"
import tw from '../../styles/tailwind';

import Text from '../../Components/Text';

import BasesModal from './BasesModal';

export function SocialButtons(props){
  return(
    <View style={tw.style(`border-t border-t-secondary border-b border-b-secondary 
        flex-1 flex-row justify-around items-center py-6 mb-8`, props.style)}>
      <Ionicons name="logo-facebook" size={32} color="black" onPress={() => {
        Linking.openURL('https://www.facebook.com/futmasterapp')
      }} />
      <Ionicons name="logo-instagram" size={32} color="black" onPress={() => {
        Linking.openURL('https://www.instagram.com/futmasterapp/')
      }} />

      <TouchableOpacity onPress={() => Linking.openURL('https://www.tiktok.com/@futmasterapp/')}>
        <Image
          source={require('./tiktok.png')}
          style={{ height: 30, width: 30, resizeMode: 'contain' }}
        />
      </TouchableOpacity>
    </View>
  )
}


export default function GiveawayScreen({route, navigation}) {
  const [modalShown, setModalShown] = useState(false);

  let ScreenHeight = Dimensions.get("window").height;

  useEffect(() => {
    navigation.setOptions({})
  }, []);


  return (
    <>
      {
        <BasesModal 
          visible={modalShown} 
          onRequestClose={() => {
          setModalShown(false)
        }}/>
      }
      <ScrollView style={tw.style(`bg-primary h-full`)} contentContainerStyle={tw`flex-1 items-center justify-center`}>

        <View style={tw`max-w-screen-md	w-full self-center pb-14 px-5 bg-primary`}>


          <Image
            source={require('./src/header-2.png')}
            style={{width: '100%', height: 135, resizeMode: 'contain', marginBottom: 30}}
          />

          <Text style={tw`text-center text-secondary mb-10`}>Gracias por participar, síguenos en nuestras redes sociales para conocer al ganador.</Text>

          <SocialButtons />

          <Text style={tw`text-center text-secondary mb-4 mt-4 font-sm`}>Derechos reservados FutMaster 2021</Text>

          <Text style={tw`text-center text-secondary font-sm`} onPress={() => setModalShown(true)}>Términos y privacidad de datos</Text>

          

        </View>
      </ScrollView>
    </>
  );
}
