import React, { useEffect, useState } from "react";
import { TouchableOpacity, View, Image, ScrollView } from "react-native";
import { StatusBar } from "expo-status-bar";

import tw from "../../../../styles/tailwind";
import Text from '../../../../Components/Text';
import Button from "../../../../Components/Button";
import BottomSheet from "../../../../Components/BottomSheet";
import api from "../../../../utils/api";
import TeamBadge from "../../../../Components/Teams/TeamBadge";
import FMIcons from "../../../../Components/FMIcon"
import {Ionicons} from "@expo/vector-icons"
import { updateMatch } from "../../../../store/Reducers/matches";
import { useDispatch } from "react-redux";

interface MatchSelectTeamProps{
  visible: boolean,
  match: Match,
  onRequestClose: () => {},
}


function TeamsSelector({teams, onChange, multiple=false}:{leagueTeams:Array<LeagueTeam>}){
  const [selectedTeams, setSelectedTeams] = useState([]);

  function onTeamSelected(team) {
    let selected;

    if(multiple){
      const optionIndex = selectedTeams.findIndex(e => e.uuid == team.uuid)

      if (optionIndex != -1) {
        selected = selectedTeams.filter(e => e.uuid != team.uuid)
      } else {
        selected = [...selectedTeams, team]
      }
    }else{
      selected = [team]
    }


    setSelectedTeams(selected)
    onChange(selected)
  }

  return (
    <View>
      {
        teams.sort((tA, tB) => tA.name.localeCompare(tB.name)).map((leagueTeam:LeagueTeam) => {
          const isSelected = !!selectedTeams.find(e => e.uuid == leagueTeam.uuid)

          return <View style={tw``} key={leagueTeam.uuid}>
            <TouchableOpacity
              key={leagueTeam.uuid}
              style={
                tw.style(
                  `bg-gray mb-3 px-3 py-2 rounded-xl flex-row items-center justify-between`,
                  isSelected && tw`bg-gray-500`,
                )
              }
              onPress={() => onTeamSelected(leagueTeam)}
            >
              <View style={tw`flex-row items-center`}>
                <TeamBadge team={leagueTeam.team} width={30} height={30} />

                <View style={tw`ml-3`}>
                  <Text style={tw.style(`font-bold text-black`, isSelected && `text-white`)}>{leagueTeam.name}</Text>
                  {/* <Text style={tw.style(`text-black`, isSelected && `text-white`)}>{leagueTeam.team.players_count} jugadores</Text> */}
                </View>
              </View>


              {
                isSelected &&
                <Ionicons name="checkmark-outline" style={tw``} size={20} color={tw.color(`green-400`)} />
              }
            </TouchableOpacity>

          </View>
        })
      }
    </View>
  )
}



export default function MatchSelectTeam({ visible, onRequestClose, match, slot } : MatchSelectTeamProps) {
  const [teams, setTeams] = useState<Array<Team>>([]);

  const dispatch = useDispatch()

  useEffect(() => {
    loadLeagueTeams()
  }, []);

  function loadLeagueTeams(){
    api.get(`/leagues/${match.league.uuid}/teams`)
    .then(({data}) => {
      setTeams(data)
    })
    .catch(({response}) => {})
  }
  

  function updateMatchTeam(leagueTeam: LeagueTeam|null, slot:"home"|"away") {
    const data = {
      slot,
      team_id: leagueTeam?.team.uuid,
    }

    api.post(`/matches/${match.uuid}/team/${slot}`, data)
      .then(({ data }) => {
        dispatch(updateMatch(data))
        onRequestClose()
      })
      .catch((error) => { })
  }

  return (
    <BottomSheet
      visible={visible}
      onRequestClose={() => {
        onRequestClose && onRequestClose()
      }}
      height={580}
      style={{ container: tw`px-3 bg-fm-background rounded-tl-xl rounded-tr-xl` }}
    >
      <ScrollView style={tw`flex-1 px-4 pt-5`} contentContainerStyle={tw`pb-14`}>
        {/*  */}
        <TeamsSelector
          teams={teams}
          onChange={selectedTeams => {
            updateMatchTeam(selectedTeams[0], slot)
          }}
          multiple={false}
        />


        {/* Botón Limpiar equipo */}
        <View style={tw`flex-row mt-2`}>
          <TouchableOpacity onPress={() => {
            updateMatchTeam(null, slot)
          }}>
            <Text style={tw`text-gray-300`}>Limpiar equipo {slot == "home" ? "Local" : "Visitante"}</Text>
          </TouchableOpacity>
        </View>
      </ScrollView>
    </BottomSheet>
  );
}
