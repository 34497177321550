import React, {useEffect} from 'react';
import { Text, View, ScrollView, Image, TouchableOpacity } from 'react-native'


import tw from '../../styles/tailwind';
import Modal from '../../Components/Modal'

import {SocialButtons} from './index'

export default function ThanksModal({ visible, onRequestClose, submission, submitterName }) {
  useEffect(() => {
    if (!!window?.fbq) window.fbq('poll_sent', 'poll_sent')
  }, []);
  return (
    <Modal
      visible={visible}
      onRequestClose={onRequestClose}
      statusBarTranslucent={false}
      style={{ wrapper: tw`py-0 px-0` }}
    >
      <View style={tw`flex-1`}>
        <View style={tw`bg-primary rounded-t-lg`}>
          <Image source={require('./src/header-2.png')} style={{height: 100, width: 'auto'}} />
        </View>

        <ScrollView style={tw`flex-1`} contentContainerStyle={tw`flex-1 px-4 py-4`}>
          <View style={tw`flex flex-1 items-center`}>

            <Text style={tw`font-worksans-black text-lg`}>Gracias {submitterName}</Text>
            <Text style={tw`font-worksans-black text-lg`}>Ya estás participando</Text>
            
            <Image source={require('./src/tacos.png')} style={tw`h-30 w-30 bg-primary rounded-full mt-2`} />

            <Text style={tw`font-worksans-bold mt-4`}>Te enviamos un correo de confirmación.</Text>
            <Text style={tw`font-worksans-black text-lg mt-5`}>Multiplica tu suerte</Text>
            <Text style={tw`font-worksans-bold mt-2 text-center`}>Comparte con tus amigos futboleros esta encuesta y por cada una que contesten tendrás mayores posibilidades de ganar.</Text>

            <TouchableOpacity style={tw.style(`mt-6 py-3 items-center rounded-md w-full px-20 bg-primary`)}
              onPress={() => {
                if (navigator.share) {
                  navigator.share({
                    title: 'Gana unos tenis con FutMaster',
                    text: 'Participa por unos tenis contestando una simple encuesta',
                    url: submission.referring_link,
                  })
                    .then(() => console.log('Successful share'))
                    .catch((error) => console.log('Error sharing', error));

                  if (!! window?.fbq) window.fbq('share_event', 'navigator_share')
                } else if (navigator.clipboard){
                  navigator.clipboard.writeText(submission.referring_link)

                  setTimeout(() => {
                    alert("El enlace ha sido copiado a tu portapapeles para que lo puedas compartir")
                  }, 500)

                  if (!! window?.fbq) window.fbq('share_event', 'clipboard_share')
                }
              }}
            >
              <Text style={tw`text-secondary font-semibold font-worksans`}>Compartir</Text>
            </TouchableOpacity>


            <Text style={tw`font-worksans-bold mt-2 text-center mt-6 text-gray-700`}>Síguenos en redes sociales para descubrir si resultas ganador</Text>

            <SocialButtons style={tw`py-7 pb-10 mt-3 w-full border-b-0 mb-5 mt-4`}/>

          </View>

        </ScrollView>
      </View>
    </Modal>
  );
}


