import React, { useState, useEffect, useRef } from 'react';
import { View, Animated, Pressable, TouchableOpacity } from "react-native"


import tw from '../../../../styles/tailwind';
import Text from '../../../../Components/Text';
import IconAsistencia from './svgs/asistencia.svg'
import IconLive from './svgs/live.svg'
import IconCedula from './svgs/cedula.svg'
import BottomButton from './BottomButton';
import { Ionicons } from "@expo/vector-icons"
import TabletModal from './TabletModal';




export function CaptureSelectionModal({ onRequestClose, teamsAssistance, onReportSelected, onLiveCaptureSelected, visible }){
  const reportCaptureDisabled = (
    (
      (teamsAssistance.home_no_show && teamsAssistance.away.length == 0)
      ||
      (teamsAssistance.away_no_show && teamsAssistance.home.length == 0)
    )
    ||
    (
      (!teamsAssistance.home_no_show && !teamsAssistance.away_no_show)
      &&
      (teamsAssistance.home.length == 0 || teamsAssistance.away.length == 0)
    )
  )

  const liveCaptureDisabled = teamsAssistance.home_no_show || teamsAssistance.away_no_show

  return (
    <TabletModal onRequestClose={onRequestClose} visible={visible}>
      <Text style={tw`font-bold text-xl text-center mb-6`}>Selecciona la forma de capturar el partido</Text>

      <View style={tw`row flex-wrap items-start justify-around`}>
        {/* Boton cedula arbitral */}
        <View style={tw.style(`w-[230px] px-4`, reportCaptureDisabled && `opacity-50`)}>
          <TouchableOpacity
            style={tw.style(`w-[200px] self-center rounded-lg items-center bg-transparent border border-primary mb-3 px-3 py-5`)}
            disabled={reportCaptureDisabled}
            onPress={onReportSelected}
          >
            <IconCedula style={tw`w-7 h-7 mb-3`} />
            <Text style={tw.style({fontSize: 24 })}>Cédula arbitral</Text>
          </TouchableOpacity>

          <Text style={tw`text-gray-300`}>Capturar los resultados al final del partido.</Text>
          <Text style={tw`text-gray-300`}>Recomendado para partidos sin cronometrista.</Text>
        </View>



        {/* Boton captura en vivo */}
        <View style={tw.style(`w-[230px] px-4`, liveCaptureDisabled && `opacity-50`)}>
          {/*  */}
          <TouchableOpacity
            style={tw.style(`w-[200px] self-center rounded-lg items-center border border-primary mb-3 px-3 py-5`)}
            disabled={liveCaptureDisabled}
            onPress={onLiveCaptureSelected}
          >
            <IconLive style={tw`w-9 h-7 mb-3`} />
            <Text style={tw.style({fontSize: 24 })}>Captura en vivo</Text>
          </TouchableOpacity>

          <Text style={tw`text-gray-300`}>Capturar las acciones durante el partido.</Text>
          <Text style={tw`text-gray-300`}>Recomendado para partidos con cronometrista.</Text>
        </View>
      </View>
    </TabletModal>
  )
}



export default function AssistanceTopBar({ match, matchHasStarted, onReverseTeamsPressed, reverseAssistance, mode, onGotoMatchPressed, onChooseCaptureOptionsPressed, onGotoReportPressed }) {
  const assistanceTitleHeightAnim = useRef(new Animated.Value(80)).current;

  useEffect(() => {
    Animated.timing(assistanceTitleHeightAnim, {
      toValue: mode == "assistance" ? 80 : 0,
      duration: 600,
      useNativeDriver: false,
    }).start();
  }, [mode]);


  // !matchHasStarted - no se puede capturar cedula, mandar al partido


  return (
    <Animated.View
      style={[
        {
          height: assistanceTitleHeightAnim,
          overflow: "hidden",
        },
      ]}>
      <View style={tw`row justify-between flex-1 px-5`}>

        {/* Título asistencia */}
        <View style={tw`row`}>
          <IconAsistencia style={tw`w-6 h-6 mr-3`} />

          <Text style={tw.style(`text-md text-white`, { fontSize: 34 })}>Asistencia</Text>
        </View>


        {/* Botón invertir equipos */}
        <TouchableOpacity
          onPress={onReverseTeamsPressed}
          style={tw.style(`rounded border border-gray-300 px-2 py-1 absolute left-50% bottom-2`, reverseAssistance && `bg-gray-300`)}
        >
          <Ionicons name="git-compare" color={reverseAssistance ? "#ccc" : "#666"} size={20} />
        </TouchableOpacity>


        {/* Botón "Ir al partido" */}
        {
          matchHasStarted ?
            match.status !== "played" ?
              <BottomButton
                text={"Ir al partido"}
                color={tw.color('primary')}
                onPress={onGotoMatchPressed}
                icon={<IconLive style={tw`-my-3 w-9 h-9 ml-2`} />}
                style={{ container: tw`bg-primary/20 border border-primary py-3`, text: { fontSize: 24 } }}
                // disabled={teamsAssistance.home_no_show || teamsAssistance.away_no_show}
              />
            :
              <BottomButton
                text={"Ir a la cédula"}
                color={tw.color('primary')}
                onPress={onGotoReportPressed}
                icon={<IconLive style={tw`-my-3 w-9 h-9 ml-2`} />}
                style={{ container: tw`bg-primary/20 border border-primary py-3`, text: { fontSize: 24 } }}
              // disabled={teamsAssistance.home_no_show || teamsAssistance.away_no_show}
              />
          :
            <BottomButton
              text={"Capturar partido"}
              color={tw.color('primary')}
              onPress={onChooseCaptureOptionsPressed}
              icon={<IconCedula style={tw`-my-3 w-7 h-7 ml-2`} />}
              style={{ container: tw`bg-primary/20 border border-primary py-3`, text: { fontSize: 24 } }}
              // disabled={teamsAssistance.home_no_show || teamsAssistance.away_no_show}
            />
        }
      </View>
    </Animated.View>
  )
}

