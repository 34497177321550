import React, { useEffect, useState } from "react";
import { Image, ScrollView, TouchableOpacity, View, Platform , Alert} from "react-native";

import tw from "../../../styles/tailwind";

import Text from "../../../Components/Text";
import Button from "../../../Components/Button";
import FMIcons from "../../../Components/FMIcon";
import TeamBadge from "../../../Components/Teams/TeamBadge";
import MatchSelectDateBottomSheet from "./MarchDetailComponents/MatchSelectDateBottomSheet";
import MatchSelectRefereeBottomSheet from "./MarchDetailComponents/MatchSelectRefereeBottomSheet";


import { getHourStr, dayOfWeekStr, monthStr } from "../../../utils/momento";
import { useDispatch, useSelector } from "react-redux";
import api from "../../../utils/api";
import { loadMatch, updateMatch } from "../../../store/Reducers/matches";
import MatchTeams from "./MarchDetailComponents/MatchTeams";
import MatchFeaturesCard from "../../../Components/Matches/MatchFeaturesCard";
import Loading from "../../../Components/Loading";


import { Ionicons } from "@expo/vector-icons"
import {
  HeaderButtons,
  HiddenItem,
  OverflowMenu,
  Divider,
} from 'react-navigation-header-buttons';

import * as Device from 'expo-device';


import { useActionSheet } from '@expo/react-native-action-sheet';
import MatchSelectTeam from "./MarchDetailComponents/MatchSelectTeam";
import RefereesPresenter from "./MarchDetailComponents/RefereesPresenter";
import { MatchNavBarTitle } from "../../Match/Match.Home";
import MatchSelectRoundBottomSheet from "./MarchDetailComponents/MatchSelectRoundBottomSheet";
import MatchSelectFieldBottomSheet from "./MarchDetailComponents/MatchSelectFieldBottomSheet";
import MatchNotifyPlayerButton from "./MarchDetailComponents/MatchNotifyPlayerButton";
import MatchTimeline from "../../Match/MatchTimeline";



function MatchDateButton({ date, onPress, played }) {
  const createdAt = date ? new Date(date) : null
  const hourStr = getHourStr(createdAt)
  const dateStr = createdAt ? `${dayOfWeekStr(createdAt)}, ${createdAt?.getDate()} ${monthStr(createdAt)}` : null

  return (
    <TouchableOpacity style={tw`flex-row items-center bg-gray-500 px-5 py-3 rounded-xl justify-between mb-4`}
      disabled={played && createdAt ? true : false}
      onPress={() => { onPress && onPress() }
      }>

      {/* Fecha */}
      <Text style={tw`text-md text-gray`}>{dateStr}</Text>

      {/* Hora e ícono */}
      <View style={tw`flex-row items-center`}>
        { played && <FMIcons name={"progreso-b"} size={15} style={tw`text-primary`} /> }
        
        <Text style={tw`text-md text-primary ml-2 mr-2`}>{createdAt ? hourStr : "Sin Fecha"}</Text>

        {
          (! played || !!! createdAt) &&
            <FMIcons name={"chev-der"} size={12} style={tw`text-primary`} />
        }
      </View>
    </TouchableOpacity>

  )
}




function MatchFieldButton({ match, played, onFieldChanged } : {match: Match}) {
  const [modalSelectFieldVisible, setModalSelectFieldVisible] = useState(false);

  // TODO: SELECTED_STADIUM_STATE
  const selectedStadiumId = useSelector((state) => state.status?.selectedStadiumId);
  const stadium = useSelector((state) => state.status?.stadiums?.find(e => e.uuid == selectedStadiumId));

  const pressable = ! played && stadium.fields?.length > 1

  if (!match.field && stadium.fields?.length < 2) return null
  if (stadium.fields?.length == 0) return null

  return (
    <>
      <TouchableOpacity style={tw`flex-row items-center bg-gray-500 px-5 py-3 rounded-xl justify-between mb-4`}
        disabled={! pressable}
        onPress={() => {
          setModalSelectFieldVisible(true)
        }
        }>

        {
          match.field ?
            <Text style={tw`text-md text-primary`}>{match.field.name}</Text>
          :
            <Text style={tw`text-md text-yellow-200`}>Sin cancha asignada</Text>
        }

        <View style={tw`flex-row items-center`}>
          {
            ( pressable ) &&
              <FMIcons name={"chev-der"} size={12} style={tw`text-primary`} />
          }
        </View>
      </TouchableOpacity>

      <MatchSelectFieldBottomSheet
        visible={modalSelectFieldVisible}
        match={match}
        onFieldChanged={() => {
          setModalSelectFieldVisible(false)
          onFieldChanged?.()
        }}
        onRequestClose={() => {
          setModalSelectFieldVisible(false)
        }}
      />
    </>
  )
}















export default function MatchDetailScreen({ navigation, route }) {
  const [modalRefereeShown, setModalRefereeShown] = useState(false);
  const [modalDateShown, setDateModalShown] = useState(false);
  const [modalSelectRoundVisible, setModalSelectRoundVisible] = useState(false);
  const [teamsSelectorSlot, setTeamsSelectorSlot] = useState();
  const [isTablet, setIsTablet] = useState(false);

  const matchId = route.params?.matchId
  const match : Match = useSelector(state => state.entities.matches[matchId])
  const selectedStadiumId = useSelector((state) => state.status?.selectedStadiumId);


  const played = match?.status == "played" || match?.status == "awarded"
  const isLive = ["created", "awarded", "played", "postponed", "pending", "canceled"].indexOf(match?.status) === -1
  
  const rateToFace = rate => rate == 1 ? "feliz" : rate == 0 ? "neutro" : "disgustado"

  // TODO: Reemplazar con endpoint
  const referees = [
    { name: "Carlos Suárez", uuid: 1 },
    { name: "Camilo Almeida", uuid: 2 },
    { name: "Pedro Villegas", uuid: 3 },
  ]

  useEffect(() => {
    const deletes = (
      ((!match?.home_team || !match?.away_team) && match?.status == "created") 
      || match?.status == "canceled"
      || match?.status == "played"
    )

    navigation.setOptions({
      title: match?.round ? match.round.name : '',
      headerShown: true,
      headerStyle: {
        // backgroundColor: '#303030',
      },
      headerTransparent: true,
      // headerTintColor: '#ebebeb',
      headerTintColor: 'white',
      // statusBarHidden: false,
      headerShadowVisible: false,
      headerTitle: ({ tintColor, children }) => {

        return (
          <MatchNavBarTitle match={match} tintColor={tintColor} children={children} />
        )
      },
      headerRight: () => <HeaderButtons>
        <OverflowMenu
          style={{ marginRight: -5 }}
          OverflowIcon={({ color }) => (
            <Ionicons name="ellipsis-vertical-outline" color={"white"} size={24} />
          )}
        >
          {
            (Platform.OS === "web") &&
              <>
                <HiddenItem
                  title={"Imprimir Cédula"}
                  titleStyle={{}}
                  onPress={onPrintReport}
                />

                <Divider />
              </>
          }

          <HiddenItem
            title={"Cambiar de Jornada"}
            titleStyle={{}}
            onPress={() => setModalSelectRoundVisible(true)}
          />

          {
            (match?.status != "canceled" && match?.status != "played" && ((match?.home_team && match?.away_team) && match?.status == "created"))
              &&
              <HiddenItem
                title={"Cancelar Partido"}
                titleStyle={{}} 
                onPress={onCancelPressed} 
              />
          }

          {
            (played)
              &&
              <HiddenItem
                title={"Editar Estadísticas"}
                titleStyle={{}} 
                onPress={() => {
                  navigation.navigate('Admin.ArbitrationCertificate', { matchId })
                }} 
              />
          }

          {
            (! played && match?.date)
              &&
              <HiddenItem
                title={"Marcar como pendiente"}
                titleStyle={{}}
                onPress={markMatchAsPending}
              />
          }

          <HiddenItem
            title={"Eliminar Partido"}
            titleStyle={{color: 'red'}} 
            onPress={onDeletePressed} 
          />
        </OverflowMenu>
      </HeaderButtons>,
    })

  }, [match]);

  useEffect(() => {
    const getDeviceType = async () => {
      const deviceType = await Device.getDeviceTypeAsync();

      setIsTablet(deviceType == Device.DeviceType.TABLET)
    }

    getDeviceType()
  }, []);


  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadMatch(matchId))
    loadMatchStats(matchId)
    loadMatchEvents()
  }, [matchId]);

  useEffect(() => {
    loadMatchStats(matchId)
  }, [match?.status]);

  function reloadMatch(){
    dispatch(loadMatch(matchId))
  }


  function updateMatchDate(date){
    api.post(`/matches/${matchId}/date`, {date})
    .then(({data})=>{
      dispatch(updateMatch(data))
    })
    .catch((error)=>{
      console.log("yepo!", error);
    })
  }


  const [matchStats, setMatchStats] = useState();
  const [matchEvents, setMatchEvents] = useState([]);

  function loadMatchStats(matchId){
    api.get(`/matches/${matchId}/stats`)
    .then(({data})=>{
      setMatchStats(data)
    })
    .catch((error)=>{
      console.log("yepo!", error);
    })
  }


  async function loadMatchEvents() {
    try {
      const response = await api.get(`/matches/${matchId}/events`)
      setMatchEvents(response.data)
    } catch (error) {
      console.log("loadMatchEvents error", JSON.stringify(error.response?.data));
    }
  }


  //-- Selector de equipos

  const { showActionSheetWithOptions } = useActionSheet();

  const navigateToTeamDetail = teamId => navigation.navigate("Admin.TeamDetail", {
    teamUid: teamId,
    leagueUid: match.league.uuid
  })

  function onTeamPressed(slot){
    if(slot == "home"){
      if (match.home_team) {
        if (played) {
          navigateToTeamDetail(match.home_team?.team.uuid)
        } else {
          const options = ['Ver equipo', 'Cambiar equipo', 'Cancelar'];
          const cancelButtonIndex = 2;

          showActionSheetWithOptions(
            {
              options,
            },
            (buttonIndex) => {
              if (buttonIndex == 0) navigateToTeamDetail(match.home_team?.team.uuid)
              if (buttonIndex == 1) {
                // TODO: ABRIR seleccionador de equipo
                setTeamsSelectorSlot("home")
              }
            }
          );
        }
      } else {
        // TODO: ABRIR seleccionador de equipo
        setTeamsSelectorSlot("home")
      }
    }

    if(slot == "away"){
      if (match.away_team) {
        if (played) {
          navigateToTeamDetail(match.away_team?.team.uuid)
        } else {
          const options = ['Ver equipo', 'Cambiar equipo', 'Cancelar'];
          const cancelButtonIndex = 2;

          showActionSheetWithOptions(
            {
              options,
            },
            (buttonIndex) => {
              if (buttonIndex == 0) navigateToTeamDetail(match.away_team?.team.uuid)
              if (buttonIndex == 1) {
                // TODO: ABRIR seleccionador de equipo
                setTeamsSelectorSlot("away")

              }
            }
          );
        }
      } else {
        // TODO: ABRIR seleccionador de equipo
        setTeamsSelectorSlot("away")
      }
    }
  }


  function markMatchAsPending(){
    if (Platform.OS !== "web") {
      Alert.alert(
        `¿Marcar este partido como pendiente?`,
        '',
        [
          {
            text: 'Marcar como pendiente',
            onPress: () => markAsPending(),
            style: 'cancel',
          },
          {
            text: 'Cerrar',
            onPress: () => { },
            // style: 'cancel',
          },
        ],
        {
          cancelable: true,
          onDismiss: () => { }
        }
      );
    } else {
      const result = confirm(`¿Marcar este partido como pendiente?\n`)
      if (result) markAsPending()
    }
  }

  function markAsPending() {
    api.post(`/matches/${match.uuid}/pending`)
      .then(({ data }) => {
        dispatch(loadMatch(matchId))
      })
      .catch(({ error }) => {

      })
  }



  function onCancelPressed(){
    if (Platform.OS !== "web") {
      Alert.alert(
        `¿Cancelar este partido?`,
        'Se notificará a los jugadores de ambos equipos.',
        [
          {
            text: 'Cancelar Pardido',
            onPress: () => cancelMatch(),
            style: 'cancel',
          },
          {
            text: 'Cerrar',
            onPress: () => { },
            // style: 'cancel',
          },
        ],
        {
          cancelable: true,
          onDismiss: () => { }
        }
      );
    } else {
      const result = confirm(`¿Cancelar este partido?\nSe notificará a los jugadores de ambos equipos.`)
      if (result) cancelMatch()
    }
  }

  function cancelMatch(){
    api.post(`/matches/${match.uuid}/cancel`)
      .then(({ data }) => {
        dispatch(loadMatch(matchId))
      })
      .catch(({ error }) => {

      })
  }

  function onDeletePressed(){
    if (Platform.OS !== "web") {
      Alert.alert(
        `¿Eliminar este partido?`,
        'Esta acción no se puede deshacer.',
        [
          {
            text: 'Eliminar',
            onPress: () => deleteMatch(),
            style: 'cancel',
          },
          {
            text: 'Cancelar',
            onPress: () => { },
            // style: 'cancel',
          },
        ],
        {
          cancelable: true,
          onDismiss: () => { }
        }
      );
    } else {
      const result = confirm(`¿Eliminar este partido?\nEsta acción no se puede deshacer.`)
      if (result) deleteMatch()
    }
  }

  function deleteMatch(){
    api.delete(`/matches/${match.uuid}`)
      .then(({ data }) => {
        // TOFIX: Un fix un tanto puerco para ponerle un flag y que TableStage no lo muestre
        // esto tendría que ser desde redux
        dispatch(updateMatch({
          ...match,
          deleted_at: new Date(),
        }))

        navigation.navigate('Admin.League.Home', { leagueUid: match.league.uuid })
      })
      .catch(({ error }) => {

      })
  }


  function onPrintReport(){
    // const url = `${api.defaults.baseURL}/stadiums/${selectedStadiumId}/matches/${match.uuid}/print`
    const url = `/matches/${match.uuid}/print`

    api.get(url)
    .then(({data}) => {
      // console.log("amo a pimriir", data)
      window.frames["printf"].contentWindow.document.write(data.view)
      window.frames["printf"].contentWindow.document.close()

      setTimeout(() => {
        window.frames["printf"].contentWindow.print()
      }, 1000)
    })
    .catch((error) => {
      console.log("error al generar eso", error)
    })
  }



  if (!match) return <Loading visible={true} style={tw`mt-10`} />

  const playerLabel = player => `${player.squad_number} | ${player.name}`
  const homeGoalsPlayers = matchStats?.home?.player_stats.filter(e => e.goals > 0).map(e => {return {count: e.goals, label: playerLabel(e.player)}})
  const awayGoalsPlayers = matchStats?.away?.player_stats.filter(e => e.goals > 0).map(e => {return {count: e.goals, label:  playerLabel(e.player)}})
  const homeYellowCards = matchStats?.home?.player_stats.filter(e => e.yellow_cards > 0).map(e => {return {label:  playerLabel(e.player)}})
  const awayYellowCards = matchStats?.away?.player_stats.filter(e => e.yellow_cards > 0).map(e => {return {label:  playerLabel(e.player)}})
  const homeRedCards = matchStats?.home?.player_stats.filter(e => e.red_cards > 0).map(e => {return {label:  playerLabel(e.player)}})
  const awayRedCards = matchStats?.away?.player_stats.filter(e => e.red_cards > 0).map(e => {return {label:  playerLabel(e.player)}})

  

  return (
    <ScrollView style={tw`flex-1 bg-background`}>
      {/* Imagen fondo */}
      <View style={tw`absolute top-0 left-0 right-0`}>
        <Image
          source={require('../../../assets/match-bg-deg.png')}
          style={tw`w-100 h-89 self-center`}
          resizeMode="cover"
        />
      </View>


      <View style={tw`flex-1 py-5 px-3 pt-[95px]`}>
        {/* Botón torneo */}
        <TouchableOpacity
          onPress={() => navigation.navigate('Admin.League.Home', { leagueUid: match.league.uuid })}
          style={tw`flex-row items-center justify-between text-white bg-gray-500 rounded-xl pl-1 pr-2 py-1 bg-opacity-80`}
        >
          <View style={tw`flex-row items-center`}>
            <Image source={{ uri: match.league.image_url }} style={tw`h-10 w-10 mr-3 rounded-xl`} />

            <Text style={tw`text-white`}>{match.league.name}</Text>
          </View>

          <FMIcons name={"chev-der"} size={12} style={tw`text-primary`} />
        </TouchableOpacity>



        {/*  */}
        <View style={tw.style(`flex-1 rounded-xl mb-2`)}>
          {/* Equipos y VS */}
          <MatchTeams
            match={match}
            style={tw``}
            played={played}
            onTeamPressed={slot => onTeamPressed(slot)}
          />
        </View>


        {/* header para asignar fecha y hora */}
        <MatchDateButton
          played={played}
          date={match.date}
          onPress={() => { setDateModalShown(true) }}
        />

        {/* header para asignar cancha */}
        <MatchFieldButton
          match={match}
          played={played}
          onFieldChanged={() => {
            reloadMatch()
          }}
        />

        {/* Detalles del partido */}
        {
          played &&
            <View>
              <MatchFeaturesCard
                match={match}
                leftValue={match.home_score}
                rightValue={match.away_score}
                iconLabel={"Goles"}
                iconName={"gol"}
                iconColor={"green"}
                style={{ container: tw`mb-3` }}
                homeStats={homeGoalsPlayers}
                awayStats={awayGoalsPlayers}
              />

              {
                (match.stats?.home.fouls > 0 || match.stats?.away.fouls > 0) &&

                <MatchFeaturesCard
                  match={match}
                  leftValue={match.stats?.home.fouls}
                  rightValue={match.stats?.away.fouls}
                  iconLabel={"Faltas"}
                  iconName={"tarjeta"}
                  iconColor={"gray"}
                  style={{ container: tw`mb-3` }}
                  homeStats={[]}
                  awayStats={[]}
                />
              }
             


              <MatchFeaturesCard
                match={match}
                leftValue={match.stats?.home.yellow_cards}
                rightValue={match.stats?.away.yellow_cards}
                iconLabel={"Tarjeta A."}
                iconName={"tarjeta"}
                iconColor={"primary"}
                style={{ container: tw`mb-3` }}
                homeStats={homeYellowCards}
                awayStats={awayYellowCards}
              />

              <MatchFeaturesCard
                match={match}
                leftValue={match.stats?.home.red_cards}
                rightValue={match.stats?.away.red_cards}
                iconLabel={"Tarjeta R."}
                iconName={"tarjeta"}
                iconColor={"red"}
                style={{ container: tw`mb-3` }}
                homeStats={homeRedCards}
                awayStats={awayRedCards}
              />

              {/* {
                match.stats?.home.review &&
                  <MatchFeaturesCard
                    match={match}
                    leftValue={rateToFace(match.stats?.home.review.puntuality)}
                    rightValue={rateToFace(match.stats?.away.review.puntuality)}
                    iconLabel={"Puntualidad"}
                    iconName={"puntual"}
                    iconColor={"primary"}
                    style={{ container: tw`mb-3` }}
                  />
              } */}

              {/* {
                match.stats?.home.review &&
                  <MatchFeaturesCard
                    match={match}
                    leftValue={rateToFace(match.stats?.home.review.conduct)}
                    rightValue={rateToFace(match.stats?.away.review.conduct)}
                    iconLabel={"Conducta"}
                    iconName={"conducta"}
                    iconColor={"red"}
                    style={{ container: tw`mb-4` }}
                  />
              } */}
              
            </View>
        }



        {/* Botón para notificar jugadores */}
        {
          ( ! isLive && !!match.home_team && !!match.away_team && match.date && ! played) &&
            <View style={tw`mt-10 items-center`}>
              <MatchNotifyPlayerButton match={match} onPlayersNotified={() => reloadMatch()} />
            </View>
        }


        {
          (matchEvents?.length > 0) &&
            <MatchTimeline matchEvents={matchEvents} match={match} />
        }


        {/* Botón para llevar a capturar cédula */}
        {
          ( !played  && !!match.home_team && !!match.away_team) &&
            <View style={tw`mt-10`}>
              <Text style={tw`text-primary text-lg text-center mb-8`}>Capturar las estadísticas del partido</Text>

              <View style={tw`lg:row justify-around`}>
                {/*  */}
                {
                  (Platform.OS !== "web" && isTablet) &&
                    <TouchableOpacity
                      onPress={() => navigation.navigate('Admin.ArbitrationTablet', { matchId })}
                      style={tw`border border-primary px-10 py-5 mb-2 rounded-lg items-center`}
                    >
                      <Text style={tw`text-primary mb-5 `}>Captura en Cancha</Text>
                      <FMIcons name="cancha" size={50} color={tw.color('primary')} />
                    </TouchableOpacity>
                }


                {/* Capturar cédul */}
                <TouchableOpacity
                  onPress={() => navigation.navigate('Admin.ArbitrationCertificate', { matchId })}
                  style={tw`border border-primary px-10 py-5 mb-2 rounded-lg items-center`}
                >
                  <Text style={tw`text-primary mb-5 `}>Capturar cédula arbitral</Text>
                  <FMIcons name="resumen-tb" size={50} color={tw.color('primary')} />
                </TouchableOpacity>
              </View>
            </View>
        }
      </View>


      {/* Bottom sheets */}
      {
        <MatchSelectDateBottomSheet
          visible={modalDateShown}
          match={match}
          leagueMatchDays={match.league?.match_days}
          onRequestClose={() => {
            setDateModalShown(false)
          }}
          onSubmit={(date) => {
            setDateModalShown(false)
            updateMatchDate(date)
          }}
        />
      }

      {
        <MatchSelectRefereeBottomSheet
          visible={modalRefereeShown}
          onRequestClose={() => {
            setModalRefereeShown(false)
          }}
        />
      }


      <MatchSelectRoundBottomSheet
        visible={modalSelectRoundVisible}
        match={match}
        onRoundChanged={() => {
          reloadMatch()
          setModalSelectRoundVisible(false)
        }}
        onRequestClose={() => {
          setModalSelectRoundVisible(false)
        }}
      />

      <MatchSelectTeam
        visible={!!teamsSelectorSlot}
        match={match}
        slot={teamsSelectorSlot}
        onRequestClose={() => {
          setTeamsSelectorSlot(null)
        }}
      />

      {
        Platform.OS === "web" &&
          <iframe id="printf" title="print" style={{width:0, height:0, border: "none"}} ></iframe>
      }
    </ScrollView>
  );
}