import React, { useState, useEffect } from 'react';
import { View, Image } from "react-native"


import tw from '../../styles/tailwind';
import Text from '../../Components/Text';
import LottieView from 'lottie-react-native';
import GamifyBaseModal from '../../Components/Gamification/GamifyBaseModal';
import XPCoin from '../../Components/Gamification/XPCoin';
import TeamBadge from '../../Components/Teams/TeamBadge';
import TeamXPBadge from '../../Components/Gamification/XPBadge';

const _Ach = {
  name: "Hat-Trick",
  image_url: "https://d1crkphdqubgyh.cloudfront.net/gamification/achievements/test/5.png?r",
  description: "Anota 3 goles en un partido",
  xp: 40,
}

export default function AchievementUnlockedModal({ onClose, achievement, leagueTeam=null }) {

  return (
    <GamifyBaseModal onClose={onClose} bgColor={"#724cc3"}>
      <View style={tw`flex-1 justify-around`}>
        <View>
          <Image source={require('./assets/achievement_unlocked.png')} style={tw`w-80 h-25 self-center`} resizeMode={'contain'} />

          {
            leagueTeam &&
            <View style={tw`items-center row justify-center -mt-4`}>
                <TeamBadge team={leagueTeam.team} width={45} />
                <Text style={tw`text-white text-xl ml-3`}>{leagueTeam.name}</Text>
              </View>
          }
        </View>


        {/* Imagen del logro */}
        <View style={tw`items-center`}>
          {/* Fondo rotante */}
          <View style={tw`absolute -mt-[115px]`}>
            <LottieView
              source={require('./flare_blur.json')}
              autoPlay
              loop
              style={{
                width: 450,
                height: 450,
              }}
              speed={1}
            />
          </View>

          <Image
            source={{ uri: achievement.image_url }}
            style={tw`h-43 w-43 mt-5`}
          />
        </View>

        {/*  */}
        <View style={tw`-mt-10 mb-10`}>
          {/* Nombre logro */}
          <Text style={tw`text-white text-sm text-center text-lg font-bold`}>{achievement.name}</Text>
          
          {/* Descripcion logro */}
          <Text style={tw`text-yellow-200 text-center`}>{achievement.description}</Text>

          {/* XP Ganado */}
          <View style={tw`row justify-center`}>
            {
              leagueTeam ? 
                <TeamXPBadge size={28} />
              :
                <XPCoin size={28} />
            }
            <Text style={tw`text-primary text-xl ml-2 my-2`}>+{achievement.xp}</Text>
          </View>

         
        </View>
      </View>
    </GamifyBaseModal>
  )
}


