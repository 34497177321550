import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from "react";
import { TouchableOpacity, View } from "react-native";

import tw from "../../../../styles/tailwind";
import api from "../../../../utils/api";

import Text from "../../../../Components/Text";
import FMIcons from '../../../../Components/FMIcon';
import { Ionicons } from "@expo/vector-icons"



import { useToast } from "react-native-toast-notifications";

import {
  HeaderButtons,
  HiddenItem,
  OverflowMenu,
  Divider,
} from 'react-navigation-header-buttons';
import HideMatchesModal from "./HideMatchesModal";


const RoundAccordion = forwardRef(({ round, onToggle, canAdmin, onMatchPressed, leagueId, league, onRoundChanged }, ref) => {

  const [isOpen, setIsOpen] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [showCreateRoundModal, setShowCreateRoundModal] = useState(false);
  const [showEditRoundModal, setShowEditRoundModal] = useState(false);

  const { loadRoundMatches, isLoading, matches } = useRoundMatches(isOpen ? round : null)


  function toggle(){
    setIsOpen(!isOpen)
  }

  function open(){
    setIsOpen(true)
  }

  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <>
      <View style={tw`bg-gray-500 mb-3 overflow-hidden mx-3 rounded-xl`}>
        {/*  */}
        <TouchableOpacity style={tw`row bg-[#333]  justify-between px-3 py-3`} onPress={toggle}>
          <Text style={tw`text-primary`}>{round.name}{round.exhibition_round ? ` (Amistosos)` : ''}</Text>
          <Text style={tw`text-gray-300`}>{round.date_str}</Text>

          {
            (round.status) && //&& round.status !== "completed"
              <Text style={tw`text-gray-300`}>{round.status_str}</Text>
          }

          <View style={tw`row`}>  
            {
              isOpen &&
                <HeaderButtons>
                  <OverflowMenu
                    style={{marginRight: 2}}
                    OverflowIcon={({ color }) => (
                      <Ionicons name="ellipsis-vertical-outline" color={"white"} size={18} />
                    )}
                  >
                    <HiddenItem
                      title="Editar Jornada"
                      icon={<Ionicons name="pencil-outline" color={"#888"} size={20} style={tw`-mr-3`} />}
                      onPress={() => { setShowEditRoundModal(true) }}
                    />

                    <HiddenItem
                      title="Crear Jornada"
                      icon={<Ionicons name="color-wand-outline" color={"#888"} size={20} style={tw`-mr-3`} />}
                      onPress={() => { setShowCreateRoundModal(true) }}
                    />

                    <HiddenItem
                      title="Ocultar Partidos"
                      icon={<Ionicons name="eye-outline" color={"#888"} size={20} style={tw`-mr-4`} />}
                      onPress={() => { setShowModal(true) }}
                    />
                    {/* <Divider /> */}
                    {/* <HiddenItem title="Eliminar Torneo" onPress={() => { }} titleStyle={tw`text-red`} /> */}
                  </OverflowMenu>
                </HeaderButtons>
            }

            <Ionicons name={isOpen ? "chevron-up" : "chevron-down"} size={15} color={tw.color('primary')} />
          </View>
        </TouchableOpacity>

        {
          isOpen &&
            <View style={tw`py-1`}>
              <RoundMatches
                round={round}
                onMatchPressed={onMatchPressed}
                canAdmin={canAdmin}
                matches={matches}
                isLoading={isLoading}
                loadRoundMatches={loadRoundMatches}
              />
            </View>

        }
      </View>

      {/* Esto debería ir acá??? */}
      <HideMatchesModal
        visible={showModal}
        onRequestClose={() => setShowModal(false)}
        round={round}
        matches={matches}
        onMatchesSaved={() => {
          loadRoundMatches(round)
          setShowModal(false)
        }}
      />

      <CreateRoundModal
        visible={showCreateRoundModal}
        leagueId={leagueId}
        league={league}
        onRequestClose={() => setShowCreateRoundModal(false)}
        round={round}
        onMatchesSaved={() => {
          loadRoundMatches(round)
          setShowCreateRoundModal(false)
        }}
      />

      <EditRoundModal
        visible={showEditRoundModal}
        leagueId={leagueId}
        league={league}
        onRequestClose={() => setShowEditRoundModal(false)}
        round={round}
        onRoundSaved={() => {
          onRoundChanged?.()
          setShowEditRoundModal(false)
        }}
      />
    </>
  )
})



function Rounds({ leagueUid, stage, onMatchPressed, canAdmin, league, onRoundChanged }) {
  const [activeRound, setActiveRound] = useState(0);
  const [rounds, setRounds] = useState(stage.rounds);
  const [isCreatingRound, setIsCreatingRound] = useState(false);

  const toast = useToast();

  const roundAccordionRefs = useRef({})

  
  useEffect(() => {
    const roundStatuses = stage.rounds.map(round => round.status)
    setRounds(stage.rounds)

    // Todo este chorizawer para seleccionar la jornada activa 0.o
    const lastInProgress = roundStatuses.lastIndexOf("in_progress")
    const nextNext = roundStatuses.indexOf("next")
    const lastCompleted = roundStatuses.lastIndexOf("completed")
    const lastCurrent = roundStatuses.lastIndexOf("current")

    const active = lastInProgress !== -1 ? lastInProgress :
                    lastCompleted !== -1 ? lastCompleted :
                      nextNext !== -1 ? nextNext : 0
                        
    setActiveRound(active)
  }, [stage.rounds]);


  function createEmptyRound() {
    setIsCreatingRound(true)
    
    api.post(`/stages/${stage.uuid}/rounds`)
      .then(({ data }) => {
        setRounds([
          ...rounds,
          data
        ])

        roundAccordionRefs.current[data.uuid]?.open()

        setActiveRound(activeRound + 1)
        setIsCreatingRound(false)

        toast.show(`${data.name} creada`);

      })
      .catch(error => {
        setIsCreatingRound(false)
        console.log(error);
      })
  }


  return (
    <View>
      {
        rounds.map(round => 
          <RoundAccordion 
            key={round.uuid}
            ref={ref => roundAccordionRefs.current[round.uuid] = ref}
            round={round}
            league={league}
            leagueId={leagueUid}
            canAdmin={canAdmin}
            onMatchPressed={onMatchPressed}
            onRoundChanged={onRoundChanged}
          />
        )
      }


      {/* Botón nuevo round */}
      {
        (canAdmin) &&
        // (activeRound == rounds.length - 1 && canAdmin) &&
          <Button
            label={"+ Jornada"}
            style={{ container: tw`mt-5 mx-3` }}
            disabled={isCreatingRound}
            onPress={() => {
              createEmptyRound()
            }}
          />
      }
    </View>
  )
}






// DOING: REDUX_NORMALIZR
import { denormalize, normalize, schema } from 'normalizr';
import { matchSchema } from '../../../../store/schemas'
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../Components/Button";
import { useNavigation } from "@react-navigation/native";


import axios from "axios";
import Loading from "../../../../Components/Loading";
import MatchRow from "../../Components/MatchRow";
import CreateRoundModal from "./CreateRoundModal";
import EditRoundModal from "./EditRoundModal";
const CancelToken = axios.CancelToken;


export function useRoundMatches(round){
  const [matchesResult, setMatchesResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [requestCancel, setRequestCancel] = useState();

  const dispatch = useDispatch()
  const navigation = useNavigation()

  useEffect(() => {
    if(round)
      loadRoundMatches(round)
  }, [round]);


  function loadRoundMatches(round) {
    setIsLoading(true)

    if (requestCancel) {
      requestCancel.cancel('Operation canceled by the user.');
    }

    const source = CancelToken.source()
    setRequestCancel(source)

    api.get(`/rounds/${round.uuid}/matches`, {
      cancelToken: source.token
    })
      .then(({ data }) => {
        // DOING: REDUX_NORMALIZR
        const normalizedData = normalize(data, [matchSchema]);

        dispatch({ entities: normalizedData.entities, type: "UPDATE_ENTITIES" })

        setMatchesResult(normalizedData.result)
        setIsLoading(false)
      })
      .catch(error => {
        // console.log(error);
      })
  }




  // DOING: REDUX_NORMALIZR
  const entities = useSelector((state) => state.entities);
  const matches = denormalize(matchesResult, [matchSchema], entities)

  // Esto es un pequeno fix, si dentro de la pantalla de match cambia la jornada
  // al regresar aqui sigue estando en la jornada anterior
  // porque no se actualiza en $matches
  // y también para que al borrar partido no aparezca :S
  
  const _matches : Array<Match> = matches?.filter(e => e.round.uuid == round?.uuid && !e.deleted_at)


    // esto ordena los partidos por fecha
    // si no tiene fecha, se usa la ordena por la fecha de creación
    // (en un tiempo muy futuro, para que quede hasta abajo)
    .sort((m1, m2) => {
      const date1 = m1.date ? (new Date(m1.date)).getTime() : (new Date(m1.created_at)).getTime() + (60 * 60 * 24 * 30 * 24 * 1000)
      const date2 = m2.date ? (new Date(m2.date)).getTime() : (new Date(m2.created_at)).getTime() + (60 * 60 * 24 * 30 * 24 * 1000)

      return date1 > date2
    })

  return {
    loadRoundMatches,
    isLoading,
    matches: _matches
  }
}



function RoundMatches({ round, onMatchPressed, canAdmin, loadRoundMatches, isLoading, matches }) {
  const navigation = useNavigation()

  const [creatingNewMatch, setCreatingNewMatch] = useState(false);

  function createEmptyMatch() {
    setCreatingNewMatch(true)

    api.post(`/rounds/${round.uuid}/matches`)
      .then(({ data }) => {
        loadRoundMatches(round)

        setCreatingNewMatch(false)

        if (!data.date) { // si no trae fecha mete directo
          // mandó a https://futmaster.app/admin/ligas/Admin.MatchDetail?
          navigation.navigate("Admin.MatchDetail", { matchId: data.uuid })
        } else {

        }
      })
      .catch(error => {
        setCreatingNewMatch(false)
        console.log(error);
      })
  }


  return (
    <View style={tw`px-3`}>
      {/* Listado de partidos */}
      <Loading visible={isLoading && (! matches || matches.length == 0)} />

      <View style={tw.style({ "opacity-40": isLoading })}>
        {
          matches?.map(match =>
            <MatchRow
              key={match.uuid}
              match={match}
              showLeague={false}
              style={{ container: tw`w-full` }}
              onPress={() => onMatchPressed(match)}
            />
          )
        }

        {
          matches && matches.length == 0 &&
            <Text style={tw`text-gray-200 text-center py-14`}>Sin partidos en esta jornada</Text>
        }

        {/* Botón nuevo partido (solo canchas) */}
        {
          canAdmin &&
            <Button
              label={"+ Partido"}
              disabled={isLoading || creatingNewMatch}
              onPress={() => {
                createEmptyMatch()
              }}
              style={{container: tw`mt-5 mb-3`}}
            />
        }
      </View>
    </View>
  )
}






export default function TableStage({ leagueUid, leagueName, stage, onMatchPressed, onRoundChanged, canAdmin, league }) {
  return (
    <View style={tw`mb-5`} >
      <Rounds
        leagueUid={leagueUid}
        league={league}
        leagueName={leagueName}
        stage={stage}
        onMatchPressed={onMatchPressed}
        onRoundChanged={onRoundChanged}
        canAdmin={canAdmin}
      />
    </View>
  );
}

