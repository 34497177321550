import React, { useEffect, useState } from "react";
import { Button, StyleSheet, View, TextInput, TouchableOpacity, ScrollView } from "react-native";
import { StatusBar } from "expo-status-bar";


import Text from "../../Components/Text";
import Input from "../../Components/Input";

import tw from "../../styles/tailwind";
import commonStyle, { theme } from "../../styles/common.style";

import { useForm } from "react-hook-form";

import api from '../../utils/api';

import { useNavigation } from '@react-navigation/native';


export interface IAppProps { }

export default function SingupScreen(props: IAppProps) {
  const navigation = useNavigation();
  const { control, handleSubmit, formState, formState: { errors }, setError } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sending, setSending] = useState();


  const doSignup = (data) => {

    setIsSubmitting(true)

    api.post(`auth/register`, data)
      .then(response => {
        setSending(response.data)
        console.log("Checando respuesta aqui qui qui qui", response)
        navigation.navigate('PlayerProfile')
      })
      .catch((err) => {
        console.log("Error en el registro: ", err)
        setIsSubmitting(false)

      })
  }

  return (
    <ScrollView style={tw`flex-1 m-5`}>
      <View style={tw`justify-center`}>
        <StatusBar style="auto" />

        <Input
          name="phone"
          control={control}
          rules={{ required: "El teléfono es requerido", pattern: { value: /\d{10}/, message: "El teléfono debe tener 10 dígitos" } }}
          type={"number"}
          placeholder="Teléfono"
          style={{ label: tw`bg-transparent` }}
        />

        <Input
          name="email"
          control={control}
          rules={{ required: "El correo electrónico es requerido", pattern: { value: /^\S+@\S+$/, message: "Correo no válido" } }}
          type={"email"}
          placeholder="Correo Electrónico"
          style={{ label: tw`bg-transparent` }}
        />

        <Input
          name="password"
          control={control}
          rules={{ required: "Contraseña requerida" }}
          type={"password"}
          placeholder="Contraseña"
          secureTextEntry
          style={{ label: tw`bg-transparent` }}
        />

        <TouchableOpacity style={tw.style(`mt-6 py-4 items-center rounded-md  px-20 `, { opacity: isSubmitting ? 0.5 : 1, backgroundColor: theme.SECONDARY_COLOR })}
          onPress={(handleSubmit(doSignup))}
          disabled={isSubmitting}
        >
          <Text style={commonStyle.btnText}>Siguiente</Text>
        </TouchableOpacity>

      </View >
    </ScrollView>
  );
}
