import React, { useState } from 'react';
import { ScrollView, TouchableOpacity, View, Image } from 'react-native'

import Text from '../../../Components/Text'
import Button from '../../../Components/Button';

import tw from '../../../styles/tailwind';
import api from '../../../utils/api'

import { Ionicons } from '@expo/vector-icons';
import BottomSheet from '../../../Components/BottomSheet';
import Loading from '../../../Components/Loading';
import QRScanner from '../../../Components/QRScanner';



function PlayersSelector({ team, players, onChange }) {
  const [selectedPlayer, setSelectedPlayer] = useState([]);

  function onPlayerSelected(player) {
    let selected;

    const optionIndex = selectedPlayer.findIndex(e => e.uuid == player.uuid)

    if (optionIndex != -1) {
      selected = selectedPlayer.filter(e => e.uuid != player.uuid)
    } else {
      selected = [...selectedPlayer, player]
    }

    setSelectedPlayer(selected)

    onChange(selected)
  }

  return (
    <View>
      {
        players.map(player => {
          const isSelected = !!selectedPlayer.find(e => e.uuid == player.uuid)

          return <View style={tw``} key={player.uuid}>
            <TouchableOpacity
              style={[tw`py-1 pr-4 pl-3 rounded-xl mb-3 flex-row items-center bg-gray-200`, isSelected && tw`bg-black-100`]}
              onPress={() => onPlayerSelected(player)}
            >

              <Text style={[tw`text-black text-xl font-bold`, isSelected && tw`text-white text-xl font-bold`]}>{player.squad_number}</Text>

              <View style={tw`flex-row ml-5 justify-between flex-1 items-center`}>
                <Text style={[tw`text-black`, isSelected && tw`font-600 text-white`]}>{player.name}</Text>

                <View style={[tw.style(`rounded-full h-7 w-7 items-center justify-center`, { backgroundColor: '#bebebe' }), isSelected && tw`bg-gray-400`]}>
                  {
                    isSelected &&
                    <Ionicons name="checkmark" size={22} color="green" />
                  }
                </View>
              </View>



            </TouchableOpacity>

          </View>
        })
      }
    </View>
  )
}


export default function AssociateTeamBottomSheet({ visible, onRequestClose, leagueId, onTeamAssociated }) {
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const [isLoadingTeam, setIsLoadingTeam] = useState(false);
  const [team, setTeam] = useState<Team>();

  function associateTeam() {
    const players = selectedPlayers.map(pl => pl.uuid)

    const data = {
      team_uid: team.uuid,
      players,
    }

    setIsSubmitting(true)


    api.post(`/leagues/${leagueId}/teams/associate`, data)
      .then(({ data }) => {
        onTeamAssociated && onTeamAssociated(data)
      })
      .catch(({ response }) => {
        setIsSubmitting(false)

        setErrorMessage(response.data?.message);
      })
  }

  function onCodeScanned(data: string) {
    const urlSlices = data.split("/")

    if (urlSlices[0] == "https:" && urlSlices[3] == "team") {
      loadPlayerWithTag(urlSlices[4])
    } else {
      alert("Este QR no es valido")
    }
  }
  
  function loadPlayerWithTag(tagId:string) {
    setIsLoadingTeam(true)

    api.get(`/teams/${tagId}`)
      .then(({ data }) => {
        setIsLoadingTeam(false)
        setTeam(data)
      })
      .catch(error => {
        setIsLoadingTeam(false)
        alert("Este equipo o QR no es valido")
      })
  }


// Lo manda scanteamqr
//   team &&
//     <AssociateTeamModal
//       team={team}
//       leagueId={leagueId}
//       onRequestClose={() => {
//         setTeam(null)
//       }}
//       onTeamAssociated={(leagueTeam) => {
//         setTeam(null)
//         onTeamAssociated && onTeamAssociated(leagueTeam)
//       }}
//     />
// }
  if(!isLoadingTeam && !team){
    return (
      <QRScanner
        onCodeScanned={onCodeScanned}
        label={"Escanea el QR del equipo"}
      />
    )
  }

  if(isLoadingTeam){
    return (
      <Loading visible={isLoadingTeam} />
    )
  }
    
  return (
    <BottomSheet
      visible={visible}
      height={500}
      onRequestClose={onRequestClose}
      style={{ wrapper: tw`py-0 px-0 bg-gray` }}
    >

      <ScrollView style={tw`flex-1`} contentContainerStyle={tw`p-5`}>

        <Text style={tw`text-center mb-5`}>Selecciona a los jugadores que necesitas incluir al equipo en este torneo</Text>

        <View style={tw`flex-row items-center justify-center mb-5`}>

          <Image source={{ uri: team.badge_url }} style={{ height: 50, width: 50 }} />
          <Text style={tw`font-bold text-lg text-center ml-3`}>{team.name}</Text>

        </View>


        <PlayersSelector
          team={team}
          players={team.players}
          onChange={(players) => {
            setSelectedPlayers(players)
          }}
        />


        {
          errorMessage && <Text>{errorMessage}</Text>
        }

        <View style={tw`flex-row justify-between mt-7 items-center`}>
          <Button
            onPress={onRequestClose}
            label={`Regresar`}
            style={{ container: tw.style(`px-8  bg-black-100`, { width: '45.5%' }), label: tw`text-white` }}
          />
          <Button
            onPress={() => {
              associateTeam()
            }}
            disabled={isSubmitting}
            label={`Agregar Equipo`}
            style={{ container: tw.style(`px-4 `, { width: '45.5%' }) }}

          />
        </View>
      </ScrollView>
    </BottomSheet>
  );
}
