import React, { useEffect, useState } from 'react';
import { Image, ScrollView, View } from 'react-native'

import Modal from '../../../../Components/Modal'
import Text from '../../../../Components/Text'
import Button from '../../../../Components/Button';

import tw from '../../../../styles/tailwind';

import { useNavigation } from "@react-navigation/native";
import ImagesPicker from '../../../../Components/ImagesPicker';
import Input from '../../../../Components/Input';
import { useForm } from 'react-hook-form';
import Select from '../../../../Components/Select';
import api from '../../../../utils/api';

import { format, parseISO } from 'date-fns/esm'

import QRScanner from '../../../../Components/QRScanner';
import Loading from '../../../../Components/Loading';





export default function AssociatePlayerToTeamScreen({navigation, route}){
  const [isLoadingPlayer, setIsLoadingPlayer] = useState(false);
  const [player, setPlayer] = useState();

  const { teamUid, leagueUid, selectedRole } = route.params

  

  useEffect(() => {
    navigation.setOptions({
      title: "",
      headerShown: false,
    })
  }, []);

  // {


  function onCodeScanned(data:string){
    const urlSlices = data.split("/")

    if (urlSlices[0] == "https:" && urlSlices[3] == "player") {
      loadPlayerWithTag(urlSlices[4])

      // regresamos el tabbar
      navigation.setOptions({
        headerShown: true,
      })
    } else {
      alert("Este QR no es válido o no pertenece a ningún jugador")
    }
  }

  function loadPlayerWithTag(tagId){
    setIsLoadingPlayer(true)

    api.get(`/players/${tagId}`)
      .then(({ data }) => {
        console.log("[ScanPlayerQR] GET /players/{tagId}", data)
        setIsLoadingPlayer(false)
        setPlayer(data)
      })
      .catch(error => {
        setIsLoadingPlayer(false)
        alert("Jugador o QR no válido ")
      })
  }
  

  function onPlayerAssociated(){
    navigation.navigate("Admin.TeamDetail", {
      teamUid,
      leagueUid,
      createdPlayerId: Math.random(), // TOFIX: una forma menos puerca para que recargue...
    })
  }
  

  return (
    <View style={tw`flex-1`}>
      {
        (! isLoadingPlayer && ! player) &&
          <QRScanner
            onCodeScanned={onCodeScanned}
            label={"Escanea el QR del jugador"}
          />
      }

      <Loading visible={isLoadingPlayer}/>
      
      {
        player &&
          <PlayerPreview 
            player={player}
            leagueId={leagueUid}
            teamId={teamUid}
            selectedRoles={selectedRole == "representative" ? [selectedRole, "player"] : [selectedRole]}
            onPlayerAssociated={onPlayerAssociated}
          />
      }
    </View>
  )
}




function PlayerPreview({ player, leagueId, teamId, selectedRoles, onPlayerAssociated }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  
  const { control, handleSubmit, formState, formState: { errors }, setError } = useForm();

  const birthdate = player.birthdate ? format(parseISO(player.birthdate), "d/M/yyyy") : null


  function createTeamPlayer(formData) {
    const data = {
      ...formData,
      roles: selectedRoles,
      player_uid: player.uuid,
    }

    console.log("createTeamPlayer", data);
    

    setIsSubmitting(true)
    setErrorMessage(null)

    api.post(`/leagues/${leagueId}/teams/${teamId}/players/associate`, data)
      .then(({ data }) => {
        // Conectar a las pantallas anteriores
        onPlayerAssociated && onPlayerAssociated()
      })
      .catch(({ response }) => {
        setIsSubmitting(false)
        setErrorMessage(response.data?.message)
      })
  }

  return (
    <ScrollView style={tw`flex-1`}>
      <View style={tw`p-5`}>

        <View style={tw`flex-col`}>
          <Image
            style={tw`self-center bg-gray-300 h-22 w-22 rounded-lg mb-5`}
            source={{uri: player.image_url}}
          />

          <Text style={tw`text-white text-center text-xl font-bold mb-5`}>{player.name}</Text>

          {/* Año nacimiento */}
          <View style={tw`flex-row justify-between items-center mb-5`}>
            <Text style={tw`text-white`}>Año de Nacimiento</Text>
            <Text style={tw`text-white`}>{birthdate}</Text>
          </View>


          <View style={tw`flex-row items-center mb-5`}>
            <Text style={tw`flex-1 text-white`}>Dorsal</Text>

            <Input
              name="squad_number"
              control={control}
              type={"text"}
              placeholder="#"
              placeholderTextColor={"white"}
              style={{ input: `text-white`}}
            />
          </View>

          {/* Documento */}
          <Text style={tw`text-white font-bold text-lg mb-5`}>Documento</Text>
          <View style={tw`text-white bg-gray-300 h-22 w-30 rounded-lg mb-5`}></View>


          {
            errorMessage && <View><Text style={tw`text-red-200 mt-2 mb-4`}>{errorMessage}</Text></View>
          }

          <Button
            onPress={handleSubmit(createTeamPlayer)}
            label={`Agregar`}
            disabled={isSubmitting}
          />
        </View>
      </View>
    </ScrollView>
  );
}
