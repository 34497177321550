import React, { useEffect, useState } from "react";
import { Button, StyleSheet, View, TextInput, TouchableOpacity, ScrollView, Linking } from "react-native";
import { StatusBar } from "expo-status-bar";


import Text from "../../Components/Text";
import Input from "../../Components/Input";

import tw from "../../styles/tailwind";
import commonStyle, { theme } from "../../styles/common.style";

import { useForm } from "react-hook-form";

import api from '../../utils/api';

import { useNavigation } from '@react-navigation/native';


export interface IAppProps { }

export default function ResetPasswordScreen({navigation, route}) {
  const { control, handleSubmit, formState, formState: { errors }, watch } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sending, setSending] = useState();
  const password = watch('password')
  const [success, setSuccess] = useState(false);
  

  const doResetPassword = (data) => {
    setIsSubmitting(true)
    const _data = {
      ...data,
      token: route.params?.token,
      email: route.params?.email,
    }

    api.post(`auth/set-password`, _data)
      .then(response => {
        setSuccess(true)
      })
      .catch((err) => {
        console.log("Error en el registro: ", err)
        setIsSubmitting(false)
      })
  }

  return (
    <ScrollView style={tw`flex-1 bg-primary`} contentContainerStyle={tw`py-8 px-4`} >
      {
        !success &&
          <View style={tw`justify-center`}>
            <Text style={tw`mb-4 text-center font-bold text-lg`}>Genera una nueva contraseña</Text>
            <Text style={tw`mb-10 text-center`}>Elige una nueva contraseña para tu cuenta Futmaster.</Text>

            <Input
              name="password"
              control={control}
              rules={{
                required: "Contraseña requerida",
                minLength: {
                  value: 8,
                  message: 'Mínimo 8 caracteres' 
                },
              }}
              type={"password"}
              placeholder="Nueva Contraseña"
              secureTextEntry
              style={{ label: tw`bg-transparent` }}
            />


            <Input
              name="password_confirmation"
              control={control}
              rules={{
                required: "Contraseña requerida",
                minLength: {
                  value: 8,
                  message: 'Mínimo 8 caracteres'
                },
                validate: (value, formValues) => {
                  return value === password || 'Las contraseñas no coinciden'
                }
              }}
              type={"password"}
              placeholder="Confirmar Contraseña"
              secureTextEntry
              style={{ label: tw`bg-transparent` }}
            />


            <TouchableOpacity style={tw.style(`mt-6 py-4 items-center rounded-md  px-20 `, { opacity: isSubmitting ? 0.5 : 1, backgroundColor: theme.SECONDARY_COLOR })}
              onPress={(handleSubmit(doResetPassword))}
              disabled={isSubmitting}
            >
              <Text style={commonStyle.btnText}>Guardar contraseña</Text>
            </TouchableOpacity>

          </View >
        }
      
      {
        success &&
        <View style={tw`justify-center`}>
          <Text style={tw`mb-4 text-center font-bold text-lg`}>Contraseña guardada</Text>
          <Text style={tw`mb-10 text-center`}>Ya puedes entrar a tu cuenta con la contraseña que acabas de generar.</Text>

          <TouchableOpacity style={tw.style(`mt-6 py-4 items-center rounded-md  px-20 bg-secondary`)}
            onPress={( ) => (Linking.openURL("https://app.futmaster.app/"))}
          >
            <Text style={commonStyle.btnText}>Ir a la app</Text>
          </TouchableOpacity>
        </View>
      }

    </ScrollView>
  );
}
