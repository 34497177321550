
import React, { useEffect, useState } from "react";
import { TouchableOpacity, View } from "react-native";

import { useNavigation, useRoute } from "@react-navigation/native";


import Text from "../../../../Components/Text";
import TeamBadge from "../../../../Components/Teams/TeamBadge";
import tw from "../../../../styles/tailwind";
import LiveIndicator from "../../../../Components/LiveIndicator";


export default function MatchTeams({ match, style, played, onTeamPressed }: { match: Match }) {

  const route = useRoute()
  const navigation = useNavigation()

  const emptyTeamBadge = { badge: { name: "empty" } }

  const showScore = match.status !== "created" && match.status !== "canceled" && match.status !== "postponed" && match.status !== "pending"

  const isLive = showScore && match.status !== "awarded" && match.status !== "played"



  return (
    <View style={tw.style(`flex-1 flex-row items-start justify-center px-5 py-4`, style?.container)}>

      {/*
        Equipo local
      */}
      <TouchableOpacity
        onPress={() => {
          onTeamPressed && onTeamPressed("home")
        }}
        style={tw.style(`flex-1 flex-col items-center`)}
      >
        <TeamBadge
          team={match.home_team?.team ?? emptyTeamBadge}
          width={80}
          height={80}
        />

        <Text
          style={tw.style(
            `text-gray text-md text-center mt-1`,
            style?.team,
            !match.home_team?.team && `text-gray-200`
          )}
        >{match.home_team?.name ?? "Elegir equipo"}</Text>
      </TouchableOpacity>



      {/* VS/marcador */}
      {
        ! showScore ?
          <Text style={tw`flex-1 text-xl text-gray self-center font-bold text-center`}>VS</Text>
        :
          <View style={tw`self-center`}>
            <View style={tw`row justify-around`}>
              <Text style={tw.style(`text-white font-700 text-xl mr-3 w-10 text-center`, { fontSize: 35 })}>{match.home_score}</Text>
              <Text style={tw.style(`text-white font-700 text-xl`, { fontSize: 35 })}>:</Text>
              <Text style={tw.style(`text-white font-700 text-xl ml-3 w-10 text-center`, { fontSize: 35 })}>{match.away_score}</Text>
            </View>

            {
              isLive &&
                <View style={tw`row mt-3 justify-center items-center`}>
                  <LiveIndicator />
                  
                  <Text style={tw`text-lg font-700 text-white text-center -mt-[2px] ml-1`}>{match.status_str}</Text>
                </View>
            }
          </View>
      }




      {/*
        Equipo Visitante
      */}
      <TouchableOpacity
        onPress={() => {
          onTeamPressed && onTeamPressed("away")
        }}
        style={tw.style(`flex-1 flex-col items-center `)}
      >
        <TeamBadge
          team={match.away_team?.team ?? emptyTeamBadge}
          width={80}
          height={80}
        />

        <Text
          style={tw.style(
            `text-gray text-md text-center mt-1`,
            style?.team,
            !match.away_team?.team && `text-gray-200`
          )}
        >{match.away_team?.name ?? "Elegir equipo"}</Text>
      </TouchableOpacity>


      
    </View>
  )
}