import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, Platform } from "react-native"


import tw from '../../styles/tailwind';
import Text from '../../Components/Text';
import FMIcons from '../../Components/FMIcon';


function eventNameStr(event){
  switch (event.type) {
    case "goal":
      return "Gol";

    case "match_started":
      return "Inicio del partido";

    case "half_time":
      return "Medio Tiempo";

    case "2nd_quarter":
      return "2do cuarto";

    case "3rd_quarter":
      return "3er cuarto";

    case "4th_quarter":
      return "4to cuarto";

    case "match_ended":
      return "Partido Finalizado";
    
    default:
      return event.type
  }
}

function EventName({event, style}){
  return <Text style={tw.style(`text-gray-200`, style)}>{eventNameStr(event)}</Text>
}


function FloatingPill({ event, isHomeTeam, minute, icon, iconColor, style }){
  return (
    <View style={tw`self-center mb-6 h-9`}>
      <View style={tw.style(
        `rounded-full py-2 px-3 row absolute`, 
        isHomeTeam ? `-right-11` : `-left-11`, 
        isHomeTeam && { flexDirection: 'row-reverse' },
        style
      )}
      >
        <FMIcons name={icon} size={20} color={'white'} style={tw.style(isHomeTeam ? `ml-2` : `mr-2` )} />
        <Text style={tw.style(`text-black font-bold`, isHomeTeam ? `ml-2` : `mr-2`)}>{minute}'</Text>
        <Text style={tw.style(`text-black`, Platform.OS === "web" && { whiteSpace: 'nowrap'})}>{event.player.squad_number} {event.player.first_name} {event.player.last_name}</Text>
      </View>
    </View>
  )
}

function EventPill({ event, style, isHomeTeam }) {
  const minute = Math.ceil(event.period_time / 60).toFixed(0)

  
  switch (event.type) {
    case "goal":
      return <FloatingPill event={event} isHomeTeam={isHomeTeam} minute={minute} style={`bg-[#00c252]`} icon={'gol'} />

    case "match_started":
    case "2nd_quarter":
    case "3rd_quarter":
    case "4th_quarter":
    case "half_time":
    case "match_ended":
      return <View style={tw.style(`bg-black/70 rounded-full py-[2px] px-2 row self-center mb-5`, event.type == "match_ended" && `mb-0`)}>
        <EventName event={event} />
      </View>

    case "yellow_card":
      return <FloatingPill event={event} isHomeTeam={isHomeTeam} minute={minute} style={`bg-primary`} icon={'tarjeta'} />


    case "red_card":
      return <FloatingPill event={event} isHomeTeam={isHomeTeam} minute={minute} style={`bg-red`} icon={'tarjeta'} />
     

    case "foul____":
      return <View style={tw`self-center mb-3 h-9`}>
        <View style={tw.style(
          `bg-gray-400 py-2 px-3 row absolute`,
          isHomeTeam ? `right-0 rounded-tl-full rounded-bl-full` : `left-0  rounded-tr-full rounded-br-full`,
        )}
        >
          <FMIcons name={'gol'} size={20} color={'white'} style={tw.style(isHomeTeam ? `mr-0` : `ml-0`)} />
        </View>
      </View>
     

    default:
      return null
  }
}

export default function MatchTimeline({ matchEvents, match }){
  return (
    <View>
      <View style={tw`border-r border-gray-300 absolute left-50% top-0 bottom-0`} />

      {
        matchEvents.map(event => {
          const isHomeTeam = event.league_team?.uuid == match.home_team?.uuid

          return (
            <EventPill key={event.uuid} event={event} isHomeTeam={isHomeTeam} />
          )
        })
      }
    </View>
  )
}
