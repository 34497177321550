import React from 'react';
import { TouchableOpacity, View } from 'react-native';

import Text from './Text';
import tw from '../styles/tailwind';
import { Shadow } from 'react-native-shadow-2';
import { Ionicons } from "@expo/vector-icons"


interface NeonButtonProps{
  label: string,
  onPress: () => void,
  icon?: string,
  iconSize?: number,
  style?: any,
  disabled?: boolean,
  mini?: boolean,
  showChevronRight?: boolean,
}


export default function NeonButton(props:NeonButtonProps) {
  const { mini = false, label, onPress, style, children, showChevronRight } = props

  return (
    <TouchableOpacity
      style={tw.style(
        `border border-[2px] border-[#fddc01] bg-transparent `,
        {borderRadius: 12},
        style?.container,
      )}
      disabled={props.disabled}
      onPress={onPress}
    >
      <Shadow
        distance={6}
        startColor={'#fddc0113'}
        finalColor={'#fddc0105'}
        paintInside={false}
        radius={7}
        containerViewStyle={tw` bg-transparent justify-center`}
        viewStyle={tw`w-full overflow-hidden bg-[#fddc0107] rounded-lg`}
      >
        <Shadow
          distance={15}
          startColor={'#fddc0110'}
          finalColor={'#fddc0122'}
          radius={4}
          offset={[0, 0]}
          containerViewStyle={tw.style(mini ? `mx-1 my-1` : `mx-3 my-3`, style?.button)}
          viewStyle={tw.style(`w-full items-center`, ! mini && `py-1`)} 
        >
          {
            label &&
              <Text style={tw.style(`text-white font-700`, ! mini && `py-1`)}>{mini ? label : label.toUpperCase() }</Text>
          }
          {
            (!label && !!children) && children
          }

          {
            showChevronRight &&
              <View style={tw`absolute top-[50%] h-5 w-5 -mt-[7px] right-3`}>
                <Ionicons
                  name='chevron-forward-outline'
                  size={20}
                  color={tw.color('primary')}
                  style={tw``}
                />
              </View>
          }
        </Shadow>
      </Shadow>
    </TouchableOpacity>
  )
}
