import React, { useState } from "react";
import { View } from "react-native";
import Button from "../../../../Components/Button";
import ImagesPicker from "../../../../Components/ImagesPicker";

import Input from "../../../../Components/Input";
import LabelSwitch from "../../../../Components/LabelSwitch";
import OptionsSelect from "../../../../Components/OptionsSelect";
import Text from "../../../../Components/Text";

import tw from "../../../../styles/tailwind";


export default function NewLeague_Step1({ control, handleSubmit, onSubmit }) {
  const [image, setImage] = useState(null);

  const onImageChanged = (data) => {
    if (data !== null) {
      console.log("recibiendo img data en parent", data);
      setImage(data)

      console.log("image", image?.base64);

    } else {
      console.log("nohaydatos")
    }
  }

  const submitStep1 = (data) => {

    const _data = {
      ...data,
      image: image?.base64,
    }

    onSubmit(_data)
  }

  return (
    <View style={tw`flex-1 flex-col mb-10 pt-5`}>

      {/* Nombre del Torneo */}
      <Input
        name="name"
        label="Nombre de Torneo"
        control={control}
        rules={{ required: "El nombre es requerido" }}
        type={"text"}
        style={{
          input: { marginBottom: 0 },
          label: tw.style(`font-normal bg-transparent`),
          container: tw`mx-3 mb-5`
        }}
      />


      {/* Modalidad */}
      <View style={tw`flex-col`}>
        <Text style={tw`mb-1 ml-7`}>Modalidad de Fútbol</Text>
        <OptionsSelect
          control={control}
          name="variation"
          options={[
            {
              icon: "7",
              value: "fut_7",
              label: "Fútbol",
            },
            {
              icon: "R",
              value: "fut_rapido",
              label: "Rápido",
            },
            {
              icon: "5",
              value: "fut_5",
              label: "Fútbol",
            },
            {
              icon: "S",
              value: "soccer",
              label: "Soccer",
            },
            {
              icon: "6",
              value: "fut_6",
              label: "Fútbol",
            },
          ]}

          style={{
            option: tw`flex-1 px-1 py-3 border-0`,
            container: tw`py-3 px-3`,
            optionsWrapper: tw`justify-between`,
            icon: tw`text-xl text-gray-300`,
            label: `font-normal text-sm mt-1`,
          }}
        />
      </View>

      <View style={tw`px-3`}>
        {/* Description */}
        <Input
          name="description"
          control={control}
          type={"text"}
          label="Descripción del torneo (opcional)"
          multiline
          numberOfLines={3}
          style={{
            container: tw`mb-5 mt-8`,
            input: { marginBottom: 0 },
            label: tw.style(`font-normal bg-transparent`),
          }}
        />


        {/* formato de torneo */}
        {/* { label: "Liga", value:"league" } */}
        <OptionsSelect
          control={control}
          name="stages_mode"
          options={[
            {
              icon: "medalla",
              iconlabel: "Liga",
              value: "league"
            },
            {
              icon: "copa",
              iconlabel: "Copa",
              value: "cup"
            },
            {
              icon: "torneo",
              iconlabel: "Liga + Copa",
              value: "league_cup"
            },
          ]}
          rules={{ required: true }}
          style={{
            container: tw`mb-6`,
            option: tw`flex-1 px-4 py-4 border-0`,
            optionsWrapper: tw`justify-between`
          }}
        />


        {/* opción de ida y vuelta */}
        {/* <LabelSwitch
          label={"Partidos de Ida y vuelta"}
          name="format"
          control={control}
          onValue="ida_y_vuelta"
          offValue="ida"
          style={{ container: tw`mb-5 rounded-xl` }}
        /> */}


        {/* Premios */}
        <View style={tw`flex-col`}>
          <Text style={tw`text-lg mb-3`}>Premios</Text>
          <Input
            name="prizes"
            control={control}
            type={"text"}
            placeholder="Descripción de los premios"
            multiline
            numberOfLines={3}
          />
        </View>

        {/* Selector de imagen */}
        {/* <ImagesPicker
          label={"Imagen del torneo"}
          onImageChanged={onImageChanged}
          icon={"cargar"}
          style={{
            buttonWrapper: tw`w-[100%] bg-transparent h-16 rounded-xl border-dashed border-2 border-gray-300 mb-4`,
            label: tw`text-gray-300`,
            labelcontainer: tw`mt-4`
          }}
        /> */}

        
        <Button
          label={"Siguiente paso"}
          onPress={(handleSubmit(submitStep1))}
        />

      </View>
    </View>
  )
}