import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, ScrollView, Image } from "react-native"
import Text from '../../../../Components/Text';
import { Ionicons } from "@expo/vector-icons"

import deburr from 'lodash/deburr';


import tw from '../../../../styles/tailwind';
import AddPlayerBottomSheet from '../../Teams/Players/AddPlayerBottomSheet';
import PlayerButton from './PlayerButton';
import CheckButton from '../../../../Components/CheckButton';
import { useForm } from 'react-hook-form';
import { TeamsAssistance } from '../Match.ArbitrationTablet';
import IconDefault from './svgs/default.svg'

export default function TeamsAssistanceSelector({
  homeTeam, awayTeam, onChange, assistance, match, onPlayerCreated, onPlayerLongPressed, reverseAssistance
}) {
  const [selectedPlayers, setSelectedPlayers] = useState<TeamsAssistance>(assistance ?? {home:[], away: [], home_no_show: false ,away_no_show: false,});
  const [newPlayerForm, setNewPlayerForm] = useState<{leagueTeam:LeagueTeam, side:"home"|"away"}|false>(false);

  const { control, watch, setValue } = useForm({ defaultValues: { no_show: { home: selectedPlayers.home_no_show, away: selectedPlayers.away_no_show}}})
  const noShows = watch("no_show")


  useEffect(() => {
    setSelectedPlayers(assistance)
    setValue("no_show.home", assistance.home_no_show)
    setValue("no_show.away", assistance.away_no_show)
  }, [assistance]);

  useEffect(() => {
    setValue("no_show.home", selectedPlayers.home_no_show)
    setValue("no_show.away", selectedPlayers.away_no_show)
  }, []);


  useEffect(() => {
    const _selected = {
      ...selectedPlayers,
    }

    const homeNoShow = !!noShows?.home
    const awayNoShow = !!noShows?.away

    let changed = false

    if (homeNoShow !== selectedPlayers.home_no_show){
      _selected.home_no_show = homeNoShow

      if (homeNoShow){
        _selected.home = []
        _selected.away_no_show = false
      }

      changed = true
    }

    if (awayNoShow !== selectedPlayers.away_no_show){
      _selected.away_no_show = awayNoShow

      if(awayNoShow){
        _selected.away = []
        _selected.home_no_show = false
      }

      changed = true
    }


    setSelectedPlayers(_selected)

    changed && onChange?.(_selected)
  }, [noShows?.home, noShows?.away]);



  function onPlayerSelected(player: Player, team, side) {
    let selected;

    const optionIndex = selectedPlayers[side].findIndex(e => e.uuid == player.uuid)

    if (optionIndex != -1) {
      selected = selectedPlayers[side].filter(e => e.uuid != player.uuid)
    } else {
      selected = [...selectedPlayers[side], player]
    }

    const _selected = {
      ...selectedPlayers,
      [side]: selected,
      [`${side}_no_show`]: false, // para desactivar un posible default del equipo
    }

    setSelectedPlayers(_selected)
    onChange && onChange(_selected)
  }


  const homePlayers = homeTeam?.members
    .filter(member => member.pivot.roles?.indexOf("player") !== -1)
    .sort((a, b) => deburr(a.name).localeCompare(deburr(b.name)))
  // .sort((a, b) => parseInt(a.squad_number) > parseInt(b.squad_number))

  const awayPlayers = awayTeam?.members
    .filter(member => member.pivot.roles?.indexOf("player") !== -1)
    .sort((a, b) => deburr(a.name).localeCompare(deburr(b.name)))
  // .sort((a, b) => parseInt(a.squad_number) > parseInt(b.squad_number))



  const homeUnselected = homePlayers?.filter(player => !!!selectedPlayers['home'].find(e => e.uuid == player.uuid))

  const homeSelected = homePlayers?.filter(player => !!selectedPlayers['home'].find(e => e.uuid == player.uuid))

  const awayUnselected = awayPlayers?.filter(player => !!!selectedPlayers['away'].find(e => e.uuid == player.uuid))

  const awaySelected = awayPlayers?.filter(player => !!selectedPlayers['away'].find(e => e.uuid == player.uuid))

  return (
    <View style={tw.style(`row flex-1 px-6 items-start -mt-2`, reverseAssistance && { flexDirection: 'row-reverse' })}>
      <Image
        source={require('./svgs/bg_tablet.png')}
        style={tw`absolute left-0 right-0 top-0 bottom-0 w-[100vw] h-100%`}
        resizeMode={"cover"}
      />

      <ScrollView style={tw`flex-1`} contentContainerStyle={tw`pr-6 pt-2`}>
        <View style={tw.style(`row flex-wrap`, reverseAssistance && `justify-end`)}>
          {
            homeSelected?.map(player => {
                return (
                  <PlayerButton
                    onPress={() => onPlayerSelected(player, homeTeam, "home")}
                    onLongPress={() => { onPlayerLongPressed(player, homeTeam, "home") }}
                    player={player}
                    key={player.uuid}
                    textColor={"#303030"}
                    style={tw.style(`mr-2 mb-3 bg-primary`)} // isSelected && `bg-primary`
                  />
                )
              })
          }

          {
            homeSelected?.length === 0 &&
              <Text style={tw`text-center text-white flex-1 text-xl py-6 text-gray-300`}>Selecciona los jugadores que ingresan a la cancha</Text>
          }
        </View>

        <View style={tw.style(`row flex-wrap mt-4 pt-4 border-t border-[#716967]`, reverseAssistance && `justify-end`)}>
          {
            homeUnselected?.map(player => {
              return (
                <PlayerButton
                  onPress={() => onPlayerSelected(player, homeTeam, "home")}
                  onLongPress={() => { onPlayerLongPressed(player, homeTeam, "home") }}
                  player={player}
                  key={player.uuid}
                  style={tw.style(`mr-2 mb-3 bg-[#716967]`)}
                  textColor={"#303030"}
                  numberColor={"#303030"}
                />
              )
            })
          }

          <TouchableOpacity
            style={tw`border-4 border-[#716967] h-28 w-30 rounded-lg px-2 pt-1 items-center`}
            onPress={() => setNewPlayerForm({leagueTeam: homeTeam, side: "home"})}>
            <Ionicons name='add' color={"#716967"} style={tw``} size={40} />
            <Text style={tw`text-[#716967] text-lg -mt-1`}>Agregar{"\n"}Jugador</Text>
          </TouchableOpacity>
        </View>

        {/* Botón default */}
        <View style={tw`mt-5 mb-2 items-center`}>
          <CheckButton
            style={{ label: tw`text-[#fa3559] text-lg`, container: tw.style(`border-[#fa3559] mb-5 px-4 rounded-xl`, noShows.home && `bg-[#281717]`), checkbox: tw`bg-transparent border-[#fa3559]` }}
            label={"Equipo no se presentó (default)"}
            control={control}
            name={"no_show.home"}
            activeColor={'#fa3559'}
            icon={<IconDefault style={tw`w-6 h-6 ml-2 -my-1`} />}
          />
        </View>
      </ScrollView>


      {/*
        Equipo visitante
      */}
      <ScrollView style={tw`flex-1`} contentContainerStyle={tw`pt-2`}>
        <View style={tw.style(`row w-full flex-wrap`, ! reverseAssistance && `justify-end`)}>
          {
            awaySelected?.map(player => {
                return (
                  <PlayerButton
                      onPress={() => { onPlayerSelected(player, awayTeam, "away") }}
                      onLongPress={() => { onPlayerLongPressed(player, awayTeam, "away") }}
                      player={player}
                      key={player.uuid}
                      textColor={"#303030"}
                      style={tw.style(`mr-2 mb-3 bg-primary`)}
                      right
                    />
                  )
                }
              )
          }

          {
            awaySelected?.length === 0 &&
            <Text style={tw`text-center text-white flex-1 text-xl py-6 text-gray-300`}>Selecciona los jugadores que ingresan a la cancha</Text>
          }
        </View>

        <View style={tw.style(`row flex-wrap mt-4 pt-4 border-t border-[#716967] w-full`, !reverseAssistance && `justify-end`)}>
          {
            awayUnselected?.map(player => {
                return (
                  <PlayerButton
                    onPress={() => { onPlayerSelected(player, awayTeam, "away") }}
                    onLongPress={() => { onPlayerLongPressed(player, awayTeam, "away") }}
                    player={player}
                    key={player.uuid}
                    style={tw.style(`mr-2 mb-3 bg-[#716967]`)}
                    textColor={"#303030"}
                    numberColor={"#303030"}
                    right
                  />
                )
              })
          }

          <TouchableOpacity
            style={tw`border-4 border-[#716967] h-28 w-30 rounded-lg px-2 pt-1 items-center`}
            onPress={() => setNewPlayerForm({ leagueTeam: awayTeam, side: "away" })}>
            <Ionicons name='add' color={"#716967"} style={tw``} size={40} />
            <Text style={tw`text-[#716967] text-lg -mt-1`}>Agregar{"\n"}Jugador</Text>
          </TouchableOpacity>
        </View>

        {/* Botón default */}
        <View style={tw`mt-5 mb-2 items-center`}>
          <CheckButton
            style={{ label: tw`text-[#fa3559] text-lg`, container: tw.style(`border-[#fa3559] mb-5 px-4 rounded-xl`, noShows.away && `bg-[#281717]`), checkbox: tw`bg-transparent border-[#fa3559]` }}
            label={"Equipo no se presentó (default)"}
            control={control}
            name={"no_show.away"}
            activeColor={'#fa3559'}
            icon={<IconDefault style={tw`w-6 h-6 ml-2 -my-1`} />}
          />
        </View>
      </ScrollView>

      <AddPlayerBottomSheet
        visible={newPlayerForm}
        selectedRole={"player"}
        onRequestClose={() => {
          setNewPlayerForm(false)
        }}
        leagueUid={match.league.uuid}
        leagueTeam={newPlayerForm.leagueTeam}
        onPlayerCreated={(player) => {
          onPlayerSelected(player, newPlayerForm.leagueTeam, newPlayerForm.side) // mete a la cancha al nuevo miembro
          setNewPlayerForm(false) 
          onPlayerCreated && onPlayerCreated()
        }}
        gender={match.league?.gender}
        autofocus
        canDuplicateSquadNumber={true}
      />
    </View>
  )
}

