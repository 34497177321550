import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, Image } from "react-native"


import tw from '../../../styles/tailwind';
import Text from '../../../Components/Text';
import TitleSeparator from '../../../Components/TitleSeparator';

let achievements = [
  // [
  //   { name: "Volando Alto", image_url: "https://cdn.futmaster.app/gamification/achievements/test/1.png?r", },
  //   { name: "Pie de Oro", image_url: "https://cdn.futmaster.app/gamification/achievements/test/2.png?r", },
  //   { name: "Como un PRO", image_url: "https://cdn.futmaster.app/gamification/achievements/test/3.png?r", },
  //   { name: "Leñador", image_url: "https://cdn.futmaster.app/gamification/achievements/test/4.png?r", },
  //   { name: "Hat-Trick", image_url: "https://cdn.futmaster.app/gamification/achievements/test/5.png?r", },
  //   { name: "Romperredes", image_url: "https://cdn.futmaster.app/gamification/achievements/test/6.png?r", },
  // ],
  // [
  //   { name: "Volando Alto", image_url: "https://cdn.futmaster.app/gamification/achievements/test/7.png?r", },
  //   { name: "Pie de Oro", image_url: "https://cdn.futmaster.app/gamification/achievements/test/8.png?r", },
  //   { name: "Como un PRO", image_url: "https://cdn.futmaster.app/gamification/achievements/test/9.png?r", },
  //   { name: "Leñador", image_url: "https://cdn.futmaster.app/gamification/achievements/test/10.png?r", },
  //   { name: "Hat-Trick", image_url: "https://cdn.futmaster.app/gamification/achievements/test/11.png?r", },
  //   { name: "Romperredes", image_url: "https://cdn.futmaster.app/gamification/achievements/test/12.png?r", },
  // ],
  [
    { name: "Volando Alto", image_url: "https://cdn.futmaster.app/gamification/achievements/player/volando-alto.png?r", },
    { name: "Pie de Oro", image_url: "https://cdn.futmaster.app/gamification/achievements/player/pie-oro.png?r", },
    { name: "Como un PRO", image_url: "https://cdn.futmaster.app/gamification/achievements/player/como-un-pro.png?r", },
    { name: "Leñador", image_url: "https://cdn.futmaster.app/gamification/achievements/player/lenador.png?r", },
    { name: "Hat-Trick", image_url: "https://cdn.futmaster.app/gamification/achievements/player/hattrick.png?r2", },
    { name: "Romperredes", image_url: "https://cdn.futmaster.app/gamification/achievements/player/5-goles.png?r", },
  ],
  [
    { name: "Tremebundo", image_url: "https://cdn.futmaster.app/gamification/achievements/test/b1.png?r", },
    { name: "Campeón", image_url: "https://cdn.futmaster.app/gamification/achievements/test/b2.png?r", },
    { name: "Balonero", image_url: "https://cdn.futmaster.app/gamification/achievements/test/b3.png?r", },
    { name: "El PRO", image_url: "https://cdn.futmaster.app/gamification/achievements/test/b4.png?r", },
    { name: "Logro Secreto", image_url: "https://cdn.futmaster.app/gamification/achievements/test/secret.png?r", },
    { name: "Logro Secreto", image_url: "https://cdn.futmaster.app/gamification/achievements/test/secret.png?r", },
  ],
]

export default function AchievementsTable({ style, locked, unlocked }) {

  const [setInverse, setSetInverse] = useState(0);

  return (
    <View style={tw.style(style)}>
      <TitleSeparator title="Mis logros" icon="logros" style={tw`mt-2 mb-5 -mx-3`} />

      <Text style={tw`text-yellow-200 mt-2 mb-8 text-center`}><Text style={tw`text-primary`}>{unlocked.length}</Text> logros personales desbloqueados</Text>

      <View style={tw`row flex-wrap justify-between items-start -mx-2`}>
        {
          unlocked.map((ach, i) =>
            <TouchableOpacity style={tw`items-center mx-2 mb-4 w-24`} key={i} onPress={() => setSetInverse(setInverse+1 < 3 ? setInverse+1 : 0)}>
              <Image
                // source={{ uri: player.team.gamify_level?.image_url }}
                key={ach.name}
                source={{ uri: ach.image_url }}
                style={tw`h-22 w-22`}
              />
              <Text style={tw`text-white text-sm text-center`}>{ach.name}</Text>

              {
                ach.count > 1 &&
                  <View style={tw`absolute top-0 right-2 bg-primary rounded-full h-5 w-5 items-center justify-center`}>
                    <Text style={tw`-mt-[2px] font-bold`}>{ach.count}</Text>
                  </View>
              }
            </TouchableOpacity>

          )
        }
      </View>

      <Text style={tw`text-yellow-200 mt-4 mb-8 text-center`}>{locked.length} logros bloqueados</Text>

      <View style={tw`row flex-wrap justify-between items-start -mx-2`}>
        {
          locked.map((ach, i) =>
            <TouchableOpacity style={tw`items-center mx-2 mb-4 w-24`} key={i} onPress={() => {}}>
              <Image
                key={ach.name}
                source={{ uri: ach.image_url }}
                style={tw`h-22 w-22`}
              />
              <Text style={tw`text-gray-600 text-sm text-center`}>{ach.name}</Text>
            </TouchableOpacity>
          )
        }
      </View>
    </View>
  )
}
