import React, { useEffect, useState } from "react";
import { View } from "react-native";
import Text from "../Components/Text";

import tw from "../styles/tailwind";


import { Slider as RNSlider } from '@miblanchard/react-native-slider';
import { useController } from "react-hook-form";


export default function Slider({ min, max, initialValue, minLabel = "mín", maxLabel = "máx", onChange, control, name, rules }) {
  const [value, setValue] = useState(initialValue);

  var { field, fieldState: { invalid, isTouched, isDirty, error } } = useController({
    control: control,
    defaultValue: initialValue,
    name: name,
    rules: rules,
  })

  function onValueChange(value) {
    setValue(value)
    onChange && onChange(value)
    field.onChange(value)
  }
  
  useEffect(() => {
    if(field.value){
      setValue(field.value)
    }
  }, [])

  return (
    <View style={{}}>
      <RNSlider
        value={value}
        onValueChange={onValueChange}
        step={1}
        minimumValue={min}
        maximumValue={max}
      />

      <View style={tw`flex-row justify-between`}>
        <Text>{value.length > 0 ? `${value[0]} (${minLabel})` : value}</Text>

        {
          value.length > 0 &&
          <Text>{value[1]} ({maxLabel})</Text>
        }
      </View>
    </View>
  );
}


