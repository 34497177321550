import React, {useEffect, useState} from "react";
import { View, ScrollView, TouchableOpacity, Image } from "react-native";
import TabScreens from "../../Components/TabScreens";
import TeamsPlayer from "./Team.Player";

import TeamsSummary from "./Team.Summary";
import tw from '../../styles/tailwind';

import api from '../../utils/api'
import Loading from "../../Components/Loading";
import AddPlayerBottomSheet from "../Admin/Teams/Players/AddPlayerBottomSheet";
import { useNavigation } from "@react-navigation/native";
import Text from "../../Components/Text";
import { useDispatch, useSelector } from "react-redux";
import { loadLeagueTeam } from "../../store/Reducers/leagueTeams";
import TeamBadgeSelectorBottomSheet from "./Components/TeamBadgeSelectorBottomSheet";
import TeamBadge from "../../Components/Teams/TeamBadge";
import { LinearGradient } from "expo-linear-gradient";
import FMIcons from "../../Components/FMIcon";
import TitleSeparator from "../../Components/TitleSeparator";
import GradientSeparator from "../../Components/GradientSeparator";
import TeamBgGradient from "../../Components/Teams/TeamBgGradient";
import NeonButton from "../../Components/NeonButton";

import { useToast } from "react-native-toast-notifications";


function Counter({ label, count, color }) {
  return (
    <View style={tw`flex-1 flex-col items-center`}>
      <Text style={tw.style(`text-xl font-bold text-gray`)}>{count}</Text>
      <Text style={tw.style(`text-md  text-${color}`)}>{label}</Text>
    </View>
  )
}



import TeamBgImage from '../../assets/teams/team_bg.svg'
import AddPlayerOptionsBottomSheet from "./Components/AddPlayerOptionsBottomSheet";
import TeamStats from "./Components/TeamStats";


function TeamHeader({ leagueTeam, style, onChangeBadgePress, canChangeBadge=false }: {
  leagueTeam: LeagueTeam,
  style?: any,
  canChangeBadge?: boolean,
  onChangeBadgePress?: () => void,
}) {
  const navigation = useNavigation()

  return (
    <View style={tw.style(`pt-4 pb-6 rounded-xl bg-gray-500 overflow-hidden mt-0`, style?.container)}>
      {/* BG linear gradient */}
      <TeamBgGradient />

      <TeamBgImage width={175} height={200} style={tw`absolute bottom-0 left-0`} />

      {/* Equipo y badge */}
      <View style={tw.style(`flex-row items-center mx-5`)}>
        <TeamBadge team={leagueTeam.team} width={80} height={80} />

        <View style={tw``}>
          <Text style={tw.style(`ml-4 text-xl text-gray font-500`)}>{leagueTeam.name}</Text>

            {
              canChangeBadge &&
                <View style={tw`items-center mt-1`}>
                  <NeonButton label="Cambiar escudo"
                    mini
                    onPress={onChangeBadgePress}
                    style={{button: tw`mx-4 my-[1px]`}}
                  />
                </View>
            }
          
        </View>
      </View>


      <GradientSeparator />


      {/* Info liga */}
      <TouchableOpacity
        onPress={() => navigation.navigate('League.Home', { leagueId: leagueTeam.league.uuid })}
        style={tw`row items-center justify-center mt-1`}>
        {
          leagueTeam?.league?.image_url &&
            <Image source={{ uri: leagueTeam.league.image_url }} style={tw.style(`h-11 w-11 `, { borderRadius: 11})} />
        }

        <View style={tw`ml-3`}>
          <Text style={tw`text-white text-md font-500`}>{leagueTeam?.league?.name}</Text>
          {
            leagueTeam.stats?.position && <Text style={tw`text-white font-500`}><Text style={tw`text-[#b8b28c] mr-2 font-400`}>Posición</Text> {leagueTeam.stats.position}</Text>
          }
        </View>
      </TouchableOpacity>



      <View style={tw`flex-row mx-3 items-center mt-3`}>
        <Counter label={"Ganados"} count={leagueTeam.stats?.win || 0} color="green" />
        <View style={tw`border-l border-gray-300 h-5`} />
        <Counter label={"Perdidos"} count={leagueTeam.stats?.loss || 0} color="red" />
        <View style={tw`border-l border-gray-300 h-5`} />
        <Counter label={"Empate"} count={leagueTeam.stats?.draw || 0} color="gray" />
      </View>
    </View>
  )
}

function PlayerImage({player, ...props}){
  return (
    <Image {...props} source={{uri: player.image_url}} />
  )
}

function PlayerRow({ player, onPress, highlighted=false }: {
  player: Player,
  onPress?: () => void,
  highlighted?: boolean,
}) {
  const isRepre = player.pivot?.roles.indexOf('representative') !== -1

  return (
    <TouchableOpacity
      style={tw`mb-5`}
      onPress={onPress}
    >
      {/* Imagen y nombre */}
      <View style={tw`w-25`}>
        <PlayerImage player={player} style={tw.style(`h-25 w-25 rounded-xl`, highlighted && {borderWidth: 2}, highlighted && `border-primary`)} />

        <Text style={tw`text-gray text-center mt-1`}>{player.name}</Text>
      </View>

      {/* Dorsal */}
      {
        player.squad_number && 
          <View style={tw.style(
            `absolute -top-[2px] -right-[2px] rounded-full bg-primary w-[30px] h-[30px] items-center justify-center`,
            ! player.is_registered && `bg-[#cccccc]`
          )}>
            <Text style={tw`font-bold text-lg`}>{player.squad_number}</Text>
          </View>
      }
      
      {/* Dorsal */}
      {
        isRepre &&
          <View style={tw.style(
            `absolute top-1 left-1 w-[27px] h-[20px] items-center justify-center`
          )}>
            <FMIcons name={"capitan"} size={20} color={tw.color('primary')} />
          </View>
      }
      
      {/* {
        player.is_registered &&
      } */}
    </TouchableOpacity>
  )
}


function NewPlayerBtn({ onPress, isFemaleTeam, disabled }){
  return (
    <NeonButton style={{container: `w-25 h-25`}} onPress={onPress} disabled={disabled}>
      <View style={tw.style(`bg-[#332f1200] items-center w-full h-full`, disabled && `opacity-20`)}>
        <Text style={tw.style(`text-white font-500`, {fontSize: 30, lineHeight: 26})}>+</Text>
        <Text style={tw`text-white font-500 text-base -mx-2 text-center`}>AGREGAR JUGADOR{isFemaleTeam ? "A" : ""}</Text>
      </View>
    </NeonButton>
  )
}


function PlayersList({ leagueTeam, players, style, onNewPlayerPressed, isTeamManager, teamId, leagueId, isFemaleTeam }) {
  const navigation = useNavigation();

  const currentPlayer = useSelector(state => state.status.player)


  return (
    <View style={tw.style(``, style?.container)}>

      {/* Título y botón */}
      <TitleSeparator title={`Jugador${isFemaleTeam ? "as" : "es"} (${players.length})`} style={tw``}/>

      
      {/* Miembros */}
      <View style={tw`row flex-wrap justify-between items-start`}>
        {
          players.sort((a, b) => parseInt(a.squad_number) > parseInt(b.squad_number) ).map(player =>
            <PlayerRow
              key={player.uuid}
              player={player}
              highlighted={currentPlayer.uuid == player.uuid}
              onPress={() => {
                //!!! TODO: Conexión a datos/redux
                navigation.navigate("Player.Detail", {
                  playerId: player.uuid,
                  leagueTeamId: leagueTeam.uuid,
                  leagueTeamName: leagueTeam.name,
                  leagueId, teamId, // 
                })
              }}
            />
          )
        }

        {/* Botón agregar jugador */}
        {
          isTeamManager &&
            <NewPlayerBtn
              onPress={onNewPlayerPressed}
              isFemaleTeam={isFemaleTeam}
              disabled={players.length >= leagueTeam.league.max_players}
            />
        }
      </View>

      {
        (isTeamManager && players.length >= leagueTeam.league.max_players) &&
          <View style={tw`border py-3 px-3 rounded-lg bg-red-100 mt-2 mb-6`}>
            <Text style={tw`text-red-400`}>El límite de jugador{isFemaleTeam ? 'a' : 'e'}s registrad{isFemaleTeam ? 'a' : 'o'}s es {leagueTeam.league.max_players}</Text>
            <Text style={tw`text-red-400`}>Para poder agregar más es necesario dar de baja a l{isFemaleTeam ? 'a' : 'o'}s jugador{isFemaleTeam ? 'a' : 'e'}s inactiv{isFemaleTeam ? 'a' : 'o'}s.</Text>
          </View>
      }

      <View>
        <View style={tw`row mb-1`}>
          <FMIcons name={"capitan"} size={17} color={tw.color('primary')} />
          <Text style={tw`text-gray-200 ml-2`}>Capitán</Text>
        </View>

        <View style={tw`row mb-1`}>
          <View style={tw`h-4 w-4 rounded-full bg-yellow mr-3 ml-1`} />
          <Text style={tw`text-gray-200`}>Registrado en FutMaster</Text>
        </View>

        <View style={tw`row mb-1`}>
          <View style={tw`h-4 w-4 rounded-full bg-[#cccccc] mr-3 ml-1`} />
          <Text style={tw`text-gray-200`}>Sin registro en FutMaster</Text>
        </View>
      </View>
    </View>
  )
}


function NewTabs({ onTabSelected, items, selectedTab, style }){

  return (
    <View style={tw.style(`flex-row pt-5 pb-4 px-3 justify-around bg-gray-500`, style?.container)}>
      {/* <ScrollView horizontal showsHorizontalScrollIndicator={false} contentContainerStyle={tw`flex-row justify-center`}> */}
        
        {items.map((tab, i) => {
          const selected = selectedTab == tab.key

          return (
            <TouchableOpacity
              key={i}
              style={tw.style(`items-center justify-center mx-2 pb-3`, selected && ``)}
              onPress={() => {
                // setIndex(i)
                ! tab.disabled &&
                  onTabSelected?.(tab.key)
              }}
            >
              <FMIcons name={`${tab.icon}`} size={25} style={tw.style(`mb-2 text-yellow-200`, selected && `text-primary`)} />
                            

              <Text
                style={tw.style(`text-sm text-yellow-200 text-center flex-1`, selected && `text-primary`)}
              >{tab.label}</Text>

              {
                selected &&
                  <LinearGradient
                    colors={['#fddc0102', "#fddc01", "#fddc0102"]}
                    start={{ x: 0, y: 0.5 }}
                    end={{ x: 1, y: 0.5 }}
                    style={tw`h-[1px] absolute -left-3 -right-3 bottom-[2px]`}
                  />
              }
            </TouchableOpacity>
          );
        })}
      {/* </ScrollView> */}
    </View>
  )
}






export default function LeagueTeamHome({route, navigation}) {

  const [selectedTab, setSelectedTab] = useState('members');

  // const [leagueTeam, setLeagueTeam] = useState<LeagueTeam | null>();
  const [team, setTeam] = useState(null);
  const [showBadgeSelector, setShowBadgeSelector] = useState();

  // Agregar jugador
  const [modalShown, setModalShown] = useState(false);
  
  const { teamId, leagueId, showDeletedPlayerToast } = route.params;
  const toast = useToast();


  // USE_HOOK - useLeagueTeam(leagueId, teamId)
  const dispatch = useDispatch()
  const leagueTeam = useSelector(state => state.entities.leagueTeams[`${leagueId}-${teamId}`])


  // TODO mover a hook?
  // 
  const myLeagueTeams = useSelector(state => state.status.league_teams)
  const isTeamManager = myLeagueTeams.filter(_leagueTeam => _leagueTeam.pivot.roles?.indexOf("representative") !== -1 && _leagueTeam.uuid === leagueTeam?.uuid).length > 0
  const isFemaleTeam = leagueTeam?.team?.gender == "female"


  useEffect(() => {
    if(showDeletedPlayerToast){
      loadTeam()
      toast.show(`Jugador${isFemaleTeam ? "a" : ""} eliminad${isFemaleTeam ? "a" : "o"} del equipo`, { type: "success", placement: "top" });
    }
  }, [showDeletedPlayerToast]);


  useEffect(() => {
    loadTeam()
  }, [teamId, leagueId])


  function loadTeam() {
    dispatch(loadLeagueTeam(leagueId, teamId))
  }



  if (!leagueTeam) return <Loading visible={true} />


  // USE_HOOK - getLeagueTeamMembers(leagueTeam, 'player')?
  const players = leagueTeam.members.filter(member => member.pivot.roles.indexOf('player') !== -1)

  
  return (
    <ScrollView
      style={tw.style(`flex-1 `)}
      contentContainerStyle={tw`px-2 py-3`}
    >
      {/* Team Card*/}
      <TeamHeader
        canChangeBadge={isTeamManager}
        leagueTeam={leagueTeam}
        onChangeBadgePress={() => {
          isTeamManager &&
            setShowBadgeSelector(true)
        }}
        style={{ container: tw`mb-5 mt-2` }}
      />

      <NewTabs 
        items={[
          { key: 'members', icon: 'equipos', label: "Integrantes" },
          { key: 'achievements', icon: 'logros', label: "Logros", disabled: true },
          { key: 'stats', icon: 'estadistica-tb', label: "Estadísticas" }
        ]}
        selectedTab={selectedTab}
        style={{container: `-mx-2 mb-4`}}
        onTabSelected={tab => setSelectedTab(tab)}
      />


      {
        selectedTab == "members" &&
          <PlayersList
            title={`Jugador${isFemaleTeam ? 'a' : 'e'}s`}
            buttonLabel={`Agregar`}
            isFemaleTeam={isFemaleTeam}
            players={players}
            leagueTeam={leagueTeam}
            isTeamManager={isTeamManager}
            onNewPlayerPressed={() => isTeamManager && setModalShown(true)}
            teamId={teamId}
            leagueId={leagueId}
          />
      }


      {
        selectedTab == "stats" &&
          <TeamStats
            isFemaleTeam={isFemaleTeam}
            players={players}
            leagueTeam={leagueTeam}
          />
      }





      {/* Sheet agregar jugador */}
      <AddPlayerOptionsBottomSheet
        visible={modalShown}
        onRequestClose={() => {
          setModalShown(false)
        }}
        onRequestOpen={() => {
          setModalShown(true)
        }}
        leagueTeam={leagueTeam}
        onPlayerAdded={() => {
          loadTeam()
          setModalShown(false)
        }}
      />

      {/*  */}
      <TeamBadgeSelectorBottomSheet 
        visible={showBadgeSelector}
        onRequestClose={() => setShowBadgeSelector(false)}
        leagueTeam={leagueTeam}
        onBadgeChanged={() => {
          loadTeam()
          setShowBadgeSelector(false)
        }}
      />

      {/* Pantallas */}
      {/* <TabScreens>
        <TeamsSummary tabTitle={"Resumen"} />
        <TeamsPlayer tabTitle={"Jugadores"} team={leagueTeam} />
      </TabScreens> */}
    </ScrollView>
  );
}

