///
//* registerForPushNotificationsAsync
///
import * as Notifications from 'expo-notifications';
import Constants from 'expo-constants';

import api from './api';


// A partir de expo44 `Expo.Constants.deviceId` valió madres....
// Entonces tuvimos que hacer nuestra propia imlementación
// https://stackoverflow.com/a/68553627 lo resuelve a medias 
// peeero: (note that for iOS standalone apps, data stored with expo-secure-store can persist across app installs)
// import * as SecureStore from 'expo-secure-store'; 
// import 'react-native-get-random-values'; // este es dependencia de uuid
// import { v4 as uuidv4 } from 'uuid'; // Y este no sirve en android ya compilado


import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';

export function uuidv4() {
  let d = new Date().getTime();

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);

    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}


async function getDeviceUid(){
  let deviceUid = await AsyncStorage.getItem('@duid')

  if(! deviceUid){
    deviceUid = uuidv4();
    
    await AsyncStorage.setItem('@duid', deviceUid)
  }

  return deviceUid
}




export async function registerForPushNotificationsAsync() {
  const deviceUid = await getDeviceUid()

  

  const { status: existingStatus } = await Notifications.getPermissionsAsync();;

  let finalStatus = existingStatus;

  // only ask if permissions have not already been determined, because
  // iOS won't necessarily prompt the user a second time.
  if (existingStatus !== 'granted') {
    // Android remote notification permissions are granted during the app
    // install, so this will only ask on iOS
    const { status } = await Notifications.requestPermissionsAsync();
    finalStatus = status;
  }

  // Stop here if the user did not grant permissions
  if (finalStatus !== 'granted') {
    console.log('No pudimos activar tus notificaciones push :( inalStatus !== granted');
    return;
  }

  let expoToken
  let tokenNative

  // Expo PushToken
  try {
    let experienceId = undefined

    // if (!Constants.appOwnership) {
    //   // Absence of the manifest means we're in bare workflow
    //   experienceId = '@paxi/Paxi';
    // }

    expoToken = await Notifications.getExpoPushTokenAsync({
      experienceId,
    });
  } catch (e) {
    console.log("[registerForPushNotificationsAsync] getExpoPushTokenAsync ERROR", e)
    return { error: "getExpoPushTokenAsync", data: e }
  }

  // Native PushToken
  try {
    tokenNative = await Notifications.getDevicePushTokenAsync();
  } catch (e) {
    console.log("[registerForPushNotificationsAsync] getDevicePushTokenAsync ERROR", e)

    return { error: "getDevicePushTokenAsync", data: e }
  }


  const deviceData = {
    ...Constants,
    systemFonts: [],
    manifest: {},
    appVersion: Constants.manifest?.version,
  }

  const data = {
    expo_token: expoToken,
    push_token: tokenNative,
    device_uid: deviceUid,
    device_data: deviceData,
  }

  // POST the token to the API
  // console.log("registerForPushNotificationsAsync - registering", JSON.stringify(data))

  api.post("/me/push-token", data)
    .then(response => {
      console.log("PUSH REGISTERED")
    })
    .catch(e => {
      console.log("PUSH REGISTRATION failed", e)
    })


  //
  if (Platform.OS === 'android') {
    Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      // vibrationPattern: [0, 250, 250, 250],
      // lightColor: '#FF231F7C',
    });
  }

  return {success: true, data}
}
/////////