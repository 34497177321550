import React, { useEffect, useState, useRef } from "react";
import * as Battery from 'expo-battery';


export default function useBatteryLevel() {
  const [batteryLevel, setBatteryLevel] = useState(0);

  const refreshBatteryLevel = async () => {
    const batteryLevel = await Battery.getBatteryLevelAsync();
    setBatteryLevel(batteryLevel)
    // console.log("[Battery] batteryLevel ", batteryLevel);
  }

  // en este no está funcionando addBatteryLevelListener, nunca lo llama?
  useEffect(() => {
    let _subscription

    const subscribe = async () => {
      await refreshBatteryLevel()

      _subscription = Battery.addBatteryLevelListener(({ batteryLevel }) => {
        setBatteryLevel(batteryLevel)
        console.log('[Battery] batteryLevel changed!', batteryLevel);
      });
    }

    subscribe()

    return () => {
      console.log("[Battery] Removing subscription")
      _subscription && _subscription.remove();
    }
  }, []);


  // Tons le pnemos un interval cada minuto y asunto arreglado
  useEffect(() => {
    const timer = setInterval(async () => {
      await refreshBatteryLevel()
    }, 1000 * 60)

    refreshBatteryLevel()

    return () => clearInterval(timer);
  }, []);

  return batteryLevel
}