import React, { useEffect, useState } from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";

import Text from "./Text";

import tw from '../styles/tailwind'

import { useController, useForm } from "react-hook-form";

import FMIcons from '../Components/FMIcon';





export default function NumberPicker({ label, min = 1, max = 9999, value, control, name, rules, onChange, style, icon }) {
  const [number, setNumber] = useState(value ? value : min)

  var { field, fieldState: { invalid, isTouched, isDirty, error } } = useController({
    control: control,
    defaultValue: number,
    name: name,
    rules: rules,
  })

  function SubstractNumber(minimum) {
    if (number != null && number > minimum) {
      setNumber(number - 1)
      onChange && onChange(number - 1)
      field.onChange(number - 1)
    }
  }

  function AddNumber(maximum) {
    if (number != null && number < maximum) {
      setNumber(number + 1)
      onChange && onChange(number + 1)
      field.onChange(number + 1)
    }
  }

  useEffect(() => {
    const number = field.value
    setNumber(number)
  }, [])

  return (

    <View style={tw.style(`flex`, style?.container)}>

      <View style={tw.style(`flex-row items-center`, style?.wrapper)}>

        <View style={tw.style(`flex-1 flex-row items-center`, style?.wrapperIconText)}>

          {icon !== null ? <FMIcons name={`${icon}`} size={40} color="#706967" style={tw`mr-5`} /> : null}

          <Text style={tw.style(`flex-1 text-md text-gray-500`, style?.label)}>{label}</Text>

        </View>
        <View style={tw.style(`flex-1 flex-row justify-between items-center`, style?.wrapperButton)}>
          <TouchableOpacity
            style={tw.style(`w-8 h-8 rounded-lg bg-black justify-center items-center`, style?.button)}
            onPress={() => SubstractNumber(min)}
          >
            <FMIcons name={"menos"} size={14} color={"#000"}/>
          </TouchableOpacity>


          <Text style={tw.style(`text-xl font-bold`, style?.number)}>{number}</Text>

          <TouchableOpacity
            style={tw.style(`w-8 h-8 rounded-lg bg-black justify-center items-center`, style?.button)}
            onPress={() => AddNumber(max)}>
            <FMIcons name={"add-b"} size={14} color={"#000"} />
          </TouchableOpacity>
        </View>

      </View>
    </View>

  );
}
