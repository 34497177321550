import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity } from "react-native"


import tw from '../../../../styles/tailwind';
import Text from '../../../../Components/Text';
import api from '../../../../utils/api';
import MatchCard from '../../Components/MatchCard';
import { useNavigation } from '@react-navigation/native';
import Loading from '../../../../Components/Loading';

export default function TeamPayments({ leagueTeam, leagueId }) {
  const [teamPayments, setTeamPayments] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigation = useNavigation()

  useEffect(() => {
    setTeamPayments([])

    if (leagueTeam) {
      loadPayments()
    }
  }, [leagueTeam?.uuid]);


  function loadPayments() {
    setLoading(true)

    api.get(`/leagues/${leagueId}/teams/${leagueTeam.team.uuid}/payments`)
      .then(({ data }) => {
        setTeamPayments(data)
        setLoading(false)
      })
      .catch(({ response }) => {
        setLoading(false)
        console.log("loadPayments - error ", response?.data)
      })
  }

  const pendingPayments = teamPayments.filter(p => p.status !== "paid")
  const paidPayments = teamPayments.filter(p => p.status === "paid")

  const pendingTotal = pendingPayments.reduce((prev, cur) => {
    return cur.amount + prev
  }, 0)

  return (
    <View style={tw.style()}>
      <Text style={tw`text-white text-xl text-center`}><Text style={tw`text-gray-200`}>Pendiente de cobro:</Text> {loading ? `-` : `$${pendingTotal}`}</Text>

      {
        ! loading ?
          <View>
            <View style={tw`mt-5`}>
              <Text style={tw`text-white text-xl mb-2`}>Pagos pendientes:</Text>

              {/* <View style={tw`row justify-between`}>
                <Text style={tw`text-gray-200`}>Inscripción</Text>
                <Text style={tw`text-white`}>$150</Text>
              </View>

              <View style={tw`row justify-between`}>
                <Text style={tw`text-gray-200`}>Arbitraje Partido Jornada 3</Text>
                <Text style={tw`text-white`}>$100</Text>
              </View> */}

              {
                pendingPayments.map(payment => {
                  return (
                    <View style={tw`row justify-between`}>
                      <Text style={tw`text-gray-200`}>{payment.description}</Text>
                      <Text style={tw`text-white`}>${payment.amount}</Text>
                    </View>
                  )
                })
              }

              {
                (! loading && pendingPayments.length == 0) &&
                  <Text style={tw`text-gray-300 italic`}>Sin pagos pendientes</Text>
              }
            </View>

            <View style={tw`mt-5`}>
              <Text style={tw`text-white text-xl mb-2`}>Pagos realizados:</Text>

              {
                paidPayments.map(payment => {
                  const date = new Date(payment.date)

                  return (
                    <View style={tw`row justify-between`}>
                      <Text style={tw`text-gray-200`}>{date.toDateString()} - {payment.description}</Text>
                      <Text style={tw`text-white`}>${payment.amount}</Text>
                    </View>
                  )
                })
              }

              {
                (!loading && paidPayments.length == 0) &&
                  <Text style={tw`text-gray-300 italic`}>Sin pagos realizados</Text>
              }
            </View>
          </View>
        :
          <Loading visible={true} />
      }
      

        {/* <View style={tw`row justify-between`}>
          <Text style={tw`text-gray-200`}>27/06/2022 - Abono Arbitraje Jornada 3</Text>
          <Text style={tw`text-white`}>$300</Text>
        </View>

        <View style={tw`row justify-between`}>
          <Text style={tw`text-gray-200`}>20/06/2022 - Arbitraje Jornada 2</Text>
          <Text style={tw`text-white`}>$400</Text>
        </View>

        <View style={tw`row justify-between`}>
          <Text style={tw`text-gray-200`}>13/06/2022 - Abono Inscripción</Text>
          <Text style={tw`text-white`}>$100</Text>
        </View>

        <View style={tw`row justify-between`}>
          <Text style={tw`text-gray-200`}>13/06/2022 - Arbitraje Jornada 1</Text>
          <Text style={tw`text-white`}>$400</Text>
        </View>

        <View style={tw`row justify-between`}>
          <Text style={tw`text-gray-200`}>10/06/2022 - Abono Inscripción</Text>
          <Text style={tw`text-white`}>$200</Text>
        </View>
      */}


      {/* <Text style={tw`text-center mt-4 text-gray-300 text-sm`}>(Pantalla demo)</Text> */}

      {/* <Text style={tw`text-white`}>Total pagos: $1000</Text> */}
    </View>
  )
}

