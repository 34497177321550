import * as React from 'react';
import {
  View,
} from 'react-native';

import Text from '../Text'

import styles from './styles';

type ItemPropTypes = {
  oneColumnSize: number,
  borderWidth: number,
  index: number,
  style?: Object,
  tenthItemStyle?: Object,
};

class Item extends React.PureComponent<ItemPropTypes> {
  static defaultProps = {
    style: null,
    tenthItemStyle: null,
  };

  render() {
    const { oneColumnSize, borderWidth, index, style, tenthItemStyle } = this.props;

    return (
      <View
        style={[
          styles.subBlock,
          { width: oneColumnSize, borderRightWidth: borderWidth },
          // (index + 1) % 10 === 0 ? { borderRightWidth: borderWidth + 2, height: 40 } : null,
          style,
          (index + 1) % 5 === 0 ? { borderColor: '#888', height: 35, borderRightWidth: borderWidth + 2 } : null,
        ]}
      >
        {
          (index + 1) % 5 === 0 &&
            <Text style={{position: 'absolute', top: -25, left: index < 12 ? 8 : 5, fontWeight: '500', fontSize: 18}}>{index + 1}</Text>
        }
      </View>
    );
  }
}

export default Item;
