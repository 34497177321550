import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, StyleSheet } from "react-native"


import tw from '../../../../styles/tailwind';
import Text from '../../../../Components/Text';
import {Ionicons} from "@expo/vector-icons"

import Voice, {
  SpeechRecognizedEvent,
  SpeechResultsEvent,
  SpeechErrorEvent,
} from '@react-native-voice/voice';
import api from '../../../../utils/api';



import Animated, { useSharedValue, useAnimatedStyle, Extrapolate, withRepeat, withTiming, Easing, interpolate } from 'react-native-reanimated';
import FMIcons from '../../../../Components/FMIcon';

const Pulse = ({ repeat }) => {
  const animation = useSharedValue(0.4);

  useEffect(() => {
    animation.value =
      withRepeat(
        withTiming(1, {
          duration: 2000,
          easing: Easing.linear,
        }),
        -1,
        false
      );
  }, []);

  const animatedStyles = useAnimatedStyle(() => {
    const opacity = interpolate(
      animation.value,
      [0, 1],
      [0.6, 0],
      Extrapolate.CLAMP
    );
    return {
      opacity: opacity,
      transform: [{ scale: animation.value }],
    };
  });
  
  return <Animated.View style={[styles.circle, animatedStyles]} />;
};

const styles = StyleSheet.create({
  circle: {
    width: 75,
    borderRadius: 150,
    height: 75,
    position: 'absolute',
    borderColor: '#e91e63',
    borderWidth: 4,
    left: -13.5,
    top: -13,
    backgroundColor: '#ff6090',
  },
});



export default function AddPlayerVoice({ onPlayerNameDetected, autoStart = false, style, onRequestClose}) {
  const [error, setError] = useState('');
  const [end, setEnd] = useState(false);
  const [started, setStarted] = useState(false);
  const [finalText, setFinalText] = useState('');
  const [listening, setListening] = useState(false);
  const [isAvailable, setIsAvailable] = useState(true);

  useEffect(() => {
    Voice.onSpeechStart = onSpeechStart;
    Voice.onSpeechEnd = onSpeechEnd;
    Voice.onSpeechError = onSpeechError;
    Voice.onSpeechResults = onSpeechResults;
    Voice.onSpeechPartialResults = onSpeechPartialResults;

    
    checkAvailability(autoStart)

    return () => {
      Voice.destroy().then(Voice.removeAllListeners);
    };
  }, []);


  const checkAvailability = async (autoStart=false) =>{
    const available = await Voice.isAvailable()
    const engines = await Voice.getSpeechRecognitionServices()

    setIsAvailable(!!available)

    // console.log(available, engines);

    if(available){
      if (autoStart){
        _startRecognizing();
      }
    }else{
      setError("Reconocimiento de voz no disponible")
    }
  }

  const onSpeechStart = (e: any) => {
    // console.log('onSpeechStart: ', JSON.stringify(e));
    setStarted(true);
    setListening(true);
    setFinalText('');
  };

  const onSpeechEnd = (e: any) => {
    // console.log('onSpeechEnd: ', JSON.stringify(e));
    setListening(false);
    setEnd(true);
  };

  const onSpeechError = (e: SpeechErrorEvent) => {
    // console.log('onSpeechError: ', JSON.stringify(e.error));
    setListening(false);
    setError(`Error en la detección, intenta de nuevo\n(${e.error.message})`)
  };

  const onSpeechResults = (e: SpeechResultsEvent) => {
    // console.log('onSpeechResults: ', JSON.stringify(e));
    setListening(false)

    setFinalText(e.value?.[0])

    if (e.value) {
      processResult(e.value[0])
    }
  };

  const processResult = (result:string) => {
    const parts = result.split(" ")
    const lastPart = parts[parts.length - 1]

    if (isNaN(lastPart) === false && parts.length >= 3) { // la ultima parte es numerica
      // procesa todo correcto
      if(parts.length === 3){
        const playerResult = {
          name: parts[0],
          last_name: parts[1],
          second_last_name: "",
          squad_number: parts[2],
          gender: null
        };

        onPlayerNameDetected?.(playerResult)
      }else{
        parseOnServer(parts)
      }
    } else {
      // mostrar un error
      setError("Formato incorrecto, intenta de nuevo")
    }
  }

  const parseOnServer = (inputParts:Array<string>) => {
    const fullName = [...inputParts] // copiamos el input y le quitamos el dorsal
    const squadNumber = fullName.splice(-1)

    const data = { input: fullName.join(" ") }

    api.post(`/players/parse-name`, data)
    .then(({data}) => {
      console.log("result server ", data)

      const playerResult = {
        name: data.name,
        last_name: data.last_name,
        second_last_name: data.second_last_name,
        squad_number: squadNumber[0],
        gender: data.gender,
      };

      onPlayerNameDetected(playerResult)
    })
    .catch(({error}) => {
      console.log("parseOnServer server ", error)
    })
  }

  const onSpeechPartialResults = (e: SpeechResultsEvent) => {
    // console.log('onSpeechPartialResults: ', JSON.stringify(e));
    setFinalText(e.value?.[0])
  };

  const _startRecognizing = async () => {
    _clearState();

    try {
      await Voice.start('es-MX');
      // console.log('called start');
    } catch (e) {
      console.error(e);
    }
  };

  const _stopRecognizing = async () => {
    try {
      await Voice.stop();
      // console.log('called stop');
    } catch (e) {
      console.error(e);
    }
  };

  const _cancelRecognizing = async () => {
    try {
      await Voice.cancel();
    } catch (e) {
      console.error(e);
    }
  };

  const _destroyRecognizer = async () => {
    try {
      await Voice.destroy();
    } catch (e) {
      console.error(e);
    }
    _clearState();
  };

  const _clearState = () => {
    setError('');
    setEnd(false);
    setStarted(false);
  };

  return (
    <View style={tw.style(`bg-white items-center py-5 px-4`, style)}>
      {/* <View style={tw`row`}>
        <Text style={styles.stat}>{`Started: ${started}`}</Text>
        <Text style={styles.stat}>{`End: ${end}`}</Text>
        <Text style={styles.stat}>{`Error: ${error}`}</Text>
      </View> */}


      <Text style={tw`text-gray-300 text-center`}>Dicta el nombre del jugador y el # del dorsal</Text>

      {
        (!finalText) ?
          <Text style={tw.style(`text-[#ccc] text-xl text-center py-3`)}>Ej: Juan Morales 33</Text>
          :
          <Text style={tw.style(`text-[#B0171F] text-xl text-center py-3`)}>{finalText}</Text>
      }

      {/* Boton microfono */}
      <View style={tw`w-full items-center mt-2`}>

        {/* Boton micro (y animacion pulso ) */}
        <View style={tw``}>
          {
            listening &&
              <Pulse />
          }

          <TouchableOpacity
            onPress={! listening ? _startRecognizing : _stopRecognizing}
            style={tw.style(`border bg-white/80 rounded-full w-12 h-12 items-center justify-center`, listening ? 'border-red' : 'border-black')}
          >
            <Ionicons name="mic" size={34} color={listening ? 'red' : 'black'} style={tw`-mr-[1px]`} />
          </TouchableOpacity>
        </View>

        
        {/* Botón cancelar */}
        {
          true &&
            <TouchableOpacity
              onPress={onRequestClose}
              style={tw.style(`absolute left-0 bottom-2`)}
            >
              <FMIcons name="keyboard" size={18} color={"#888"} />
            </TouchableOpacity>
        }
      </View>


      {
        !! error &&
          <Text style={tw`text-red mt-3 text-center`}>{error}</Text>
      }

      {/* <TouchableOpacity onPress={_stopRecognizing}>
        <Text style={styles.action}>Stop Recognizing</Text>
      </TouchableOpacity>

      <TouchableOpacity onPress={_cancelRecognizing}>
        <Text style={styles.action}>Cancel</Text>
      </TouchableOpacity> */}

      {/* <TouchableHighlight onPress={_destroyRecognizer}>
        <Text style={styles.action}>Destroy</Text>
      </TouchableHighlight> */}
    </View>
  );
}