import React, { useEffect, useState } from "react";
import { View, Switch } from "react-native";

import tw from "../styles/tailwind";

import Text from "../Components/Text";

import { useController } from "react-hook-form";


export default function LabelSwitch({ label, onChange, value, control, name, style, onValue=true, offValue=false }) {
  const [isEnabled, setIsEnabled] = useState(value);

  var { field, fieldState: { invalid, isTouched, isDirty, error } } = useController({
    control: control,
    defaultValue: isEnabled ? onValue : offValue,
    name: name,
  })

  function toggleSwitch() {
    setIsEnabled(previousState => !previousState);
    onChange && onChange( ! isEnabled ? onValue : offValue )
    field.onChange( ! isEnabled ? onValue : offValue )
  }
    

  useEffect(() => {
    const state = field.value == onValue? true : false
    setIsEnabled(state)
  }, [field.value])

  return (
    <View style={tw.style(`flex-row items-center px-5 h-14`, isEnabled ? "bg-gray-500"  : "bg-gray-50", style?.container)}>
      <Text style={tw.style( isEnabled ? "text-gray" : "text-gray-400" ,style?.label)}>{label}</Text>

      <Switch
        trackColor={{ false: '#FFFFFF', true: '#767577' }}
        thumbColor={isEnabled ? '#efc42e' : '#000'}
        ios_backgroundColor="#3e3e3e"
        onValueChange={toggleSwitch}
        value={isEnabled}
        style={tw`absolute right-1`}
      />
    </View>
  )
}