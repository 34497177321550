import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity } from "react-native"


import tw from '../../../styles/tailwind';
import Text from '../../../Components/Text';


import PlayerXPBar from '../../../Components/Gamification/PlayerXPBar';


// GAMIFY_ASSETS
import XPCoin from '../../../Components/Gamification/XPCoin';
import PlayerLevel from '../../../Components/Gamification/PlayerLevel';
import { useSelector } from 'react-redux';



export default function PlayerLevels({ style, player }) {
  const playerXP = player.gamify?.xp ?? 0
  const playerLevel = player.gamify?.level?.level ?? 1

  const fm_league = useSelector(state => state.status.fm_league)
  const currentLevel = fm_league?.levels?.player?.find(e => e.level === playerLevel)

  return (
    <View style={tw.style(style)}>
      <View style={tw`row`}>
        <View style={tw`border-b border-gray/40 flex-1`} />

        <PlayerLevel size={140} style={tw`-m-[20px]`} level={playerLevel} />
        
        <View style={tw`border-b border-gray/40 flex-1`} />
      </View>

      <Text style={tw`font-600 text-center text-white -mt-2`}>NIVEL {playerLevel}</Text>

      <View style={tw`row mt-3 mb-6 justify-center px-3`}>
        <Text style={tw`text-gray-200 text-sm mr-2`}>Cada vez que juegas ganas <Text style={tw`text-primary`}>Puntos de experiencia</Text></Text>
        <XPCoin width={30} height={30} />
      </View>

      <PlayerXPBar current={playerXP} highLimit={currentLevel?.range_high} />
    </View>
  )
}

