import React, { useState, useEffect, useRef } from 'react';
import { View, Animated } from "react-native"



export default function LiveIndicator() {
  const fadeAnim = useRef(new Animated.Value(0.4)).current

  useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(fadeAnim, {
          toValue: 1,
          useNativeDriver: true,
          duration: 800,
        }),
        Animated.timing(fadeAnim, {
          toValue: 0.4,
          useNativeDriver: true,
          duration: 800,
        })
      ])
    ).start();
  }, []);

  return (
    <Animated.View style={
      {
        width: 10, height: 10, backgroundColor: 'red', borderRadius: 10, marginRight: 5,
        opacity: fadeAnim
      }
    } />
  )
}