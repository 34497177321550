import React, { useEffect, useState } from "react";
import { TouchableOpacity, View, Alert, Platform, Image } from "react-native";

import tw from '../../../styles/tailwind';
import api from "../../../utils/api";

import TabScreens from '../../../Components/TabScreens';
import LeagueCalendar from './League.Calendar';
import LeagueStats from './League.Stats';
import LeagueTeams from './League.Teams';
import LeagueSummary from "./League.Summary";
import Text from "../../../Components/Text";
import {Ionicons} from "@expo/vector-icons"


import useLeague from "../../../hooks/useLeague";



import {
  HeaderButtons,
  HiddenItem,
  OverflowMenu,
  Divider,
} from 'react-navigation-header-buttons';
import { Linking } from "react-native";

function LeagueNavBarTitle({ tintColor, league, children }) {
  return (
    <View style={tw`row -ml-4`}>
      {
        league?.image_url &&
          <Image source={{ uri: league?.image_url }} style={tw`h-10 w-10 rounded-lg`} />
      }
      <Text style={tw.style(`text-lg ml-3 font-bold`, { color: tintColor })}>{children}</Text>
    </View>
  )
}



export default function LeagueHome({route, navigation}) {
  const [tabIndex, setTabIndex] = useState(1);

  const leagueId = route.params?.leagueUid;
  const league = useLeague(leagueId)

  useEffect(() => {
    // cambiar el título del navbar
    navigation.setOptions({
      title: league?.name,
      headerTitle: ({ tintColor, children }) => 
          <LeagueNavBarTitle league={league} tintColor={tintColor} children={children} />,
      headerRight: () => <HeaderButtons>
        <OverflowMenu
          style={{marginRight: -5}}
          OverflowIcon={({ color }) => (
            <Ionicons name="ellipsis-vertical-outline" color={"white"} size={24} />
          )}
        >
          {/* <HiddenItem title="Editar Torneo" onPress={() => {}} />
          <Divider /> */}
          {
            tabIndex == 1 &&
            <HiddenItem title="Eliminar Torneo" onPress={() => {
              if(Platform.OS !== "web"){
                Alert.alert(
                  `¿Eliminar torneo ${league?.name}?`,
                  'Esta acción eliminará también todos los partidos y estadísticas de la liga.',
                  [
                    {
                      text: 'Eliminar',
                      onPress: () => deleteTournament(),
                      style: 'cancel',
                    },
                    {
                      text: 'Cancelar',
                      onPress: () => {},
                      // style: 'cancel',
                    },
                  ],
                  {
                    cancelable: true,
                    onDismiss: () =>
                      {}
                  }
                );
              }else{
                const result = confirm(`¿Eliminar ${league?.name}?\nEsta acción eliminará también todos los partidos y estadísticas de la liga.`)
                if (result) deleteTournament()
              }
            }} titleStyle={tw`text-red`} />
          }
          <Divider />

          {
            tabIndex == 3 &&
              <HiddenItem title="Compartir Tabla General" onPress={createLeagueStatsImage} />
          }
          {
            tabIndex == 3 &&
              <HiddenItem title="Compartir Tabla Goleo" onPress={() => createLeagueStatsImage('goals')} />
          }
        </OverflowMenu>
      </HeaderButtons>,
    })
  }, [league, tabIndex]);


  function deleteTournament(){
    api.delete(`/leagues/${leagueId}`)
    .then(({data}) => {
      // mandar a la baby
      navigation.navigate("Tournaments", {reload: Math.random()})
    })
    .catch(({error}) => {

    })
  }

  function createLeagueStatsImage(type){
    api.post(`/leagues/${leagueId}/stats/image`, { type })
    .then(({data}) => {
      Linking.openURL(data.url)
    })
    .catch(({error}) => {

    })
  }



  return (
    <View
      style={tw.style(`flex-1`)}
    >
      {/* Pantallas */}
      <TabScreens onIndexChange={setTabIndex} initialTabIndex={tabIndex}>
        <LeagueSummary league={league} tabTitle={"Resumen"} tabIcon={"resumen-tb"} leagueId={leagueId} />
        <LeagueCalendar tabTitle="Calendario" tabIcon={"calendario-tb"} leagueId={leagueId} />
        <LeagueTeams tabTitle="Equipos" tabIcon={"equipos-tb"}/>
        <LeagueStats tabTitle="Estadísticas" tabIcon={"estadistica-tb"} leagueId={leagueId} />
      </TabScreens>
    </View>
  );
}



