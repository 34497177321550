import React, { useEffect, useState } from "react";
import { Control, useController, useForm } from "react-hook-form";
import { View, Image, TouchableOpacity, ScrollView } from "react-native";

import tw from "../../../../styles/tailwind";
import FMIcons from "../../../../Components/FMIcon";

import Text from "../../../../Components/Text";
import Input from "../../../../Components/Input";
import Button from "../../../../Components/Button";
import TeamBadge from "../../../../Components/Teams/TeamBadge";
import MatchFeaturesCard from "../../../../Components/Matches/MatchFeaturesCard";
import { useSelector } from "react-redux";



interface ArbitrationCertificateConfirmationProps{
  match: Match,
  submitDisabled: boolean,
  onSubmit: (data: MatchTeamsReview) => void,
  handleBack?: () => void,
  defaultingTeam: "home" | "away" | null,
}


export default function ArbitrationCertificateConfirmation({ 
  match, onSubmit, handleBack, submitDisabled = false,
  matchStats, playerAssistance, defaultingTeam,
  homeScore, awayScore
} : ArbitrationCertificateConfirmationProps) {
  const { control, handleSubmit, setValue } = useForm();

  const homePlayers = useSelector(state => state.entities.leagueTeams[`${match.league.uuid}-${match.home_team?.team.uuid}`]).members
  const awayPlayers = useSelector(state => state.entities.leagueTeams[`${match.league.uuid}-${match.home_team?.team.uuid}`]).members


  const submit = (data: MatchTeamsReview) => {
    onSubmit(data)
  }

  const _matchStats = {
    home: Object.keys(matchStats.home).map(playerId => {
      return {
        ...matchStats.home[playerId],
        player: homePlayers.find(player => player.uuid == playerId)
      }
    }),
    away: Object.keys(matchStats.away).map(playerId => {
      return {
        ...matchStats.away[playerId],
        player: awayPlayers.find(player => player.uuid == playerId)
      }
    })
  }

  const homeGoalsPlayers = _matchStats?.home?.filter(e => e.goals > 0).map(e => { return { count: e.goals, label: e.player?.name } })
  const awayGoalsPlayers = _matchStats?.away?.filter(e => e.goals > 0).map(e => { return { count: e.goals, label: e.player?.name } })
  const homeYellowCards = _matchStats?.home?.filter(e => e.yellow_cards > 0).map(e => { return { count: e.yellow_cards, label: e.player?.name } })
  const awayYellowCards = _matchStats?.away?.filter(e => e.yellow_cards > 0).map(e => { return { count: e.yellow_cards, label: e.player?.name } })
  const homeRedCards = _matchStats?.home?.filter(e => e.red_cards > 0).map(e => { return { label: e.player?.name } })
  const awayRedCards = _matchStats?.away?.filter(e => e.red_cards > 0).map(e => { return { label: e.player?.name } })
  
  // console.log(JSON.stringify({matchStats, playerAssistance}));
  
  const defaultGoals = match.league.settings?.default_goals ?? 2
  const defaultPoints = match.league.settings?.default_points ?? 3

  const countReducer = (prev, cur) => {
    return prev + (cur?.count ?? 0)
  }
  // const homeScore = defaultingTeam ? defaultingTeam == "home" ? 0 : defaultGoals : homeGoalsPlayers.reduce(countReducer, 0)
  // const awayScore = defaultingTeam ? defaultingTeam == "away" ? 0 : defaultGoals : awayGoalsPlayers.reduce(countReducer, 0)

  // console.log(JSON.stringify({homeScore, awayScore}));
  // console.log(JSON.stringify({homeGoalsPlayers}));
  // console.log(JSON.stringify({ awayGoalsPlayers, aw: playerAssistance.away, mstats: matchStats?.away}));

  return (
    <ScrollView style={tw`flex-1`}>
      {/* Equipos */}
      <View style={tw`row mb-4 mt-3`}>
        <View style={tw`items-center flex-1`}>
          <TeamBadge team={match.home_team.team} width={50} height={50} />
          <Text style={tw`text-white text-lg mt-2`}>{match.home_team.name}</Text>
        </View>
        
        <View style={tw`items-center flex-1`}>
          <TeamBadge team={match.away_team.team} width={50} height={50} />
          <Text style={tw`text-white text-lg mt-2`}>{match.away_team.name}</Text>
        </View>
      </View>

      {
        defaultingTeam &&
          <View>
            {
              defaultingTeam == "home" ?
                <Text style={tw`text-gray text-lg py-6`}>
                  <Text style={tw`font-bold`}>{match?.home_team.name}</Text> no se presentó.{"\n"}Se agregarán {defaultGoals} goles y {defaultPoints} puntos a
                  <Text style={tw`font-bold`}> {match?.away_team.name}</Text>
                </Text>
                :
                <Text style={tw`text-gray text-lg py-6`}>
                  <Text style={tw`font-bold`}>{match?.away_team.name}</Text> no se presentó.{"\n"}Se agregarán {defaultGoals} goles y {defaultPoints} puntos a
                  <Text style={tw`font-bold`}> {match?.home_team.name}</Text>
                </Text>
            }
          </View>
      }

      <MatchFeaturesCard
        match={match}
        leftValue={homeScore}
        rightValue={awayScore}
        iconLabel={"Goles"}
        iconName={"gol"}
        iconColor={"green"}
        style={{ container: tw`mb-3` }}
        homeStats={homeGoalsPlayers}
        awayStats={awayGoalsPlayers}
      />


      <MatchFeaturesCard
        match={match}
        leftValue={homeYellowCards.reduce(countReducer, 0)}
        rightValue={awayYellowCards.reduce(countReducer, 0)}
        iconLabel={"Tarjeta A."}
        iconName={"tarjeta"}
        iconColor={"primary"}
        style={{ container: tw`mb-3` }}
        homeStats={homeYellowCards}
        awayStats={awayYellowCards}
      />

      <MatchFeaturesCard
        match={match}
        leftValue={homeRedCards.length}
        rightValue={awayRedCards.length}
        iconLabel={"Tarjeta R."}
        iconName={"tarjeta"}
        iconColor={"red"}
        style={{ container: tw`mb-3` }}
        homeStats={homeRedCards}
        awayStats={awayRedCards}
      />



      {/* Botones inferiores */}
      <View style={tw`w-full flex-row self-center justify-around mt-8`}>
        <Button
          style={{ container: tw`flex-row py-3 px-0 bg-secondary px-2`, label: tw`text-primary px-8`, iconstyle: tw`text-primary text-md` }}
          label={"Detalles"}
          onPress={handleBack}
          icon={"chev-izq"}
        />

        <Button
          style={{ container: tw`py-3 px-14` }}
          label={"Terminar"}
          disabled={submitDisabled}
          onPress={handleSubmit(submit)}
        />
      </View>
    </ScrollView>
  )
}