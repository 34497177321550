import React from "react";

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import Ionicons from '@expo/vector-icons/Ionicons'

import Tournaments from "./Admin/Admin.Leagues";
import AdminOverview from "./Admin/Admin.Overview";

import FMIcons from '../Components/FMIcon';

import tw from "../styles/tailwind";
import { View } from "react-native";

const Tab = createBottomTabNavigator();

export default function TabStack() {

  return (
    <Tab.Navigator
      screenOptions={({ route }) => ({
        tabBarIcon: ({ focused, color, size }) => {
          let iconName;

          if (route.name === 'Weekly') {
            iconName = 'calendario-tb';

          } else if (route.name === 'Tournaments') {
            iconName = 'copa';
          }

          return <FMIcons name={iconName} size={23} color={focused ? '#efc42e' : '#706967'}  />
        },
        tabBarActiveTintColor: '#efc42e',
        tabBarInactiveTintColor: '#706967',
        tabBarStyle: { paddingBottom: 6, height: 60, justifyContent: 'center' },
        tabBarBackground: () => (
          <View style={tw`flex-1 bg-secondary border-t border-gray-300`} />
        ),
      })}
    >

      <Tab.Screen
        name="Tournaments"
        component={Tournaments}
        options={{ headerShown: false, tabBarLabel: 'Mis Torneos', }}
      />

      <Tab.Screen
        name="Weekly"
        component={AdminOverview} 
        options={{ tabBarLabel: 'Mi Semana', headerShown: false, }}
      />
    </Tab.Navigator>
  );
}
