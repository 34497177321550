import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, ScrollView } from "react-native"


import tw from '../../../../../styles/tailwind';
import Text from '../../../../../Components/Text';
import TabletModal from '../../../Matches/TabletComponents/TabletModal';
import api from '../../../../../utils/api';
import Loading from '../../../../../Components/Loading';
import TeamBadge from '../../../../../Components/Teams/TeamBadge';
import { Ionicons } from '@expo/vector-icons'

export default function TeamSelector({ leagueId, round, onRequestClose, rivalTeam, onTeamSelected }) {
  const [teams, setTeams] = useState<null|[]>(null);

  useEffect(() => {
    loadLeagueTeams()
  }, []);

  function loadLeagueTeams() {
    const params = {
      rival_id: rivalTeam?.uuid,
    }

    api.get(`/rounds/${round.uuid}/teams`, { params })
      .then(({ data }) => {
        setTeams(data)
      })
      .catch(({ response }) => { })
  }

  return (
    <TabletModal visible={true} onRequestClose={onRequestClose} style={tw.style()}>
      <ScrollView style={tw``} contentContainerStyle={{ flexGrow: 1 }}>
        {
          rivalTeam &&
            <Text style={tw`text-lg text-center font-bold mb-4`}>{rivalTeam.name} VS:</Text>
        }

        <Loading visible={teams === null} />
        
        {
          teams?.map(leagueTeam => {
            const isSelected = false

            if(rivalTeam?.uuid == leagueTeam.uuid) return null

            return (
              <TouchableOpacity
                key={leagueTeam.uuid}
                style={
                  tw.style(
                    `bg-gray mb-3 px-3 py-2 rounded-xl flex-row items-center justify-between`,
                    isSelected && tw`bg-gray-500`,
                  )
                }
                onPress={() => onTeamSelected(leagueTeam)}
              >
                <View style={tw`flex-row items-center`}>
                  <TeamBadge team={leagueTeam.team} width={30} height={30} />

                  <View style={tw`ml-3`}>
                    <Text style={tw.style(`font-bold text-black`, isSelected && `text-white`)}>{leagueTeam.name}</Text>
                    {/* <Text style={tw.style(`text-black`, isSelected && `text-white`)}>{leagueTeam.team.players_count} jugadores</Text> */}
                  </View>
                </View>

                <View style={tw`row`}>
                  {
                    (rivalTeam && leagueTeam.matches_vs_rival !== null) &&
                      <Text>Encuentros: {leagueTeam.matches_vs_rival}</Text>
                  }

                  {
                    isSelected &&
                      <Ionicons name="checkmark-outline" style={tw``} size={20} color={tw.color(`green-400`)} />
                  }
                </View>
              </TouchableOpacity>
            )
          })
        }
      </ScrollView>
    </TabletModal>
  )
}

