import React, { useState, useEffect} from 'react';
import { Button, TextInput, Text, Image, View, TouchableOpacity, ScrollView } from 'react-native'

import api from "../../utils/api"
import tw from '../../styles/tailwind';

import OptionsSelector from './OptionsSelector'
import Select from '../../Components/Select'
import CheckButton from '../../Components/CheckButton'
import Input from '../../Components/Input'

import { useForm } from "react-hook-form";


import questions from './questions'
import ThanksModal from './ThanksModal';


const birthdayYears = []
for(let i=2008; i > 1940; i-- ){
  birthdayYears.push({ label: `${i}`, value: `${i}` })
}


function LocationSelector({control, onChange}){
  const [states, setStates] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const [selectedState, setSelectedState] = useState();

  useEffect(() => {
    api.get("https://locations.ouvioapis.com/Locations/states")
    .then(({data}) => {
      setStates(data)
    })
  }, []);

  function fetchMunicipalities(stateId){
    api.get(`https://locations.ouvioapis.com/Locations/municipalities?state_id=${stateId}`)
    .then(({ data }) => {
      setMunicipalities(data)
    })
  }

  return (
    <>
      <Select
        name="state"
        items={states.map(state => {
          return {label: state.name, value: state.id}
        })}
        placeholder={{ label: "Estado" }}
        control={control}
        rules={{ required: true }}
        style={{ wrapper: tw`mb-3` }}
        onChange={st => {
          setSelectedState(st)
          fetchMunicipalities(st.value)
        }}
      />

      {
        selectedState &&
          <View>
            <Select
              name="city"
              items={municipalities.map(mun => {
                return { label: mun.name, value: mun.id }
              })}
              placeholder={{ label: "Municipio/Delegación" }}
              control={control}
              rules={{ required: true }}
              style={{ wrapper: tw`mb-3` }}
              onChange={mun => {
                // setSelectedState(st)
                onChange && onChange({
                  municipality: mun,
                  state: selectedState,
                })
              }}
            />
          </View>
      }
    </>
  )
}




export default function PollScreen({ route, navigation }) {
  const [answers, setAnswers] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submission, setSubmission] = useState();
  const [submitterName, setSubmitterName] = useState();

  const { control, handleSubmit, formState, formState: { errors }, setError } = useForm();
  formState: { errors }
  console.log("formErrors", errors, route);

  useEffect(() => {
    navigation.setOptions({ })
  }, []);

  function onSubmit(data) {
    const _data = {
      poll_answers: answers,
      ...data,
      birth_year: data.birth_year.value,
      city: data.city.label,
      city_id: data.city.value,
      state: data.state.label,
      state_id: data.state.value,
      referrer_id: route.params?.r,
      params: route.params,
    }

    setIsSubmitting(true)

    // console.log("onsubmit", _data)

    api.post("/giveaway/submit", _data)
      .then(response => {
        setSubmission(response.data)
        setSubmitterName(_data.name.split(" ")[0])
      })
      .catch(({response}) => {
        if (response) {
          Object.keys(response.data.errors).forEach(errorKey => {
            setError(errorKey, { type: "manual", message: response.data.errors[errorKey][0] })
          })

          setIsSubmitting(false)
        }
      })
  }

  const showSubmitForm = !!answers.find(q => ( q.question_id == "juega_fifa"))
  const juegaEnLiga = !! answers.find(q => q.question_id == "juega_en_liga")?.answer.find(a => a.id == "si")

  return (
    <>
      {
        submission &&
          <ThanksModal
            visible={true}
            submission={submission}
            submitterName={submitterName}
          />
      }

      <ScrollView style={tw`flex-1 bg-primary`}>
        <View style={tw`max-w-screen-md	w-full self-center py-5 px-5`}>
          <Image
            source={require('./banner-encuesta.png')}
            style={{ height: 80, width: 'auto', resizeMode: 'contain', marginBottom: 20}}
          />
          <View>
            {
              questions.map((q) => {
                const show = q.show && q.show(answers)

                if(!show) return null

                return (
                  <OptionsSelector
                    key={q.questionId}
                    title={q.title}
                    options={q.answers}
                    onChange={value => {
                      const answerIndex = answers.findIndex(e => e.question_id == q.questionId)

                      const answer = {
                        question_id: q.questionId,
                        title: q.title,
                        answer: value
                      }

                      if(answerIndex == -1){
                        setAnswers([...answers, answer])
                      }else{
                        const _answers = [...answers]
                        _answers[answerIndex] = answer
                        
                        setAnswers(_answers)
                      }
                    }}
                    multiple={q.multiple}
                    showOtherInput={q.showOtherInput}
                    showInRows={q.showInRows}
                  />
                )
              })
            }

            {
              showSubmitForm &&
                <View>
                  <Input
                    name="name"
                    control={control}
                    label={"Tu Nombre Completo"}
                    rules={{required: "Tu nombre es requerido"}}
                  />
                  
                  <Input
                    name="email"
                    control={control}
                    label={"Correo electrónico"}
                    rules={{ required: "El correo electrónico es requerido", pattern: { value: /^\S+@\S+$/, message: "Correo no válido" }}}
                    type={"email"}
                  />
                  
                  <Input
                    name="phone"
                    control={control}
                    label={"Teléfono"}
                    rules={{ required: "El teléfono es requerido", pattern: { value: /\d{10}/, message: "Tiene que ser de 10 digitos" }}}
                    maxLength={10}
                  />


                  <Select
                    name="birth_year"
                    items={birthdayYears}
                    placeholder={{ label: "Año de nacimiento" }}
                    control={control}
                    rules={{required: true}}
                    style={{wrapper: tw`mb-3`}}
                  />

                  <LocationSelector control={control} />

                  <CheckButton
                    name="subscribe"
                    control={control}
                    label="Me gustaría recibir novedades de FutMaster"
                    style={{container: tw`mb-4`, label: tw`font-worksans`, error: tw`font-worksans`}}
                  />

                  <CheckButton
                    name="tos_accepted"
                    control={control}
                    label="Acepto los términos y condiciones del concurso"
                    style={{container: tw`mb-4`, label: tw`font-worksans`, error: tw`font-worksans`}}
                    rules={{required: "Para participar tienes que aceptar los términos y condiciones "}}
                  />

                  <TouchableOpacity style={tw.style(`mt-6 py-4 items-center rounded-md w-full px-20 bg-secondary`, { opacity: isSubmitting ? 0.5 : 1})}
                    onPress={handleSubmit(onSubmit)}
                    disabled={isSubmitting}
                    >
                    <Text style={tw`text-primary font-semibold font-worksans`}>Enviar</Text>
                  </TouchableOpacity>
                </View>
            }
          </View>
          
        </View>
      </ScrollView>
    </>
  );
}
