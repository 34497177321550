import React, { useEffect, useState} from "react";
import { ScrollView, View } from "react-native";

import api from "../../../utils/api";
import tw from "../../../styles/tailwind";


import Button from "../../../Components/Button";
import Text from "../../../Components/Text";
import TableStage from "./Stages/TableStage";
import { useNavigation, useRoute } from "@react-navigation/native";
import useLeague from "../../../hooks/useLeague";

function LeagueCalendar({ leagueId }) {
  const [calendar, setCalendar] = useState(null);

  const route = useRoute();
  const navigation = useNavigation()
  const league = useLeague(leagueId)


  useEffect(() => {
    reloadCalendar()
  }, [])

  function reloadCalendar(){
    api.get(`/leagues/${leagueId}/calendar`)
      .then(({ data }) => {
        setCalendar(data)
      })
      .catch(error => { })
  }


  const [creatingEmptyTable, setCreatingEmptyTable] = useState(false);

  function createEmptyTable() {
    const data = {empty:true}

    setCreatingEmptyTable(true)

    api.post(`/leagues/${leagueId}/stages/table`, data)
      .then(({ data }) => {
        reloadCalendar()
      })
      .catch(error => {
        setCreatingEmptyTable(false)
        console.log(error.response.data, "[NewTableScreen] createTable error")
      })
  }

  return (
    <ScrollView style={tw`flex-1`} >
      {
        calendar &&
          <View style={tw`flex-1`}>
            {
              calendar.stages[0]?.type == "table" && 
                <TableStage
                  leagueUid={leagueId}
                  league={league}
                  stage={calendar.stages[0]}
                  onMatchPressed={match => {
                    navigation.navigate("Admin.MatchDetail", { matchId: match.uuid })
                  }}
                  onRoundChanged={() => {
                    reloadCalendar()
                  }}
                  canAdmin={true}
                />
            }

            {
              calendar.stages.length == 0 &&
                <View style={tw`flex-1 items-center justify-center pt-28`}>
                  {/* <Text>Sin calendario</Text> */}
                  
                  <Button
                    label={"Generar pareos de Copa"}
                    onPress={() => {
                      navigation.navigate("Admin.Stage.NewTable", {
                        leagueUid: leagueId,
                        // ESTO NO DEBE ESTAR AQUI
                        // los props del navigation tienen que ser serializables
                        // Lo pongo en lo que conectamos el redux con el calendario
                        onCreated: (data) => {
                          reloadCalendar()
                        }
                      })
                    }}
                  />


                  <Button
                    label={"Crear calendario vacío"}
                    style={{container: tw`mt-8`}}
                    disabled={creatingEmptyTable}
                    onPress={() => {
                      //
                      createEmptyTable()
                    }}
                  />
                </View>
            }
          </View>
      }
    </ScrollView>
  );
}


export default React.memo(LeagueCalendar)