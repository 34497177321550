import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { TouchableOpacity, View } from "react-native";

import Button from "../../../Components/Button";
import PosistionSelector from "../../../Components/Players/PositionSelector";
import Select from "../../../Components/Select";
import StepIndicator from "../../../Components/StepIndicator";
import Text from "../../../Components/Text";

import tw from "../../../styles/tailwind";



const country = [
  { id: "arabia", label: "Árabe" },
  { id: "bolivia", label: "Bolivaniano" },
  { id: "canada", label: "Canadiense" },
  { id: "mexico", label: "Mexicano" },
  { id: "...", label: "..." },
]


export default function NewPlayer_Step4({ onSubmit, handleBack, style }) {
  const { control, handleSubmit, formState, formState: { errors }, setError } = useForm();

  const [weight, setWeight] = useState(40);
  const [height, setHeight] = useState(40);
  const [selectedPosition, setSelectedPosition] = useState();

  const returnStep = () => {
    handleBack()
  }

  const submitStep4 = () => {
    const data = {
      // ...lodelformulario,
      weight,
      height,
    }
    onSubmit(data)
  }

  return (
    <View style={tw.style(``, style?.container)}>

      <StepIndicator
        style={{ container: tw`mb-6` }}
        currentStep={4}
      />

      <View style={tw.style(`bg-gray-100 flex-1 mb-6 rounded-xl self-center`)}>
        <Text style={tw`text-gray-300 text-center`}>
          Selecciona tu posición favorita en el campo, esto,
          solamente para verse reflejado en el perfil de
          FutMaster y conozcan sus habilidades.
        </Text>
      </View>


      {/* Selector de posición */}
      <PosistionSelector  />

      {/* Selector de Nacionalidad */}
      <View style={tw`mb-8`}>
        <Select
          name="country"
          items={country}
          placeholder={{ label: "Nacionalidad" }}
          control={control}
          style={{ container: tw.style(``, { flexShrink: 1 }) }}
        />
      </View>

      {/* Botones */}
      <View style={tw`flex-row justify-around`}>

        <Button
          label={"Anterior"}
          onPress={() => { returnStep() }}
          style={{ container: tw`px-8  py-3 bg-black-100`, label: tw`text-white` }}
        />

        <Button
          label={"Siguiente"}
          onPress={(handleSubmit(submitStep4))}
          style={{ container: tw`px-8  py-3` }}
        />

      </View>
    </View>
  )
}