import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, ScrollView, TextInput, Alert, Platform } from "react-native"
import Button from '../../../../Components/Button';
import FMIcons from '../../../../Components/FMIcon';
import TeamBadge from '../../../../Components/Teams/TeamBadge';


import Text from '../../../../Components/Text';
import tw from '../../../../styles/tailwind';
import api from '../../../../utils/api';
import {MatchPlayerFeaturesModal} from '../MarchDetailComponents/Match.PlayerFeaturesModal';
import { Ionicons } from "@expo/vector-icons"
import MatchTeams from './MatchTeams';
import { useNavigation } from '@react-navigation/native';
import IconAsistencia from './svgs/asistencia.svg'


function MyConfirm(title, message?, confirmText, cancelTxt?="Cancelar", onConfirm, options?=null){
  if (Platform.OS !== "web") {
    Alert.alert(
      title,
      message,
      [
        {
          text: confirmText,
          onPress: () => onConfirm(),
          // style: 'cancel',
        },
        {
          text: cancelTxt,
          onPress: () => { },
          style: 'cancel',
        },
      ],
      {
        cancelable: true,
        onDismiss: () => { }
      }
    );
  } else {
    const text = title + (message ? `\n${message}` : '')
    const result = confirm(text)
    if (result) onConfirm()
  }
}


function TeamPlayersTable({ teamPlayers, style, onPlayerSelected, canModify, teamNoShow }){
  return (
    <View style={tw.style(`flex-1 px-3 landscape:px-7`, style)}>
      <View style={tw`row`}>
        <Text style={tw`text-white flex-1`}></Text>
        <FMIcons name="gol" color={"white"} size={20} style={tw`w-8 py-1 text-center bg-gray-500`} />
        <FMIcons name="tarjeta" color={"yellow"} size={20} style={tw`w-8 py-1 text-center `} />
        <FMIcons name="tarjeta" color={"red"} size={20} style={tw`w-8 py-1 text-center `} />
      </View>

      {
        teamPlayers?.map(player =>
          <TouchableOpacity
            disabled={!canModify}
            key={player?.player?.uuid}
            style={tw`row  items-stretch border-b border-white/20`}
            onPress={() => onPlayerSelected?.(player)}
          >
            <Text style={tw`text-white text-lg landscape:text-xl py-2 landscape:py-1 flex-1`}>{player?.player?.squad_number} | {player?.player?.name}</Text>

            <Text style={tw`text-white text-lg w-8 text-center landscape:text-xl py-2 landscape:py-1 bg-gray-500`}>{player?.stats?.goals > 0 ? player?.stats?.goals : ''}</Text>

            <View style={tw`w-8 items-center justify-center landscape:text-xl py-2 landscape:py-1`}>
              {
                [...Array(player?.stats?.yellow_cards || 0)].map((e, i) =>
                  <View key={i} style={tw`bg-yellow rounded-full w-2 h-2 my-1`} />
                )
              }
            </View>

            <View style={tw`w-8 items-center justify-center landscape:text-xl py-2 landscape:py-1`}>
              {
                [...Array(player?.stats?.red_cards || 0)].map((e, i) =>
                  <View key={i} style={tw`bg-red rounded-full w-2 h-2 my-1`} />
                )
              }
            </View>
          </TouchableOpacity>
        )
      }

      {
        teamNoShow &&
          <Text style={tw`text-lg text-center text-white mt-10`}>Equipo no se presentó</Text>
      }
    </View>
  )
}


export default function MatchStatsCapture({
  match, homeTeam, awayTeam, matchEvents,
  matchAssistance, canModify, onGoToAssistancePressed,
  onReportFinished, reverseAssistance,
  teamsManualStats, setTeamsManualStats,
  matchStatus
}:{
  match: Match
}) {
  // Estos dos sirven para abrir el modal/sheet para seleccionar los goles/tarjetas/etc de un jugador
  const [selectedPlayer, setSelectedPlayer] = useState<Player | null>();
  const [selectedPlayerTeam, setSelectedPlayerTeam] = useState<"home" | "away" | null>();

  // Esto sirve para la captura manual de la cedula
  const totalGoals = { home: 0, away: 0 }
  const isDefault = matchAssistance.home_no_show || matchAssistance.away_no_show

  // Observaciones y otros datos del reporte
  const [observations, setObservations] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const capturedFromReport = match.referee_report?.capture_method !== "live"

  const navigation = useNavigation()


  function finishMatchReport() {
    const data = {
      observations,
      teams_stats: capturedFromReport ? {
        home: {
          player_stats: teamsManualStats.home,
        },
        away: {
          player_stats: teamsManualStats.away,
        },
      } : null,

      // Si es default forza el calculo desde reporte
      // TODO: FIX: URGENT: Falta que si en lugar de usar el en vivo se usa la cedula
      // tambien haga el cálculo
      force_report: matchStatus.running_period == "paused" && isDefault,
    }
    
    setIsSubmitting(true)


    api.post(`/matches/${match.uuid}/referee-report`, data)
      .then(({ data }) => {
        onReportFinished?.()
      })
      .catch((error) => {
        console.log("finishMatchReport reror!", error);
        setIsSubmitting(false)
      })
  }

  function convertAssistanceEventsAndStatsToTeamPlayersArray(teamAssistance, side){
    totalGoals[side] = 0

    const playersArray = teamAssistance?.map((player: Player) => {
      const events = matchEvents.filter(ev => ev.player?.uuid == player.uuid)

      // Agrupa los eventos por tipo
      const playerEvents = {
        goals: events.filter(ev => ev.type === "goal"),
        yellow_cards: events.filter(ev => ev.type === "yellow_card"),
        red_cards: events.filter(ev => ev.type === "red_card"),
        fouls: events.filter(ev => ev.type === "foul"),
      }

      // Estas son las estadísticas versión cédula
      const statsGoals = teamsManualStats[side]?.[player.uuid]?.goals || 0
      const statsYellowCards = teamsManualStats[side]?.[player.uuid]?.yellow_cards || 0
      const statsRedCards = teamsManualStats[side]?.[player.uuid]?.red_cards || 0


      // Sumamos los eventos + PlayerStats en uno solo
      // (en teoría no deben usarse los dos juntos, pero lo pongo temporalmente para unificar)
      const playerStats = {
        goals: playerEvents.goals.length + statsGoals,
        yellow_cards: playerEvents.yellow_cards.length + statsYellowCards,
        red_cards: playerEvents.red_cards.length + statsRedCards,
        fouls: playerEvents.fouls.length,
      }

      totalGoals[side] += playerStats.goals

      return {
        player,
        events: playerEvents,
        stats: playerStats,
      }
    })

    if (matchAssistance.home_no_show){
      totalGoals["home"] = 0
      totalGoals["away"] = match.league.settings?.default_goals ?? 2
    }
    if (matchAssistance.away_no_show){
      totalGoals["home"] = match.league.settings?.default_goals ?? 2
      totalGoals["away"] = 0
    }

    return playersArray
  }




  const homePlayers = convertAssistanceEventsAndStatsToTeamPlayersArray(matchAssistance?.home, "home")
  const awayPlayers = convertAssistanceEventsAndStatsToTeamPlayersArray(matchAssistance?.away, "away")

  

  return (
    <View style={tw`flex-1`}>
      {/* Si el partido no fue capturado live pintamos nuestra propia barrita
      Esto se puede fixear si nos pudieramos comunicar con la del parent */}
      {
        (capturedFromReport || matchAssistance.home_no_show || matchAssistance.away_no_show) &&
          <View style={tw`row pt-3 pb-4`}>
            <MatchTeams
              match={match}
              homeScore={totalGoals["home"]}
              awayScore={totalGoals["away"]}
              style={{ container: tw`pt-0 pb-1 bg-transparent px-5`, jaja: `` }}
              played={false}
              reverseAssistance={reverseAssistance}
            />
          </View>
      }


      <ScrollView style={tw`flex-1`} contentContainerStyle={tw`pb-5 `}>
        <View style={tw.style(`row justify-between items-stretch mb-10`, reverseAssistance && {flexDirection: 'row-reverse'})}>
          <TeamPlayersTable
            canModify={canModify && ! isDefault}
            teamPlayers={homePlayers}
            teamNoShow={matchAssistance.home_no_show}
            onPlayerSelected={player => {
              setSelectedPlayer(player)
              setSelectedPlayerTeam("home")
            }}
            style={reverseAssistance ? `border-l border-white` : ''}
          />

          <TeamPlayersTable
            canModify={canModify && ! isDefault}
            teamPlayers={awayPlayers}
            teamNoShow={matchAssistance.away_no_show}
            onPlayerSelected={player => {
              setSelectedPlayer(player)
              setSelectedPlayerTeam("away")
            }}
            style={!reverseAssistance ?`border-l border-white` : ''}
          />
        </View>
      </ScrollView>


      {/* Botones abajo (cerrar cedula/asistencia) */}
      <View style={tw`items-center row  mt-2 mb-3 px-3`}>
        {
          !match.referee_report?.captured ?
            <>
              {
                // capturedFromReport &&
                  <TouchableOpacity style={tw`border rounded-lg row border-primary px-4 py-2`} onPress={onGoToAssistancePressed}>
                    <Ionicons name="chevron-back-outline" size={20} color={tw.color(`primary`)} />

                    <IconAsistencia style={tw`w-5 h-5 mr-2 ml-3`} />

                    <Text style={tw`text-white row text-lg`}>Asistencia</Text>
                  </TouchableOpacity>
              }

              {/* Text input de Observaciones */}
              {
                !match.referee_report?.captured ?
                  <TextInput
                    multiline
                    numberOfLines={2}
                    style={tw.style(`flex-1 bg-white mx-3 landscape:mx-7 rounded-lg items-start px-4 py-3 text-lg my-0`, { textAlignVertical: 'top' })}
                    placeholder="Observaciones"
                    onChangeText={setObservations}
                    value={observations}
                  />
                  :
                  <Text style={tw`text-white mx-3 landscape:mx-7 text-lg`}>Observaciones: {match.referee_report?.observations}</Text>
              }


              <Button
                label={"Finalizar cédula"}
                style={{ label: `text-lg`, container: `my-0` }}
                disabled={isSubmitting}
                onPress={() => {
                  MyConfirm(
                    "Finalizar la cédula?",
                    "Una vez finalizada no se podrán hacer modificaciones.",
                    "Finalizar",
                    "Cancelar",
                    () => {
                      finishMatchReport()
                    }
                  )
                }}
              />
            </>
            :
            <View style={tw`items-center flex-1`}>
              <Button
                label={"Ir a pantalla de partidos"}
                style={{ label: `text-lg` }}
                onPress={() => {
                  navigation.goBack()
                }}
              />
            </View>
        }
      </View>

      {/* Bottomsheet seleccion de goles/etc */}
      <MatchPlayerFeaturesModal
        bigText
        visible={!!selectedPlayer}
        player={selectedPlayer?.player}
        playerStats={selectedPlayer?.stats}
        onRequestClose={() => {
          setSelectedPlayer(null)
          setSelectedPlayerTeam(null)
        }}
        onSubmit={playerStats => {
          setTeamsManualStats({
            ...teamsManualStats,
            [selectedPlayerTeam]: {
              ...teamsManualStats[selectedPlayerTeam],
              [selectedPlayer.player.uuid]: playerStats,
            }
          })

          setSelectedPlayer(null)
          setSelectedPlayerTeam(null)
        }}
      />
    </View>
  )
}

