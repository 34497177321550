import React, {useEffect, useState} from "react";
import { ScrollView, View } from "react-native";
import { StatusBar } from "expo-status-bar";

import Text from '../../Components/Text';
import tw from "../../styles/tailwind";

import * as ScreenOrientation from 'expo-screen-orientation';


import { dayOfWeekStr, format } from "../../utils/momento";
import api from "../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import MatchCard from "./Components/MatchCard";

// TODO: REDUX_NORMALIZR
import { denormalize, normalize, schema } from 'normalizr';
import Loading from "../../Components/Loading";
import { matchSchema } from '../../store/schemas'


export default function AdminRefereesScreen({navigation}) {
  const [dayMatches, setDayMatches] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date);
  const [isLoading, setIsLoading] = useState(false);

  // TODO: mover a un hook/selector SELECTED_STADIUM_STATE
  const selectedStadiumId = useSelector((state) => state.status?.selectedStadiumId);
  const stadium = useSelector((state) => state.status?.stadiums?.find(e => e.uuid == selectedStadiumId));

  const dayOfWeek = dayOfWeekStr(selectedDate, false)
  const dateStr = format(selectedDate, "d MMMM yyyy")


  useEffect(() => {
    loadDayMatches(selectedDate)
  }, [stadium, selectedDate]);



  // TODO: REDUX_NORMALIZR
  const dispatch = useDispatch()
  const entities = useSelector((state) => state.entities);

  const matches = denormalize(dayMatches, [matchSchema], entities)


  function loadDayMatches(day){
    if (! stadium) return null

    setIsLoading(true)

    // Sino envía el tiempo en UTC
    const localDate = new Date(day.getTime() - (day.getTimezoneOffset() * 60000))

    api.get(`/stadiums/${stadium.uuid}/matches`, { params: { day: localDate}})
    .then(({data}) => {
      const normalizedData = normalize(data, [matchSchema]);

      dispatch({ entities: normalizedData.entities, type: "UPDATE_ENTITIES" })

      setDayMatches(normalizedData.result)


      setIsLoading(false)
    })
    .catch((error) => {
      console.log("loaddaymatnche rror", error);
      
    })
  }



  return (
    <View style={tw`flex-1 pt-3 px-3`} >
      <Text style={tw.style(`text-xl mb-5 mt-4 text-white`)}>Partidos para hoy</Text>

      {/* <View style={tw`-mx-3`}>
        <HeaderPager
          onLeftPressed={() => setSelectedDate(subDays(selectedDate, 1))}
          onRightPressed={() => setSelectedDate(addDays(selectedDate, 1))}
          title={dayOfWeek}
          subtitle={dateStr}
        />
      </View> */}

      <Loading visible={isLoading} />

      <ScrollView style={tw.style({ "opacity-50": isLoading })}>
        {
          matches.map((match : Match) => 
            <MatchCard
              match={match}
              key={match.uuid}
              showDate={false}
              style={{
                container: `mb-5`
              }}
              onPress={() => 
                // navigation.navigate("Admin.MatchDetail", { matchId: match.uuid })
                navigation.navigate('Admin.ArbitrationTablet', { matchId: match.uuid })
              }
            />
          )
        }

        {
          (matches.length == 0 && ! isLoading) && 
            <Text style={tw`text-center text-gray-300 mt-32`}>Sin partidos programados</Text>
        }
      </ScrollView>

    </View>
  );
}
