import React, { useEffect, useRef, useState } from "react";
import { View, Image, ScrollView, RefreshControl, TouchableOpacity, Animated } from "react-native";

import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../store/Reducers/auth";

import Text from "../../Components/Text";
import Button from "../../Components/Button";
import FMIcon from '../../Components/FMIcon';

import tw from "../../styles/tailwind";
import { Ionicons } from '@expo/vector-icons';
import FMIcons from "../../Components/FMIcon";

import Constants from 'expo-constants';
import { setSelectedStadiumId, updateStatus } from "../../store/Reducers/status";
import TeamBadge from "../../Components/Teams/TeamBadge";
import api from "../../utils/api";
import { PositionsTable, TopScorersTable } from "../Admin/Leagues/League.Stats";

import MatchCard from "../Admin/Components/MatchCard";
import { registerForPushNotificationsAsync } from "../../utils/pushNotifications";
import NeonButton from "../../Components/NeonButton";
import { useHeaderHeight } from '@react-navigation/elements';
import * as Analytics from 'expo-firebase-analytics';


import {
  HeaderButtons,
  HiddenItem,
  OverflowMenu,
  Divider,
} from 'react-navigation-header-buttons';
import PlayerPicture from "../../Components/Players/PlayerPicture";





function TeamSection({ style, leagueTeam, onPress }:{leagueTeam: LeagueTeam}) {
  const leagueId = leagueTeam.league.uuid
  const teamId =leagueTeam.team.uuid



  // USE_HOOK - useLeagueTeam(leagueId, teamId)
  const dispatch = useDispatch()
  const leagueTeamRedux = useSelector(state => state.entities.leagueTeams[`${leagueId}-${teamId}`])



  // TODO mover a hook?
  // 
  const myLeagueTeams = useSelector(state => state.status.league_teams)
  const isTeamManager = myLeagueTeams.filter(_leagueTeam => _leagueTeam.pivot.roles?.indexOf("representative") !== -1 && _leagueTeam.uuid === leagueTeam?.uuid).length > 0

  useEffect(() => {
    if(leagueTeam){
      loadTeam(leagueTeam.league.uuid, leagueTeam.team.uuid)
    }
  }, [leagueTeam]);
  
  function loadTeam(leagueId, teamId) {
    dispatch(loadLeagueTeam(leagueId, teamId))
  }

  //
  const currentPlayer = useSelector(state => state.status.player)
  const meLeagueTeamPlayer = leagueTeamRedux?.members.find(member => currentPlayer.uuid == member.uuid)

  //
  const players = leagueTeamRedux?.members.filter(member => member.pivot.roles.indexOf('player') !== -1 && currentPlayer.uuid !== member.uuid)
  const sortedPlayers = players ? [...players].sort((a, b) => a.image_url < b.image_url) : []
  const first7 = sortedPlayers.slice(0, 7)
  const leftCount = sortedPlayers.length - first7.length

  

  

  return (
    <View style={tw`rounded-xl overflow-hidden`}>
      <TeamBgGradient />

      <TitleSeparator title={`Mi equipo`} icon="equipos-tb" style={tw`mt-4 mb-2`} />

      <TouchableOpacity
        style={tw.style(`row px-4 py-2 justify-around mb-2`)}
        onPress={onPress}
      >
        {/* Logo de equipo */}
        <TeamBadge team={leagueTeam?.team} height={75} width={75} />

        {/* información de liga */}
        <View style={tw`flex-1 ml-3`}>
          <Text style={tw`text-lg text-gray font-bold mb-1`}>{leagueTeam?.name}</Text>

          <View style={tw`row`}>
            <View style={tw`row`}>
              <FMIcon name="copa" size={20} color="yellow" />
              <Text style={tw`font-bold ml-1 text-lg text-gray`}> {leagueTeam?.stats?.position}º</Text>
              <Text style={tw`text-gray-300 ml-1 text-gray text-md`}>Posición</Text>
            </View>

            <View style={tw`row ml-4`}>
              <Text style={tw`font-bold text-lg text-gray`}>{leagueTeam?.stats?.points} </Text>
              <Text style={tw`text-gray-300 mr-2 text-gray text-md`}> Puntos</Text>
            </View>
          </View>
        </View>
      </TouchableOpacity>



      {/* Seccion jugadores del equipo */}
      <TouchableOpacity
        style={tw`bg-[#184451] px-3 pt-2 pb-4`}
        onPress={onPress}
      >
        <LinearGradient
          colors={['#12373d', "#184858"]}
          start={{ x: 0, y: 1 }}
          end={{ x: 1, y: 0 }}
          style={tw`absolute right-0 top-0 left-0 bottom-0`}
        />

        <Text style={tw`text-yellow-200 text-sm text-center mb-3`}>{players?.length} {leagueTeam.team.gender == "female" ? "jugadoras" : "jugadores"}</Text>


        <View style={tw`row justify-between`}>
          {/* Mi fotito y # dorsal */}
          <View style={tw`row`}>
            <PlayerPicture
              player={meLeagueTeamPlayer}
              style={`h-10 w-10 border-2 border-primary rounded-full`}
            />

            <FMIcon name="jugadores-b" size={16} style={tw`text-yellow-200 ml-2 mr-1`} />

            <Text style={tw`text-lg text-yellow-200`}>{meLeagueTeamPlayer?.squad_number}</Text>
          </View>
          

          {/* Fotitos otros jugaores y chevron */}
          <View style={tw`row`}>
            {
              first7.map((pl: Player) =>
                <PlayerPicture
                  key={pl.uuid}
                  player={pl}
                  style={tw`h-10 w-10 border-0 -ml-5`}
                />
              )
            }

            {
              (leftCount > 0) &&
              <View style={tw`h-10 w-10 rounded-full items-center justify-center bg-gray-500 -ml-5`}>
                <Text style={tw`text-lg text-gray-600`}>{leftCount}+</Text>
              </View>
            }

            <Ionicons name="chevron-forward" size={16} color={tw.color('primary')} />
          </View>
        </View>
        
        {
          isTeamManager &&
            <View>
              <GradientSeparator />

              <View style={tw`row justify-center mt-4 mb-1`}>
                <FMIcon name="capitan" size={18} color={tw.color('primary')} />
                <Text style={tw`text-yellow-200 ml-3`}>Eres capit{leagueTeam.team.gender == "female" ? "ana" : "án"} de {leagueTeam.name}</Text>
              </View>
            </View>
        }
      </TouchableOpacity>{/* Seccion jugadores del equipo */}

    </View>
  )
}

function TeamInfo({ style, leagueTeam }) {
  return (
    <View style={tw.style(`flex-1 flex-col items-center justify-center`, style?.container)}>
      {/* Escudo equipo */}
      <TeamBadge team={leagueTeam?.team} height={75} width={75} />

      {/* Nombre de equipo */}
      <Text style={tw`text-white font-bold text-md text-center`}>{leagueTeam?.name}</Text>
    </View>
  )
}

function LeagueInfo({ style, league, roundName }) {
  return (
    <TouchableOpacity style={tw`flex-1 flex-row justify-between items-center p-4 rounded-xl border border-primary bg-gray-400`}>
      <View style={tw`flex-row items-center`}>
        <FMIcon name="copa" size={20} color="yellow" />
        <Text style={tw`text-primary text-md ml-1`}>{league.name}</Text>
      </View>

      <View style={tw`flex-row items-center`}>
        <Text style={tw`text-gray text-md mr-2 `}>{roundName}</Text>

        <Ionicons name="chevron-forward" size={20} color="gray" />
      </View>
    </TouchableOpacity>
  )
}


function LeagueCard({ league, style, onPress, selected=false, pressable=true}:{league:League}){
  return (
    <TouchableOpacity
      style={tw.style(`items-center rounded-lg px-3 py-2`, selected && `border border-primary`, style)}
      onPress={onPress}
      disabled={!pressable}
    >
      <View style={tw`row`}>
        <Image source={{ uri: league.image_url }} style={tw`h-20 w-20 rounded-lg`} />

        <View style={tw`ml-2`}>
          <Text style={tw`text-white`}>{league.name}</Text>
          <Text style={tw`text-gray-300`}>{league.stadium?.name}</Text>
          <Text style={tw`text-primary/50`}>{league.match_days_str}</Text>
        </View>
      </View>
    </TouchableOpacity>
  )
}


function UpcomingMatches({matches}){
  if(!matches || matches.length == 0) return null

  const _matches = [...matches]

  const nextMatch = _matches.splice(0, 1)[0]

  const navigation = useNavigation()

  const createdAt = nextMatch.date ? new Date(nextMatch.date) : null
  const hourStr = getHourStr(createdAt)
  const dateStr = createdAt ? `${dayOfWeekStr(createdAt)}, ${createdAt?.getDate()} ${monthStr(createdAt)}` : null

  return (
    <View style={tw`mb-8 rounded-xl overflow-hidden`}>
      <Image
        source={require('../../assets/home_matches_bg.png')}
        style={tw` w-full absolute rounded-xl left-0 right-0 bottom-0 h-full`}
        resizeMode={'cover'}
      />
      
      <View style={tw`px-3 pt-5 pb-3`}>
        <TitleSeparator title={_matches.length > 0 ? `Próximos Partidos` : `Próximo Partido`} icon="cancha" style={tw`mb-5 px-2`} />

        {/* Protsimo partido */}
        <View style={tw``}>
          <Text style={tw.style(`text-yellow-200 text-center`, dateStr && `text-white`)}>{dateStr ? dateStr : "Sin fecha"}</Text>

          <Text style={tw`text-primary text-lg font-500 text-center mt-1 -mb-3`}>{hourStr ? hourStr : ""}</Text>

          <MatchCard
            match={nextMatch}
            style={{ container: tw`mr-4 w-full bg-transparent`, round: tw`mt-1` }}
            showLeague={false}
            onPress={() => navigation.navigate("Match.Detail", { matchId: nextMatch.uuid })}
            showRound
            showStatus={nextMatch.status !== "created"}
            showDate={false}
            showTime={false}
          />
        </View>

        {/* Siguientes partidos */}
        {
          _matches.length > 0 &&
            <View>
              <GradientSeparator style={tw`mb-0`} />

              <ScrollView horizontal={true}>
                {
                  _matches.map((match) =>
                    <MatchRow
                      key={match.uuid}
                      match={match}
                      style={{ container: tw`mr-4 w-80 mb-0 bg-transparent`, teamsName: tw`text-sm`, roundName: tw`text-sm` }}
                      showLeague={false}
                      onPress={() => navigation.navigate("Match.Detail", { matchId: match.uuid })}
                      showRound
                      showStatus={nextMatch.status !== "created"}
                      showBottomBorder={false}
                    />
                  )
                }
              </ScrollView>
            </View>
        }
      </View>
    </View>
  )
}


import * as Notifications from 'expo-notifications';
import { saveMatchEvent } from "../../store/Reducers/matches";
import PlayerPictureXP from "../../Components/Players/PlayerPictureXP";
import PlayerEditProfileModal from "../Player/PlayerEditProfileModal";
import TitleSeparator from "../../Components/TitleSeparator";
import MatchRow from "../Admin/Components/MatchRow";
import GradientSeparator from "../../Components/GradientSeparator";
import { useNavigation } from "@react-navigation/native";
import { dayOfWeekStr, getHourStr, monthStr } from "../../utils/momento";
import TeamBgGradient from "../../Components/Teams/TeamBgGradient";
import { loadLeagueTeam } from "../../store/Reducers/leagueTeams";
import { LinearGradient } from "expo-linear-gradient";
import GamifyNotificationsScreen from "../Gamification/GamifyNotificationsScreen";



const onPressProfileButton = uid => {
  Analytics.logEvent('InvitarCompanere', {
    sender: 'boton_escondido',
    user: uid,
    screen: 'home',
    purpose: 'Invitar a un cabron',
  });
};



export default function AuthenticatedIndexScreen({ route, navigation }) {
  const status = useSelector((state) => state.status);
  const [showDebug, setShowDebug] = useState(false);
  const [leagueStats, setLeagueStats] = useState();
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [stadiumsHidden, setStadiumsHidden] = useState(0);

  const dispatch = useDispatch()

  const { player, stadiums, league_teams, upcoming_matches, past_matches } = status


  const [selectedLeagueTeam, setSelectedLeagueTeam] = useState<LeagueTeam | null>(league_teams?.[0]);

  const leagueTeamUpcomingMatches = upcoming_matches.filter(match => match.home_team?.uuid === selectedLeagueTeam?.uuid || match.away_team?.uuid === selectedLeagueTeam?.uuid)
  const leagueTeamPastMatches = past_matches.filter(match => match.home_team?.uuid === selectedLeagueTeam?.uuid || match.away_team?.uuid === selectedLeagueTeam?.uuid)
  


  // animacion opacidad header
  const headerHeight = useHeaderHeight();

  const yOffset = useRef(new Animated.Value(0)).current;
  const headerOpacity = yOffset.interpolate({
    inputRange: [0, 80],
    outputRange: [0, 0.98],
    extrapolate: "clamp",
  });


  useEffect(() => {
    navigation.setOptions({
      headerShown: true,
      headerTransparent: true,
      headerStyle: {
        // opacity: headerOpacity,
      },
      headerTitleAlign: 'center',
      headerTitle: () => <Image
        style={{ width: 117, height: 45 }}
        source={require('../../assets/logo_yellow.png')}
      />,
      headerBackground: () => (
        <Animated.View
          style={{
            backgroundColor: tw.color('background'),
            position: 'absolute', right: 0, left: 0, top: 0, bottom: 0,
            opacity: headerOpacity,
          }}
        />
      ),
      headerRight: () => <HeaderButtons>
        <OverflowMenu
          style={{ }}
          OverflowIcon={({ color }) => (
            <Ionicons name="ellipsis-vertical-outline" color={tw.color('primary')} size={24} />
          )}
        >
          <HiddenItem
            title={"Editar mi perfil"}
            titleStyle={{}}
            onPress={() => setShowEditProfile(true) }
          />

          <HiddenItem
            title={"Cerrar sesión"}
            titleStyle={{}}
            onPress={() => dispatch(actions.logout()) }
          />
        </OverflowMenu>
      </HeaderButtons>,
    })
  }, [navigation, headerOpacity])


  useEffect(() => {
    if (league_teams?.length > 0 && ! selectedLeagueTeam){
      setSelectedLeagueTeam(league_teams[0])
    }
  }, [league_teams]);


  useEffect(() => {
    if (selectedLeagueTeam){
      api.get(`leagues/${selectedLeagueTeam.league.uuid}/stats`)
      .then(({data}) => {
        setLeagueStats(data)
      })
      .catch(({response}) => {

        console.log("Las estadisticas :S", response)
      })
    }
  }, [selectedLeagueTeam])



  /**
   * 
   * 
   * Lógica de push notifications!!!
   * Creo este no es el mejor lugar
   * 
   */
  useEffect(() => {
    const register = async () => await registerForPushNotificationsAsync()

    register()

    Notifications.setNotificationHandler({
      handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: true,
        shouldSetBadge: false,
      })
    });

    Notifications.addNotificationReceivedListener(_handleNotification);

    Notifications.addNotificationResponseReceivedListener(_handleNotificationResponse);
  }, []);

  const _handleNotification = notification => {
    // this.setState({ notification: notification });
    // console.log("_handleNotification", notification);

    // request.content.body
    // request.content.data.match_id
  };

  const _handleNotificationResponse = response => {
    // console.log("_handleNotificationResponse", response);

    // Un par de lineas para mandar al detalle de un partido si trae un match_id (mejorable)
    if (response.notification?.request.content.data.match_id){
      navigation.navigate("Match.Detail", {matchId : response.notification?.request.content.data.match_id})
    }
  };



  /*

  */
  // const net = useSelector(state => state.network)

  // console.log(net)

  // useEffect(() => {
  //   if (!net.isConnected) {
  //     alert("Revisar conexión a internet")
  //   }
  // }, [net.isConnected]);

  // const [loquesea, setLoquesea] = useState();

  const [refreshing, setRefreshing] = React.useState(false);

  const onRefresh = React.useCallback(async () => {
    setRefreshing(true);
    setStadiumsHidden(0)
    
    await dispatch(updateStatus())

    setRefreshing(false)
  }, []);


  return (
    <Animated.ScrollView
      style={tw`flex-1 w-full`}
      contentContainerStyle={tw.style(
        // `pt-[${headerHeight > 0 ? headerHeight : 80}]`,
        // `mt-[80]`
      )}
      style={tw`mt-[80]`}
      refreshControl={<RefreshControl colors={[tw.color(`primary`)]} refreshing={refreshing} onRefresh={onRefresh} />}
      onScroll={Animated.event(
        [
          {
            nativeEvent: {
              contentOffset: {
                y: yOffset,
              },
            },
          },
        ],
        { useNativeDriver: true }
      )}
      scrollEventThrottle={16}
      >
      <View style={tw`py-6 pt-7 px-2 mb-12`}>

        {/* <Text style={tw`mb-3 text-lg`} onPress={() => setLoquesea(Math.random())}>refresh state</Text>
        <Text style={tw`mb-5 text-lg`} onPress={() => dispatch(saveMatchEvent("prueba", "ed90b563-3981-47b5-8488-aa42c732b3dc", Math.random(), { perro: "bueno", date: new Date() }))}>Evento {net.isConnected ? "online" : "offline"}</Text> */}


        {/*
          Jugador perfil
        */}
        <View style={tw.style(`mb-8 px-4`)}>
          <View style={tw.style(`row`, !player?.profile_completed && false && `row`)}>
            <PlayerPictureXP
              player={player}
              style={{ container: `mb-1` }}
              showName={false}
              showXPBar={false}
              showLevel={false}
              onPicturePressed={() => {
                navigation.navigate("PlayerProfile")
              }}
            />

            {/* ! player.profile_completed && `items-center` */}
            <View style={tw.style(`ml-5 flex-1`)}>
              <Text style={tw`text-sm text-gray-200 mb-2`}>Bienvenido</Text>

              <Text style={tw`text-lg font-600 text-gray mb-2`}>{player?.name}</Text>

              {
                player?.nickname &&
                <Text style={tw`text-sm text-primary`}>"{player.nickname}"</Text>
              }
            </View>
          </View>

          {
            player && ! player.profile_completed &&
              <View style={tw`mt-6`}>
                <NeonButton
                  label="Completa tu perfil"
                  onPress={() => {
                    setShowEditProfile(true)
                  }}
                  showChevronRight
                />
              </View>
          }
        </View>
        


        {/*
          Lista de estadios
        */}
        {
          (stadiums?.length > 0 && stadiumsHidden < 2)&&
          <View style={tw`mb-5`}>
            <TouchableOpacity onPress={() => setStadiumsHidden(stadiumsHidden + 1)}>
              <TitleSeparator title="Mis Estadios" />
            </TouchableOpacity>

            {
              stadiums?.map((stadium: Stadium) =>
                <TouchableOpacity
                  key={stadium.uuid}
                  style={tw`mb-3 bg-white rounded-lg py-3 px-3 flex-row items-center justify-between`}
                  onPress={() => {
                    dispatch(setSelectedStadiumId(stadium.uuid))
                    navigation.navigate("Admin.Home", { stadiumId: stadium.uuid })
                  }}
                >
                  <Text style={tw`text-gray-700`}>{stadium.name}</Text>
                  <FMIcons name={"chev-der"} size={12} style={tw`text-gray-300`} />
                </TouchableOpacity>
              )
            }
          </View>
        }


        {/* Mis Torneos */}
        {
          (player && selectedLeagueTeam) &&
            <View style={tw`mb-5`}>
              <TitleSeparator title={`${league_teams?.length > 1 ? "Mis Torneos" : "Torneo"}`} icon="copa" style={tw`mb-5`} />

              {
                league_teams?.length > 1 &&
                  <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
                    {
                      league_teams?.map((leagueTeam: LeagueTeam) =>
                        <LeagueCard
                          key={leagueTeam.uuid}
                          league={leagueTeam.league}
                          style={tw`mx-3`}
                          selected={leagueTeam.uuid === selectedLeagueTeam.uuid}
                          onPress={() => {
                            setSelectedLeagueTeam(leagueTeam)
                          }}
                        />
                      )
                    }
                  </ScrollView>
              }

              {
                league_teams?.length === 1 &&
                  <View style={tw`items-center`}>
                    <LeagueCard
                      league={league_teams[0].league}
                      selected={false}
                      onPress={() => {
                        // setSelectedLeagueTeam(league_teams[0])
                      }}
                      pressable={false}
                    />
                  </View>
              }
            </View>
        }

        

        {
          (player && selectedLeagueTeam) &&
            <View>
              {/*
                Mis Equipo
              */}
              <View style={tw`mt-3 mb-8`}>
                <TeamSection
                  leagueTeam={selectedLeagueTeam}
                  style={{ container: `w-80 mr-3` }}
                  onPress={() => {
                    navigation.navigate("LeagueTeam.Home", { teamId: selectedLeagueTeam?.team.uuid, leagueId: selectedLeagueTeam?.league.uuid })
                  }}
                />
              </View>


              {/*
                Próximos partidos
              */}
              {
                leagueTeamUpcomingMatches.length > 0 &&
                  <UpcomingMatches matches={leagueTeamUpcomingMatches} />
              }



              {/*
                Partidos pasados
              */}
              {
                leagueTeamPastMatches.length > 0 &&
                  <View style={tw`mb-8`}>
                    <TitleSeparator title="Partidos Pasados" icon="cancha" />


                    <ScrollView horizontal={true}>
                      {
                        leagueTeamPastMatches.map((match:Match) =>
                          <MatchCard
                            key={match.uuid}
                            match={match}
                            style={{ container: `mr-4 w-80 bg-[#333]` }}
                            showLeague={false}
                            onPress={() => navigation.navigate("Match.Detail", { matchId: match.uuid })}
                            showRound
                          />
                        )
                      }
                    </ScrollView>
                  </View>
              }


              {/*
                Estadísticas del torneo
              */}
              {
                !! (league_teams && league_teams.length > 0) && 
                  <View style={tw`mb-8`}>
                    {/* Tabla general */}
                    <TitleSeparator title={`Tabla general ${false ? selectedLeagueTeam?.league.name : ''}`} icon="medalla" style={tw` flex-1`} />

                    <PositionsTable
                      positionTable={leagueStats?.teams_stats}
                      selectedTeamId={selectedLeagueTeam?.uuid}
                      qualification={selectedLeagueTeam?.league.settings?.qualification}
                      color={"white"}
                      style={{container: `-mx-2 mb-8`}}
                    />

                    {/* Goleo  */}
                    <TitleSeparator title={`Tabla de goleo`} icon="gol" style={tw` flex-1`} />

                    <TopScorersTable
                      players={leagueStats?.top_scorers}
                      selectedPlayerId={player.uuid}
                      color={"white"}
                      style={{container: `-mx-2 mt-0`}}
                      hideTitle
                    />
                  </View>
              }
            </View>
        }

        {
          status?.gamify_notifications &&
            <GamifyNotificationsScreen />
        }

        <Text onPress={() => {
          setShowDebug(true)
        }} style={tw`text-center text-[#555] mt-4`}>v{Constants.manifest.version}</Text>

        {/* Debug */}
        {
          showDebug &&
          <View style={tw`mt-5`}>
            <Text style={tw`text-gray`}>{process.env.API_URL}</Text>
            <Text style={tw`text-gray`}>Channel: {Constants.manifest.releaseChannel}</Text>
            {/* <NeonButton label={"Invitar Compañere"} onPress={() => onPressProfileButton("invitar_companere")} /> */}
          </View>
        }


        {/*  */}
        <PlayerEditProfileModal
          visible={showEditProfile}
          onRequestClose={() => setShowEditProfile(false)}
          player={player}
          onPlayerUpdated={() => {
            dispatch(updateStatus())
            setShowEditProfile(false)
          }}
        />
      </View>
    </Animated.ScrollView>
  )
}