import React, { useEffect, useRef, useState } from "react";
import { ScrollView, View, Image, Animated, TouchableHighlight, StyleSheet, TouchableOpacity } from "react-native";
import { StatusBar } from "expo-status-bar";

import Text from '../../Components/Text';
import tw from "../../styles/tailwind";
import { Ionicons } from '@expo/vector-icons';
import Button from "../../Components/Button";

import FMIcons from "../../Components/FMIcon";
import { useDispatch, useSelector } from "react-redux";
import { useHeaderHeight } from '@react-navigation/elements';
import * as Clipboard from 'expo-clipboard';


function JerseyImg(props) {
  return (
    <Image source={require('../../assets/jersey.png')} resizeMode={"contain"} {...props} />
  )
}

import {
  HeaderButtons,
  HiddenItem,
  OverflowMenu,
  Divider,
} from 'react-navigation-header-buttons';
import PlayerEditProfileModal from "./PlayerEditProfileModal";
import { updateStatus } from "../../store/Reducers/status";
import { LinearGradient } from "expo-linear-gradient";
import PlayerLevels from "./ProfileComponents/PlayerLevels";
import PlayerTeamLevels from "./ProfileComponents/PlayerTeamLevels";
import AchievementsTable from "./ProfileComponents/AchievementsTable";

// import AchievementModal from "../Gamification/AchievementModal"
// import TeamMatchSummary from "../Gamification/TeamMatchSummary";
// import PersonalMatchSummary from "../Gamification/PersonalMatchSummary";
// import FMLeagueSummary from "../Gamification/FMLeagueSummary";
import api from "../../utils/api";



function StatsSummary({ played, goals, redCards, yellowCards, won, lost, drawed }) {
  return (
    <View style={tw`flex-row w-full justify-around items-center`}>

      <View style={tw`flex-col items-center`}>
        <FMIcons name="cancha" color="white" size={28} />
        <Text style={tw`text-gray text-xl font-bold`}>{played}</Text>
        <Text style={tw`text-gray text-xs -mt-1`}>Partidos</Text>
      </View>


      <View style={tw`flex-col items-center`}>
        <FMIcons name="gol" color="green" size={28} />
        <Text style={tw`text-gray text-xl font-bold`}>{goals}</Text>
        <Text style={tw`text-gray text-xs -mt-1`}>Goles</Text>
      </View>

      <View style={tw`flex-col items-center`}>
        <FMIcons name="tarjeta" color="yellow" size={28} />
        <Text style={tw`text-gray text-xl font-bold`}>{yellowCards}</Text>
        <Text style={tw`text-gray text-xs -mt-1`}>T. Amarillas</Text>
      </View>

      <View style={tw`flex-col items-center`}>
        <FMIcons name="tarjeta" color="red" size={28} />
        <Text style={tw`text-gray text-xl font-bold`}>{redCards}</Text>
        <Text style={tw`text-gray text-xs -mt-1`}>T. Rojas</Text>
      </View>
    </View>
  )
}



export default function PlayerProfileScreen({ navigation }) {
  const [showEditProfile, setShowEditProfile] = useState(false);

  const dispatch = useDispatch()

  const player = useSelector(state => state.status.player)
  const league_teams = useSelector(state => state.status.league_teams)

  // animacion opacidad header
  const headerHeight = useHeaderHeight();

  const yOffset = useRef(new Animated.Value(0)).current;
  const headerOpacity = yOffset.interpolate({
    inputRange: [0, 80],
    outputRange: [0, 0.98],
    extrapolate: "clamp",
  });

  useEffect(() => {
    navigation.setOptions({
      headerShown: true,
      headerTransparent: true,
      headerStyle: {
        // opacity: headerOpacity,
      },
      headerTitleAlign: 'center',
      headerTitle: () => <Image
        style={{ width: 117, height: 45 }}
        source={require('../../assets/logo_yellow.png')}
      />,
      headerBackground: () => (
        <Animated.View
          style={{
            backgroundColor: tw.color('background'),
            position: 'absolute', right: 0, left: 0, top: 0, bottom: 0,
            opacity: headerOpacity,
          }}
        />
      ),
      headerRight: () => <HeaderButtons>
        <OverflowMenu
          style={{}}
          OverflowIcon={({ color }) => (
            <Ionicons name="ellipsis-vertical-outline" color={tw.color('primary')} size={24} />
          )}
        >
          <HiddenItem
            title={"Editar mi perfil"}
            titleStyle={{}}
            onPress={() => setShowEditProfile(true)}
          />
        </OverflowMenu>
      </HeaderButtons>,
    })
  }, [navigation, headerOpacity])

  useEffect(() => {
    loadAchievements();
  }, []);


  const [achievements, setAchievements] = useState([]);

  function loadAchievements(){
    api.get(`/gamification/achievements/player`)
    .then(({data}) => {
      setAchievements(data)
    })
    .catch(({response}) => {

    })
  }

  const unlockedAchievements = achievements.filter(e => e.unlocked == true)
  const lockedAchievements = achievements.filter(e => e.unlocked == false)


  // TODO: PLAYER_STATS
  const tempPlayerStats = league_teams?.[0].pivot.stats
  const leagueTeamPlayerStats = JSON.parse(tempPlayerStats ?? `{}`)
  const leagueTeamStats = league_teams?.[0].stats

  

  /*
  leagueTeamPlayerStats
  {
      "goals": 5,
      "played": 1,
      "red_cards": 0,
      "yellow_cards": 1,
      "match_goals_avg": 5
  }
  leagueTeamStats
  {
    "win": 6,
    "draw": 0,
    "loss": 1,
    "last_5": [
        "win",
        "win",
        "win",
        "loss",
        "win",
        "win",
        "win"
    ],
    "played": 7,
    "points": 18,
    "position": 1,
    "goals_for": 41,
    "goals_against": 12,
    "goals_difference": 29
}
  */
  
  const [showModalTest, setShowModalTest] = useState(false);

  return (
    <Animated.ScrollView
      contentContainerStyle={tw.style(
        // `pt-[${headerHeight > 0 ? headerHeight : 80}]`,
        // `mt-[80]`
        // `pt-[50]`
      )}
      style={tw``}
      onScroll={Animated.event(
        [
          {
            nativeEvent: {
              contentOffset: {
                y: yOffset,
              },
            },
          },
        ],
        { useNativeDriver: true }
      )}
      scrollEventThrottle={16}
    >
      <View style={tw`flex-1 px-2 pt-[90] pb-14`}>
        {/* Foto / Nombre y Playera */}
        <View style={tw`mb-7`}>
          <View style={tw`row justify-center items-center mb-2`}>
            <View style={tw`w-14 mr-8`}></View>

            {/* Foto */}
            <View>
              <LinearGradient
                colors={['#fddc0102', "#82927c", "#fddc0102"]}
                start={{ x: 0, y: 0.5 }}
                end={{ x: 1, y: 0.5 }}
                style={tw.style(` w-24 h-24 -mx-1 -my-1 absolute rounded-full`)}
              />
              <View style={tw`absolute bg-yellow rounded-full w-24 h-24 -mx-1 -my-1`}></View>
              <Image source={{ uri: player?.image_url }} style={tw.style(`h-22 w-22 rounded-full mb-2 border border-black`, {borderWidth: 0.5})} />
            </View>

            {/* Playerita */}
            <View style={tw`ml-8`}>
              <JerseyImg style={tw`w-15 h-18`} />
              <Text style={tw.style(`absolute self-center top-30% text-lg`, { fontFamily: 'AgencyFB'})}>{player?.squad_number}</Text>
            </View>
          </View>

          {/* nombre */}
          <Text style={tw`text-gray text-lg font-700 text-center px-4`}>{player?.name}</Text>

          {
            player?.nickname &&
              <Text style={tw`text-sm text-primary text-center`}>"{player.nickname}"</Text>
          }

          {
            player?.tag_id &&
              <Text style={tw`text-sm text-gray-200 text-center`} onPress={
                () => {
                  if (Clipboard){
                    Clipboard.setStringAsync(`#${player.tag_id}`);
                  }
                }
              }>#{player.tag_id}</Text>
          }
        </View>

        {/* Resumen de resultados */}
        <StatsSummary
          played={leagueTeamStats?.played}
          won={leagueTeamStats?.win}
          lost={leagueTeamStats?.loss}
          drawed={leagueTeamStats?.draw}
          goals={leagueTeamPlayerStats?.goals}
          redCards={leagueTeamPlayerStats?.red_cards}
          yellowCards={leagueTeamPlayerStats?.yellow_cards}
        />

        <PlayerLevels style={tw`mt-3 mb-10`} player={player} />

        {
          league_teams?.map(leagueTeam =>
            <PlayerTeamLevels key={leagueTeam.uuid} leagueTeam={leagueTeam} style={tw`mb-6`} />
          )
        }

        <AchievementsTable style={tw`mt-4`} locked={lockedAchievements} unlocked={unlockedAchievements} />
        

        {/*
          Pruebas
        */}
        {/* <TouchableOpacity style={tw`mb-2 mt-4`} onPress={() => setShowModalTest(1) }>
          <Text style={tw`text-center text-white py-2`}>Resumen partido (equipo)</Text>
        </TouchableOpacity>

        <TouchableOpacity style={tw`mb-2`} onPress={() => setShowModalTest(2) }>
          <Text style={tw`text-center text-white py-2`}>Resumen partido (personal)</Text>
        </TouchableOpacity>

        <TouchableOpacity style={tw`mb-2`} onPress={() => setShowModalTest(3) }>
          <Text style={tw`text-center text-white py-2`}>Liga FM</Text>
        </TouchableOpacity>

        <TouchableOpacity style={tw`mb-2`} onPress={() => setShowModalTest(4) }>
          <Text style={tw`text-center text-white py-2`}>Logro desbloqueado</Text>
        </TouchableOpacity>
        
        {
          showModalTest == 1 &&
            <TeamMatchSummary
              onClose={() => setShowModalTest(false) }
              match={{}}
              leagueTeam={league_teams[0]}
              actions={[
                {amount: 1, xp_total: 100, action: {name: "Juega un partido"}},
                {amount: 1, xp_total: 300, action: {name: "Gana un partido"}},
                {amount: 6, xp_total: 60, action: {name: "Anota un gol"}},
              ]}
              prevXP={100}
              newXP={180}
            />
            // <PersonalMatchSummary onClose={() => setShowModalTest(false) } />
        }
        
        {
          showModalTest == 2 &&
            <PersonalMatchSummary
              onClose={() => setShowModalTest(false) }
              player={player}
              actions={[
                { amount: 1, xp_total: 100, action: { name: "Juega un partido" } },
                { amount: 1, xp_total: 300, action: { name: "Gana un partido" } },
                { amount: 6, xp_total: 60, action: { name: "Anota un gol" } },
              ]}
              prevXP={150}
              newXP={500}
            />
        }

        {
          showModalTest == 3 &&
            <FMLeagueSummary
              leagueTeam={league_teams[0]}
              onClose={() => setShowModalTest(false) }
              data={{
                team: {
                  actions: [
                    { amount: 1, xp_total: 5, action: { name: "Juega un partido" } },
                    { amount: 1, xp_total: 10, action: { name: "Gana un partido" } },
                  ],
                  previous_xp: 0,
                  new_xp: 15,
                  total_xp: 15,
                },
                personal: {
                  actions: [
                    { amount: 6, xp_total: 60, action: { name: "Anota un gol" } },
                  ],
                  previous_xp: 0,
                  new_xp: 6,
                  total_xp: 6,
                },
              }}
              
            />
        }

        {
          showModalTest == 4 &&
            <AchievementModal onClose={() => setShowModalTest(false)} achievement={{
              name: "Hat-Trick",
              image_url: "https://cdn.futmaster.app/gamification/achievements/player/hattrick.png?r=1",
              description: "Anota 3 goles en un partido",
              xp: 40,
            }} />
        } */}


        {/* Botón crear mi perfil */}
        {/* {
          !player.profile_completed  && &&
          <>
            <Button
              label={"Completar mi perfil de jugador"}
              onPress={() => navigation.navigate("Player.New")}
            />
          </>
        } */}
      </View>


      


      <PlayerEditProfileModal
        visible={showEditProfile}
        onRequestClose={() => setShowEditProfile(false)}
        player={player}
        onPlayerUpdated={() => {
          dispatch(updateStatus())
          setShowEditProfile(false)
        }}
      />

    </Animated.ScrollView>
  );
}

