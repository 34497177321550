const { plugin } = require('twrnc');


module.exports = {
    purge: [],
    darkMode: 'media', // or 'media' or 'class' or false
    theme: {
        screens: {
            sm: '380px',
            md: '420px',
            lg: '680px',
            // or maybe name them after devices for `tablet:flex-row`
            tablet: '1024px',
        },

        fontSize: {

            'xl': '24px', //Titulos
            'lg': '18px', //morado
            'md': '14px', //azul
            'sm': '12px', //rojo
            'xs': '10px', //rojo
            // ïcono con indicativo 12, texto para leer 14, 
        },

        extend: {

            colors: {
                // primary: '#fbdb05', este es el original
                primary: '#efc42e', //Nueva implementación de color
                secondary: '#121212',
                'primary-gradient': '#fec500',
                background: '#151515',

                'green': {
                    DEFAULT: '#39B54A',
                    '50': '#BCEAC2',
                    '100': '#ACE5B4',
                    '200': '#8DDB98',
                    '300': '#6ED17C',
                    '400': '#4FC860',
                    '500': '#39B54A',
                    '600': '#2C8A39',
                    '700': '#1E6027',
                    '800': '#113516',
                    '900': '#030A04'
                },

                'red': {
                    DEFAULT: '#FF365B',
                    '50': '#FFEEF1',
                    '100': '#FFD9E0',
                    '200': '#FFB0BF',
                    '300': '#FF889E',
                    '400': '#FF5F7C',
                    '500': '#FF365B',
                    '600': '#FD002F',
                    '700': '#C50024',
                    '800': '#8D001A',
                    '900': '#550010'
                },

                'blue': {
                    DEFAULT: '#0055B8',
                    '50': '#71B2FF',
                    '100': '#5CA7FF',
                    '200': '#3391FF',
                    '300': '#0B7CFF',
                    '400': '#0068E1',
                    '500': '#0055B8',
                    '600': '#003B80',
                    '700': '#002148',
                    '800': '#000710',
                    '900': '#000000'
                },

                'purple': {
                    DEFAULT: '#833399',
                    '50': '#D3A3E0',
                    '100': '#CC94DB',
                    '200': '#BD75D1',
                    '300': '#AF57C7',
                    '400': '#9D3DB8',
                    '500': '#833399',
                    '600': '#5F256F',
                    '700': '#3B1745',
                    '800': '#17091B',
                    '900': '#000000'
                },

                'gray': {
                    DEFAULT: '#ebebeb',
                    50: '#e0e0e0',
                    100: '#e6e6e6',
                    200: '#b8b4b3',
                    300: '#706967',
                    400: '#2d2d2d',
                    500: '#212121',
                },

                'yellow': {
                    DEFAULT: '#FFDD00',
                    200: '#b8b28c',
                    500: '#fddc01',
                    600: '#FFC600',
                },

                'black': {
                    DEFAULT: '#000000',
                    100: '#151515'
                },

                'orange': {
                    DEFAULT: '#FF8300',
                },

                // Agregar un color entero
                // Una buena página para generar
                'fm': {
                    background: '#eff2f1',

                    // TODO: mover estos

                    //cards
                    cardBackgroud: '#FFFFFF',
                    cardBorder: '#A6A6A6',
                    cardTeamBackground: '#DCE0DE',


                },

                'ocean-green': {
                    DEFAULT: '#48BB78',
                    '50': '#EFF9F3',
                    '100': '#DDF2E6',
                    '200': '#B8E4CA',
                    '300': '#92D7AF',
                    '400': '#6DC993',
                    '500': '#48BB78',
                    '600': '#389860',
                    '700': '#2B7249',
                    '800': '#1D4D31',
                    '900': '#0F2819'
                },
            },
        },
    },
    variants: {
        extend: {},
    },
    plugins: [
        plugin(({ addUtilities }) => {
            addUtilities({
                row: {
                    flexDirection: 'row',
                    alignItems: 'center',
                },
            });
        }),
        plugin(({ addUtilities }) => {
            addUtilities({
                'font-worksans-100': {
                    fontFamily: `WorkSans_100Thin`,
                },

                'font-worksans-200': {
                    fontFamily: `WorkSans_200ExtraLight`
                },

                'font-worksans-300': {
                    fontFamily: `WorkSans_300Light`,
                },
                'font-normal': {
                    fontFamily: `WorkSans_400Regular`,
                },

                'font-worksans-500': {
                    fontFamily: `WorkSans_500Medium`
                },

                'font-worksans-600': {
                    fontFamily: `WorkSans_600SemiBold`
                },

                'font-bold': {
                    fontFamily: `WorkSans_700Bold`,
                },

                'font-worksans-800': {
                    fontFamily: `WorkSans_800ExtraBold`,
                },

                'font-worksans-black': {
                    fontFamily: `WorkSans_900Black`,
                },

                'font-worksans-italic-100': {
                    fontFamily: `WorkSans_100Thin_Italic`,
                },

                'font-worksans-italic-200': {
                    fontFamily: `WorkSans_200ExtraLight_Italic`,
                },

                'font-worksans-italic-300': {
                    fontFamily: `WorkSans_300Light_Italic`,
                },

                'font-worksans-italic-400': {
                    fontFamily: `WorkSans_400Regular_Italic`,
                },

                'font-worksans-italic-500': {
                    fontFamily: `WorkSans_500Medium_Italic`,
                },

                'font-worksans-italic-600': {
                    fontFamily: `WorkSans_600SemiBold_Italic`,
                },

                'font-worksans-italic-700': {
                    fontFamily: `WorkSans_700Bold_Italic`,
                },

                'font-worksans-italic-800': {
                    fontFamily: `WorkSans_800ExtraBold_Italic`,
                },

                'font-worksans-italic-900': {
                    fontFamily: `WorkSans_900Black_Italic`,
                },


            });
        }),
    ],
}
