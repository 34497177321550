import React, { useEffect, useState } from "react";
import { Button, Image, StyleSheet, Text, TextInput, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import Loading from "../../Components/Loading";
import useLeague from "../../hooks/useLeague";
import tw from "../../styles/tailwind";


import LeagueCalendar from "./LeagueCalendar";
import LeagueStats from "../Admin/Leagues/League.Stats";



// http://localhost:19007/liga/liga-futmaster

export default function LeagueHome({navigation, route}) {
  const {leagueId} = route.params;
  const league = useLeague(leagueId)
  const [selectedTab, setSelectedTab] = useState(route.params?.selectedTab || "estadisticas");

  useEffect(() => {

    if (selectedTab == "estadisticas"){
      // selecionar tab estadísticas
    }
  }, []);

  return (
    <ScrollView style={tw``} >
      <Loading visible={! league} />

      {
        league &&
          <View style={tw`py-4`}>
            {
              league.image_url &&
                <Image
                  source={{uri: league.image_url}}
                  style={tw`h-28 mb-3`}
                  resizeMode={"contain"}
                />
            }

            <Text style={tw`text-white text-lg text-center mb-3`}>{league.name}</Text>

            {
              selectedTab === "estadisticas" &&
                <View style={tw`mt-4`}>
                  <Text style={tw`text-white px-4`}>{league.description}</Text>

                  <LeagueStats leagueId={leagueId} />
                </View>
            }


            {
              selectedTab === "calendario" &&
                <View style={tw`mt-4`} >
                  <LeagueCalendar leagueId={leagueId}  />
                </View>
            }
          </View>
      }

    </ScrollView>
  );
}


