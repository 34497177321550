import React, { useEffect, useState } from "react";
import { View } from "react-native";

import api from "../../utils/api";
import tw from "../../styles/tailwind";


import Text from "../../Components/Text";
// import TableStage from "./Stages/TableStage";
import TableStage from "../Admin/Leagues/Stages/TableStage";
import { useNavigation } from "@react-navigation/native";

export default function LeagueCalendar({ leagueName, leagueId }) {
  const [calendar, setCalendar] = useState(null);


  useEffect(() => {
    reloadCalendar()
  }, [])

  function reloadCalendar() {
    api.get(`/leagues/${leagueId}/calendar`)
      .then(({ data }) => {
        setCalendar(data)
      })
      .catch(error => { })
  }


  const navigation = useNavigation()




  return (
    <View style={tw`flex-1`} >
      {
        calendar &&
          <View style={tw`flex-1`}>
            {
              calendar.stages[0]?.type == "table" &&
              <TableStage
                leagueUid={leagueId}
                leagueName={leagueName}
                stage={calendar.stages[0]}
                canAdmin={false}
                onMatchPressed={match => {
                  navigation.navigate("Match.Detail", { matchId: match.uuid })
                }}
              />
            }

            {
              calendar.stages.length == 0 &&
              <View style={tw`flex-1 items-center justify-center pt-28`}>
                <Text>Sin calendario</Text>
              </View>
            }
          </View>
      }
    </View>
  );
}
