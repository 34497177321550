import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, Image } from "react-native"


import tw from '../../../styles/tailwind';
import Text from '../../../Components/Text';
import { useSelector } from 'react-redux';


function PlayerRow({ player, onPress, highlighted = false }: {
  player: Player,
  onPress?: () => void,
  highlighted?: boolean,
}) {
  const isRepre = player.pivot?.roles.indexOf('representative') !== -1

  return (
    <TouchableOpacity
      style={tw`border-b border-gray-500 row justify-between`}
      onPress={onPress}
    >
      {/* Imagen y nombre */}
      <View style={tw`row flex-1`}>
        <Image
          source={{ uri: player.image_url }}
          style={tw.style(`h-9 w-9 rounded-xl mr-1`, highlighted && { borderWidth: 1 }, highlighted && `border-primary`)}
        />

        <Text style={tw`text-gray font-bold w-7 text-center mr-1`}>{player.squad_number}</Text>
        <Text style={tw`text-gray flex-1`} numberOfLines={1}>{player.name}</Text>
      </View>

      <View style={tw`row`}>
        <Text style={tw`text-gray text-center w-15`}>{player.pivot.stats?.goals || 0}</Text>
        <Text style={tw`text-gray text-center w-15`}>{player.pivot.stats?.played || 0}</Text>
      </View>
    </TouchableOpacity>
  )
}


export default function TeamStats({ isFemaleTeam, players, leagueTeam}) {
  const currentPlayer = useSelector(state => state.status.player)

  const sortByGoals = players.sort((a, b) => a.pivot.stats?.goals < b.pivot.stats?.goals)

  return (
    <View style={tw.style()}>
      <View style={tw`items-end`}>
        <View style={tw`row`}>
          <Text style={tw`text-gray text-center w-15`}>Goles</Text>
          <Text style={tw`text-gray text-center w-15`}>Jugados</Text>
        </View>
      </View>
      {
        sortByGoals.map(player =>
          <PlayerRow
            key={player.uuid}
            player={player}
            highlighted={currentPlayer.uuid == player.uuid}
            onPress={() => {
              //!!! TODO: Conexión a datos/redux
              // navigation.navigate("Player.Detail", {
              //   playerId: player.uuid,
              //   leagueTeamId: leagueTeam.uuid,
              //   leagueTeamName: leagueTeam.name,
              //   leagueId, teamId, // 
              // })
            }}
          />
        )
      }
    </View>
  )
}

