import { format as dateFormat, formatDistance, formatRelative, addDays, subDays } from 'date-fns/esm'
import es from 'date-fns/locale/es'

export function getHourStr(date){
    const _date = date ? new Date(date) : null
    return _date ? format(_date, "HH:mm", { locale: es }) : null
}

export function dayOfWeekStr(date, short=false){
    const _date = date ? new Date(date) : null

    return _date ? capitalize(format(_date, `eee${ ! short ? "e": "" }`, { locale: es })) : null
}

export function monthStr(date, short=false){
    const _date = date ? new Date(date) : null
    
    return _date ? capitalize(format(_date, `MMM${ ! short ? "M": "" }`, { locale: es })) : null
}

export function format(date, format){
    const _date = date ? new Date(date) : null

    return _date ? dateFormat(_date, format, { locale: es }) : null
}

export function capitalize(str){
    return str.charAt(0).toUpperCase() + str.slice(1)
}