import React, { useEffect, useState } from "react";
import { View, Image, TouchableOpacity, Platform } from "react-native";


import Text from "../../Components/Text";
import Input from "../../Components/Input";

import tw from "../../styles/tailwind";

import { useDispatch } from 'react-redux';
import { actions } from "../../store/Reducers/auth";

import { useForm } from "react-hook-form";

import api from '../../utils/api'

import { useNavigation } from '@react-navigation/native';
import Button from "../../Components/Button";
import {Ionicons} from "@expo/vector-icons"
import FMIcons from "../../Components/FMIcon";
import ScanInvitationQR from "./ScanInvitationQR";

export interface IAppProps { }

export default function LoginScreen(props: IAppProps,) {
  const navigation = useNavigation();
  const { control, handleSubmit, setValue } = useForm();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [passwordResetMsg, setPasswordResetMsg] = useState();

  const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);

  const [showInvitationScanner, setShowInvitationScanner] = useState(false);


  const doLogin = (data) => {
    setIsSubmitting(true)
    setErrorMessage()

    api.post(`/auth/login`, data)
      .then(({ data }) => {
        setIsSubmitting(false)
        dispatch(actions.login(data.user, data.access_token))
      })
      .catch((err) => {
        if(err?.response?.data?.message){
          setErrorMessage(err.response.data.message)
        }
        console.log("El error en login es: ", err.response)
        setIsSubmitting(false)

      })
  }

  const sendPasswordResetLink = (data) => {
    setIsSubmitting(true)
    setErrorMessage()
    setPasswordResetMsg()

    api.post(`/auth/recover-password`, data)
      .then(({ data }) => {
        setIsSubmitting(false)
        setPasswordResetMsg(data.message)
        setValue("email", "")
        console.log(data);
        
      })
      .catch((err) => {
        if(err?.response?.data?.message){
          setErrorMessage(err.response.data.message)
        }
        console.log("El error en login es: ", err.response)
        setIsSubmitting(false)

      })
  }


  return (

    <View style={tw`flex-1  p-5 bg-primary`} >
      {/* <StatusBar style="auto" /> */}

      <Image source={require('../../assets/logo_black.png')} style={tw`h-16 w-auto rounded-xl self-center mt-4 mb-18`} resizeMode={"contain"} />


      {/* Login Form */}
      {
        !showResetPasswordForm &&
          <View style={tw`flex-1 mt-5 justify-center`}>
            <Text style={tw`-mt-6 mb-10 text-center font-bold text-lg`}
            onPress={() => navigation.navigate("ToS.Privacy")}
            >Inicia sesión</Text>

            <Input
              name="email"
              control={control}
              rules={{ required: "El correo electrónico es requerido", pattern: { value: /^\S+@\S+$/, message: "Correo no válido" } }}
              keyboardType={"email-address"}
              label={"Correo Electrónico"}
              style={{ container: tw`mb-10`, label: tw.style(`font-normal bg-transparent`), input: tw`font-normal border border-gray-200 bg-white rounded-xl`, subcontainer: tw`border-0` }}
            />

            <Input
              name="password"
              control={control}
              rules={{ required: "Contraseña requerida" }}
              type={"password"}
              label="Contraseña"
              secureTextEntry
              style={{ label: tw`font-normal bg-transparent`, input: tw`font-normal bg-white border border-gray-200 rounded-xl`, subcontainer: tw`border-0` }}
            />

            <Button style={{container: [`bg-secondary`, isSubmitting ? `opacity-50` : `opacity-100`], label: tw`text-gray`}}
              // tw.style(``, {opacity: isSubmitting ? 0.5 : 1})
              onPress={handleSubmit(doLogin)}
              disabled={isSubmitting}
              label={"Iniciar Sesión"}
            />

            {
              errorMessage &&
                <Text style={tw`text-red text-center mt-4`}>{errorMessage }</Text>
            }
          </View>
      }

      {
        showResetPasswordForm &&
          <View style={tw`flex-1 mt-5 justify-center`}>
            <Text style={tw`-mt-6 mb-3 text-center font-bold text-lg`}>¿Olvidaste tu contraseña?</Text>
            <Text style={tw`mb-10 text-center`}>Introduce el correo electrónico con el que te registraste y te enviaremos instrucciones para recuperar tu contraseña.</Text>

            <Input
              name="email"
              control={control}
              rules={{ required: "El correo electrónico es requerido", pattern: { value: /^\S+@\S+$/, message: "Correo no válido" } }}
              keyboardType={"email-address"}
              label={"Correo Electrónico"}
              style={{ container: tw`mb-10`, label: tw.style(`font-normal bg-transparent`), input: tw`font-normal border border-gray-200 bg-white rounded-xl`, subcontainer: tw`border-0` }}
            />


            <Button style={{container: [`bg-secondary`, isSubmitting ? `opacity-50` : `opacity-100`], label: tw`text-gray`}}
              // tw.style(``, {opacity: isSubmitting ? 0.5 : 1})
              onPress={handleSubmit(sendPasswordResetLink)}
              disabled={isSubmitting}
              label={"Restaurar contraseña"}
            />

            {
              errorMessage &&
                <Text style={tw`text-red text-center mt-4`}>{errorMessage }</Text>
            }

            {
              passwordResetMsg &&
                <Text style={tw`text-gray-500 text-center mt-4`}>{passwordResetMsg }</Text>
            }
          </View>
      }


      {/* 
        Botones inferiores (Escanear QR / Olvidé contraseña)
      */}
      {
        Platform.OS !== "web" &&
          <TouchableOpacity
            style={tw.style(`row border border-secondary py-4 mb-5 rounded-xl px-5 rounded-xl items-center justify-center bg-primary`, isSubmitting && `opacity-50`)}
            onPress={() => {
              // abrir escaner de QR
              setShowInvitationScanner(true)
            }}
            >
            <FMIcons name={"qr"} size={20} />
            <Text style={tw`ml-3`}>Escanear QR de invitación</Text>
          </TouchableOpacity>
      }


      <TouchableOpacity
        style={tw.style(`row border border-secondary py-4 rounded-xl px-5 rounded-xl items-center justify-center bg-primary`, isSubmitting && `opacity-50`)}
        onPress={() => {
          // abrir escaner de QR
          // setShowInvitationScanner(true)
          setShowResetPasswordForm(!showResetPasswordForm)
        }}
      >
        {/* <FMIcons name={"qr"} size={20} /> */}
        <Text style={tw`ml-3`}>Olvidé mi contraseña</Text>
      </TouchableOpacity>



      {/* <Button style={{ container: tw`mt-2 bg-secondary`, label: tw`text-gray` }}
        onPress={() => navigation.navigate('Auth.Signup')}
        label={"Registrarse"}
      /> */}

      <Text style={tw`mt-8 border-t border-[#999]/50 pt-4`}><Text style={tw`font-bold`}>¿Buscas registrate?</Text>  Solicita una invitación a tu capitán o en la administración de la cancha.</Text>

      {
        
      }
      <ScanInvitationQR visible={showInvitationScanner} onRequestClose={() => setShowInvitationScanner(false)} />

    </View >
  );
}