

import React, { useState } from "react";
import { View } from "react-native";
import tw from "../styles/tailwind";
import FMIcons from "./FMIcon";

import Text from "./Text";


export default function TitleSeparator(
  { title, titleBgColor = "transparent", style, icon, color = "#b8b28c" }
: { title: string, style?: any, icon?: string }) {
  return (
    <View style={tw.style(`row items-center mb-7 px-4`, style)}>
      {/* Linea izquierda */}
      <View style={tw`flex-1 h-full`}>
        <View style={tw`h-[1px] bg-[${color}] absolute right-0 left-0 top-50%`}></View>
      </View>

      {/* Titulo */}
      <View style={tw`bg-[${titleBgColor}] px-4 row`}>
        {
          icon &&
          <FMIcons
            name={icon}
            color={tw.color(`text-[${color}]`)}
            size={24}
            style={tw`mr-3`}
          />

        }
        <Text style={tw.style(`text-[${color}] font-500`, {fontSize: 16})}>{title}</Text>
      </View>

      {/* Linea derecha */}
      <View style={tw`flex-1 h-full`}>
        <View style={tw`h-[1px] bg-[${color}] absolute right-0 left-0 top-50%`}></View>
      </View>
    </View>
  )
}