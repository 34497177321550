import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, Image } from "react-native"


import tw from '../../styles/tailwind';
import PlayerXPBar from '../Gamification/PlayerXPBar';
import Text from '../Text';
import PlayerPicture from './PlayerPicture';






export default function PlayerPictureXP(
  { player, style, showName = true, showXPBar = true, showLevel = true, onPicturePressed } 
    : { player: Player, style?: any, showName: boolean, showXPBar: boolean, showLevel: boolean, onPicturePressed?:() => any }
) {
  if(!player) return null

  return (
    <View style={tw.style(`row`, style?.container)}>
      <TouchableOpacity onPress={onPicturePressed} disabled={!onPicturePressed}>
        <PlayerPicture player={player} style={tw.style(`h-22 w-22 rounded-full border-primary`, {borderWidth: 3})} />
      </TouchableOpacity>

      <View style={tw.style(`justify-around ml-3 pt-1 pb-2 bg-red-100`, showXPBar && `flex-1` )}>
        {
          showName &&
            <Text style={tw`text-white text-lg`}>{player.name}</Text>
        }

        <View style={tw`-ml-4`}>
          {
            showXPBar &&
              <PlayerXPBar current={2400} highLimit={4000} style={{ container: tw`` }} />
          }

          {
            showLevel &&
              <Image
                // source={{ uri: player.team.gamify_level?.image_url }}
                source={{ uri: "https://ouvio-dev8003.ngrok.io/assets/gamify/levels/player-1.png" }}
                style={tw`absolute -left-3 -top-1 h-8 w-8`}
              />
          }
        </View>
      </View>
    </View>
  )
}

