import { StyleSheet } from 'react-native';


import defaultTheme from './theme.style'

export const theme = defaultTheme


export default StyleSheet.create({
    btn: {
        padding: 10,
        borderWidth: 1,
        backgroundColor: theme.PRIMARY_COLOR,
    },
    select: {
        paddingVertical: 15,
        paddingHorizontal: 20,
        paddingRight: 33,
        // marginBottom: 25,
        borderColor: theme.COMPONENTS_BORDER_COLOR,
        // backgroundColor: theme.PRIMARY_COLOR, //
        borderWidth: 1,
        borderRadius: 8,
        fontWeight: "600",
        color: theme.PLACEHOLDER_COLOR,
        // flex: 1,
        // alignSelf: 'stretch',
    },
    inputWrapper: {
        borderWidth: 1,
        borderRadius: 8,
    },
    checkbox: {
        borderColor: theme.COMPONENTS_BORDER_COLOR,
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderRadius: 4,
        width: 20,
        height: 20,
    },
    checkboxFill: {
        flex: 1,
        borderRadius: 2,
        backgroundColor: 'black',
        margin: 3,
    },


    // Tabs
    

    //Card
    //tw.style(`flex rounded-xl h-52 justify-end`, { backgroundColor: props.color})
    card: {
        display: 'flex',
        height: '13rem',
        alignItems: 'flex-end',
        borderRadius: 8,
    },

    //app button
    appbtn: {
        paddingHorizontal: 30,
        paddingVertical: 12,
        backgroundColor: theme.SECONDARY_COLOR,
        // textAlign: 'center',
        borderRadius: 16,
        marginBottom:8
    },

    btnText: {
        color: theme.TEXT_SECONDARY,
        textAlign: 'center'
    },

    //list

    list: {
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        backgroundColor: "#FFF",
        width:'100%',
        paddingVertical: 8,
        paddingHorizontal: 6,
        marginBottom: 8,
        borderWidth: 1, 
        borderColor: theme.COMPONENTS_BORDER_COLOR,
        borderRadius: 10,

    },

    //input

    input:{
        display:'flex',
        alignItems:'center',
        backgroundColor:"#FFF",
        padding:13,
        borderRadius: 10
    },
});