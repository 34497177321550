import React from "react";
import { View, Image } from "react-native";
import Text from "../../Components/Text";

import tw from "../../styles/tailwind";

export default function GuestIndexScreen({navigation}){
  return (
    <View style={tw`flex-1 bg-primary items-center justify-center px-20`}>
      <Text style={tw`absolute right-4 top-5 font-bold`} onPress={() => navigation.navigate("Auth.Login")}>Iniciar Sesión</Text>


      <View style={tw`max-w-screen-md	w-full self-center items-center`}>
        <Image source={require('../../assets/logo_black.png')} style={tw`h-20 w-full`} resizeMode="contain" />

        <Text style={tw`text-xl text-center mt-10`}>Una nueva forma de vivir tus partidos</Text>

        <Text style={tw`text-center mb-4 mt-12`}>Descárgala en</Text>

        <a href='https://play.google.com/store/apps/details?id=app.futmaster&utm_source=web_home&utm_campaign=installations' title="Descarga FutMaster en Google Play">
          <img style={tw`h-20`} alt='Fut Master Disponible en Google Play' src='https://play.google.com/intl/es-419/badges/static/images/badges/es-419_badge_web_generic.png' />
        </a>

        <a href="https://apps.apple.com/us/app/futmaster/id1623310602" title="Descarga FutMaster en la App Store">
          <img style={tw`h-[60px]`} src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/es-mx?size=250x83&amp;releaseDate=1511913600&h=e1e831b4e462e6a28b0493bcf7c62632" alt="Fut Master Disponible en App Store" />
        </a>
      </View>

    </View>
  )
}