import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity } from "react-native"


import tw from '../../../../styles/tailwind';
import Text from '../../../../Components/Text';
import TabletModal from '../../Matches/TabletComponents/TabletModal';
import Button from '../../../../Components/Button';
import {Ionicons} from '@expo/vector-icons'
import api from '../../../../utils/api';

export default function HideMatchesModal({ visible, onRequestClose, round, matches, onMatchesSaved }:{matches:Array<Match>}) {
  const [hiddenMatches, setHiddenMatches] = useState<{string?:boolean}>({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (visible){
      const _hiddenMatches = {}

      matches.forEach(match => {
        _hiddenMatches[match.uuid] = !! match.hidden
      })

      setHiddenMatches(_hiddenMatches)

    }else{
      setHiddenMatches({})
      setIsLoading(false)
    }
  }, [visible]);


  function saveMatches(){
    setIsLoading(true)

    const data = {
      matches: hiddenMatches,
    }

    console.log(data);
    

    api.post(`/rounds/${round.uuid}/matches/set-visibility`, data)
    .then(({data}) => {
      // setIsLoading(false)
      onMatchesSaved?.()
    })
    .catch(({response})=> {
      setIsLoading(false)
      console.log("HideMatchesModal error - ", JSON.stringify(response));
    })
  }

  if(!visible) return null

  return (
    <TabletModal visible={visible} onRequestClose={onRequestClose} style={{wrapper: tw`w-90%`}}>
      <Text style={tw`text-lg text-bold text-center mb-3`}>Ocultar partidos</Text>

      {
        matches.map(match => {
          return (
            <TouchableOpacity
              style={tw`border-b border-gray-100 py-2 row justify-between`}
              onPress={() => {
                setHiddenMatches({
                  ...hiddenMatches,
                  [match.uuid]: ! hiddenMatches[match.uuid],
                })
              }}
            >
              <View style={tw``}>
                <Text>{match.home_team?.name}</Text>
                <Text>{match.away_team?.name}</Text>
              </View>

              <Ionicons name={`eye${hiddenMatches[match.uuid] ? '-off' : ''}-outline`} size={20} />
            </TouchableOpacity>
          )
        })
      }

      <Button label={isLoading ? "Guardando" : "Guardar"} disabled={isLoading} onPress={saveMatches} />
    </TabletModal>
  )
}

