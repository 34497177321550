import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, Image } from "react-native"


import tw from '../../styles/tailwind';
import Text from '../../Components/Text';

import FMIcon from '../../Components/FMIcon';
import TeamBadge from "../../Components/Teams/TeamBadge";
import { LinearGradient } from "expo-linear-gradient";
import TitleSeparator from "../../Components/TitleSeparator";

import Medal2nd from './assets/medal_2nd.svg'
import Medal3rd from './assets/medal_3rd.svg'

export function Medal1st({ width, height }) {
  return <Image source={require('./assets/medal_1st.png')} style={tw`w-[${width}px] h-[${height}px]`} resizeMode="contain" />
}


function FMLeaderboardTable({ teamsRanking }){
  const rankTable = teamsRanking?.slice(3)

  function renderRow(rank, teamName, leagueName, stadiumName, trophies, badge, isSelected=false){
    const colors = {
      odd: ["#917B41", "#BAA257"],
      even: ["#A38A47", "#D3B761"],
    }

    return (
      <View style={tw`row justify-between py-2 px-3`} key={rank}>
        <LinearGradient
          colors={colors[rank % 2 ? "even" : "odd"]}
          start={{ x: 0, y: 0.5 }}
          end={{ x: 1, y: 0.5 }}
          style={tw`absolute right-0 top-0 left-0 bottom-0`}
        />

        {
          isSelected &&
          <View style={tw`absolute left-0 w-[5px] bg-[#F4E0A4] top-0 bottom-0`} />
        }

        <View style={tw`row flex-1`}>
          <Text style={tw`text-white`}>{rank}</Text>

          <Text style={tw`text-white px-2`}>|</Text>

          <TeamBadge team={{badge}} width={25} height={25} style={tw`mr-2`} />
          
          <View style={tw`flex-1`}>
            <Text style={tw.style(`text-white`, isSelected && `font-bold text-[#F4E0A4]`)}>{teamName}</Text>
            <Text
              style={tw.style(`text-white text-sm`, isSelected && `text-[#F4E0A4]`)}
              numberOfLines={1}
              >{leagueName} <Text style={tw`px-1`}>•</Text> {stadiumName}</Text>
          </View>
        </View>

        <View style={tw`row`}>
          <FMIcon name="masterpoints" size={18} color={"#F4E0A4"} />
          {/* <MPIcon style={tw`w-6 h-6 -mt-1`} /> */}
          <Text style={tw`text-white ml-2`}>{trophies}</Text>
        </View>
      </View>
    )
  }

  return (
    <View style={tw``}>
      <Image source={require('./assets/degradado_gold.png')} style={tw`absolute w-full h-full rounded-lg`} resizeMode={"stretch"} />

      <View style={tw`m-[1.5px] rounded-lg overflow-hidden`}>
        {
          rankTable?.map(teamRank => 
            renderRow(teamRank.rank, teamRank.name, teamRank.league, teamRank.stadium, teamRank.trophies, teamRank.badge, teamRank.highlighted)
          )
        }
      </View>
    </View>
  )
}

function MPIcon({style}){
  return <Image source={require('./assets/masterpoints_icon.png')} style={tw.style(style)} resizeMode={"contain"}  />
}


export default function FMTeamsLeaderboard({teamsRanking}) {

  return (
    <View style={tw`mx-2`}>
      <TitleSeparator title={"Posición"} icon={"posiciones"} style={tw`mt-4`} />

      <View style={tw`row items-end -mt-2`}>
        {/*
          2do lugar
        */}
        <View style={tw`flex-1 items-center`}>
          <Medal2nd width={27} height={27} />
          <View style={tw`bg-[#303030] rounded-tl-lg absolute bottom-0 right-0 left-0 h-31`} />

          {/* Degradado negro abajo */}
          <LinearGradient
            colors={['#00000000', tw.color('black-100')]}
            start={{ x: 0.5, y: 0 }}
            end={{ x: 0.5, y: 1 }}
            style={tw`absolute right-0 left-0 bottom-0 h-22`}
          />

          <TeamBadge width={65} height={65} style={tw`mt-3 mb-1`} team={{ badge: teamsRanking?.[1]?.badge}} />

          <View style={tw`w-full rounded-tl-lg px-2 pb-7`}>
            <View style={tw`row justify-center mb-2`}>
              <FMIcon name="masterpoints" size={18} color={"#F4E0A4"} />
              {/* <MPIcon style={tw`w-5 h-5 -mt-[1px]`} /> */}

              <Text style={tw`text-white text-center font-600 text-lg ml-1`}>{teamsRanking?.[1]?.trophies ?? "-"}</Text>
            </View>

            <Text style={tw`text-white text-md font-500 text-center mb-2`}>{teamsRanking?.[1]?.name ?? "-"}</Text>
            <Text style={tw`text-yellow-200 text-center text-sm`}>{teamsRanking?.[1]?.stadium}</Text>
          </View>
        </View>


        {/*
          1er lugar
        */}
        <View style={tw`flex-1 items-center`}>
          <Medal1st width={35} height={35} />

          <View style={tw`w-full mt-10`}>
            <Image source={require('./assets/degradado_gold.png')} style={tw`absolute w-full h-full rounded-tl-lg rounded-tr-lg`} resizeMode={"stretch"} />

            <View style={tw`rounded-tl-lg rounded-tr-lg items-center mx-[1.5px] mt-[1.5px] px-2  pb-7`}>
              <LinearGradient
                colors={['#C3B37C', "#8F7A45"]}
                start={{ x: 0, y: 0.5 }}
                end={{ x: 1, y: 0.5 }}
                style={tw`absolute right-0 top-0 left-0 bottom-0 rounded-tl-lg rounded-tr-lg`}
              />

              {/* Degradado negro abajo */}
              <LinearGradient
                colors={['#00000000', tw.color('black-100')]}
                start={{ x: 0.5, y: 0 }}
                end={{ x: 0.5, y: 1 }}
                style={tw`absolute -right-1 -left-1 -bottom-0 h-22`}
              />

              <TeamBadge width={80} height={80} style={tw`mt-3 -mt-7 mb-1`} team={{ badge: teamsRanking?.[0]?.badge }} />

              <View style={tw`row justify-center mb-2`}>
                <FMIcon name="masterpoints" size={18} color={"#F4E0A4"} />
                {/* <MPIcon style={tw`w-5 h-5 -mt-[1px]`} /> */}

                <Text style={tw`text-white text-center font-600 text-lg ml-1`}>{teamsRanking?.[0]?.trophies ?? "-"}</Text>
              </View>

              <Text style={tw`text-white text-md font-500 text-center mb-2`}>{teamsRanking?.[0]?.name ?? "-"}</Text>
              <Text style={tw`text-yellow-200 text-center text-sm`}>{teamsRanking?.[0]?.stadium}</Text>
            </View>
          </View>
        </View>


        {/*
          3er lugar
        */}
        <View style={tw`flex-1 items-center`}>
          <Medal3rd width={27} height={27} />

          <View style={tw`bg-[#303030] absolute bottom-0 right-0 left-0 h-31 rounded-tr-lg`} />

          {/* Degradado negro abajo */}
          <LinearGradient
            colors={['#00000000', tw.color('black-100')]}
            start={{ x: 0.5, y: 0 }}
            end={{ x: 0.5, y: 1 }}
            style={tw`absolute right-0 left-0 -bottom-0 h-22`}
          />

          <TeamBadge width={65} height={65} style={tw`mt-3 mb-1`} team={{badge: teamsRanking?.[2]?.badge}} />

          <View style={tw` w-full rounded-tr-lg px-2 pb-7`}>
            <View style={tw`row justify-center mb-2`}>
              <FMIcon name="masterpoints" size={18} color={"#F4E0A4"} />

              {/* <MPIcon style={tw`w-5 h-5 -mt-[1px]`} /> */}

              <Text style={tw`text-white text-center font-600 text-lg ml-1`}>{teamsRanking?.[2]?.trophies ?? "-"}</Text>
            </View>

            <Text style={tw`text-white text-md font-500 text-center mb-2`}>{teamsRanking?.[2]?.name ?? "-"}</Text>
            <Text style={tw`text-yellow-200 text-center text-sm`}>{teamsRanking?.[2]?.stadium}</Text>
          </View>
        </View>
      </View>

      <FMLeaderboardTable teamsRanking={teamsRanking} />
    </View>
  )
}