import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, Image } from "react-native"

import tw from '../../styles/tailwind';
// import Level_1 from './assets/players/levels/nivel_1.svg'

export default function PlayerLevel({size=50, level, style=null}) {
  return <Image
    source={{ uri: `https://cdn.futmaster.app/gamification/levels/players/level_${level}.png` }}
    style={tw.style(`h-[${size}px] w-[${size}px]`, style)}
  />
}
