import React, { useState, useRef, useEffect } from 'react';
import { View } from 'react-native';


import Text from '../../Components/Text';
import BottomSheet from '../../Components/BottomSheet';

import tw from '../../styles/tailwind';
import QRScanner from '../../Components/QRScanner';
import { useNavigation } from '@react-navigation/native';






export default function ScanInvitationQR({ visible, onRequestClose }) {
  const [selectedScreen, setSelectedScreen] = useState();
  const navigation = useNavigation()

  function onCodeScanned(data: string){
    // "https://futmaster.app/invite/773a12c4-3d7d-4f9b-9fff-de3eda3bc649"
    const parts = data.split("/")

    if(parts[3] === "invite"){
      navigation.navigate("Invitation.Index", {token: parts[4]})
    }else{
      alert("Ese no es un código de invitación")
    }
    
    onRequestClose()
  }

  return (
    <BottomSheet
      visible={visible}
      onRequestClose={() => {
        // setSelectedScreen(0)
        onRequestClose && onRequestClose()
      }}
      height={250}
      style={{ container: tw`bg-gray rounded-tl-xl rounded-tr-xl` }}
    >

      <QRScanner
        onCodeScanned={onCodeScanned}
        // label={"Escanea el QR del equipo"}
      />

      <Text style={tw.style(`absolute top-2 left-0 right-0 text-white mb-4 mt-3 text-lg text-center`)}>Escanea el QR de invitación</Text>

    </BottomSheet >
  );
}
