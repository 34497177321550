import React, { useEffect, useState } from "react";
import { ScrollView, View, TouchableOpacity, Image } from "react-native";
import Text from "./Text";

import api from '../utils/api'

import tw from '../styles/tailwind';
import FMIcons from './FMIcon';


export default function HeaderPager({leftDisabled=false, onLeftPressed, rightDisabled=false, onRightPressed, title, subtitle}){
  return (
      <View style={tw`items-center mb-4 flex-row justify-between items-center bg-gray-500 py-2 px-3`}>
        {/* Boton atras */}
        <TouchableOpacity
          onPress={onLeftPressed}
          disabled={leftDisabled}
        >
          <View style={tw.style(`bg-primary rounded-xl py-4 px-4`, leftDisabled && `bg-background`)}>
            <FMIcons name={'chev-izq'} size={11} color={'black'} />
          </View>
        </TouchableOpacity>


        {/* Título y subtítulo */}
        <View style={tw`items-center`}>
          <Text style={tw`text-gray text-lg`}>{title}</Text>

          {
            subtitle && 
              <Text style={tw`text-gray-300`}>{subtitle}</Text>
          }
        </View>
        


        {/* Boton adelante */}
        <TouchableOpacity
          onPress={onRightPressed}
          style={rightDisabled && { opacity: 0 }}
          disabled={rightDisabled}
        >
          <View style={tw`bg-primary rounded-xl py-4 px-4`}>
            <FMIcons name={'chev-der'} size={11} color={'black'} />
          </View>
        </TouchableOpacity>
      </View>
  )
}