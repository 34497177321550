import React, { useEffect, useState } from "react";
import { View, Platform } from "react-native";
import Text from "./Text";
import LottieView from 'lottie-react-native';

export default function Loading({visible, style}:{visible: boolean, style?: any}){
  if(!visible) return null

  return (
    <View style={[{alignItems: 'center', marginVertical: 20}, style]}>
      {
        Platform.OS !== 'web' && // TODO: WEB_ADAPT
        <LottieView
          source={require('../assets/lotties/FM_loader.json')}
          autoPlay
          loop
          style={{
            width: 300,
            height: 100,
          }}
        />
      }
    </View>
  )
}