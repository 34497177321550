import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, ScrollView, Image } from "react-native"


import tw from '../../styles/tailwind';
import Text from '../../Components/Text';
import Modal from '../../Components/Modal';
import ImagesPicker from '../../Components/ImagesPicker';
import { useForm } from 'react-hook-form';
import Input from '../../Components/Input';
import OptionsSelect from '../../Components/OptionsSelect';
import Button from '../../Components/Button';
import api from '../../utils/api';
import PosistionSelector from '../../Components/Players/PositionSelector';
import FMIcons from '../../Components/FMIcon';
import NeonButton from '../../Components/NeonButton';

function JerseyImg(props){
  return (
    <Image source={require('../../assets/jersey.png')} resizeMode={"contain"} {...props} />
  )
}

export default function PlayerEditProfileModal({ onRequestClose, visible, player, onPlayerUpdated }) {
  const { control, handleSubmit, watch, formState: { errors }, setError, setValue, reset } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [image, setImage] = useState();

  const name = watch("name")
  const last_name = watch("last_name")
  const nickname = watch("nickname")


  useEffect(() => {
    if (player) {
      reset({
        name: player.first_name,
        last_name: player.last_name,
        second_last_name: player.second_last_name,
        squad_number: player.squad_number,
        gender: player.gender,
        nickname: player.nickname,
        position: player.position,
      })
    }
  }, [player]);


  const onImageChanged = (data) => {
    setImage(data)
  }


  function submitPlayer(data) {
    const _data = {
      ...data,
    }

    if (image) {
      _data.image_uuid = image.uuid
    }

    setIsSubmitting(true)

    console.log("submitPlayer", _data)

    api.post(`/players/${player.uuid}`, _data)
    .then(({ data }) => {
      setIsSubmitting(false)
      onPlayerUpdated?.()
    })
    .catch(({ response }) => {
      setIsSubmitting(false)

      console.log(response?.data?.message);
      
      if (response?.data?.message) {
        // setErrorMessage(response.data.message)
      }

      if (response?.data?.errors) {
        Object.keys(response.data.errors).forEach(errorKey => {
          setError(errorKey, { type: "manual", message: response.data.errors[errorKey][0] })
        })
      }
    })
  }

  if(!player) return null

  const changingName = name != player.first_name || last_name != player.last_name


  return (
    <Modal
      visible={visible}
      onRequestClose={onRequestClose}
      onDismiss={onRequestClose}
      style={{ wrapper: tw`py-0 bg-[#333333] top-4 bottom-4` }}
      transparent
      animationType="slide"
      hardwareAccelerated={true}
    >
      <ScrollView
        // ref={scrollViewRef}
        style={tw`flex-1 -mx-4`}
        contentContainerStyle={tw`py-3 pt-5 px-4`}
      >
        <View style={tw``}>
          <ImagesPicker
            label={"Sube tu foto"}
            onImageChanged={onImageChanged}
            initialUrl={player?.image_url}
            style={{
              buttonWrapper: tw`bg-secondary border-primary border-solid`,
              label: tw`text-gray`,
              buttonWrapperFill: tw``
            }}
          />

          {
            player.can_change_name ?
              <>
                <Input
                  name="name"
                  control={control}
                  rules={{ required: "El nombre es requerido" }}
                  type={"text"}
                  label="Nombre(s)"
                  style={{
                    container: tw`mt-10`,
                    label: tw`-mt-[30px] bg-transparent font-400 self-center text-yellow-200 font-normal`,
                    input: tw`bg-white font-300 text-center`
                  }}
                  // autoFocus={autofocus}
                />


                <View style={tw`row`}>
                  <Input
                    name="last_name"
                    control={control}
                    rules={{ required: "Apellido Paterno Requerido" }}
                    type={"text"}
                    label="Apellido Paterno"
                    style={{
                      container: tw`flex-1 mt-7 mr-2`,
                      label: tw`bg-transparent font-400 -mt-[30px] self-center text-yellow-200 -mr-2 -ml-2`,
                      input: tw`bg-white font-300 text-center`
                    }}

                  />

                  <Input
                    name="second_last_name"
                    control={control}
                    type={"text"}
                    label="Apellido Materno"
                    style={{
                      container: tw`flex-1 mt-7 ml-2`,
                      label: tw`bg-transparent font-400 -mt-[30px] self-center text-yellow-200 -mr-2 -ml-2`,
                      input: tw`bg-white font-300 text-center`
                    }}
                  />

                </View>

                {
                  changingName && 
                    <View style={tw`bg-[#1a1a1a] -mx-4 px-5 mb-4 py-5 items-center`}>
                      <FMIcons name={'alerta'} size={28} color={tw.color('text-[#e56363]')} />

                      <Text style={tw`mt-2 text-[#e56363] text-center leading-6	`}>Verifica que tu nombre esté bien escrito, al guardar ya no podrás cambiarlo.</Text>
                    </View>
                }
              </>
            :
              <View>
                <Text style={tw`font-500 text-center text-xl mt-4 mb-4 text-white`}>{player.name}</Text>
                {/* <Text style={tw`text-sm text-center`}></Text> */}
              </View>
          }


          <View style={tw`row justify-around mt-5 mb-3`}>
            <Text style={tw`text-yellow-200`}>¿Tienes un apodo?</Text>

            <Input
              name="nickname"
              control={control}
              // label="Apodo (opcional)"
              maxLength={15}
              style={{ container: tw`w-35 ml-2 mb-0`, label: ` font-normal ml-1`, input: tw`bg-white` }}
            />
          </View>

          <View style={tw`mt-4`}>
            <Text style={tw`text-white mb-5`}>Elige el número dorsal que más utilizas</Text>

            <JerseyImg style={tw`w-full h-55`} />

            <Text style={tw`absolute self-center top-30%`}>{nickname}</Text>

            <Input
              name="squad_number"
              control={control}
              keyboardType='numeric'
              maxLength={3}
              // label="# Dorsal"
              placeholder="00"
              rules={{ required: "Dorsal Requerido", pattern: { value: /^[0-9]{1,3}$/, message: "El dorsal tiene que ser un número" } }}
              style={{
                container: tw`self-center top-50% -mt-12 w-30 absolute`,
                label: tw`bg-transparent font-normal ml-1`,
                subcontainer: tw`border-0`,
                input: tw.style(`bg-transparent text-center`,{ fontSize: 60, fontFamily: 'AgencyFB' } )
              }}
              onSubmitEditing={({ nativeEvent }) => {
                // console.log("onSubmitEditing", nativeEvent.text)
                // handleSubmit(submitPlayer)()
              }}
            />
          </View>

          <View style={tw`mt-9 mb-5`}>
            <OptionsSelect
              style={{
                container: tw`mb-5`,
                option: `flex-1 px-2 py-4 row bg-transparent border-0`,
                optionsWrapper: tw`justify-between`,
                icon: `text-[#666]`,
                iconLabel: tw`-mx-0 mt-0 ml-3 text-white`
              }}
              control={control}
              name={"gender"}
              options={[
                {
                  icon: "varonil",
                  iconlabel: "Masculino",
                  value: "male"
                },
                {
                  icon: "feminil",
                  iconlabel: "Femenino",
                  value: "female"
                },
              ]}
              rules={{ required: true }}
            />
          </View>


          <View style={tw`items-center mb-3  mt-3`}>
            <Text style={tw`mb-4 text-white text-center`}>Elige tu posición</Text>

            <PosistionSelector
              position={watch('position')}
              onChange={position => setValue("position", position)}
            />
          </View>
        </View>
      </ScrollView>


      <NeonButton
        label={!isSubmitting ? "Actualizar Perfil" : "Actualizando..."}
        disabled={isSubmitting}
        onPress={handleSubmit(submitPlayer)}
        mini
        style={{ container: tw`mb-2 mt-3`, button: `my-3` }}
      />
    </Modal>
  )
}

