import React from 'react';
import { View } from 'react-native';
import { useFonts } from 'expo-font';
import { createIconSetFromIcoMoon } from '@expo/vector-icons';
import tw from '../styles/tailwind';


const FMIcons = createIconSetFromIcoMoon(
  require('../assets/icomoon/selection.json'),
  'IcoMoon',
  'icomoon.ttf'
);

export default FMIcons;