import React from "react";

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import Ionicons from '@expo/vector-icons/Ionicons'

import FMIcons from '../Components/FMIcon';

import PlayerHome from "./Index";
import PlayerProfile from "./Player/Player.Profile";
import FMLeagueScreen from "./FMLeague/FMLeagueScreen"
import { View } from "react-native";
import tw from "../styles/tailwind";

const Tab = createBottomTabNavigator();

import { useSafeAreaInsets } from 'react-native-safe-area-context';

import * as Analytics from 'expo-firebase-analytics';



import { Dimensions, Platform } from 'react-native';
import { useNavigation } from "@react-navigation/native";

export function isIphoneX() {
  const dim = Dimensions.get('window');

  return (
    // This has to be iOS
    Platform.OS === 'ios' &&

    // Check either, iPhone X or XR
    (isIPhoneXSize(dim) || isIPhoneXrSize(dim))
  );
}

export function isIPhoneXSize(dim) {
  return dim.height == 812 || dim.width == 812;
}

export function isIPhoneXrSize(dim) {
  return dim.height == 896 || dim.width == 896;
}

function isIphone(){
  return Platform.OS === 'ios'
}



export default function PlayerStack() {
  const insets = useSafeAreaInsets();

  return (
    <Tab.Navigator
      screenOptions={({ route }) => ({
        tabBarIcon: ({ focused, color, size }) => {
          let iconName;

          if (route.name === 'Home') {
            iconName = 'inicio-nav';

          } else if (route.name === 'FMLeagueScreen') {
            iconName = 'copa';

          } else if (route.name === 'PlayerProfile') {
            iconName = 'perfil-nav';
          }

          return <FMIcons name={iconName} size={23} color={focused ? '#efc42e' : '#706967'} />;
        },
        tabBarActiveTintColor: '#efc42e',
        tabBarInactiveTintColor: '#706967',
        tabBarStyle: isIphone() ? { position: 'absolute', paddingBottom: insets.bottom - 5, height: 60 + insets.bottom - 5 } : { position: 'absolute', paddingBottom: 6, height:60 },
        tabBarBackground: () => (
          <View style={tw`flex-1 bg-gray-400`} />
        ),
          
      })}
    >
      <Tab.Screen
        name="Home"
        component={PlayerHome}
        options={{ headerShown: false, tabBarLabel:  "Inicio" }}
      />

      <Tab.Screen
        name="PlayerProfile"
        component={PlayerProfile}
        options={{ tabBarLabel: 'Mi Perfil', }}
        listeners={{
          tabPress: (e) => {
            Analytics.logEvent('PlayerProfileTabView', {
              sender: 'TabBar',
              purpose: 'Ver pantalla Perfil',
            });
          },
        }}
      />


      <Tab.Screen
        name="FMLeagueScreen"
        component={FMLeagueScreen}
        options={{ headerShown: false, tabBarLabel: 'Torneo FM' }}
        listeners={{
          tabPress: (e) => {
            Analytics.logEvent('FMLeagueScreenView', {
              sender: 'TabBar',
              purpose: 'Ver pantalla LigaFM',
            });
          },
        }}
      />
    </Tab.Navigator>
  );
}
