import React, { useState, useEffect } from "react";
import { TouchableOpacity, View } from "react-native";

import tw from "../../../styles/tailwind";

import Text from "../../../Components/Text";
import FMIcons from '../../../Components/FMIcon';

import { useNavigation } from "@react-navigation/native";
import { getHourStr, dayOfWeekStr, monthStr } from "../../../utils/momento";
import TeamBadge from "../../../Components/Teams/TeamBadge";

import MatchSelectRefereeBottomSheet from "../Matches/MarchDetailComponents/MatchSelectRefereeBottomSheet";
import LiveIndicator from "../../../Components/LiveIndicator";


function MatchCardTeam({ team, style }) {
  const emptyTeamBadge = { badge: { name: "empty" } }

  return (
    <View style={tw`items-center flex-1`}>
      <TeamBadge team={team?.team ?? emptyTeamBadge} height={70} width={70} style={tw`mb-1`} />

      <Text
        style={tw.style(
          `text-center mt-1 text-gray`,
          style?.name,
          ! team?.team && `text-gray-300`,
        )}
      >{team?.name ?? "Sin equipo"}</Text>
    </View>
  )
}



function RefereeSelector({ style, referee, round }) {
  const [modalShown, setModalShown] = useState(false);
  return (
    <TouchableOpacity
      style={tw`flex-row justify-between items-center p-4 rounded-xl bg-secondary`}
      onPress={() => { setModalShown(true) }}
    >
      <View style={tw`flex-row items-center`}>
        <FMIcons name="arbitro" size={20} color="#efc42e" />
        <Text style={tw`text-gray text-md ml-2`}>{referee}</Text>
      </View>

      <FMIcons name="chev-der" size={11} color="#efc42e" />

      <MatchSelectRefereeBottomSheet
        visible={modalShown}
        onRequestClose={() => {
          setModalShown(false)
        }}
      />
    </TouchableOpacity>
  )
}



interface MatchCardProps{
  match: Match,
  showLeague: boolean,
  showDate: boolean,
  showTime: boolean,
  showRound: boolean,
  showStatus: boolean,
  showField: boolean,
  onPress?: () => void,
  style?: any,
}

function matchStatusColor(status:MatchStatus){
  switch (status) {
    case 'canceled':
      return "red";
      break;
  
    default:
      return "#ddd";
  }
}


export default function MatchCard(
  { match, style, showLeague = true, showDate = true, showTime = true, onPress, showRound, showStatus = true, showField = true }
: MatchCardProps) {
  const navigation = useNavigation();

  const createdAt = match.date ? new Date(match.date) : null
  const hourStr = getHourStr(createdAt)
  const dateStr = createdAt ? `${dayOfWeekStr(createdAt)}, ${createdAt?.getDate()} ${monthStr(createdAt)}` : null

  const statusColor = matchStatusColor(match.status)

  const showScore = match.status !== "created" && match.status !== "canceled" && match.status !== "postponed" && match.status !== "pending"// || match.status == "awarded"
  const isLive = showScore && match.status !== "awarded" && match.status !== "played"

  return (
    <TouchableOpacity
      // onPress={() => {
      //   // Mueve a la siguiente pantalla
      //   // navigation.navigate("Admin.MatchDetail", { matchId: match.uuid })
      // }}
      onPress={onPress}
      style={tw.style(`pt-3 rounded-xl bg-gray-500`, style?.container)}
    >

      {/* Fecha y hora*/}
      {
        ( showLeague || showDate || showTime) &&
          <View style={tw`flex-row items-center justify-between border-b border-gray-300 mx-5 mb-3 pb-2`}>
            {
              (showLeague && match.league) &&
                <Text style={tw`text-white`}>{match.league.name}</Text>
            }
            {
              showDate && 
                <Text style={tw.style(`text-gray-300 text-center`, dateStr && `text-white`)}>{dateStr ? dateStr : "Sin fecha"}</Text>
            }
            {
              showTime && 
                <View style={tw`flex-row items-center`}>
                  <Text style={tw`text-primary text-md ml-1`}>{hourStr ? hourStr : ""}</Text>
                </View>
            }
          </View>
      }

      {/* Contrincantes */}
      <View style={tw`flex-row justify-between items-center mb-3`}>

        {/* Local */}
        <MatchCardTeam
          team={match.home_team}
          style={{  }}
        />


        {/* VS */}
        {
          ! showScore ?
            <Text style={tw.style(`text-gray font-bold`, {fontSize: 30})}>VS</Text>
          :
            <Text style={tw`text-white font-bold text-xl`}>{match.home_score} - {match.away_score}</Text>
        }

        {/* Visitante */}
        <MatchCardTeam
          team={match.away_team}
          style={{  }} />

      </View>
        {
          (match.field && showField) &&
            <View style={tw``}>
              <Text style={tw`text-gray text-center`}>{match.field.name}</Text>
            </View>
        }

        {
          !! (showRound && match.round) &&
            <View>
              <Text style={tw.style(`text-gray text-center text-primary mb-2 -mt-1`, style?.round)}>{match.round?.name}</Text>
            </View>
        }

        {
          (match.status_str && showStatus) &&
            <View style={tw`row justify-center mb-2`}>
              {
                isLive &&
                  <LiveIndicator />
              }
              
              <Text style={tw`font-bold text-[${statusColor}] text-center`}>{match.status_str}</Text>
            </View>
        }

      {/* Lanza Bottomsheet para elegir "árbitro"  */}
      {/* {
        (showRefereeSelector && match.status != "played" && match.status != "awarded") &&
          <RefereeSelector referee={"Sin árbitro asignado"} round={"10"} />
      } */}

    </TouchableOpacity>
  )
}