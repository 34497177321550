import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, Pressable, Image, Alert, Platform } from "react-native"


import Text from '../../../../Components/Text';
import tw from '../../../../styles/tailwind';
import FMIcons from "../../../../Components/FMIcon";
import PlayerPicture from '../../../../Components/Players/PlayerPicture';
import api from '../../../../utils/api';
import Button from '../../../../Components/Button';
import Input from '../../../../Components/Input';
import { useForm } from 'react-hook-form';
import {Ionicons} from "@expo/vector-icons"
import PlayerTakePictureModal from './PlayerTakePictureModal';

export default function PlayerDetailModal({ player, playerLeagueTeam, league, onRequestClose, onPlayerEdited }) {
  const { control, handleSubmit,  setValue, watch } = useForm();
  const [showCameraModal, setShowCameraModal] = useState(false);
  const [playerImageUrl, setPlayerImageUrl] = useState();

  const [showForm, setShowForm] = useState();
  const [isLoading, setIsLoading] = useState();
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    setValue("squad_number", "")
    setShowForm(true)
    setPlayerImageUrl(player)
  }, [player]);

  if (!player) return null

 
  function changeSquadNumber(formData) {
    const data = {
      squad_number: formData.squad_number,
    }

    setIsLoading(true)
    
    api.post(`leagues/${league.uuid}/players/${player.uuid}`, data)
      .then(({ data }) => {
        onPlayerEdited?.()
        setIsLoading(false)
      })
      .catch(({ response }) => {
        console.log("el error", response?.data)
        setIsLoading(false)
        if (response.data.message) {
          setErrorMessage(response.data.message)
        }
      })
  }




  return (
    <View style={tw`flex-1 absolute right-0 top-0 left-0 bottom-0 items-center justify-center`}>

      <Pressable onPress={onRequestClose} style={tw`absolute right-0 top-0 left-0 bottom-0 bg-black/85`} />


      <View style={tw`bg-white rounded-3xl py-5 px-5`}>
        


        <View style={tw`items-center`}>
          {
            player &&
            <View style={tw`items-center`}>
              <TouchableOpacity
                disabled={playerImageUrl?.image_url?.includes("placeholder") === false}
                onPress={() => setShowCameraModal(true)} style={tw``}
              >
                <PlayerPicture player={playerImageUrl} style={tw`h-30 w-30 rounded-xl`} />
              </TouchableOpacity>

              <Text style={tw.style(`text-center mt-5`, { fontSize: 30 })}>#{player.squad_number} | <Text style={tw`font-bold`}>{player.name}</Text></Text>


              {
                showForm &&
                <View style={tw`row pt-4 pb-3`}>
                  {/* icon-chev-der */}
                  <Text style={tw.style(`text-gray-400`,{fontSize: 45})}>{player.squad_number}</Text>
                  <Ionicons name="chevron-forward-outline" size={30} style={tw`mx-3 text-gray-200`} />
                  <Input
                    autoFocus
                    keyboardType="phone-pad"
                    style={{container: tw`mb-0`, input: {fontSize: 50, paddingTop: 0, paddingBottom:0}}}
                    control={control}
                    maxLength={3}
                    name="squad_number"
                    rules={{required: true}}
                    editable={!isLoading}
                    onSubmitEditing={({ nativeEvent }) => {
                      // console.log("onSubmitEditing", nativeEvent)
                      handleSubmit(changeSquadNumber)()
                    }}
                  />
                </View>
              }

              {
                errorMessage && <Text style={tw`text-center font-bold text-red`}>{errorMessage}</Text>
              }

              <Button
                style={{ container: tw`row bg-gray-100`, label: tw`ml-3`}}
                label={isLoading ? 'Cambiando...' : 'Cambiar # dorsal'}
                icon='jugadores-b'
                iconSize={20}
                onPress={() => {
                  !showForm ? setShowForm(true) : handleSubmit(changeSquadNumber)()
                }}
                disabled={isLoading}
              />
            </View>
          }

          {/* <Text style={tw.style(`text-center`, { fontSize: 30 })}>{event.league_team.name}</Text> */}
        </View>

      </View>

      <Text onPress={onRequestClose} style={tw`text-white absolute right-12 bottom-12 text-lg`}>Cerrar</Text>

      <PlayerTakePictureModal
        visible={showCameraModal}
        leagueId={league.uuid}
        player={player}
        onImageChanged={(imageUrl) => {
          setShowCameraModal(false)
          onPlayerEdited?.(false)
          setPlayerImageUrl({ image_url: imageUrl})
        }}
        onRequestClose={() => {
          setShowCameraModal(false)
        }}
      />
    </View>
  )
}

