import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, Platform } from "react-native"


import LottieView from 'lottie-react-native';

export default function ClockAnimation({ size=40 }) {
  if(Platform.OS == "web") return null
  
  return (
    <LottieView
      source={require('./assets/clock.json')}
      autoPlay
      loop
      style={{
        width: size,
        height: size,
      }}
      speed={1}
    />
  )
}

