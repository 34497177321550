import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, Image } from "react-native"


import tw from '../../styles/tailwind';
import Text from '../../Components/Text';

import FMIcon from '../../Components/FMIcon';
import TeamBadge from "../../Components/Teams/TeamBadge";
import { LinearGradient } from "expo-linear-gradient";
import TitleSeparator from "../../Components/TitleSeparator";

import Medal2nd from './assets/medal_2nd.svg'
import Medal3rd from './assets/medal_3rd.svg'

import GoldBG from './assets/bg_gold.svg'
import SilverBG from './assets/bg_silver.svg'
import BronzeBG from './assets/bg_bronze.svg'
import { Medal1st } from './FMTeamsLeaderboard';





function PlayerImage({ style, path, medal, backgroundGradient, imageSize=15}){
  
  return (
    <View style={tw.style(`items-center`, style?.container)}>
      <View style={tw.style(`rounded-full h-${imageSize + 1} w-${imageSize + 1} items-center justify-center`, style?.image)}>
        {
          backgroundGradient 
            ? (
              (typeof backgroundGradient == "number") ?
                <Image source={backgroundGradient} style={tw`absolute w-full h-full rounded-full`} resizeMode={"stretch"} />
              :
                <View style={tw`absolute w-full h-full rounded-full overflow-hidden`}>
                  {backgroundGradient}
                </View>
            )
          : null
        }
        
        <Image
          source={{ uri: path}}
          style={tw`w-${imageSize} h-${imageSize} rounded-full`}
        />
      </View>

      <View style={tw`-mt-3`}>
        { medal }
      </View>
    </View>
  )
}


interface PlayerRank{
  rank: number,
  name: string,
  goals: number,
  image_url: string,
  teams: Array<{
    name:string, 
    league:string,
    stadium:string,
  }>,
  highlighted: boolean,
}

function FMPlayersLeaderboardTable({playersRanking}) {
  const rankTable = playersRanking?.slice(3)

  function renderRow(playerRank: PlayerRank) {
    const teamsStr = playerRank.teams[0].name

    return (
      <View
        style={tw.style(`row justify-between py-2 px-3`, playerRank.rank % 2 ? `bg-[#303030]` : `bg-[#151515]`)}
        key={playerRank.rank}>
        {
          playerRank.highlighted &&
            <LinearGradient
              colors={["#585524", "#58552400"]}
              start={{ x: 0, y: 0.5 }}
              end={{ x: 1, y: 0.5 }}
              style={tw`absolute top-0 left-0 bottom-0 right-30%`}
            />
        }

        <View style={tw`row flex-1`}>
          <Text style={tw`text-white`}>{playerRank.rank}</Text>

          <Text style={tw`text-white px-2`}>|</Text>

          <Image
            source={{ uri: playerRank.image_url }}
            style={tw`w-8 h-8 mr-[5px] rounded-full`}
          />

          <View>
            <Text style={tw`text-white`}>{playerRank.name}</Text>
            <Text style={tw`text-yellow-200 text-sm`}>{teamsStr}</Text>
          </View>
        </View>

        <View style={tw`row`}>
          <FMIcon name="gol" size={16} color={"#F4E0A4"} />
          <Text style={tw`text-white ml-[3px]`}>{playerRank.goals}</Text>
        </View>
      </View>
    )
  }

  return (
    <View style={tw``}>
      <View style={tw`rounded-lg overflow-hidden`}>
        {/* {renderRow(1, "even")}
        {renderRow(2, "odd")}
        {renderRow(1, "even", true)}
        {renderRow(2, "odd")}
        {renderRow(1, "even")}
        {renderRow(2, "odd")} */}
        {
          rankTable?.map(playerRank =>
            renderRow(playerRank)
          )
        }
      </View>
    </View>
  )
}

export default function FMGoalsLeaderboard({ playersRanking }: { playersRanking : Array<PlayerRank>}) {
  return (
    <View style={tw`px-2`}>
      <TitleSeparator title={"Goleo"} icon={"gol"} style={tw`mt-3`} />


      <View style={tw`row items-end mt-4`}>
        {/*
          2do lugar
        */}
        <View style={tw`flex-1 items-center  bg-[#303030] rounded-tr-lg rounded-tl-lg mr-1`}>
          <PlayerImage
            style={{ container: tw`mb-2 -mt-7` }}
            medal={<Medal2nd width={27} height={27} />}
            backgroundGradient={<SilverBG style={tw``} />}
            path={playersRanking?.[1]?.image_url}
          />

          {/* Degradado negro abajo */}
          <LinearGradient
            colors={['#00000000', tw.color('black-100')]}
            start={{ x: 0.5, y: 0 }}
            end={{ x: 0.5, y: 1 }}
            style={tw`absolute right-0 left-0 -bottom-0 h-20`}
          />

          <View style={tw`w-full rounded-tl-lg px-2 pb-5`}>
            <View style={tw`row justify-center mb-2`}>
              <FMIcon name="gol" size={15} color={"#F4E0A4"} />
              <Text style={tw`text-white text-center font-600 text-lg ml-1`}>{playersRanking?.[1]?.goals ?? "-"}</Text>
            </View>

            <Text style={tw`text-white text-md font-500 text-center mb-2`}>{playersRanking?.[1]?.name ?? "-"}</Text>
            <Text style={tw`text-yellow-200 text-center text-sm`}>{playersRanking?.[1]?.teams[0]?.name}</Text>
          </View>
        </View>


        {/*
          1er lugar
        */}
        <View style={tw`flex-1 items-center bg-[#303030] rounded-tr-lg rounded-tl-lg mx-1 mt-6`}>
          <PlayerImage
            imageSize={23}
            style={{ container: tw`mb-2 -mt-12` }}
            medal={<Medal1st width={38} height={38} />}
            backgroundGradient={<GoldBG />}
            path={playersRanking?.[0]?.image_url}
            // backgroundGradient={require('./degradado.png')}
          />

          {/* Degradado negro abajo */}
          <LinearGradient
            colors={['#00000000', tw.color('black-100')]}
            start={{ x: 0.5, y: 0 }}
            end={{ x: 0.5, y: 1 }}
            style={tw`absolute right-0 left-0 -bottom-0 h-20`}
          />
          
          <View style={tw`px-2 pb-5`}>
            <View style={tw`row justify-center mb-2`}>
              <FMIcon name="gol" size={15} color={"#F4E0A4"} />
              <Text style={tw`text-white text-center font-600 text-lg ml-1`}>{playersRanking?.[0]?.goals ?? "-"}</Text>
            </View>

            <Text style={tw`text-white text-md font-500 text-center mb-2`}>{playersRanking?.[0]?.name ?? "-"}</Text>
            <Text style={tw`text-yellow-200 text-center text-sm`}>{playersRanking?.[0]?.teams[0]?.name}</Text>
          </View>
        </View>


        {/*
          3er lugar
        */}
        <View style={tw`flex-1 items-center bg-[#303030] rounded-tr-lg rounded-tl-lg ml-1`}>
          <PlayerImage
            style={{ container: tw`mb-2 -mt-7` }}
            medal={<Medal3rd width={27} height={27} />}
            backgroundGradient={<BronzeBG />}
            path={playersRanking?.[2]?.image_url}
          />

          {/* Degradado negro abajo */}
          <LinearGradient
            colors={['#00000000', tw.color('black-100')]}
            start={{ x: 0.5, y: 0 }}
            end={{ x: 0.5, y: 1 }}
            style={tw`absolute right-0 left-0 -bottom-0 h-20`}
          />

          <View style={tw` w-full rounded-tr-lg px-2 pb-5`}>
            <View style={tw`row justify-center mb-2`}>
              <FMIcon name="gol" size={15} color={"#F4E0A4"} />
              <Text style={tw`text-white text-center font-600 text-lg ml-1`}>{playersRanking?.[2]?.goals ?? "-"}</Text>
            </View>

            <Text style={tw`text-white text-md font-500 text-center mb-2`}>{playersRanking?.[2]?.name ?? "-"}</Text>
            <Text style={tw`text-yellow-200 text-center text-sm`}>{playersRanking?.[2]?.teams[0]?.name}</Text>
          </View>
        </View>
      </View>

      <FMPlayersLeaderboardTable playersRanking={playersRanking} />
    </View>
  )
}