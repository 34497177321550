import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity } from "react-native"


import tw from '../../../../styles/tailwind';
import Text from '../../../../Components/Text';
import Button from "../../../../Components/Button";
import api from '../../../../utils/api';
import {Ionicons} from '@expo/vector-icons'

export default function MatchNotifyPlayerButton({ match, onPlayersNotified }) {
  const [submitting, setSubmitting] = useState(false);
  const notified = match.settings?.players_notified
  const isFemaleLeague = match.league?.gender === "female"


  function notifyPlayers() {
    setSubmitting(true)

    api.post(`/matches/${match.uuid}/notify-players`)
      .then(({ data }) => {
        // setSubmitting(false)
        onPlayersNotified?.()
      })
      .catch((error) => {
        setSubmitting(false)
        console.log("error al notificar jugadores", error)
      })
  }


  return (
    <Button
      label={notified 
        ? `Jugador${isFemaleLeague ? 'a' : 'e'}s notificad${isFemaleLeague ? 'a' : 'o'}s` :
        (submitting ? "Enviando notificaciones..." : `Notificar a los jugador${isFemaleLeague? 'a' : 'e'}s`)
      }
      icon={<Ionicons name={ ! notified ? "notifications-outline" : "checkmark" } size={20} style={tw`mr-2`} />}
      disabled={notified || submitting}
      onPress={notifyPlayers}
      style={{container: `row`}}
    />
  )
}

