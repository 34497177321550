import React, { useState, useEffect } from 'react';
import { Text, Image, View, TouchableOpacity, Platform } from 'react-native'
import { useSelector } from 'react-redux';

import tw from '../../styles/tailwind';

import AuthenticatedIndexScreen from './AuthenticatedIndex';
import LoginScreen from '../Auth/LoginScreen';

export default function Index({ route, navigation }) {
  const auth = useSelector((state) => state.auth);

  // aqui también se podría evaluar state.status.roles
  // y dependiendo si tiene ciertos roles mostrar ciertas pantallas...

  if( auth.isLogged ){
    return <AuthenticatedIndexScreen route={route} navigation={navigation} />
  }




  return <LoginScreen route={route} navigation={navigation} />
}
