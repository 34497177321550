import React, { useEffect, useRef, useState } from "react";
import { View, Image, TouchableOpacity, ScrollView } from "react-native";
import { StatusBar } from "expo-status-bar";

import tw from "../../../styles/tailwind";

import Text from '../../../Components/Text';
import ArbitrationCertificateStep1 from "./Referee Certification/ArbitrationCertificateStep1";
import ArbitrationCertificateStep2, { StatsPerPlayer } from "./Referee Certification/ArbitrationCertificateStep2";
import ArbitrationCertificateStep3 from "./Referee Certification/ArbitrationCertificateStep3";
import TeamBadge from "../../../Components/Teams/TeamBadge";
import { useDispatch, useSelector } from "react-redux";
import api from "../../../utils/api";
import { updateMatch } from "../../../store/Reducers/matches";
import ArbitrationCertificateConfirmation from "./Referee Certification/ArbitrationCertificateConfirmation";


// Componente que permite la selección de los equipos
function TeamSelect({ style, match, selectedTeam, onTeamPressed }) {

  return (
    <View style={tw.style(`flex-row items-center justify-between`, style?.wrapper)}>
      {/* Local */}
      <TouchableOpacity
        style={tw.style(
          `flex-1 flex-col justify-center rounded-lg mb-2 items-center px-4 py-4`,
          selectedTeam == "home" ? `bg-secondary opacity-100` : `opacity-60`
        )}
        onPress={() => onTeamPressed("home")}
      >
        <Text style={tw`text-gray-300 text-sm text-center mb-4`}>Local</Text>

        <TeamBadge team={match.home_team.team} width={60} height={60} />

        <Text style={tw`text-gray text-md text-center mt-4`}>{match.home_team?.name}</Text>
      </TouchableOpacity>


      {/* Visitante */}
      <TouchableOpacity
        style={tw.style(
          `flex-1 flex-col justify-center rounded-lg mb-2 items-center opacity-60 px-4 py-4`,
          selectedTeam == "away" ? `bg-secondary opacity-100` : `opacity-60`,
        )}
        onPress={() => onTeamPressed("away")}
      >
        <Text style={tw`text-gray-300 text-sm text-center mb-4`}>Visitante</Text>

        <TeamBadge team={match.away_team.team} width={60} height={60} />

        <Text style={tw`text-gray text-md text-center mt-4`}>{match.away_team?.name}</Text>
      </TouchableOpacity>
    </View>
  )
}




export type TeamStats = { home: StatsPerPlayer, away: StatsPerPlayer }
type TeamGoals = { home: number, away: number }
export type PlayerAssistance = { home: Array<Player>, away: Array<Player> }


export default function ArbitrationCertificate({ navigation, route }) {
  const [teamsHeader, setTeamsHeader] = useState(true);
  const [step, setStep] = useState(1);
  const [showPreviousTeamButton, setShowPreviousTeamButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  const matchId = route.params?.matchId
  const match: (Match | null) = useSelector(state => state.entities.matches[matchId])
  
  
  const [selectedTeam, setSelectedTeam] = useState<'home' | 'away'>('home');
  const [teamStats, setTeamStats] = useState<TeamStats>({home: {}, away: {}});
  const [teamGoals, setTeamGoals] = useState<TeamGoals>({home: 0, away: 0});
  const [teamsNoShow, setTeamsNoShow] = useState({home: false, away: false});
  const [playerAssistance, setPlayerAssistance] = useState<PlayerAssistance>({home: [], away: []});
  const [matchReview, setMatchReview] = useState();

  const defaultingTeam = teamsNoShow.home ? "home" : teamsNoShow.away ? "away" : null

  const scrollRef = useRef<ScrollView>(null);



  //
  const defaultGoals = match?.league.settings?.default_goals ?? 2
  const defaultPoints = match?.league.settings?.default_points ?? 3

  const countReducer = (prev, cur) => {
    return prev + (cur?.count ?? 0)
  }


  const homeScore = defaultingTeam ? defaultingTeam == "home" ? 0 : defaultGoals : teamGoals.home
  const awayScore = defaultingTeam ? defaultingTeam == "away" ? 0 : defaultGoals : teamGoals.away



  /*
    Cuando es un partido existente cargamos los stats del partido 
  */
  useEffect(() => {
    if(match?.status === "played" && match?.stats){
      const homePlayerStats = Object.assign({}, match.stats.home.player_stats)
      const awayPlayerStats = Object.assign({}, match.stats.away.player_stats)

      setTeamStats({
        home: homePlayerStats,
        away: awayPlayerStats,
      })

      setPlayerAssistance({
        home: match.stats.home.player_assistance || [],
        away: match.stats.away.player_assistance || [],
      })

      const homeTotalGoals = Object.keys(homePlayerStats).map(playerId => homePlayerStats[playerId].goals).reduce((prev, cur) => prev + cur, 0)
      const awayTotalGoals = Object.keys(awayPlayerStats).map(playerId => awayPlayerStats[playerId].goals).reduce((prev, cur) => prev + cur, 0)

      setTeamGoals({
        home: homeTotalGoals,
        away: awayTotalGoals,
      })
    }
  }, []);



  // TODO: Abstraer esto
  useEffect(() => {
    navigation.setOptions({
      headerShown: true,
      headerStyle: {
        backgroundColor: '#303030',
      },
      headerTintColor: '#ebebeb',
    })
  }, []);


  function CheckTeam(alreadyTeamSelected) {
    
    if(alreadyTeamSelected === "home") {
      // Pasa a la asistencia del equipo visitante
      if(step == 1 || step == 2){
        if (step == 2 && defaultingTeam) { // 
          setTeamsHeader(false)
          setStep(step + 1)
          return 
        }

        setSelectedTeam("away")
        setShowPreviousTeamButton(true)
      }
      
      // setStep(1)
    } else if (alreadyTeamSelected === "away"){

      if(step == 1){
        if (! defaultingTeam){
          setTeamsHeader(false)
          setStep(3) // a la pantalla de confirmacion (antes 2)

          if (scrollRef.current) {
            scrollRef.current.scrollTo({
              x: 0,
              y: 0,
              animated: false,
            });
          }

          // setSelectedTeam("home")
        } else {// si hubo algun default manda directo a la pantalla 3
          setTeamsHeader(false)
          setStep(3)
        }
      }

      if(step == 2){
        setTeamsHeader(false)
        setStep(step + 1)
      }
      // if (noShow === false) {
      // }else{
      // }
    }
  }

  function PreviousTeam(alreadyTeamSelected) {
    if (alreadyTeamSelected === "away") {
      setSelectedTeam("home")

      if(step == 1){
        setShowPreviousTeamButton(false)
      }
    }
  }

  const dispatch = useDispatch()

  const submitCertification = (_matchReview) => {
    const params = {
      home: {
        player_stats: teamStats.home,
        player_assistance: playerAssistance.home,
        review: _matchReview.home,
        no_show: teamsNoShow.home,
      },
      away: {
        player_stats: teamStats.away,
        player_assistance: playerAssistance.away,
        review: _matchReview.away,
        no_show: teamsNoShow.away,
      },
    }

    console.log(JSON.stringify(params), match.uuid)

    setIsLoading(true)

    api.post(`/matches/${match.uuid}/stats`, params)
    .then(({data}) => {
      // Creo que esto será mejor mandarlo por el navigator a la pantalla del detalle
      dispatch(updateMatch(data))
      navigation.goBack()
    })
    .catch(e => {
      setIsLoading(false)
    })
  }


  return (
    <ScrollView style={tw` bg-background`} ref={scrollRef}>
      <View style={tw`px-3 py-3`}>
        {/* {
          step == 1 &&
            <Text style={tw`text-gray text-xl mb-5 text-center`}>Asistencia del partido</Text>
        }
        {
          step == 2 &&
            <Text style={tw`text-gray text-xl mb-5 text-center`}>Goles y tarjetas</Text>
        } */}
        {
          step == 3 &&
            <Text style={tw`text-gray text-xl mb-5 text-center`}>Confirma los datos</Text>
        }

        {
          teamsHeader == true &&
            <TeamSelect
              match={match}
              style={{ wrapper: tw`mb-5` }}
              selectedTeam={selectedTeam}
              onTeamPressed={selectedTeam => {
                setSelectedTeam(selectedTeam)

                if(selectedTeam == "home" && step == 1)
                  setShowPreviousTeamButton(false)

                if(selectedTeam == "away" && step == 1)
                  setShowPreviousTeamButton(true)
              }}
            />
        }

        {/* Captura Asistencia */}
        {
          step == 1 &&
            <ArbitrationCertificateStep1
              match={match}
              selectedTeam={selectedTeam}
              value={playerAssistance[selectedTeam]}
              playersStats={teamStats[selectedTeam]}
              defaultingTeam={defaultingTeam}
              onAssistanceChange={(selectedPlayers, noShow) => {
                console.log("assistantce changed", selectedPlayers);
                
                setPlayerAssistance({
                  ...playerAssistance,
                  [selectedTeam]: noShow ? [] : selectedPlayers,
                })

                setTeamsNoShow({
                  ...teamsNoShow,
                  [selectedTeam]: noShow,
                })

                if(noShow){
                  console.log("noshow!")
                  setTeamStats({home: {}, away: {}})
                }
              }}
              goals={selectedTeam == "home" ? homeScore : awayScore}
              onStatsChange={(_teamStats) => {
                const totalGoals = Object.keys(_teamStats).map(playerId => _teamStats[playerId].goals).reduce((prev, cur) => prev + cur, 0)
                console.log("onStatsChange", _teamStats)

                setTeamGoals({
                  ...teamGoals,
                  [selectedTeam]: totalGoals,
                })

                setTeamStats({
                  ...teamStats,
                  [selectedTeam]: _teamStats,
                })
              }}
              onSubmit={(selectedPlayers, noShow) => {
                CheckTeam(selectedTeam)
              }} 
              showPreviousBtn={showPreviousTeamButton}
              onPreviousPressed={() => { PreviousTeam(selectedTeam)}}
            />
        }


        {/* Captura Goles y Tarjetas */}
        {/* {
          step == 2 &&
            <ArbitrationCertificateStep2
              match={match}
              players={playerAssistance[selectedTeam]}
              value={teamStats[selectedTeam]}
              defaultingTeam={defaultingTeam}
              onSubmit={() => {
                CheckTeam(selectedTeam)
              }}
              onChange={(_teamStats) => {
                setTeamStats({
                  ...teamStats,
                  [selectedTeam]: _teamStats,
                })
              }}

              handleBack={() => {
                setStep(step - 1)
              }}
            />
        } */}


        {/*  */}
        {/* {
          step == 3 &&
            <ArbitrationCertificateStep3
              match={match}
              defaultingTeam={defaultingTeam}
              submitDisabled={isLoading}
              onSubmit={(data) => {
                // esto es partde de nuestros toodooooooos
                // navigation.navigate('Admin.MatchDetail', { matchId: match.uuid }) 
                setMatchReview(data)

                submitCertification(data)
              }}

              handleBack={() => {
                setStep(step - 1)
                setTeamsHeader(true)
              }}
            />
        } */}

        {/*  */}
        {
          step == 3 &&
            <ArbitrationCertificateConfirmation
              match={match}
              submitDisabled={isLoading}
              playerAssistance={playerAssistance}
              defaultingTeam={defaultingTeam}
              matchStats={teamStats}
              homeScore={homeScore}
              awayScore={awayScore}
              onSubmit={(data) => {
                // esto es partde de nuestros toodooooooos
                // navigation.navigate('Admin.MatchDetail', { matchId: match.uuid }) 
                setMatchReview(data)

                submitCertification(data)
              }}

              handleBack={() => {
                setStep(1)
                setTeamsHeader(true)
              }}
            />
        }
      </View>
    </ScrollView>
  );
}
