import React, { useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { StatusBar } from "expo-status-bar";
import { TouchableOpacity, View } from "react-native";

import CalendarPicker from "react-native-calendar-picker";

import Text from '../../../../Components/Text';
import BottomSheet from '../../../../Components/BottomSheet';
import Button from '../../../../Components/Button';

import FMIcons from '../../../../Components/FMIcon';
import { Ionicons } from '@expo/vector-icons';

import tw from '../../../../styles/tailwind';



export default function MatchSelectDateBottomSheet({ visible, onRequestClose, onSubmit, match, leagueMatchDays=null }) {
  const [date, setDate] = useState(new Date());
  const [hour, setHour] = useState(date.getHours());
  const [minute, setMinute] = useState(date.getMinutes());
  const [random, setRandom] = useState();

  useEffect(() => {
    const newDate = match?.date ? new Date(match.date) : new Date()
    const isNow = ! match?.date

    if(isNow){
      newDate.setMinutes(0)
      newDate.setSeconds(0)
    }

    setDate(newDate)
    setHour(newDate.getHours())
    setMinute(newDate.getMinutes())
  }, [match?.date]);

  const onDateChange = (newDate) => {
    newDate = new Date(newDate)

    // Copiamos la fecha solamente a nuestro date
    date.setDate(newDate.getDate())
    date.setMonth(newDate.getMonth())
    date.setFullYear(newDate.getFullYear())

    // TOFIX: para que rerenderee y llame a isToday
    setRandom(Math.random())
  }

  const hourTopLimit = () => {
    const newHour = (hour + 1 < 24 ? hour + 1 : 0)

    setHour(newHour)

    date.setHours(newHour)
  }

  const hourBottomLimit = () => {
    const newHour = (hour - 1 > 0 ? hour - 1 : 23)
    
    setHour(newHour)

    date.setHours(newHour)
  }

  const minuteTopLimit = () => {
    const newMinutes = (minute + 5) > 59 ? 0 : minute + 5
    setMinute(newMinutes)
    date.setMinutes(newMinutes)
  }

  const minuteBottomLimit = () => {
    const newMinutes = (minute - 5) > 0 ? minute - 5 : 0
    setMinute(newMinutes)
    date.setMinutes(newMinutes)
  }

  const isToday = (someDate) => {
    const today = new Date()
    return someDate?.getDate() == today.getDate() &&
      someDate?.getMonth() == today.getMonth() &&
      someDate?.getFullYear() == today.getFullYear()
  }

  const customDatesStylesCallback = date => {
    if (leagueMatchDays?.find(e => e == date.isoWeekday())){
      return {
        style: {
          // backgroundColor: '#909',
        },
        containerStyle: {
          backgroundColor: '#ccc',
        },
        textStyle: {
          // color: '#0f0',
          fontWeight: 'bold',
        }
      };
    }
  }

  const customDayHeaderStylesCallback = date => {
    if (leagueMatchDays?.find(e => e == date.dayOfWeek)) {
      return {
        style: {
          backgroundColor: '#ccc',
          paddingVertical: 10,
          marginVertical: -10,
        },
        textStyle: {
          // color: '#0f0',
          // fontWeight: 'bold',
        }
      };
    }
  }


  const isValid = !! date
  const dateIsToday = isToday(date)

  return (
    <BottomSheet
      visible={visible}
      onRequestClose={() => {
        onRequestClose && onRequestClose()
      }}
      height={580}
      style={{ container: tw`px-3 bg-fm-background rounded-tl-xl rounded-tr-xl` }}
    >
      <View style={tw`flex-1 py-5 bg-fm-background`} >
        <View style={tw`flex-col`}>
          <CalendarPicker
            initialDate={date}
            selectedStartDate={date}
            customDatesStyles={customDatesStylesCallback}
            customDayHeaderStyles={customDayHeaderStylesCallback}
            onDateChange={onDateChange}
            todayTextStyle={! dateIsToday ? tw`text-black` : null}
            todayBackgroundColor="#e0e0e0"
            height={340}
            selectedDayColor="#212121"
            selectedDayStyle={tw`h-8 w-8 bg-gray-500 rounded-md`}
            selectedDayTextColor="#efc42e"
            startFromMonday={true}
            weekdays={["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"]}
            months={['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']}
            previousComponent={
              <View style={tw`rounded-xl w-9 h-9 bg-primary items-center justify-center`}>
                <FMIcons name="chev-izq" size={12} color="black" />
              </View>
            }
            nextComponent={
              <View style={tw`rounded-xl w-9 h-9 bg-primary items-center justify-center`}>
                <FMIcons name="chev-der" size={12} color="black" />
              </View>
            }
            selectMonthTitle="Selecciona un mes de "
            selectYearTitle="Selecciona año"
          />


          {/* Selector hora - mover a componente */}
          <View style={tw`flex-row items-center justify-center mt-4 mb-8`}>
            <View style={tw`justify-between row`}>
              {/* Selector horas */}
              <View style={tw`flex-col items-center bg-gray-50 rounded-xl`}>
                <TouchableOpacity style={tw`flex-row items-center mb-4 pt-2 px-10`} onPress={() => hourTopLimit()}>
                  <Ionicons name="chevron-up-outline" size={24} color="black" />
                </TouchableOpacity>

                <Text style={tw`text-gray-500 text-lg font-bold px-4 `}>{hour}h</Text>

                <TouchableOpacity style={tw`flex-row items-center mt-4 pb-2 px-10`} onPress={() => hourBottomLimit()}>
                  <Ionicons name="chevron-down-outline" size={24} color="black" />
                </TouchableOpacity>
              </View>

              <Text style={tw`text-xl font-bold px-2  text-black`}>:</Text>

              {/* Selector minutso */}
              <View style={tw`flex-col items-center bg-gray-50 rounded-xl`}>
                <TouchableOpacity style={tw`flex-row items-center mb-4 pt-2 px-10`} onPress={() => minuteTopLimit()}>
                  <Ionicons name="chevron-up-outline" size={24} color="black" />
                </TouchableOpacity>

                <Text style={tw`text-gray-500 text-lg font-bold px-4 `}>{minute}m</Text>

                <TouchableOpacity style={tw`flex-row items-center mt-4 pb-2 px-10`} onPress={() => minuteBottomLimit()}>
                  <Ionicons name="chevron-down-outline" size={24} color="black" />
                </TouchableOpacity>
              </View>
            </View>
          </View>

          
          <Button
            label={"Guardar"}
            disabled={! isValid}
            onPress={() => onSubmit && onSubmit(date)}
          />
        </View>
      </View>
    </BottomSheet>
  );
}
