import React, { useEffect, useState, useRef } from "react";
import { View, TouchableOpacity } from "react-native";

import tw from "../../../../styles/tailwind";

import Text from '../../../../Components/Text';


import { Ionicons } from "@expo/vector-icons"


// https://itnext.io/how-to-work-with-intervals-in-react-hooks-f29892d650f2


export default function TimerButton({ 
  reverse = false,
  matchState, currentPeriod, periodDuration = null, elapsedTime = 0,
  onPress,
}) {
  const [isBlinking, setIsBlinking] = useState(false);

  // El parpadeador
  useEffect(() => {
    let interval

    if (matchState?.running_period === "paused") {
      interval = setInterval(() => {
        setIsBlinking((isBlinking) => !isBlinking);
      }, 1000);
    } else {
      setIsBlinking(false)
    }

    return () => clearInterval(interval);
  }, [matchState?.running_period]);

  

  const totalSeconds = (! reverse || periodDuration === null )? elapsedTime : periodDuration - elapsedTime
  const limitMinutes = Math.floor(totalSeconds / 60)
  const limitSeconds = totalSeconds % 60
  const limitStr = `${limitMinutes > 9 ? limitMinutes : `0${limitMinutes}`}:${limitSeconds > 9 ? limitSeconds : `0${limitSeconds}`}`

  const color = matchState?.running_period !== "running" ? tw.color('green') : tw.color('primary')

  return (
    <View style={tw`justify-center items-center row`}>


      <TouchableOpacity
        style={
          tw.style(`bg-[${color}] rounded-lg px-5 pb-2`, isBlinking && `bg-opacity-50`, )
        }
        onPress={onPress}
      >
        <View style={tw`row`}>
          {
            (matchState !== "match_finished") ? 
              <Text style={tw.style(`text-white text-xl mr-4 -mt-1`, { fontSize: 50 })}>{limitStr}</Text>
            :
              <Text style={tw.style(`text-white text-xl mr-4 -mt-1`, { fontSize: 30 })}>Terminar Partido</Text>
          }

          {
            matchState?.running_period !== "running" ?
              <Ionicons name="play" size={40} color={"white"} />
              :
              <Ionicons name="pause" size={40} color={"white"} />
          }
        </View>

        {
          (matchState === "not_started") ?
            <Text style={tw`font-bold text-white text-center text-lg -mt-1`}>Iniciar Partido</Text>
          :
            (matchState === "match_finished") ?
              <Text style={tw`font-bold text-white text-center text-lg -mt-1`}>0:00</Text>
            :
            <Text style={tw`font-bold text-white text-center text-lg -mt-1`}>{`${matchState?.running_period === "not_started" ? "Iniciar " : ""}${currentPeriod}º tiempo`}</Text>
        }
        
        {/* {
          <Text style={tw`font-bold text-white text-center text-lg -mt-1`}>{JSON.stringify(matchState)}</Text>
        } */}
      </TouchableOpacity>
      
    </View>
  )
}

{/* Botón menos */}
{/* <TouchableOpacity style={tw`row bg-green-300 rounded-full px-3 py-3 mx-3`}>
        <Ionicons name="remove" size={30} color={"white"} />
      </TouchableOpacity> */}
{/* Botón más */ }
{/* <TouchableOpacity style={tw`row bg-green-300 rounded-full px-3 py-3 mx-3`}>
        <Ionicons name="add" size={30} color={"white"} />
      </TouchableOpacity> */}