import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity } from "react-native"

import { useSelector } from 'react-redux';

export default function useFMLeagueEndsAt(short=false) {
  const [random, setRandom] = useState(0);
  const fm_league = useSelector(state => state.status.fm_league)

  const endsAt = new Date(fm_league?.ends_at)
  // const endsAt = new Date("2022-10-28T08:00:00.000000Z")
  const now = new Date
  const diffInSecs = (endsAt - now) / 1000 

  const daysSecs = 24 * 60 * 60
  const days = Math.floor(diffInSecs / daysSecs)
  const hoursLeft = ((diffInSecs / daysSecs) - days) * 24
  const hours = Math.floor(hoursLeft)
  const minutes = Math.floor((hoursLeft - hours) * 60)

  useEffect(() => {
    let interval = setInterval(() => {
      setRandom(Math.random())
    }, 30000)

    return () => {
      clearInterval(interval)
    }
  }, []);

  return {
    timeStr: days > 0 ? `${days}${short ? 'd' : ' días'} ${hours} hrs` : `${hours} hrs ${minutes} mins`,
    timeSeconds: diffInSecs,
  }
}