import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { View } from 'react-native';
import CalendarPicker from 'react-native-calendar-picker';

import Select from '../../../../Components/Select';
import Text from '../../../../Components/Text';
import Button from '../../../../Components/Button';

import moment from 'moment'
moment.locale('es')

import tw from '../../../../styles/tailwind';
import FMIcons from '../../../../Components/FMIcon';

export default function NewLeague_Step3({ onSubmit, handleBack }) {
  const { control, handleSubmit, formState: { errors }, setError, setValue, getValues, register } = useForm();

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const values = getValues(["date_start", "date_end"])


  useEffect(() => {
    // Registro de nombres de parametros en el form
    register("date_start")
    register("date_end")
  }, [])


  useEffect(() => {
    if (values) {
      setStartDate(values[0])
      setEndDate(values[1])
    }
  }, [])


  const onDateChange = (date, type) => {
    // date es una instancia de moment
    const formattedDate = date?.format("YYYY-MM-DD")

    if (type == "END_DATE") {
      setEndDate(date)
      setValue("date_end", formattedDate, { shouldValidate: true })
      console.log(date);


    } else {
      setStartDate(date)
      setValue("date_start", formattedDate, { shouldValidate: true })
    }
  }


  const submitStep3 = data => {
    onSubmit(data)
  }

  const returnStep = () => {
    handleBack()
  }

  const birthdayDays = [
    { id: "1", label: "1" },
    { id: "2", label: "2" },
    { id: "3", label: "3" },
    { id: "4+", label: "..." },
  ]

  return (
    <View style={tw`mb-8 px-3`}>
      <View style={tw`mb-5`}>
        <CalendarPicker
          onDateChange={onDateChange}
          allowRangeSelection={true}
          todayBackgroundColor="#e0e0e0"
          todayTextStyle={tw`text-gray-300`}
          selectedDayColor="#e0e0e0"
          selectedDayTextColor="#706967"
          selectedRangeStartTextStyle={tw`text-secondary`}
          selectedRangeEndTextStyle={tw`text-primary`}
          startFromMonday={true}
          weekdays={["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"]}
          months={['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']}
          previousComponent={
            <View style={tw`rounded-xl w-10 h-10 bg-primary items-center justify-center ml-2`}>
              <FMIcons name="chev-izq" size={12} color="black" />
            </View>
          }
          nextComponent={
            <View style={tw`rounded-xl w-10 h-10 bg-primary items-center justify-center mr-2`}>
              <FMIcons name="chev-der" size={12} color="black" />
            </View>
          }
          selectMonthTitle="Selecciona un mes de "
          selectYearTitle="Selecciona año"
          selectedRangeStartStyle={tw.style(`bg-primary h-9`, { borderTopLeftRadius: 4, borderBottomLeftRadius: 4 })}
          selectedRangeEndStyle={tw.style(`bg-gray-500 h-9`, { borderTopRightRadius: 4, borderBottomRightRadius: 4 })}
          selectedRangeStyle={tw`h-9`}
        />
      </View>

      <View style={tw`flex-col mb-8`}>
        <View style={tw`flex-1`}><Text style={tw`ml-5`}>Fecha límite Altas/Bajas</Text></View>
        <View style={tw`flex-row justify-between`}>

          <Select
            name="birth_day"
            items={birthdayDays}
            placeholder={{ label: "Día", value: "" }}
            control={control}
            style={{ container: tw.style(``, { flexShrink: 1, width: '29%' }) }}
          />

          <Select
            name="birth_month"
            items={birthdayDays}
            placeholder={{ label: "Mes", value: "" }}
            control={control}
            style={{ container: tw.style(``, { flexShrink: 1, width: '29%' }) }}
          />

          <Select
            name="birth_year"
            items={birthdayDays}
            placeholder={{ label: "Año", value: "" }}
            control={control}
            style={{ container: tw.style(``, { flexShrink: 1, width: '29%' }) }}
          />


        </View>
      </View>


      <View style={tw`flex-1 bg-gray-50 py-8 px-8 rounded-xl mb-8`}>
        <View style={tw`flex-1 justify-between`}>
          <Text style={tw`mb-5`}><Text style={tw`font-bold`}>Inicio:</Text> {startDate ? startDate.format("dddd D [de] MMMM") : ''}</Text>
          {/* <Text style={tw`mb-5`}><Text style={tw`font-bold`}>Fecha límite altas/bajas:</Text> {startDate ? startDate.format("dddd D [de] MMMM") : 'Sin límite de fecha'}</Text> */}
          <Text><Text style={tw`font-bold`}>Fin:</Text> {endDate ? endDate.format("dddd D [de] MMMM") : 'Sin fecha definida'}</Text>
        </View>
      </View>

      <View style={tw`flex-row justify-around`}>

        <Button
          label={"Anterior"}
          onPress={() => { returnStep() }}
          style={{ container: tw`bg-secondary px-8 py-3`, label: tw`text-white` }}
        />

        <Button
          label={"Crear Torneo"}
          onPress={(handleSubmit(submitStep3))}
          style={{ container: tw`px-8  py-3` }}
        />

      </View>
    </View>
  )
}