import React, { useState } from "react";
import { View } from "react-native";
import tw from "../styles/tailwind";

import Text from "./Text";


export default function StepIndicator({ stepsCount = 5, currentStep, style }) {
  const [selectedStep, setSelectedStep] = useState(currentStep)

  const steps = []

  for (var i = 1; i <= stepsCount; i++) {
    steps.push(i)
  }


  return (
    <View style={tw.style(`flex-row flex-1 items-center`, style?.container)}>
      {
        steps.map(i =>
          <>
            <View
              key={i}
              style={tw.style(
                `h-6 w-6 flex items-center justify-center bg-gray-200`,
                { borderRadius: 100 },
                i == currentStep && `bg-black`,
                i < currentStep && `bg-gray-300`,
                style?.step,
              )}
            >
              <Text style={tw.style(
                `text-gray-300`,
                style?.label,
                i <= currentStep && `text-primary`,
              )}>{i}</Text>
            </View>

            {
              i < stepsCount &&
              <View style={tw.style({ flex: 1, height: 2, backgroundColor: 'black' }, style?.line)} />
            }
          </>
        )

      }

    </View>
  )
}
