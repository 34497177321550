import api from "../../utils/api";
import { offlineActionCreators } from 'react-native-offline';

const types = {
  MATCH_UPDATED: 'MATCH_UPDATED',
};


export function loadMatch(matchId) {
  return (dispatch, getState) => {
    api.get(`/matches/${matchId}`)
      .then(({ data }) => {
        dispatch(updateMatch(data))
      })
      .catch(error => { })
  }
}


export function updateMatch(match: Match){
  return (dispatch, getState) => {
    dispatch({
      entities: {
        matches: {
          [match.uuid]: match,
        }
      },
      type: "UPDATE_ENTITIES",
    })
  }
}


export const saveMatchEvent = (type, matchId, time, data=null) => {
  function thunk(dispatch) {
    // fetch(url)
    //   .then(response => response.json())
    //   .then(responseJson => {
    //     dispatch({ type: FETCH_USER_SUCCESS, payload: responseJson });
    //   })
    //   .catch(error => {
    //     console.error(error);
    //   });
    const event = {
      type,
      match_id: matchId,
      time: time,
      data,
    }
    console.log("[OFFLINE] saveMatchEvent", event);

    api.post(`/matches/${matchId}/events`, event)
    .then(({ data }) => {
      // console.log("Event created - ", JSON.stringify(data));
      // setEvents([...events, data])

      // dispatch(loadMatch(matchId))
      console.log("[OFFLINE] event created ");
    })
    .catch((error) => {
      const { response } = error

      console.log("[OFFLINE] saveMatchEvent error ", response?.data);

      if (response?.status >= 500){
        dispatch(offlineActionCreators.fetchOfflineMode(thunk));
      }
    })
  }

  thunk.interceptInOffline = true; // This is the important part

  thunk.meta = {
    retry: true,
    // retry?: boolean, // By passing true, your thunk will be enqueued on offline mode
    // dismiss?: Array<string> // Array of actions which, once dispatched, will trigger a dismissal from the queue

    name: 'saveMatchEvent',// +(new Date()).toString(),
    args: [type, matchId, time, data],
    removeSimilar: false, // < esto lo agregué en offlineComparisonFn
  }
  return thunk; // Return it afterwards
};


const initialState = {
  
};



export const reducer = (state = initialState, action) => {
  const {type, user} = action;

  switch (type) {
    // case types.MATCH_UPDATED:
    //   return Object.assign({}, state, {
    //     roles: action.status.roles,
    //     stadiums: action.status.stadiums,
    //     player: action.status.player,
    //   });

    default:
      return state;
  }
};
  