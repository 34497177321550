export default [
    {
        questionId: "cantidad_veces",
        title: "¿Cuántas veces juegas futbol a la semana?",
        answers: [
            { id: "1", label: "1 vez" },
            { id: "2", label: "2 veces" },
            { id: "3", label: "3 veces" },
            { id: "3+", label: "Más de 3 veces" },
        ],
        multiple: false,
        show: () => true,
    },
    {
        questionId: "tipo_futbol",
        title: "¿Qué tipo de fútbol juegas?",
        answers: [
            { id: "fut_7", label: "Fútbol 7" },
            { id: "fut_rapido", label: "Fútbol rápido" },
            { id: "fut_5", label: "Fútbol 5" },
            { id: "fut_soccer", label: "Fútbol 11" },
            { id: "otro", label: "Otro" },
        ],
        multiple: true,
        show: answers => !!answers.find(q => q.question_id == "cantidad_veces"),
    },
    {
        questionId: "juega_en_liga",
        title: "¿Juegas en alguna liga/torneo?",
        answers: [
            { id: "si", label: "Sí" },
            { id: "no", label: "No" },
        ],
        show: answers => !!answers.find(q => q.question_id == "tipo_futbol")//?.answer.find(a => ["fut_7", "fut_rapido", "fut_soccer"].find(e => e == a.id)),
    },
    {
        questionId: "como_se_entera",
        title: "¿Cómo te enteras cuándo es tu siguiente partido?",
        answers: [
            { id: "whatsapp", label: "Whatsapp" },
            { id: "telefono", label: "Por teléfono" },
            { id: "telegram", label: "Telegram" },
        ],
        showInRows: true,
        showOtherInput: true,
        multiple: true,
        show: answers => !!answers.find(q => q.question_id == "juega_en_liga")?.answer.find(a => a.id == "si"),
    },
    {
        questionId: "cancha",
        title: "¿Cómo se llama la cancha donde juegas?",
        answers: [ ],
        showInRows: true,
        showOtherInput: true,
        multiple: true,
        show: answers => !!answers.find(q => q.question_id == "como_se_entera"),
    },
    {
        questionId: "intereses",
        title: "¿Qué características te gustaría tener en una app de ligas amateur?",
        answers: [
            { id: "admin_cancha", label: "Notificaciones y calendario de partidos" },
            { id: "logros", label: "Desbloquear logros" },
            { id: "estadisticas_mias", label: "Ver mis estadísticas" },
            { id: "estadisticas_equipo", label: "Estadísticas de mi equipo" },
            { id: "repeticiones", label: "Ver las repeticiones de mis jugadas" },
            { id: "patrocinios", label: "Conseguir patrocinios" },
        ],
        showInRows: true,
        showOtherInput: true,
        multiple: true,
        show: answers => !!answers.find(q => q.question_id == "cancha"),
    },
    {
        questionId: "organiza_equipo",
        title: "¿Organizas algún equipo?",
        answers: [
            { id: "si", label: "Sí" },
            { id: "no", label: "No" },
        ],
        show: answers => !!answers.find(q => q.question_id == "intereses"),
    },
    {
        questionId: "principales_problemas",
        title: "¿En qué tienes más problemas al organizar tu equipo?",
        answers: [
            { id: "conseguir_jugadores", label: "Conseguir jugadores" },
            { id: "no_calendario", label: "No recibir el calendario de juegos" },
            { id: "mala_administracion", label: "Mala administración del lugar" },
            { id: "estadisticas", label: "No contar con estadísticas específicas y actualizadas" },
        ],
        showInRows: true,
        showOtherInput: true,
        multiple: true,
        show: answers => !!answers.find(q => q.question_id == "organiza_equipo")?.answer.find(a => a.id == "si"),
    },
    {
        questionId: "sistema_notificacion",
        title: "¿Cómo notificas a tus compañeros de un próximo partido?",
        answers: [
            // { id: "whatsapp", label: "Whatsapp" },
            // { id: "telefono", label: "Por teléfono" },
            // { id: "telegram", label: "Telegram" },
        ],
        showInRows: true,
        showOtherInput: true,
        multiple: true,
        show: answers => !!answers.find(q => q.question_id == "principales_problemas"),
    },
    {
        questionId: "que_afecta_mas",
        title: "Cuándo entras en un torneo ¿Qué te afecta más?",
        answers: [
            { id: "admin_cancha", label: "Que las canchas no tengan buena administración" },
            { id: "notificacion_estadisticas", label: "No ser notificado en tiempo y forma de los movimientos y estadísticas" },
            { id: "inscripcion_anticuada", label: "Inscripción anticuada y poco efectiva" },
        ],
        showInRows: true,
        showOtherInput: true,
        multiple: true,
        show: answers => !!answers.find(q => q.question_id == "sistema_notificacion"),
    },
    {
        questionId: "juega_fifa",
        title: "¿Juegas algún videojuego como FIFA/PES?",
        answers: [
            { id: "si", label: "Sí" },
            { id: "no", label: "No" },
        ],
        show: answers => !!answers.find(q => (
            q.question_id == "que_afecta_mas"
            || 
            answers.find(q => q.question_id == "juega_en_liga")?.answer.find(a => a.id == "no")
            || 
            answers.find(q => q.question_id == "organiza_equipo")?.answer.find(a => a.id == "no")
        )),
    },
]