import React, { useState, useRef, useEffect } from 'react';

import { View } from 'react-native';

import NewTeamForm from './NewTeamForm';
import ScanTeamQR from './ScanTeamQR';

import Modal from '../../../Components/Modal'
import OptionsSelector from '../../../Components/OptionsSelector';
import Text from '../../../Components/Text';
import Button from '../../../Components/Button';
import BottomSheet from '../../../Components/BottomSheet';

import tw from '../../../styles/tailwind';
import AssociateTeamBottomSheet from './AssociateTeamBottomSheet';






export default function AddTeamBottomSheet({ visible, onRequestClose, leagueId, onTeamAssociated, onTeamCreated }) {
  const [selectedScreen, setSelectedScreen] = useState();

  return (
    <BottomSheet
      visible={visible}
      onRequestClose={() => {
        setSelectedScreen(0)
        onRequestClose && onRequestClose()
      }}
      height={250}
      style={{ container: tw`bg-gray rounded-tl-xl rounded-tr-xl` }}
    >
      <Text style={tw.style(`mb-4 mt-3 font-black text-xl text-center`)}>Agregar Equipo</Text>

      {
        !selectedScreen &&
        <>
          <Button style={{ container: tw`flex-row items-center mb-3`, label: tw`ml-2` }} label={"Crear nuevo equipo"} icon={'equipo-boton'} onPress={() => setSelectedScreen(1)} />
          <Button style={{ container: tw`flex-row items-center mb-3`, label: tw`ml-2` }} label={"Escanear código de equipo"} icon={'qr'} onPress={() => setSelectedScreen(2)} />
        </>
      }

      {/* Nuevo equipo */}
      {
        selectedScreen == 1 &&
        <NewTeamForm
          leagueId={leagueId}
          onTeamCreated={onTeamCreated}
        />
      }

      {/* Escaneo de QR */}
      {
        selectedScreen == 2 &&
          <AssociateTeamBottomSheet
            visible={true}
            leagueId={leagueId}
            onTeamAssociated={onTeamAssociated}
            onRequestClose={() => setSelectedScreen(null)}
          />
      }
    </BottomSheet >
  );
}
