import { useEffect, useRef, useState } from "react";
import * as ScreenOrientation from 'expo-screen-orientation';


export default function useLandscapeOrientation(){
  const currentOrientationRef = useRef(ScreenOrientation.OrientationLock.DEFAULT);

  async function changeToLandscape() {
    // console.log("changeToLandscape")
    await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE_RIGHT);
  }
  async function changeToDefault() {
    // console.log("changeToDefault", currentOrientationRef.current)

    await ScreenOrientation.lockAsync(currentOrientationRef.current);
  }

  async function getOrientationLockAsync() {
    const currentLock = await ScreenOrientation.getOrientationLockAsync()

    currentOrientationRef.current = currentLock

    // console.log("getOrientationLockAsync", currentLock);
  }

  useEffect(() => {
    getOrientationLockAsync()
    changeToLandscape()

    return () => {
      changeToDefault()
    }
  }, []);
}