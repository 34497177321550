import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity } from "react-native"


import tw from '../../styles/tailwind';
import Text from '../../Components/Text';
import { useSelector } from 'react-redux';
import AchievementUnlockedModal from './AchievementModal';
import TeamMatchSummary from './TeamMatchSummary';
import PersonalMatchSummary from './PersonalMatchSummary';
import FMLeagueSummary from './FMLeagueSummary';
import api from '../../utils/api';


function GamifyMatchActions({matchNotifications, onSeen}){
  const [shownScreen, setShownScreen] = useState(null);
  const player = useSelector(state => state.status.player)

  useEffect(() => {
    if (matchNotifications?.team?.actions){
      setShownScreen("team")
    }
  }, [matchNotifications]);


  function onClose(){

    if (shownScreen == "team" && matchNotifications.personal?.actions){
      setShownScreen("personal")
      return
    }

    if (shownScreen == "personal" && matchNotifications.fm){
      setShownScreen("fm_league")
      return
    }

    setShownScreen(null)
    onSeen?.()
  }

  if ( shownScreen == "team")
    return <TeamMatchSummary
        match={matchNotifications.match}
        matchResult={matchNotifications.match_result}
        leagueTeam={matchNotifications.team.team}
        actions={matchNotifications.team.actions}
        prevXP={matchNotifications.team.previous_xp}
        newXP={matchNotifications.team.new_xp}
        onClose={onClose}
      />

  if (shownScreen == "personal")
    return <PersonalMatchSummary
        onClose={onClose}
        matchResult={matchNotifications.match_result}
        actions={matchNotifications.personal.actions}
        prevXP={matchNotifications.personal.previous_xp}
        newXP={matchNotifications.personal.new_xp}
        player={player}
      />

  if (shownScreen == "fm_league")
    return <FMLeagueSummary
        onClose={onClose} 
        leagueTeam={matchNotifications.team.team}
        data={matchNotifications.fm}
      />

  return null
}


export default function GamifyNotificationsScreen({ }) {
  const status = useSelector((state) => state.status);

  const { gamify_notifications } = status
  const [notifications, setNotifications] = useState();

  const [currentMatchIndex, setCurrentMatchIndex] = useState(null);
  const [currentTeamAchievementIndex, setCurrentTeamAchievementIndex] = useState(null);
  const [currentPlayerAchievementIndex, setCurrentPlayerAchievementIndex] = useState(null);

  const teamAchievements = notifications?.achievements.team
  const playerAchievements = notifications?.achievements.personal

  useEffect(() => {
    setNotifications(gamify_notifications)

    setCurrentMatchIndex(null)
    setCurrentTeamAchievementIndex(null)
    setCurrentPlayerAchievementIndex(null)

    if(gamify_notifications?.matches_actions?.length > 0){
      setCurrentMatchIndex(0)
    } else if(gamify_notifications?.achievements?.team?.length > 0){
      setCurrentTeamAchievementIndex(0)
    } else if (gamify_notifications?.achievements?.team?.length > 0) {
      setCurrentPlayerAchievementIndex(0)
    }
  }, [gamify_notifications]);

  function onMatchSeen(){
    markNotificationSeen({ match_id: notifications?.matches_actions[currentMatchIndex].match.uuid })

    //
    if (currentMatchIndex + 1 < notifications?.matches_actions?.length){
      setCurrentMatchIndex(currentMatchIndex + 1)
    }else{
      setCurrentMatchIndex(null)

      // pasar a achievements de team / player
      if (teamAchievements?.length > 0){
        setCurrentTeamAchievementIndex(0)
      } else if (playerAchievements?.length > 0) {
        setCurrentPlayerAchievementIndex(0)
      }
    }
  }

  function onTeamAchievementSeen(){
    // console.log("achi", teamAchievements[currentTeamAchievementIndex].notification_id);
    markNotificationSeen({ notification_id: teamAchievements[currentTeamAchievementIndex].notification_id })

    // API -
    if (currentTeamAchievementIndex + 1 < teamAchievements?.length) {
      setCurrentTeamAchievementIndex(currentTeamAchievementIndex + 1)
    } else {
      setCurrentTeamAchievementIndex(null)

      // pasar a achievements de jugador
      if (playerAchievements?.length > 0) {
        setCurrentPlayerAchievementIndex(0)
      }
    }
  }

  function onPlayerAchievementSeen(){
    // API - 
    // console.log(playerAchievements[currentPlayerAchievementIndex].notification_id);
    markNotificationSeen({ notification_id: playerAchievements[currentPlayerAchievementIndex].notification_id })

    if (currentPlayerAchievementIndex + 1 < playerAchievements?.length) {
      setCurrentPlayerAchievementIndex(currentPlayerAchievementIndex + 1)
    } else {
      setCurrentPlayerAchievementIndex(null)
    }
  }

  function markNotificationSeen(data){
    api.post(`/gamification/notifications/seen`, data)
    .then(() => {
    })
    .catch(({response}) => {
    })
  }

  // return null
  return (
    <>
      {/* <Text style={tw`text-white`}>{JSON.stringify(notifications?.achievements)}</Text> */}
      
      {
        currentMatchIndex !== null &&
          <GamifyMatchActions
            matchNotifications={notifications.matches_actions[currentMatchIndex]}
            onSeen={onMatchSeen}
          />
      }

      {
        currentTeamAchievementIndex !== null &&
          <AchievementUnlockedModal
            achievement={teamAchievements[currentTeamAchievementIndex].achievement}
            leagueTeam={teamAchievements[currentTeamAchievementIndex].team}
            onClose={onTeamAchievementSeen}
          />
      }

      {
        currentPlayerAchievementIndex !== null &&
          <AchievementUnlockedModal
            achievement={playerAchievements[currentPlayerAchievementIndex].achievement}
            onClose={onPlayerAchievementSeen}
          />
      }
    </>
  )
}

