import React, { useEffect, useState, useRef } from "react";
import { TouchableOpacity } from "react-native";

import tw from "../../../../styles/tailwind";

import Text from '../../../../Components/Text';
import FMIcons from "../../../../Components/FMIcon";


function PlayerButton({ player, style, onPress, right = false, textColor, numberColor, bottomComponent, onLongPress }, { player: Player }) {
  return (
    <TouchableOpacity
      onPress={onPress}
      onLongPress={onLongPress}
      style={tw.style(`bg-[#222] h-28 w-30 rounded-lg px-2 pt-3`, style)}
      >
      <Text style={tw.style(`text-white text-xl text-center h-9 leading-[50px]`, { fontSize: 55 }, numberColor && `text-[${numberColor}]`)}>{player.squad_number}</Text>
      <Text style={tw.style(`text-gray-200 text-lg text-center`, right ? `text-right` : `text-left`, textColor && `text-[${textColor}]`)}>{player.name}</Text>

      {
        player.pivot?.roles.indexOf('representative') !== -1 && 
          <FMIcons name="capitan" style={tw.style(`absolute top-2 right-2 text-gray-300`, textColor && `text-[${textColor}]`)} size={15} />
      }

      {
        bottomComponent
      }
    </TouchableOpacity>
  )
}

export default React.memo(PlayerButton)