import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, ScrollView } from "react-native"
import FMIcons from '../../../../Components/FMIcon';
import Text from '../../../../Components/Text';


import tw from '../../../../styles/tailwind';
import PlayerButton from './PlayerButton';

import {Ionicons} from "@expo/vector-icons"


interface NewPlayerEventButtonsProps{
  matchEvents: Array<MatchEvent>,
}

export default function NewPlayerEventButtons({ homeTeam, awayTeam, assistance, matchEvents = [], onPlayerSelected, reverseAssistance }: NewPlayerEventButtonsProps) {
  const homePlayers = homeTeam?.members.filter(player => assistance.home.find(p => p.uuid === player.uuid)) || []
  const awayPlayers = awayTeam?.members.filter(player => assistance.away.find(p => p.uuid === player.uuid)) || []

  function renderPlayerButton(player, team, right=false){
    const goals = matchEvents.filter(ev => ev.type === "goal" && ev.player?.uuid === player.uuid)
    const yellow_cards = matchEvents.filter(ev => ev.type === "yellow_card" && ev.player?.uuid === player.uuid)
    const red_cards = matchEvents.filter(ev => ev.type === "red_card" && ev.player?.uuid === player.uuid)
    const fouls = matchEvents.filter(ev => ev.type === "foul" && ev.player?.uuid === player.uuid)

    return (
      <PlayerButton
        onPress={() => onPlayerSelected(player, team)}
        player={player}
        key={player.uuid}
        style={tw`mr-2 mb-3 border border-primary bg-gray-500`}
        right={right}
        bottomComponent={
          <View style={tw`row items-center  flex-wrap`}>
            {goals.map((e, i) => <FMIcons key={i} name='gol' style={tw`mr-1 mb-1`} size={14} color={tw.color('primary')} />)}
            {fouls.map((e, i) => <Ionicons key={i} name='alert-circle-outline' style={tw`mr-1 mb-1`} size={16} color={'white'} />)}
            {yellow_cards.map((e, i) => <FMIcons key={i} name='tarjeta' style={tw`mr-1 mb-1`} size={14} color={'yellow'} />)}
            {red_cards.map((e, i) => <FMIcons key={i} name='tarjeta' style={tw`mr-1 mb-1`} size={14} color={'red'} />)}
          </View>
        }
      />
    )
  }

  return (
    <View style={tw.style(`row flex-1 px-6 items-start`, reverseAssistance && {flexDirection : 'row-reverse'})}>
      <ScrollView style={tw`flex-1`} contentContainerStyle={tw.style(`row flex-wrap`, reverseAssistance && `justify-end`)}>
        {
          homePlayers
            .sort((a, b) => parseInt(a.squad_number) > parseInt(b.squad_number))
            .map(player => renderPlayerButton(player, homeTeam))
        }
      </ScrollView>


      <ScrollView style={tw`flex-1`} contentContainerStyle={tw.style(`row flex-wrap`, ! reverseAssistance && `justify-end`)}>
        {
          awayPlayers
            .sort((a, b) => parseInt(a.squad_number) > parseInt(b.squad_number))
            .map(player => renderPlayerButton(player, awayTeam, true))
            
        }
      </ScrollView>
    </View>
  )
}

