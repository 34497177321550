import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, Image } from "react-native"


import tw from '../../styles/tailwind';
import Text from '../../Components/Text';
import { LinearGradient } from 'expo-linear-gradient';

import Degrat from './assets/team_xp_bar_bg.svg'
import FMIcons from '../FMIcon';


export function TeamLevel({ size=50, style=null, level }){
  const margin = Math.floor(size / 5)

  return (
    <Image
      source={{ uri: `https://cdn.futmaster.app/gamification/levels/teams/level-${level}.png`}}
      style={tw.style(`h-[${size}px] w-[${size}px] -mx-[${size / 5}px] -mb-[${size / 5}px] -mt-[${size / 6}]`, style)}
    />
  )
}

import Animated, {
  withTiming,
  useAnimatedStyle,
  useSharedValue,
  Extrapolation,
  interpolate
} from 'react-native-reanimated';
import { CountUp } from 'use-count-up'



export default function TeamXPBar({ current=0, highLimit, style, showLevel, level }) {
  const [initial, setInitial] = useState(current);
  const [_current, set_current] = useState(current);
  const [_highLimit, set_highLimit] = useState(highLimit);
  const [animationDuration, setAnimationDuration] = useState(500);

  const barWidth = useSharedValue(_current/highLimit);

  useEffect(() => {
    if (highLimit == _highLimit){
      barWidth.value = current / highLimit
      set_current(current)
    }

    if(highLimit > _highLimit){
      // manda a 100, espera

      barWidth.value = 100

      set_highLimit(highLimit)
      set_current(current)

      setTimeout(() => {
        // manda a 0 (imediado)
        setAnimationDuration(0)
        barWidth.value = 0
        
        // manda a current
        setTimeout(() => {
          setAnimationDuration(500)
          barWidth.value = current / highLimit
        }, 300)
      }, 800)
    }
  }, [current, highLimit]);


  const barWidthAnimatedStyle = useAnimatedStyle(() => {
    const interpolatedWidth = interpolate(
      barWidth.value,
      [0, 1],
      [0, 100],
      { extrapolateRight: Extrapolation.CLAMP }
    );

    return {
      width: withTiming(`${interpolatedWidth}%`, {
        duration: animationDuration,
        useNativeDriver: false,
      }),
    };
  });

  return (
    <View style={tw.style(`row items-start`, showLevel && {flexDirection: "row-reverse"}, style?.container)}>     
      <View style={tw.style(`h-6 rounded-lg overflow-hidden flex-1`, showLevel && `mt-[1px] -ml-1`)}>
        {/* <Image source={require('./degradado.png')} style={tw`absolute w-full h-full`} /> */}
        <Degrat style={tw`absolute w-full h-full`} preserveAspectRatio={"none"} />

        <View style={tw.style(`rounded-lg m-[0.7px]`)}>

          <LinearGradient
            colors={['#706967', "#2E2E2E"]}
            start={{ x: 0, y: 0.5 }}
            end={{ x: 1, y: 0.5 }}
            style={tw`absolute right-0 top-0 left-0 bottom-0 rounded-lg`}
          />

          {/* Degradado linea creciente */}
          <Animated.View
            style={[tw`absolute top-0 bottom-0 mt-[0px] mb-[0px] left-0 rounded overflow-hidden`, barWidthAnimatedStyle ]}>
            <LinearGradient
              colors={['#f6eef7', "#dd92dd", "#dd92dd"]}
              locations={[0, 0.25, 1]}
              start={{ x: 0.5, y: 0 }}
              end={{ x: 0.5, y: 1 }}
              style={tw`h-100% w-100%`}
            />
          </Animated.View>

          {/* <View style={tw.style(`absolute top-0 bottom-0 mt-[-1px] mb-[-1px] left-0 rounded-lg bg-primary`, { width: `${percentage}%` })}></View> */}

          {/* <Degrat width={200} height={50}  style={tw`bg-red`}  /> */}

          {/* mt-[-2px] */}
          <View style={tw`justify-center row items-center `}>
            <Text style={tw.style(`text-white font-600`, { fontSize: 17, lineHeight: 23 })}><CountUp isCounting start={initial} end={_current} duration={4} />  </Text>
            <Text style={tw.style(`text-white font-600`, { fontSize: 17, lineHeight: 23 })}>/ {highLimit}</Text>
            <FMIcons name={"xp-b"} size={14} color={tw.color('primary')}  style={tw`mt-[1px] ml-2`}/>
          </View>
        </View>
      </View>

      {
        showLevel &&
          <TeamLevel style={tw``} size={49} level={level} />
      }
    </View>
  )
}