import React, { useState, useEffect } from 'react';
import { View, Image } from "react-native"


import tw from '../../styles/tailwind';
import Text from '../../Components/Text';
import LottieView from 'lottie-react-native';
import GamifyBaseModal from '../../Components/Gamification/GamifyBaseModal';

import TeamBadge from '../../Components/Teams/TeamBadge';
import FMIcon from '../../Components/FMIcon';

import BG from './TFM_bg.svg'
import { CountUp } from 'use-count-up'


export default function FMLeagueSummary({ onClose, leagueTeam, data }:{leagueTeam:LeagueTeam}){
  const renderAction = (actionName, amount, totalXP) => {
    return (
      <View style={tw`row justify-between my-2`} key={actionName}>
        <Text style={tw`text-white`}>{actionName}{amount > 1 ? ` (${amount})` : ''}</Text>

        <View style={tw`row`}>
          <FMIcon name="masterpoints" size={18} color={"#F4E0A4"} />

          <Text style={tw`text-primary ml-2`}>+{totalXP}</Text>
        </View>
      </View>
    )
  }

  return (
    <GamifyBaseModal
      onClose={onClose}
      bg={
        <BG style={tw`absolute left-0 top-0 right-0 w-100% h-100% bg-red`} preserveAspectRatio={"none"} />
      }
    >

      <View style={tw`flex-1 justify-around`}>
        <Text style={tw`text-center text-white text-xl font-800 mb-3`}>Recompensas de la Temporada FutMaster</Text>

        <View>
          {/* Equipo */}
          <View style={tw`items-center mb-5`}>
            <TeamBadge width={90} team={leagueTeam.team} />

            <Text style={tw`text-white font-bold mb-3 text-lg`}>{leagueTeam.name}</Text>
          </View>
          

          {/* Acciones */}
          <View style={tw``}>
            <View style={tw`bg-black/40 rounded-lg -mx-6 px-5 py-3`}>
              {
                data.team.actions.map(action => renderAction(action.action.name, action.amount, action.xp_total) )
              }
            </View>
          </View>
        </View>


        <View style={tw`row justify-around mt-6`}>
          {/* Trofeos */}
          <View style={tw`items-center`}>
            <Text style={tw`text-primary text-center`}>Trofeos</Text>

            <View style={tw`row`}>
              <FMIcon name="masterpoints" size={28} color={"#F4E0A4"} />

              <Text style={tw`text-white text-xl font-bold ml-2`}>
                <CountUp isCounting start={data.team.previous_xp} end={data.team.new_xp} duration={3} />
              </Text>
            </View>
          </View>
        </View>
      </View>
    </GamifyBaseModal>
  )  
}


