import React, { useEffect, useState, useRef } from "react";
import { View, Image, TouchableOpacity, ScrollView, Pressable, Animated } from "react-native";

import tw from "../../../../styles/tailwind";
import Text from '../../../../Components/Text';
import FMIcons from "../../../../Components/FMIcon";


export function SuspensionReasonSelector({selected, setSelected}){ 
  const reasons = [
    { key: "juego_brusco", description: "1.- Juego brusco grave.", matches: 3},
    { key: "conducta_violenta", description: "2.- Conducta violenta.", matches: 4},
    { key: "escupir", description: "3.- Escupir a un adversario.", matches: 5},
    { key: "mano", description: "4.- Impedir con mano intencionada un gol.", matches: 2},
    { key: "malograr", description: "5.- Malograr la oportunidad manifiesta de marcar un gol a un adversario.", matches: 2},
    { key: "lenguaje", description: "6.- Emplear lenguaje ofensivo, grosero y obseno.", matches: 4},
    { key: "segunda_tarjeta", description: "7.- Segunda tarjeta de amonestacion.", matches: 4},
  ]

  function renderOption(reason, i){
    const isSelected = selected?.key == reason.key 

    return <TouchableOpacity
      key={i}
      style={tw.style(`px-3 py-2 mb-3 border border-[#ccc] rounded-lg`, isSelected && `bg-red`)}
      onPress={() => {
        setSelected(reason)
      }}
    >
      <Text style={tw.style(`text-lg text-black`, isSelected && `text-white`)}>{reason.description}</Text>
    </TouchableOpacity>
  }

  return (
    <View style={tw`bg-white rounded-3xl py-5 px-5`}>
      <Text style={tw`text-xl font-bold mb-3`}>Causal de la expulsión</Text>

      {
        reasons.map((reason, i) => renderOption(reason, i))
      }
    </View>
  )
}



export default function NewPlayerEvent({ player, leagueTeam, onRequestClose, onSelect }) {
  const [selected, setSelected] = useState();


  const [selectedReason, setSelectedReason] = useState();
  const reasonModalWidth = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    setSelected(null)
    setSelectedReason(null)

    Animated.timing(reasonModalWidth, {
      toValue: 0,
      duration: 300,
      useNativeDriver: false,
    }).start();
  }, [player]);


  function selectOption(option){
    setSelected(option)

    setSelectedReason(null)

    Animated.timing(reasonModalWidth, {
      toValue: option == "red_card" ? 420 : 0,
      duration: 300,
      useNativeDriver: false,
    }).start();
  }

  if (!player) return null

  return (
    <View style={tw`flex-1 absolute right-0 top-0 left-0 bottom-0 items-center justify-center`}>
      <Pressable onPress={onRequestClose} style={tw`absolute right-0 top-0 left-0 bottom-0 bg-black/85`} />

      <View style={tw`row`}>
        <View style={tw`bg-white rounded-3xl py-5 px-5`}>
          <View style={tw`items-center`}>
            {/* <Image source={{uri: player.image_url}} style={tw`h-20 w-20 rounded-lg`} /> */}
            <Text style={tw.style(`text-center`, { fontSize: 30 })}>#{player.squad_number} | <Text style={tw`font-bold`}>{player.name}</Text></Text>
            <Text style={tw.style(`text-center`, { fontSize: 30 })}>{leagueTeam.name}</Text>
          </View>

          <View style={tw`row justify-around mt-6`}>
            <TouchableOpacity style={tw.style(`bg-gray-100 w-50 h-50 mx-3 justify-center items-center rounded-xl`, selected == "goal" && `bg-black`)} onPress={() => selectOption("goal")}>
              <FMIcons name={`gol`} size={60} color={'green'} />

              <Text style={tw.style(`text-black text-center pt-2 `, { fontSize: 30 }, selected == "goal" && `font-bold text-white`)}>Gol</Text>
            </TouchableOpacity>

            <TouchableOpacity style={tw.style(`bg-gray-100 w-50 h-50 mx-3 justify-center items-center rounded-xl`, selected == "yellow_card" && `bg-black`)} onPress={() => selectOption("yellow_card")}>
              <FMIcons name={`tarjeta`} size={60} color={tw.color('primary')} />
              <Text style={tw.style(`text-black text-center pt-2 `, { fontSize: 30 }, selected == "yellow_card" && `font-bold text-white`)}>Tarjeta{"\n"}Amarilla</Text>
            </TouchableOpacity>
          </View>

          <View style={tw`row justify-around mt-3`}>
            <TouchableOpacity style={tw.style(`bg-gray-100 w-50 h-50 mx-3 justify-center items-center rounded-xl`, selected == "red_card" && `bg-black`)} onPress={() => selectOption("red_card")}>
              <FMIcons name={`tarjeta`} size={60} color={'red'} />
              <Text style={tw.style(`text-black text-center pt-2 `, { fontSize: 30 }, selected == "red_card" && `font-bold text-white`)}>Tarjeta{"\n"}Roja</Text>
            </TouchableOpacity>

            <TouchableOpacity style={tw.style(`bg-gray-100 w-50 h-50 mx-3 justify-center items-center rounded-xl`, selected == "foul" && `bg-black`)} onPress={() => selectOption("foul")}>
              <FMIcons name={`tarjeta`} size={60} color={'gray'} />
              <Text style={tw.style(`text-black text-center pt-2 `, { fontSize: 30 }, selected == "foul" && `font-bold text-white`)}>Falta</Text>
            </TouchableOpacity>
          </View>


          {
            (selected && (selected != "red_card" || (selected == "red_card"  && selectedReason !== null)))&&
            <TouchableOpacity
              onPress={() => {
                const extra = selected == "red_card" ? {...selectedReason} : null
                
                onSelect(selected, extra)
              }}
              style={tw.style(
                `bg-green row px-5 py-5 justify-center mt-6 -mx-5 -mb-5 rounded-br-3xl rounded-bl-3xl`,
                selected == "foul" && `bg-gray-400`,
                selected == "red_card" && `bg-red`,
                selected == "yellow_card" && `bg-primary`,
              )}
            >
              <Text style={tw.style(`text-center text-white ml-4`, { fontSize: 30 })}>Confirmar</Text>
            </TouchableOpacity>
          }
        </View>


        <Animated.View style={{ width: reasonModalWidth, overflow: 'hidden', marginLeft: 10}}>
          <SuspensionReasonSelector setSelected={setSelectedReason} selected={selectedReason} />
        </Animated.View>
      </View>

      <Text onPress={onRequestClose} style={tw`text-white absolute right-6 bottom-6 text-lg`}>Cerrar</Text>
    </View>
  )
}