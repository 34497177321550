import React, { useEffect, useState, useRef } from "react";
import { View, Image, TouchableOpacity, ScrollView, Pressable, Animated, Button } from "react-native";

import tw from "../../../styles/tailwind";

import Text from '../../../Components/Text';


import { useDispatch, useSelector } from "react-redux";
import { loadMatch, saveMatchEvent, updateMatch } from "../../../store/Reducers/matches";

import Loading from "../../../Components/Loading";
import MatchTeams from "./TabletComponents/MatchTeams";


import {Ionicons} from "@expo/vector-icons"
import { StatusBar } from 'expo-status-bar';


import MatchTimeline from "./TabletComponents/MatchTimeline";
import TimerButton from "./TabletComponents/TimerButton";
import NewPlayerEvent from "./TabletComponents/NewPlayerEvent";
import MatchEventDetailModal from "./TabletComponents/MatchEventDetailModal";

import { useKeepAwake } from 'expo-keep-awake';
import NewPlayerEventButtons from "./TabletComponents/NewPlayerEventButtons";
import TeamsAssistanceSelector from "./TabletComponents/TeamsAssistance";
import MatchStats from "./TabletComponents/MatchStats";
import FMRemoteBottomSheet from "./TabletComponents/FMRemoteBottomSheet";
import useBatteryLevel from "../../../hooks/useBatteryLevel";
import useLandscapeOrientation from "../../../hooks/useLandscapeOrientation";
import PlayerDetailModal from "./TabletComponents/PlayerDetailModal";
import MatchStatsCapture from "./TabletComponents/MatchStatsCapture";
import FMIcons from '../../../Components/FMIcon';


import IconAsistencia from './TabletComponents/svgs/asistencia.svg'

import { useMachine } from '@xstate/react';
import { matchMachine } from "./TabletComponents/matchMachine";
import useMatchEvents from "./TabletComponents/useMatchEvents";
import useMatchAssistance from "./TabletComponents/useMatchAssistance";
import AssistanceTopBar, { CaptureSelectionModal } from "./TabletComponents/AssistanceTopBar";
import Clock from "./TabletComponents/Clock";
import TeamDetailModal from "./TabletComponents/TeamDetailModal";
import { TeamStats } from "./Match.ArbitrationCertificate";
import useRemoteControl, { controlSignals } from "./TabletComponents/useRemoteControl";

import NetInfo, { useNetInfo } from "@react-native-community/netinfo";

export type TeamsAssistance = { home: Array<Player>, away: Array<Player>, home_no_show: boolean, away_no_show: boolean }


/*
 - +/- tiempo
 - doble amarilla expulsa en automatico
 x indicador conexión
 - indicador conexión control
*/


const thize = num => `${num}${num === 1 ? 'st' : num === 2 ? 'nd' : num === 3 ? 'rd' : 'th'}`




function TeamFoulsButton({ match, matchEvents, currentPeriod, leagueTeam, onPress, style}){
  const keepsFouls = match.settings?.keep_fouls_on_periods ?? false


  const fouls = matchEvents.filter(ev => 
    ev.type === "foul"
    && ev.league_team?.uuid === leagueTeam.uuid
    && (keepsFouls || ev.period === currentPeriod)
  )

  return (
    <TouchableOpacity
      style={tw.style(`bg-red-300 pt-3 pb-3 items-center justify-center px-3 rounded-lg`, style)}
      onPress={onPress}
    >
      <View style={tw`row items-start`}>
        <Text style={tw.style(`font-bold text-xl text-white -mb-1 mr-[5px]`, { fontSize: 30, lineHeight: 30 })}>{fouls?.length}</Text>

        <Text style={tw`text-white text-center text-lg`}>Faltas</Text>
      </View>

      <Text style={tw`text-white text-center`}>Agregar falta</Text>
    </TouchableOpacity>
  )
}



export default function TabletUI({ navigation, route }) {
  const matchId = route.params?.matchId
  const match: Match = useSelector(state => state.entities.matches[matchId])
  
  
  const [fmRemoteVisible, setFmRemoteVisible] = useState(false);
  const [mode, setMode] = useState<"assistance" | "match" | "stats" | "stats_confirmation">("assistance");
  const [teamDetailModal, setTeamDetailModal] = useState(null);

  const [showCaptureModeSelector, setShowCaptureModeSelector] = useState(false);
  const [captureMode, setCaptureMode] = useState<"live" | "report">();
  const autosaveTimeoutRef = useRef()


  // Para la captura manual de cedula
  const [teamsManualStats, setTeamsManualStats] = useState<TeamStats>({ home: {}, away: {} });


  const { sendSignal } = useRemoteControl(match.field ? match.field.uuid : "default")


  // TODO: SELECTED_STADIUM_STATE
  const selectedStadiumId = useSelector((state) => state.status?.selectedStadiumId);
  const stadium = useSelector((state) => state.status?.stadiums?.find(e => e.uuid == selectedStadiumId));

  const dispatch = useDispatch()
  const batteryLevel = useBatteryLevel()

  useLandscapeOrientation()
  useKeepAwake()


  const matchPlayed = (match.status === "played" || match.status === "awarded")


  const reloadMatch = () => dispatch(loadMatch(matchId))

  useEffect(() => {
    reloadMatch()
  }, [matchId]);



  // 
  //------------- MASHINE
  const periodsCount = match.settings?.periods_count ?? 2
  const halfTimeLength = match.settings?.halftime_length ?? 0
  const periodDuration = match.settings?.period_duration ?? 25
  const reverseTimer = match.settings?.period_duration ?? true


  const [machineState, send, service] = useMachine(matchMachine, {
    context: {
      periodsCount,
      periodDuration: periodDuration * 60,
      halfTimeLength,
    },
    actions: {
      onMatchStart: (context, event) => {
        console.log('[macho] onMatchStart', JSON.stringify(context));

        // esto es porque la accion que suma currentPeriod+1 se llama junto con esta
        // y entonces machineState no lo tiene todavía...
        newEvent("match_started", null, null, {period: context.currentPeriod})
      },
      onPeriodStart: (context, event) => {
        // console.log('[macho] onPeriodStart', context);

        let eventName = null

        if (periodsCount === 2) {
          eventName = `${thize(context.currentPeriod)}_half`
        } else if (periodsCount === 4) {
          eventName = `${thize(context.currentPeriod)}_quarter`
        } else {
          eventName = `${thize(context.currentPeriod)}_period`
        }

        setTimeout(() => {
          // este timeout no me gusta, pero pasa que match_started y 1st_half/1st_quarter
          // se lanzan al mismo tiempo pero entra primero el 1st_xxx :/
          newEvent(eventName, null, null, { period: context.currentPeriod })
        }, 150)
      },
      onPeriodPause: (context, event) => {
        // console.log('[macho] onPeriodPause', JSON.stringify(context));

        newEvent("match_paused")
      },
      onPeriodResume: (context, event) => {
        // console.log('[macho] onPeriodResume', JSON.stringify(context));

        newEvent("match_resumed")
      },
      onPeriodFinish: (context, event) => {
        // console.log('[macho] onPeriodFinish', JSON.stringify(context));
        newEvent("period_finished")

        if (context.currentPeriod == context.periodsCount / 2) {
          newEvent("half_time")

          // Sube el marcador en canchas que tengan halfTimeLength
          if (context.halfTimeLength > 0){
            const minutesToAdd = Math.ceil(context.halfTimeLength / 60)
            
            sendSignal(`${controlSignals.add_minute} ${minutesToAdd}`)
          }
        }



        // Resetear faltas en el tablero (para canchas que apliquen)
        if (match.settings?.keep_fouls_on_periods !== true) {
          const periodHomeFoulsCount = events.filter(ev =>
            ev.type === "foul"
            && ev.league_team?.uuid === match.home_team.uuid
            && (ev.period === context.currentPeriod)
          ).length

          if (periodHomeFoulsCount > 0) {
            sendSignal(`${controlSignals.local_foul_down} ${periodHomeFoulsCount}`)
          }

          const periodVisitFoulsCount = events.filter(ev =>
            ev.type === "foul"
            && ev.league_team?.uuid === match.away_team.uuid
            && (ev.period === context.currentPeriod)
          ).length

          if (periodVisitFoulsCount > 0) {
            sendSignal(`${controlSignals.visit_foul_down} ${periodVisitFoulsCount}`)
          }
        }
      },
      onMatchFinish: (context, event) => {
        console.log('[macho] onMatchFinish');


        // newEvent("match_ended", {
        //   player_assistance: {
        //     home: teamsAssistance.home.map(player => player.uuid),
        //     away: teamsAssistance.away.map(player => player.uuid),
        //   }
        // })

        // esto ahora se despacha al darle click al boton de timer
        // que dice "Finalizar partido"
        // newEvent("match_ended")


        // setMode("stats_confirmation") // esto lo debería ser uno de los effects, no?
      },
    }
  });

  
  const matchHasStarted = ! machineState.matches('not_started') // ? lo dejamos?

 
  

  const currentTime = machineState.context.elapsedMatchTime
  //-----=========== mashine




  /*
    NETWORK [WIP]
    https://github.com/react-native-netinfo/react-native-netinfo
  */
  const [showNetDebug, setShowNetDebug] = useState(false);
  const network = useSelector(state => state.network)
  const netInfo = useNetInfo();
  
  const [networkStatus, setNetworkStatus] = useState({
    offlineStatusConnected: true,
    type: null,
    isConnected: true,
  });
  // console.log(network)

  useEffect(() => {
    if(!network.isConnected){
      // alert("Revisar conexión a internet")
    }
    
    setNetworkStatus({
      ...networkStatus,
      offlineStatusConnected: network.isConnected,
    })
  }, [network.isConnected]);

  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener(state => {
      console.log("[NetInfo] Connection type", state.type);
      console.log("[NetInfo] Is connected?", state.isConnected);

      setNetworkStatus({
        ...networkStatus,
        type: state.type,
        isConnected: state.isConnected,
      })
    });

    return () => {
      unsubscribe();
    }
  }, []);




  // 
  useEffect(() => {
    if (match) {
      if (matchPlayed) {
        setMode("stats_confirmation")
      }

      const inMatchEvents = ['1st_half', '2nd_half', '1st_quarter', '2nd_quarter', '3rd_quarter', '4th_quarter', 'half_time', "match_started", "match_paused", "match_resumed", "period_finished"];

      if (inMatchEvents.find(e => e == match.status)) {
        setMode("match")
      }

      if(match.referee_report?.capture_mode){
        setCaptureMode(match.referee_report?.capture_mode)
      }
    }
  }, [match]);


  //
  useEffect(() => {
    if (match) {
      if (matchPlayed) {
        setMode("stats_confirmation")
      }

      // aqui estaba antes algo similar a lo de inMatchEvents de arriba... 
      // poner si se necesita...

      _loadMatchEvents()
    }
  }, [match?.id]);



  function goToAssistance(){
    setMode("assistance")
    loadTeams()
  }



  const [boardInited, setBoardInited] = useState(false);

  function initBoard(){
    sendSignal(controlSignals.reset_board)
    
    const leftName = (!reverseAssistance ? match.home_team?.name : match.away_team?.name)?.substr(0, 10).replace(" ", "-").toUpperCase()
    const rightName = (!reverseAssistance ? match.away_team?.name : match.home_team?.name)?.substr(0, 10).replace(" ", "-").toUpperCase()

    sendSignal(`${controlSignals.set_names} ${leftName} ${rightName}`)
  }




  /**************************************  ASISTENCIA *********************************************/
  const { homeTeam, awayTeam, teamsAssistance, setTeamsAssistance, saveAssistance, loadTeams} = useMatchAssistance(match)
  const [reverseAssistance, setreverseAssistance] = useState(false);



  /**************************************   EVENTOS *********************************************/
  const [selected, setSelected] = useState({ player: null, leagueTeam: null });
  const { events, newEvent, postEvent, loadMatchEvents, _loadMatchEvents } = useMatchEvents(match, machineState, send, reverseAssistance)
 

  function onPlayerNewEvent(eventType:string, extra:null|object){
    // events = [...events, event] todo? offline

    const extraParams = {
      extra: extra,
      team_id: selected.leagueTeam.team.uuid,
      player_id: selected.player.uuid,
    }

    newEvent(eventType, null, null, extraParams)

    setSelected({})
  }


  function newTeamFoul(leagueTeam:LeagueTeam){
    const extraParams = {
      team_id: leagueTeam.team.uuid,
    }

    newEvent("foul", null, null, extraParams)
  }
  

  function playerSelected(player, leagueTeam:LeagueTeam){
    // Pequena validacion de seguridad, si ya esta jugado
    /// o el partido no ha comenzado no hacen nada los botones
    if (matchPlayed || currentTime == 0) return

    setSelected({ player, leagueTeam: leagueTeam })
  }


  // Modal de Evento seleccionado
  const [selectedEvent, setSelectedEvent] = useState();

  function onEventPress(event:MatchEvent){
    setSelectedEvent(event)
  }

  function onEventDeleted(event:MatchEvent){
    setSelectedEvent(null)

    loadMatchEvents()

    reloadMatch()
  }


  function clearAssistanceAutosaveTimeout(){
    if (autosaveTimeoutRef.current){
      clearTimeout(autosaveTimeoutRef.current)
      autosaveTimeoutRef.current = null
    }
  }


  // Modal de seleccion de jugador
  const [openedPlayer, setOpenedPlayer] = useState<{player:Player, playerLeagueTeam:LeagueTeam, side: "home"|"away"} | null>();

  function onPlayerSelectedLong(player: Player, playerLeagueTeam: LeagueTeam, side: "home" | "away") {
    setOpenedPlayer({player, playerLeagueTeam, side})
  }
  /**************************************  /EVENTOS *********************************************/



  //
  // Animaciones al cambiar de modo
  //
  const timelineHeightAnim = useRef(new Animated.Value(-132)).current;
  const timerButtonMarginAnim = useRef(new Animated.Value(-110)).current;
  const foulBtnMarginAnim = useRef(new Animated.Value(7)).current;


  useEffect(() => {
    Animated.timing(timelineHeightAnim, {
      toValue: (mode === "match" || mode === "stats" || (mode === "stats_confirmation" && matchHasStarted)) ? 0 : -132,
      duration: 600,
      useNativeDriver: false,
    }).start();


    Animated.timing(timerButtonMarginAnim, {
      toValue: mode == "match" ? 0 : -110,
      duration: 400,
      useNativeDriver: false,
    }).start();

    Animated.timing(foulBtnMarginAnim, {
      toValue: mode == "match" ? 7 : -130,
      duration: 400,
      useNativeDriver: false,
    }).start();
  }, [mode]);



  if(!match) return <Loading visible={true} />

  return (
    <>
      <View style={tw`flex-1 bg-background`} >
        <StatusBar hidden={true} />

        {/* 
          El timeline :D
         */}
        <Animated.View
          style={[
            {
              marginTop: timelineHeightAnim,
              marginBottom: 20,
            },
          ]}>
          <MatchTimeline
            events={events}
            match={match}
            reverseAssistance={reverseAssistance}
            currentPeriod={machineState.context.currentPeriod}
            periodDuration={periodDuration}
            periodsCount={periodsCount}
            currentTime={currentTime}
            onEventPress={onEventPress}
          />
        </Animated.View>

        

        {/* 
          Titulo captura asistencia / Botones ir a partido / boton ir a cedula
         */}
        <AssistanceTopBar
          match={match}
          matchHasStarted={matchHasStarted}
          reverseAssistance={reverseAssistance}
          onReverseTeamsPressed={ () => setreverseAssistance(!reverseAssistance)}
          mode={mode}
          onGotoMatchPressed={() => {
            setMode("match")
            saveAssistance()
            clearAssistanceAutosaveTimeout()
          }}
          onGotoReportPressed={() => {
            setMode("stats_confirmation")
            saveAssistance()
            clearAssistanceAutosaveTimeout()
          }}
          onChooseCaptureOptionsPressed={() => {
            saveAssistance()
            setShowCaptureModeSelector(true)
            clearAssistanceAutosaveTimeout()
          }}
        />


        {/* 
              AQUI COMIENZA CONTENIDO
        */}
        {
          mode === "match" &&
          <Image
            source={require('./TabletComponents/svgs/bg_tablet.png')}
            style={tw`absolute left-0 right-0 top-[112px] bottom-0 w-100% h-[84vh]`}
            resizeMode={"cover"}
          />
        }



        {/* 
          Titulo Equipo A vs Equipo B
         */}
        <View style={
          tw.style(
            `row items-center mb-2`,
            { 
              marginTop: mode == "stats_confirmation"
              ? -70 : 0 
            }, 
            mode == "stats_confirmation" && (!matchHasStarted || (teamsAssistance.home_no_show || teamsAssistance.away_no_show)) && {marginTop: -100, opacity: 0.0},
            mode == "assistance" && `bg-gray-500`,
            reverseAssistance && { flexDirection: 'row-reverse' },
          )
        }>
          <Animated.View
            style={[
              {
                marginLeft: ! reverseAssistance ? foulBtnMarginAnim : 0,
                marginRight: reverseAssistance ? foulBtnMarginAnim : 0,
              },
            ]}
          >
            <TeamFoulsButton
              match={match}
              currentPeriod={machineState.context.currentPeriod}
              matchEvents={events}
              leagueTeam={match.home_team}
              onPress={() => {
                newTeamFoul(match?.home_team)
              }}
              style={tw`mr-3 ml-2`}
            />
          </Animated.View>

          <MatchTeams
            match={match}
            homeScore={match.home_score}
            awayScore={match.away_score}
            reverseAssistance={reverseAssistance}
            style={{ container: tw`pt-0 pb-1 bg-transparent flex-1 px-7`, jaja: `` }}
            played={false}
            onTeamPressed={leagueTeam => setTeamDetailModal(leagueTeam)}
          />

          <Animated.View
            style={[
              {
                marginRight: ! reverseAssistance ? foulBtnMarginAnim : 0,
                marginLeft: reverseAssistance ? foulBtnMarginAnim : 0,
              },
            ]}
          >
            <TeamFoulsButton
              match={match}
              currentPeriod={machineState.context.currentPeriod}
              matchEvents={events}
              leagueTeam={match.away_team}
              onPress={() => {
                newTeamFoul(match.away_team)
              }}
              style={tw`ml-2 mr-2`}
            />
          </Animated.View>
        </View>



        {/* 
          Los botones de jugadores
         */}
         {
           mode === "match" &&
            <NewPlayerEventButtons
              assistance={teamsAssistance}
              reverseAssistance={reverseAssistance}
              homeTeam={homeTeam}
              awayTeam={awayTeam}
              matchEvents={events}
              onPlayerSelected={playerSelected}
            />
         }

         {/* Pantalla asistencia */}
         {
           mode === "assistance" &&
            <TeamsAssistanceSelector
              homeTeam={homeTeam}
              awayTeam={awayTeam}
              reverseAssistance={reverseAssistance}
              onChange={(value) => {
                setTeamsAssistance(value)

                // Autosave el assistance a los 10 segundos
                // solo que esto tiene el tipico bug de los timeouts
                // saveAssistance en este punto tiene teamsAssistance no el nuevo value :/
                if (autosaveTimeoutRef.current){
                  clearTimeout(autosaveTimeoutRef.current)
                }

                autosaveTimeoutRef.current = setTimeout(saveAssistance, 10000)
              }}
              assistance={teamsAssistance}
              match={match}
              onPlayerCreated={() => {
                loadTeams()
              }}
              onPlayerLongPressed={onPlayerSelectedLong}
            />
         }

         {/* Pantalla cierre (cédula) */}
         {
          mode === "stats_confirmation" &&
            <MatchStatsCapture
              homeTeam={homeTeam}
              awayTeam={awayTeam}
              match={match}
              reverseAssistance={reverseAssistance}
              matchEvents={events}
              matchAssistance={teamsAssistance}
              canModify={(!matchHasStarted || machineState.value?.running_period == "paused")}
              matchStatus={machineState.value}
              onGoToAssistancePressed={goToAssistance}
              onReportFinished={() => {
                // setMode("stats")
                reloadMatch()
              }}

              teamsManualStats={teamsManualStats}
              setTeamsManualStats={setTeamsManualStats}
            />
         }

         {/* Pantalla cerrado */}
         {
           mode === "stats" &&
            <MatchStats
              match={match}
            />
         }




        {/* 
          El botón del timer!
         */}
        {
          (! matchPlayed) &&
            <Animated.View style={[{
              marginBottom: timerButtonMarginAnim,
              paddingVertical: 10
            }]}>

              <TouchableOpacity style={tw`absolute border rounded-lg row border-primary bottom-8 left-8 px-4 py-3`} onPress={goToAssistance}>
                <Ionicons name="chevron-back-outline" size={20} color={tw.color(`primary`)} />

                <IconAsistencia style={tw`w-5 h-5 mr-2 ml-3`} />

                <Text style={tw`text-white row text-lg`}>Asistencia</Text>
              </TouchableOpacity>

              <TimerButton
                matchMachine={machineState}
                matchState={machineState.value}
                elapsedTime={machineState.context.elapsedPeriodTime}
                currentPeriod={machineState.context.currentPeriod}
                periodDuration={machineState.context.periodDuration}
                reverse={reverseTimer}
                onPress={() => {
                  if (machineState.value !== "match_finished"){
                    send("PAUSE_RESUME")
                  }else{
                    newEvent("match_ended")

                    // Reseteo del tablero
                    sendSignal(controlSignals.reset_board)

                    setMode("stats_confirmation")
                  }
                }}
              />

              {
                machineState.value?.running_period == "paused" &&
                  <TouchableOpacity
                    style={tw`absolute right-25% bottom-6 bg-red-400 py-3 px-5 roundedlg`}
                    onPress={() => {
                      setMode("stats_confirmation")
                    }}
                  >
                    <Text style={tw`text-white text-red-200`}>Terminar partido</Text>
                  </TouchableOpacity>
              }

              {
                (machineState.value == "not_started") &&
                  <TouchableOpacity style={tw`absolute border rounded-lg row border-green bottom-8 right-18% px-4 py-3`} onPress={initBoard}>
                    <FMIcons name="tablero" size={20} color={tw.color(`primary`)} />

                    <Text style={tw`text-white row text-lg ml-2`}>Iniciar Tablero</Text>
                  </TouchableOpacity>
              }
            </Animated.View>
        }



        {
          mode == "match" &&
          <>
            {/* Batería */}
            <View style={tw`absolute bottom-6 right-2 row items-center`}>
              <Ionicons name="battery-dead" style={tw``} size={20} color={"white"} />
              <Text style={tw`text-white -mt-[2px] ml-2`}>{batteryLevel ? (batteryLevel * 100).toFixed(0) : ''}%</Text>
            </View>

            {/* Reloj */}
            <TouchableOpacity style={tw`absolute bottom-2 right-5`} onPress={() => setShowNetDebug(!showNetDebug)}>
              {/*  font-bold absolute bottom-2 right-5 */}
              <Clock style={tw`font-bold`} />
            </TouchableOpacity>

            {/* Botón control */}
            <TouchableOpacity onPress={() => setFmRemoteVisible(true)} style={tw`absolute bottom-12 right-3 px-2 py-2`}>
              <FMIcons name="tablero" style={tw``} size={20} color={network.isConnected ? "white" : "red"} />
            </TouchableOpacity>


            {/* Boton prueba offline event */}
            {/* <Text style={tw`absolute bottom-5 left-4`} onPress={() => dispatch(saveMatchEvent("prueba", "ed90b563-3981-47b5-8488-aa42c732b3dc", Math.random(), {perro: "bueno", date: new Date()})) }>Eventooffline</Text> */}
          </>
        }
       
        {/*  */}
        {
          (networkStatus.type == "none") &&
            <Text style={tw`text-white absolute bottom-0 left-45% px-2 bg-red rounded-sm`}>Sin conexión a Wi-Fi</Text>
        }

        {
          (networkStatus.isConnected === false && networkStatus.type !== "none") &&
            <Text style={tw`text-white absolute bottom-0 left-45% px-2 bg-red rounded-sm`}>Sin conexión a Internet</Text>
        }
      </View>

      {
        showNetDebug &&
          <Text style={tw`absolute bottom-0 left-0 text-white`}>{JSON.stringify({ networkStatus, netInfo})}</Text>
      }

      {/*  */}
      <NewPlayerEvent
        player={selected.player}
        leagueTeam={selected.leagueTeam}
        onRequestClose={() => setSelected({})}
        onSelect={onPlayerNewEvent}
      />

      {/*  */}
      <MatchEventDetailModal
        event={selectedEvent}
        match={match}
        reverseAssistance={reverseAssistance}
        onRequestClose={() => setSelectedEvent(null)}
        onEventDeleted={() => onEventDeleted(selectedEvent)}
      />

      {/*  */}
      <PlayerDetailModal
        player={openedPlayer?.player}
        playerLeagueTeam={openedPlayer?.playerLeagueTeam}
        league={match.league}
        onRequestClose={() => setOpenedPlayer(null)}
        onPlayerEdited={(closeModal=true) => {
          if (closeModal){
            setOpenedPlayer(null)
          }
          
          loadTeams()
        }}
      />

      <TeamDetailModal
        visible={teamDetailModal}
        leagueTeam={teamDetailModal}
        onRequestClose={() => setTeamDetailModal(null)}
        stadium={stadium}
        match={match}
      />


      <CaptureSelectionModal
        visible={showCaptureModeSelector}
        teamsAssistance={teamsAssistance}
        onReportSelected={() => {
          setMode("stats_confirmation")
          // saveAssistance()
          setShowCaptureModeSelector(false)
        }}
        onLiveCaptureSelected={() => {
          setMode("match")
          // saveAssistance()
          setShowCaptureModeSelector(false)
        }}
        onRequestClose={() => {
          setShowCaptureModeSelector(false)
        }}
      />



      {/*  */}
      <FMRemoteBottomSheet
        visible={fmRemoteVisible}
        fieldId={match.field ? match.field.uuid : "default"}
        onRequestClose={() => setFmRemoteVisible(false)} 
        // elapsedTime={machineState.context.elapsedPeriodTime}
        periodDuration={machineState.context.periodDuration}
      />
    </>
  );
}
