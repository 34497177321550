import React, { useEffect } from "react";
import {Image, ScrollView, StyleSheet, View } from "react-native";
import { StatusBar } from "expo-status-bar";

import tw from "../../../styles/tailwind";

import Text from "../../../Components/Text";
import { useNavigation } from "@react-navigation/native";
import { format } from "date-fns/esm";
import useLeague from "../../../hooks/useLeague";




export default function LeagueSummary({ leagueId}) {
  const navigation = useNavigation();


  const league = useLeague(leagueId)


  // TODO: Abstraer
  const genderStrs = { "male": "Varonil", "female": "Femenil", "mixed": "Mixto" }
  const stagesStrs = { "league": "Liga", "cup": "Copa", "league_cup": "Liga + Copa" }

  const dateStr = league?.date_start ? format(new Date(league?.date_start), "dd/LL/yyyy") : null
  const categoryStr = league?.category == "free" ? "Categoría libre" : "Límite de edad"

  return (
    <ScrollView style={tw`flex-1 px-3`}>
      <View style={tw`items-center`}>
        <Image source={{ uri: league?.image_url }} style={tw`h-22 w-22 mb-6 rounded-xl`} />
      </View>


      {/* Información del torneo */}
      <View style={tw`flex-row justify-between`}>
        <View>
          <Text style={tw`text-gray-200`}>{league?.variation_str}</Text>
          {/* <Text style={tw`text-gray-200`}>{league?.description}</Text> */}
          <Text style={tw`text-gray-200`}>{genderStrs[league?.gender]}</Text>
          {/* <Text style={tw`text-gray-200`}>{dateStr}</Text> */}
          <Text style={tw`text-gray-200 text-right`}>{categoryStr}</Text>
        </View>

        <View>
          <Text style={tw`text-gray-200 text-right`}>{stagesStrs[league?.stages_mode]}</Text>
          <Text style={tw`text-gray-200 text-right`}>Máx equipos: {league?.max_teams}</Text>
          <Text style={tw`text-gray-200 text-right`}>Máx. jug. por equipo: {league?.max_players}</Text>
        </View>
      </View>

      <View style={tw`mt-3`}>
        {
          league?.match_days_str && 
            <Text style={tw`text-gray-200`}>Días de juego: {league?.match_days_str}</Text>
        }

        {
          league?.description &&
            <Text style={tw`text-gray-200 mt-3`}>{league?.description}</Text>
        }

        {
          league?.settings?.default_goals &&
            <Text style={tw`text-gray-200 mt-3`}>Goles en default: {league?.settings?.default_goals}</Text>
        }
      </View>
    </ScrollView>
  );
}

