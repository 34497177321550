import React, { useEffect, useState } from 'react';
import { Pressable, View, TouchableOpacity } from "react-native";

import commonStyle, { theme } from "../styles/common.style";
import { Control, useController } from "react-hook-form";

import tw from '../styles/tailwind';

import FMIcons from './FMIcon';
import Text from './Text';



interface OptionsSelectProps{
  name: string,
  control: Control,
  onChange?: (value : string) => void,
  style?: any,
  rules?: object,
  options: Array<string|{
    icon?: string,
    iconlabel?: string,
    label?: string,
    value: string,
  }>,
}


export default function OptionsSelect({ onChange, control, name, style, rules, options }:OptionsSelectProps) {
  const [selected, setSelected] = useState();

  var { field, fieldState: { invalid, isTouched, isDirty, error } } = useController({
    control: control,
    defaultValue: selected,
    name: name,
    rules: rules,
  })

  function onOptionChanged(option, i) {
    setSelected(i)
    onChange && onChange(option, i)
    field.onChange(option.value ? option.value : i)

  }

  useEffect(() => {
    // buscar field.value en options[].value
    // y setSelected(indice de la opcion)

    const index = options.findIndex(item => item.value == field.value)
    setSelected(index)
  }, [field.value]);



  return (
    <View style={tw.style(style?.container)}>
      <View style={tw.style(`flex-row justify-between`, style?.optionsWrapper)}>
        {
          options.map((option, i) =>
            <TouchableOpacity
              key={i}
              onPress={() => onOptionChanged(option, i)}
              style={tw.style(
                `flex-1 bg-gray-50 py-8 items-center justify-center rounded-lg border`,
                { borderColor: theme.COMPONENTS_BORDER_COLOR },
                i == 0 ? "mr-1" : "mx-1",
                i == options.length - 1 && "mr-0",
                style?.option,
                selected == i && "bg-gray-500",
              )}
            >
              {
                option.icon == undefined ?
                  null
                  :
                  <>
                    <FMIcons
                      name={`${option.icon}`}
                      style={tw.style(
                        `text-secondary`,
                        { fontSize: 35 },
                        style?.icon,
                        selected == i && "text-primary"
                      )}
                    />

                    {
                      option.iconlabel &&
                        <Text style={tw.style(`text-secondary mt-2 -mx-2`, style?.iconLabel, selected == i && "text-gray")}>{option.iconlabel}</Text>
                    }
                  </>
              }
              
              {
                typeof option == 'string' &&
                <Text
                  style={tw.style(
                    `font-bold text-gray-500`,
                    selected == i && "text-primary",
                    style.label,
                  )
                  }>{option}</Text>
              }


              {
                typeof option.label == 'string' &&
                <Text
                  style={tw.style(
                    `font-bold text-gray-500`,
                    selected == i && "text-white",
                    style.label,
                  )
                  }>{option.label}</Text>
              }


              {
                typeof option.label == 'object' &&
                option.label
              }
            </TouchableOpacity>
          )
        }
      </View>

      {
        error && <Text style={{ color: 'red', marginTop: 5 }}>{error.message ? error.message : "Selecciona una opción"}</Text>
      }
    </View>
  )
}