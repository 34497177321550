import React, { useState, useEffect, useRef } from 'react';
import { View, TouchableOpacity, ScrollView } from "react-native"

import { useForm } from 'react-hook-form';

import Modal from '../../../../Components/Modal'
import Button from '../../../../Components/Button';
import CheckButton from "../../../../Components/CheckButton";
import AddTeamBottomSheet from "../../Teams/AddTeamBottomSheet";
import Text from '../../../../Components/Text';
import tw from '../../../../styles/tailwind';
import TeamBadge from '../../../../Components/Teams/TeamBadge';
import api from '../../../../utils/api';


export default function DeleteLeagueTeamModal({ onRequestClose, onLeagueTeamDeleted, visible, leagueTeam, leagueUid }: { leagueTeam: LeagueTeam }) {
  const [showNewTeamBottomsheet, setShowNewTeamBottomsheet] = useState();
  const [leagueTeams, setLeagueTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState();
  const [isDeleting, setIsDeleting] = useState(false);


  const { handleSubmit, watch, errors, setValue, control } = useForm()
  const delete_stats = watch("delete_stats")
  const transfer_stats = watch("transfer_stats")

  const emptyTeams = leagueTeams.filter((leagueTeam: LeagueTeam) => ! leagueTeam.stats || !!! leagueTeam.stats.played)

  const scrollViewRef = useRef(null)

  const hasMatchesPlayed = ! leagueTeam.stats || !! leagueTeam.stats.played

  useEffect(() => {
    loadLeagueTeams()
  }, []);

  function deleteLeagueTeam(formData) {
    
    setIsDeleting(true)

    const data = { }

    if(transfer_stats){
      //
      data.transfer_stats_to = selectedTeam?.team.uuid
    }

    api.delete(`/leagues/${leagueUid}/teams/${leagueTeam.team.uuid}`, {params: data})
      .then(({ data }) => {
        setIsDeleting(false)
        onLeagueTeamDeleted?.();
      })
      .catch(({ error }) => {
        setIsDeleting(false)
      })
  }



  function loadLeagueTeams() {
    api.get(`/leagues/${leagueUid}/teams`)
      .then(({ data }) => {
        setLeagueTeams(data)
      })
      .catch(error => {
        console.log("api error", error)
      })
  }


  return (
    <Modal
      visible={visible}
      onRequestClose={onRequestClose}
      onDismiss={onRequestClose}
      style={{ wrapper: tw`py-0` }}
      transparent
    >
      <ScrollView
        ref={scrollViewRef}
        style={tw`flex-1`}
        contentContainerStyle={tw`py-3 pt-5`}
      >
        <Text style={tw`font-bold text-xl text-center`}>Dar de baja a</Text>

        <View style={tw`row justify-center mt-4`}>
          <TeamBadge team={leagueTeam.team} height={50} width={50} />
          <Text style={tw`font-bold text-xl text-center ml-4`}>{leagueTeam.name}</Text>
        </View>


        {
          hasMatchesPlayed &&
            <View>
              {/* Estadisticas */}
              <View style={tw`row justify-around mt-6 mb-4`}>
                <View style={tw`items-center`}>
                  <Text style={tw`text-lg`}>Partidos</Text>
                  <Text style={tw`text-xl`}>{leagueTeam.stats?.played || 0}</Text>
                </View>

                <View style={tw`items-center`}>
                  <Text style={tw`text-lg`}>Puntos</Text>
                  <Text style={tw`text-xl`}>{leagueTeam.stats?.points || 0}</Text>
                </View>

                <View style={tw`items-center`}>
                  <Text style={tw`text-lg`}>Goles</Text>
                  <Text style={tw`text-xl`}>{leagueTeam.stats?.goals_for || 0}</Text>
                </View>
              </View>


              <Text style={tw`mb-3 text-center`}>¿Qué hacemos con estas estadísticas?</Text>

              <CheckButton
                label={"Transferir a otro equipo"}
                control={control}
                name={"transfer_stats"}
                activeColor={"black"}
                style={{ container: tw`mb-3`, checkbox: tw`rounded-full`, checkStyle: tw`rounded-full` }}
                onChange={(value) => {
                  if (value) {
                    setValue("delete_stats", false)
                    scrollViewRef.current.scrollToEnd({ animated: true, duration: 1500 })
                    setSelectedTeam(null)
                  }
                }}
              />

              <CheckButton
                label={"Eliminar estadísticas"}
                control={control}
                name={"delete_stats"}
                activeColor={"black"}
                style={{ checkbox: tw`rounded-full`, checkStyle: tw`rounded-full` }}
                onChange={(value) => {
                  if (value) {
                    setValue("transfer_stats", false)
                  }
                }}
              />


              {
                transfer_stats &&
                <View style={tw`mt-4`}>
                  <Text style={tw`mb-2`}>Selecciona el equipo:</Text>

                  {
                    emptyTeams.length == 0 &&
                    <Text style={tw`text-gray-300 text-center mt-4`}>No tienes ningún equipo vacío.</Text>
                  }

                  {
                    emptyTeams.map((leagueTeam: LeagueTeam) =>
                      <TouchableOpacity
                        key={leagueTeam.uuid}
                        style={tw.style(
                          `px-3 py-3 rounded-lg border border-[#ccc] mb-2`,
                          leagueTeam.uuid == selectedTeam?.uuid && `bg-gray-100 border-[#888]`
                        )}
                        onPress={() => setSelectedTeam(leagueTeam)}
                      >
                        <Text>{leagueTeam.name}</Text>
                      </TouchableOpacity>
                    )
                  }

                  <Text style={tw`text-center bg-primary self-center py-3 px-5 rounded-lg mt-5`}
                    onPress={() => setShowNewTeamBottomsheet(true)}
                  >Crear equipo</Text>
                </View>
              }
            </View>
        }



        <Button
          disabled={isDeleting || (hasMatchesPlayed && ! delete_stats && !(transfer_stats && selectedTeam))}
          label={isDeleting ? "Borrando..." : "Dar de baja"}
          // color={"red"}
          onPress={handleSubmit(deleteLeagueTeam)}
          style={{ container: tw`mt-8` }}
        />
      </ScrollView>

      <AddTeamBottomSheet
        visible={showNewTeamBottomsheet}
        leagueId={leagueUid}
        onRequestClose={() => {
          setShowNewTeamBottomsheet(false)
        }}
        onTeamAssociated={(leagueTeam) => {
          setShowNewTeamBottomsheet(false)
          loadLeagueTeams()
        }}
        onTeamCreated={(team) => {
          setShowNewTeamBottomsheet(false)
          loadLeagueTeams()
        }}

      />
    </Modal>
  )
}

