import React, { useEffect, useState } from "react";
import { ScrollView, View, TouchableOpacity, Platform , Alert, Image, Linking} from "react-native";

import api from '../../../utils/api'
import tw from '../../../styles/tailwind';

import Text from "../../../Components/Text";

import AddPlayerBottomSheet from "./Players/AddPlayerBottomSheet";
import TeamBadge from "../../../Components/Teams/TeamBadge";
import FMIcons from '../../../Components/FMIcon';
import Loading from "../../../Components/Loading";


import { useNavigation, useRoute } from "@react-navigation/native";
import { useDispatch, useSelector } from "react-redux";
import { loadLeagueTeam, updateLeagueTeam } from "../../../store/Reducers/leagueTeams";

import DeleteTeamModal from './Components/DeleteTeamModal'


import {
  HeaderButtons,
  HiddenItem,
  OverflowMenu,
  Divider,
} from 'react-navigation-header-buttons';
import {Ionicons} from "@expo/vector-icons"
import TeamMatches from "./Components/TeamMatches";
import TeamPayments from "./Components/TeamPayments";
import ChangeTeamNameModal from "./Components/ChangeTeamNameModal";


function MembersListRow({ member, onPress }) {
  return (
    <TouchableOpacity
      style={tw`bg-secondary py-3 px-3 rounded-xl mb-3 row justify-between`}
      onPress={onPress}
    >
        <View style={tw`row items-center`}>
          {
            member.squad_number && <Text style={tw`mr-2 text-gray text-md w-[8] text-center`}>{member.squad_number}</Text>
          }

          <View style={tw`border-l border-gray-300 h-5 mr-2`} />
          <Text style={tw`text-gray text-md`}>{member.name}</Text>
        </View>



      <View style={tw`row -my-3 -mr-2`}>
        <View style={tw`items-end mr-3`}>
          <Text style={tw`text-gray-300 text-sm`}>Jugados: {member.pivot.stats?.played || 0}</Text>
          <Text style={tw`text-gray-300 text-sm`}>Goles: {member.pivot.stats?.goals || 0}</Text>
        </View>

        {/* Foto */}
        {
          (member.is_registered && member.image_url) &&
            <Image source={{ uri: member.image_url }} style={tw`h-10 w-10 rounded`} />
        }
      </View>
    </TouchableOpacity>
  )
}


function MembersList({ leagueTeam, leagueId, title, members, onCreatePressed, infoText, style }) {
  const navigation = useNavigation();

  return (
    <View style={tw.style(``, style?.container)}>

      {/* Título y botón */}
      <View style={tw`flex-row items-center justify-between mb-4`}>
        <Text style={tw.style(`text-lg text-gray`)}>{title} ({members.length})</Text>

        <TouchableOpacity 
          style={tw`px-3 py-3 rounded-xl bg-primary`}
          onPress={onCreatePressed}
        >
          <FMIcons name={'add-b'} size={12} color={'#000'} />
        </TouchableOpacity>
      </View>

      {
        members.length != 0 ? null
          :
          <Text style={tw`text-md text-gray-300`}>{infoText}</Text>
      }
      {/* Miembros */}
      <View>
        {
          members.map(member =>
            <MembersListRow
              key={member.uuid}
              member={member}
              onPress={() => {
                navigation.navigate("Admin.Player.Detail", { playerId: member.uuid, leagueTeamId: leagueTeam.uuid, leagueId, teamId: leagueTeam.team.uuid })
              }}
            />
          )
        }
      </View>
    </View>
  )
}

function TeamHeaderBadge({ team, style, onPress }) {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={tw.style(`bg-gray-800 py-3 px-3 border border-primary rounded-2xl`, style?.container)}>
      <TeamBadge team={team} width={60} height={60} />
    </TouchableOpacity>
  )
}


function XPBar({ percentage }) {
  return (
    <View style={tw`border border-gray-300 h-3 rounded-xl flex-1`}>
      <View style={tw.style(`rounded-xl bg-primary flex-1`, { width: `${percentage}%` })}></View>
    </View>
  )
}


function Counter({ label, count, color }) {
  return (
    <View style={tw`flex-1 flex-col items-center`}>
      <Text style={tw.style(`text-xl text-gray`)}>{count}</Text>
      <Text style={tw.style(`text-md  text-${color}`)}>{label}</Text>
    </View>
  )
}


export function TeamHeader({ leagueTeam, style, onBadgePress }: {
  leagueTeam: LeagueTeam,
  style?: any,
}) {
  return (
    <View style={tw.style(`py-5 rounded-xl bg-gray-500`, style?.container)}>
      {/*  */}

      {/* Badge equipo */}
      <View style={tw.style(`flex-1 flex-row justify-center`)}>
        <TeamHeaderBadge onPress={onBadgePress} team={leagueTeam.team} style={{ container: tw`absolute -top-[14]` }} />
      </View>

      {/* Info equipo */}
      <View style={tw`flex-col items-center mt-[40px] mx-3`}>
        <Text style={tw.style(`text-xl text-gray`)}>{leagueTeam.name}</Text>

        {
          leagueTeam.deleted_at &&
            <Text style={tw.style(`text-sm text-gray`)}>(Equipo suspendido)</Text>
        }
        {/* mb-4 */}
        {/* <XPIndicator style={{ container: tw`w-[100%] ` }} /> */}
      </View>

      <View style={tw`flex-row mx-3 items-center mt-3`}>
        <Counter label={"Ganados"} count={leagueTeam.stats?.win || 0} color="green" />
        <View style={tw`border-l border-gray-300 h-5`} />
        <Counter label={"Perdidos"} count={leagueTeam.stats?.loss || 0} color="red" />
        <View style={tw`border-l border-gray-300 h-5`} />
        <Counter label={"Empate"} count={leagueTeam.stats?.draw || 0} color="gray" />
      </View>
    </View>
  )
}


function TeamMembers({ leagueTeam, leagueUid, onPlayerCreated }){
  const [modalShown, setModalShown] = useState(false);
  const [selectedRole, setSelectedRole] = useState();


  // USE_HOOK - Esto se puede mover a un selector/hook/algo
  const players = leagueTeam.members.filter(member => member.pivot.roles.indexOf('player') !== -1 )
  const representatives = leagueTeam.members.filter(member => member.pivot.roles.indexOf('representative') !== -1 )

  return (
    <View>
      <View style={tw`mb-5`}>
        {/*  */}
        <MembersList
          title={"Representantes"}
          buttonLabel={"Agregar"}
          members={representatives}
          onCreatePressed={() => {
            setModalShown(true)
            setSelectedRole("representative")
          }}
          infoText={"Necesita haber al menos un representante por equipo"}
          style={{ container: tw`mb-4` }}
          leagueTeam={leagueTeam}
          leagueId={leagueUid}
        />

        <MembersList
          title={`Jugador${leagueTeam.team?.gender == "female" ? 'a' : 'e'}s`}
          buttonLabel={`Agregar`}
          members={players.sort((a, b) => a.name.localeCompare(b.name)) }
          onCreatePressed={() => {
            setModalShown(true)
            setSelectedRole("player")
          }}
          leagueTeam={leagueTeam}
          leagueId={leagueUid}
        />


        {/* Modal */}
        <AddPlayerBottomSheet
          visible={modalShown}
          selectedRole={selectedRole}
          onRequestClose={() => {
            setModalShown(false)
          }}
          leagueUid={leagueUid}
          leagueTeam={leagueTeam}
          onPlayerCreated={() => {
            setModalShown(false)
            onPlayerCreated?.()
          }}
          autofocus
        />
      </View>
    </View>
  );
}



function Tabs({ items, selected, onChange }) {
  const [_selected, setSelected] = useState(selected ?? items[0].key);

  useEffect(() => {
    if (selected)
      setSelected(selected)
  }, [selected]);

  return (
    <View style={tw`row justify-around mb-4`}>
      {
        items.map(item =>
          <TouchableOpacity
            key={item.key}
            onPress={() => {
              setSelected(item.key)
              onChange?.(item.key)
            }}
            style={tw.style(`items-center py-2`, _selected == item.key && `border-b border-white`)}
          >
            <Text style={tw`text-gray-200`}>{item.name}</Text>
          </TouchableOpacity>
        )
      }
    </View>
  )
}


export default function AdminTeamDetailScreen({ navigation, route }) {
  const [showDeleteTeamModal, setShowDeleteTeamModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState("players");
  const [showChageNameModal, setShowChageNameModal] = useState(false);

  const leagueUid = route.params?.leagueUid;
  const teamUid = route.params?.teamUid;

  const tabs = [
    { key: "players", name: "Jugadores" },
    { key: "matches", name: "Partidos" },
    { key: "payments", name: "Pagos" }
  ]


  // USE_HOOK - useLeagueTeam(leagueId, teamId)
  const dispatch = useDispatch()
  const leagueTeam = useSelector(state => state.entities.leagueTeams[`${leagueUid}-${teamUid}`])

  useEffect(() => {
    loadTeam();

    navigation.setOptions({
      // title: leagueName, //<< TODO: conectar con redux
    })
  }, [])

  useEffect(() => {
    loadTeam();
  }, [route.params?.createdPlayerId]);


  useEffect(() => {
    // cambiar el título del navbar
    navigation.setOptions({
      headerRight: () => <HeaderButtons>
        <OverflowMenu
          style={{ marginRight: -5 }}
          OverflowIcon={({ color }) => (
            <Ionicons name="ellipsis-vertical-outline" color={"white"} size={24} />
          )}
        >
          {
            ! leagueTeam?.deleted_at ?
              <>
                <HiddenItem title="Cambiar nombre" onPress={showNameChangeModal} titleStyle={tw``} />

                <HiddenItem title="Dar de baja Equipo" onPress={showDeleteModal} titleStyle={tw`text-red`} />
                
                {
                  Platform.OS === "web" &&
                  <>
                    <Divider />
                    <HiddenItem title="Poster bienvenida" onPress={createTeamPosterImage} titleStyle={tw``} />
                  </>
                }
              </>
            :
              <HiddenItem title="Reactivar equipo" onPress={confirmTeamReactivation} titleStyle={tw``} />
          }
        </OverflowMenu>
      </HeaderButtons>,
    })
  }, [leagueTeam]);

  function loadTeam() {
    dispatch(loadLeagueTeam(leagueUid, teamUid))
  }

  function showNameChangeModal() {
    setShowChageNameModal(true)
  }

  function showDeleteModal() {
    setShowDeleteTeamModal(true)
  }

  function createTeamPosterImage() {
    api.post(`/leagues/${leagueUid}/teams/${teamUid}/poster`, { })
      .then(({ data }) => {
        Linking.openURL(data.url)
      })
      .catch(({ error }) => {

      })
  }

  function confirmTeamReactivation(){
    if (Platform.OS !== "web") {
      Alert.alert(
        `¿Reactivar equipo?`,
        '',
        [
          {
            text: 'Reactivar',
            onPress: () => reactivateTeam(),
            style: 'cancel',
          },
          {
            text: 'Cancelar',
            onPress: () => { },
            // style: 'cancel',
          },
        ],
        {
          cancelable: true,
          onDismiss: () => { }
        }
      );
    } else {
      const result = confirm(`¿Reactivar equipo?`)
      if (result) reactivateTeam()
    }
  }

  function reactivateTeam(){
    const data = {
      
    }

    api.post(`/leagues/${leagueUid}/teams/${leagueTeam.team.uuid}/activate`, data)
      .then(({ data }) => {
        loadTeam();
      })
      .catch(({ response }) => {
        console.log(response);

        // setIsSubmitting(false)
      })
  }

  if (!leagueTeam) return <Loading visible={true} />


  return (
    <ScrollView style={tw`flex-1 px-3`} contentContainerStyle={tw`py-3`}>
      {/* Header equipo */}
      <TeamHeader leagueTeam={leagueTeam} style={{ container: tw`mb-5 mt-10` }} />

      {/* Pestanas */}
      <Tabs
        items={tabs}
        onChange={key => setSelectedTab(key)}
      />

      {
        selectedTab == "players" &&
          <TeamMembers leagueTeam={leagueTeam} leagueUid={leagueUid} onPlayerCreated={() => loadTeam()} />
      }

      {
        selectedTab == "matches" &&
          <TeamMatches leagueTeam={leagueTeam} leagueUid={leagueUid} />
      }

      {
        selectedTab == "payments" &&
          <TeamPayments leagueTeam={leagueTeam} leagueId={leagueUid} />
      }

      <DeleteTeamModal
        visible={showDeleteTeamModal}
        onRequestClose={() => setShowDeleteTeamModal(false)}
        leagueTeam={leagueTeam}
        leagueUid={leagueUid}
        onLeagueTeamDeleted={() => {
          navigation.navigate('Admin.League.Home', { leagueUid })
        }}
       
      />

      <ChangeTeamNameModal
        visible={showChageNameModal}
        onRequestClose={() => setShowChageNameModal(false)}
        leagueUid={leagueUid}
        leagueTeam={leagueTeam}
        onNameChanged={() => {
          loadTeam()
          setShowChageNameModal(false)
        }}
      />
    </ScrollView>
  )
}