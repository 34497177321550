import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TouchableOpacity, View } from "react-native";

import api from "../../../../utils/api";

import { Ionicons } from '@expo/vector-icons';
import tw from "../../../../styles/tailwind";

import Button from "../../../../Components/Button";
import Text from '../../../../Components/Text';
import Loading from "../../../../Components/Loading";
import AddPlayerBottomSheet from "../../Teams/Players/AddPlayerBottomSheet";
import CheckButton from "../../../../Components/CheckButton";


import { useToast } from "react-native-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import { loadLeagueTeam } from "../../../../store/Reducers/leagueTeams";
import FMIcons from "../../../../Components/FMIcon";
import MatchPlayerFeaturesModal from "../MarchDetailComponents/Match.PlayerFeaturesModal";
import { StatsPerPlayer } from "./ArbitrationCertificateStep2";
import deburr from 'lodash/deburr';




// Componente selector de jugadores
// esto no era un componente que se usaba en otro lado?
function PlayersSelector({ players, selected, style, onChange, onPlayerRemoved, onPlayerStatsChanged, statsPerPlayer }:{
  players: Array<Player>,
  selected: Array<string>,
  onChange: (players: Array<string>) => void,
  style? : any,
}) {
  const [selectedPlayers, setSelectedPlayers] = useState<Array<string>>(selected);
  // modal stats
  const [openPlayerStats, setOpenPlayerStats] = useState<Player | null>();

  

  useEffect(() => {
    setSelectedPlayers(selected)
    console.log("defining selected", selected);
    
  }, [selected]);


  function onPlayerSelected(player: Player) {
    let selected;

    const optionIndex = selectedPlayers.findIndex(playerId => playerId == player.uuid)

    if (optionIndex != -1) {
      onPlayerRemoved(player)
      selected = selectedPlayers.filter(playerId => playerId != player.uuid)
    } else {
      selected = [...selectedPlayers, player.uuid]
    }

    setSelectedPlayers(selected)
    onChange && onChange(selected)
  }

  function openPlayerStatsSelector(player:Player){
    setOpenPlayerStats(player)
  }

  if(!players) return null

  const _players = players.sort((pA, pB) => deburr(pA.name).localeCompare(deburr(pB.name)))


  return (
    <View style={style?.container}>
      {
        selected.map(playerId => {
          const player: Player = players.find(el => el.uuid === playerId)

          if(!player) return null

          const playerStats = statsPerPlayer[player.uuid]

          return <View style={tw``} key={player.uuid}>
            <TouchableOpacity
              style={[tw`border border-gray-300 px-3 rounded-xl mb-3 flex-row items-stretch bg-[#333333] border-background`]}
              onPress={() => 
                // onPlayerStatsPressed(player) 
                openPlayerStatsSelector(player)
              }
            >
              <View style={tw`py-3 row`}>
                {
                  player.squad_number && <Text style={tw`mr-2 text-gray text-md w-[7] text-center`}>{player.squad_number}</Text>
                }

                <View style={tw`border-l border-gray-300 h-4 mr-2`} />

                <Text style={tw`text-gray text-md`}>{player.name}</Text>
              </View>
              

              <View style={tw`flex-1 flex-row justify-end items-center`}>
                {
                  [...Array(playerStats?.goals || 0)].map((e, i) =>
                    <View key={i} style={tw`bg-green rounded-full h-2 w-2 mr-1`} />
                  )
                }
                {
                  [...Array(playerStats?.yellow_cards || 0)].map((e, i) =>
                    <View key={i} style={tw`bg-yellow rounded-full h-2 w-2 mr-1`} />
                  )
                }
                {
                  [...Array(playerStats?.red_cards || 0)].map((e, i) =>
                    <View key={i} style={tw`bg-red rounded-full h-2 w-2 mr-1`} />
                  )
                }

                {/* Botón derecho (check asistencia) */}
                <TouchableOpacity onPress={() => onPlayerSelected(player)} style={tw`px-2 ml-1 py-2 -mt-2 -mr-2 -mb-2`}>
                  <View style={tw`border-l border-gray-600 absolute left-0 -my-1 top-0 bottom-0`}></View>

                  {/* Check */}
                  <View style={tw`w-5 h-5 border border-gray-300 rounded`}></View>

                  <Ionicons name="checkmark" size={19} color="#39B54A" style={tw`absolute top-2 right-2`} />
                </TouchableOpacity>

              </View>

            </TouchableOpacity>
          </View>
        })
      }

      {
        _players.map(player => {
          const isSelected = !! selectedPlayers.find(playerId => playerId == player.uuid)

          if (isSelected) return null

          return <View style={tw``} key={player.uuid}>
            <TouchableOpacity
              style={[tw`border border-gray-300 px-3 rounded-xl mb-3 flex-row items-center`, isSelected && tw`bg-secondary border-background`]}
              onPress={() => {
                openPlayerStatsSelector(player)
              }}
            >
              {
                player.squad_number && <Text style={tw`mr-2 text-gray text-md w-[7] text-center`}>{player.squad_number}</Text>
              }

              <View style={tw`border-l border-gray-300 h-4 mr-2`} />

              <View style={tw`flex-1 flex-row justify-between items-center`}>
                <Text style={tw`text-gray text-md py-3`}>{player.name}</Text>


                {/* Botón derecho (check asistencia) */}
                <TouchableOpacity onPress={() => onPlayerSelected(player)} style={tw`px-2 py-2 -mt-2 -mr-2 -mb-2`}>
                  <View style={tw`border-l border-gray-600 absolute left-0 -my-1 top-0 bottom-0`}></View>

                  {/* check */}
                  <View style={tw`w-5 h-5 border border-gray-300 rounded`}></View>

                  {
                    isSelected &&
                    <Ionicons name="checkmark" size={19} color="#39B54A" />
                  }
                </TouchableOpacity>
              </View>

            </TouchableOpacity>
          </View>
        })
      }




      <MatchPlayerFeaturesModal
        visible={!!openPlayerStats}
        player={openPlayerStats}
        playerStats={statsPerPlayer[openPlayerStats?.uuid]}
        onRequestClose={() => {
          openPlayerStatsSelector(null)
        }}
        onSubmit={playerStats => {
          onPlayerStatsChanged(openPlayerStats?.uuid, playerStats)

          // Si el jugador no estaba seleccionado y se agregan estadísticas aquí lo agregamos
          if(playerStats.goals > 0 || playerStats.red_cards > 0 || playerStats.yellow_cards > 0){
            const optionIndex = selectedPlayers.findIndex(playerId => playerId == openPlayerStats?.uuid)

            if (optionIndex === -1) {
              onPlayerSelected(openPlayerStats)
            }
          }

          openPlayerStatsSelector(null)
        }}

      />
    </View>
  )
}


interface ArbitrationCertificateStep1Props{
  onSubmit: (selectedPlayers: Array<Player>) => void,
  onAssistanceChange: (selectedPlayers: Array<Player>, noShow: boolean) => void,
  match: Match,
  selectedTeam: 'home' | 'away',
  defaultingTeam: 'home' | 'away' | null,
  value: Array<Player>,
  onPreviousPressed: () => void,
  showPreviousBtn: boolean,
}


export default function ArbitrationCertificateStep1({
  match, selectedTeam, value = [], onSubmit, onPreviousPressed,
  showPreviousBtn, defaultingTeam, onAssistanceChange,
  onStatsChange, playersStats, goals
}: ArbitrationCertificateStep1Props) {
  const [selectedPlayers, setSelectedPlayers] = useState(value);
  const [showNewPlayerForm, setShowNewPlayerForm] = useState(false);
  
  const {control, watch, setValue} = useForm()
  const noShow = watch("no_show")
  const [_noShow, set_noShow] = useState();



  //

  useEffect(() => {
    setValue("no_show", defaultingTeam == selectedTeam)
    set_noShow(defaultingTeam == selectedTeam)
  }, [defaultingTeam, selectedTeam]);


  useEffect(() => {
    setSelectedPlayers(value)
  }, [value]);
  

  useEffect(() => {
    // Usamos un cache de noShow
    // porque el effect de arriba se dispara al montar y eso
    // hace que también se dispare onAssistanceChange() y eso borra 
    // la asistencia para un partido que se está editando
    if (noShow !== _noShow){

      // Logica 
      if (noShow){
        onAssistanceChange([], noShow)
        setSelectedPlayers([])
        // setStatsPerPlayer({})
        // onStatsChange({})
      }else{
        onAssistanceChange(selectedPlayers, noShow)
      }
      // /Logica

      set_noShow(noShow)
    }
  }, [noShow]);


  const team = selectedTeam == "home" ? match.home_team?.team : selectedTeam == "away" ? match.away_team?.team : null

  const toast = useToast();


  useEffect(() => {
    if(! team) return

    loadTeam()

    setValue("no_show", defaultingTeam == selectedTeam)
    
  }, [team?.uuid])



  // USE_HOOK - useLeagueTeam(leagueId, teamId)
  const dispatch = useDispatch()
  const leagueTeam = useSelector(state => state.entities.leagueTeams[`${match.league.uuid}-${team.uuid}`])
  const leagueTeamPlayers = leagueTeam?.members.filter(member => member.pivot.roles.indexOf('player') !== -1)


  function loadTeam(){
    dispatch(loadLeagueTeam(match.league.uuid, team.uuid))
  }

  function onPlayersChanged(players){
    setSelectedPlayers(players)

    onAssistanceChange(players, false)
  }


  const submitStep = () => {
    onSubmit(selectedPlayers, noShow)
  }

  
  //  Esto era parte del Step2
  const [statsPerPlayer, setStatsPerPlayer] = useState<StatsPerPlayer>(playersStats); // value

  useEffect(() => {
    setStatsPerPlayer(playersStats)
  }, [playersStats]);


  const reducer = (prev, cur) => prev + cur
  // const totalGoals = Object.keys(statsPerPlayer).map(playerId => statsPerPlayer[playerId].goals).reduce(reducer, 0)
  const totalGoals = goals
  const totalYellowCards = Object.keys(statsPerPlayer).map(playerId => statsPerPlayer[playerId].yellow_cards).reduce(reducer, 0)
  const totalRedCards = Object.keys(statsPerPlayer).map(playerId => statsPerPlayer[playerId].red_cards).reduce(reducer, 0)

  return (
    <View style={tw`flex-1 justify-between`}>
      <View style={tw`flex-row justify-between mb-4`}>
        <TouchableOpacity style={tw`flex-row items-center justify-center bg-secondary rounded-xl py-1 px-8`}>
          <FMIcons name={"gol-ced"} color={"#39B54A"} size={18} />
          <Text style={tw`text-lg text-green font-bold ml-2`}>{totalGoals}</Text>
        </TouchableOpacity>

        <View style={tw`flex-row items-center justify-center bg-secondary rounded-xl py-1 px-8`}>
          <FMIcons name={"tarjeta-ced"} color={"#efc42e"} size={18} />
          <Text style={tw`text-lg text-primary font-bold ml-2`}>{totalYellowCards}</Text>
        </View>

        <View style={tw`flex-row items-center justify-center bg-secondary rounded-xl py-1 px-8`}>
          <FMIcons name={"tarjeta-ced"} color={"#FF365B"} size={18} />
          <Text style={tw`text-lg text-red font-bold ml-2`}>{totalRedCards}</Text>
        </View>
      </View>

      <Loading visible={! leagueTeam} />

      {/* Equipo no se presentó */}
      {
        leagueTeamPlayers &&
          <View style={tw`mt-2 mb-2 items-center`}>
            <CheckButton
              style={{ label: tw`text-red-200`, container: tw`border-red-400 mb-5 px-4 rounded-xl`, checkbox: tw`bg-transparent border-red-400` }}
              label={"Equipo no se presentó (default)"}
              control={control}
              name={"no_show"}
              activeColor={tw.color('red-400')}
            />
          </View>
      }

      {
        noShow == false &&
          <PlayersSelector
            players={leagueTeamPlayers}
            statsPerPlayer={statsPerPlayer}
            selected={selectedPlayers}
            onChange={onPlayersChanged}
            // onPlayerStatsPressed={player => ! defaultingTeam ? setSelectedPlayer(player) : null}
            onPlayerStatsChanged={(playerId, playerStats) => {
              const _statsPerPlayer = {
                ...statsPerPlayer,
                [playerId]: playerStats,
              }

              // console.log(JSON.stringify(_statsPerPlayer))
              setStatsPerPlayer(_statsPerPlayer)
              onStatsChange(_statsPerPlayer)
            }}
            onPlayerRemoved={(player:Player) => {
              const _statsPerPlayer = {
                ...statsPerPlayer,
              }

              delete _statsPerPlayer[player.uuid]

              setStatsPerPlayer(_statsPerPlayer)
              onStatsChange(_statsPerPlayer)
            }}
          />
      }


      {
        leagueTeam !== null && leagueTeamPlayers?.length === 0 &&
          <Text style={tw`text-gray-300 text-center mt-2`}>Aún no hay jugadores registrados en este equipo.</Text>
      }


      {
        (leagueTeamPlayers && noShow == false) && 
          <View style={tw`mt-5 items-center justify-around -mx-4 lg:row`}>
            {/* Agregar nuevo jugador (WIP) */}
            <TouchableOpacity
              style={tw`row border border-primary py-3 px-4 rounded-xl mb-5`}
              onPress={() => setShowNewPlayerForm(true)}>
              <Ionicons name="add" size={19} color={tw.color("primary")} />

              <Text style={tw`text-primary text-md ml-3`}>Agregar nuevo jugador</Text>
            </TouchableOpacity>
          </View>
      }


      {/* Bottom sheet nuevo jugador */}
      <AddPlayerBottomSheet
        minimal
        autofocus
        visible={showNewPlayerForm}
        selectedRole={"player"}
        onRequestClose={() => {
          setShowNewPlayerForm(false)
        }}
        gender={match.league.gender !== "mixed" ? match.league.gender : null}
        leagueUid={match.league.uuid}
        leagueTeam={leagueTeam}
        onPlayerCreated={(newPlayer) => {
          setShowNewPlayerForm(false)
          loadTeam()
          onPlayersChanged([...selectedPlayers, newPlayer.uuid])
          toast.show("Jugador creado", {type: "success", placement: "top"});
        }}
      />


      <View style={tw`w-full flex-row justify-between mt-5`}>
        {
          showPreviousBtn ?
            <Button
              style={{ container: tw`flex-row py-3 px-0 bg-secondary px-2`, label: tw`text-primary px-4`, iconstyle: tw`text-primary text-md` }}
              label={"Equipo Anterior"}
              icon={"chev-izq"}
              onPress={onPreviousPressed}
            />
          : <View></View>
        }

        <Button
          style={{ container: tw`py-3 px-14` }}
          label={"Siguiente"}
          onPress={submitStep}
          disabled={selectedPlayers?.length == 0 && noShow === false}
        />
      </View>


    </View>
  )
}