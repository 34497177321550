import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, Pressable, Image, Alert, Platform } from "react-native"


import Text from '../../../../Components/Text';
import tw from '../../../../styles/tailwind';
import FMIcons from "../../../../Components/FMIcon";
import PlayerPicture from '../../../../Components/Players/PlayerPicture';
import api from '../../../../utils/api';

export default function MatchEventDetailModal({ event, onRequestClose, match, reverseAssistance, onEventDeleted }) {
  if(!event) return null

  function onDeletePressed() {
    if (Platform.OS !== "web") {
      Alert.alert(
        `¿Eliminar este evento?`,
        'Esta acción no se puede deshacer.',
        [
          {
            text: 'Eliminar',
            onPress: () => deleteEvent(),
            style: 'cancel',
          },
          {
            text: 'Cancelar',
            onPress: () => { },
            // style: 'cancel',
          },
        ],
        {
          cancelable: true,
          onDismiss: () => { }
        }
      );
    } else {
      const result = confirm(`¿Eliminar este partido?\nEsta acción no se puede deshacer.`)
      if (result) deleteEvent()
    }
  }

  function deleteEvent() {
    const data = {
      reverse_assistance: reverseAssistance
    }

    api.delete(`/matches/${match.uuid}/events/${event.uuid}`, { params: data })
      .then(({ data }) => {
        onEventDeleted?.()
      })
      .catch(({ response }) => {
        console.log("el error", response?.data)

        if(response?.data.message){
          alert(response.data.message)
        }
      })
  }

  return (
    <View style={tw`flex-1 absolute right-0 top-0 left-0 bottom-0 items-center justify-center`}>

      <Pressable onPress={onRequestClose} style={tw`absolute right-0 top-0 left-0 bottom-0 bg-black/85`} />


      <View style={tw`bg-white rounded-3xl py-5 px-5`}>
        {
          event.type === "goal" &&
            <View style={tw`row justify-center mb-4`}>
              <FMIcons name={`gol`} size={40} color={'green'} />
              <Text style={tw.style({fontSize: 30}, `ml-3`)}>Gol</Text>
            </View>
        }

        {
          event.type === "yellow_card" &&
            <View style={tw`row justify-center mb-4`}>
              <FMIcons name={`tarjeta`} size={30} color={tw.color('primary')} />
              <Text style={tw.style({fontSize: 30}, `ml-3`)}>Tarjeta Amarilla</Text>
            </View>
        }

        {
          event.type === "red_card" &&
            <View style={tw`row justify-center mb-4`}>
              <FMIcons name={`tarjeta`} size={30} color={tw.color('red')} />
              <Text style={tw.style({fontSize: 30}, `ml-3`)}>Tarjeta Roja</Text>
            </View>
        }

        {
          event.type === "foul" &&
            <View style={tw`row justify-center mb-4`}>
              <FMIcons name={`tarjeta`} size={30} color={'gray'} />
              <Text style={tw.style({fontSize: 30}, `ml-3`)}>Falta</Text>
            </View>
        }



        <View style={tw`items-center`}>
          {
            event.player &&
            <View style={tw`items-center`}>
              <PlayerPicture player={event.player} />

              <Text style={tw.style(`text-center`, { fontSize: 30 })}>#{event.player.squad_number} | <Text style={tw`font-bold`}>{event.player.name}</Text></Text>
            </View>
          }

          <Text style={tw.style(`text-center`, { fontSize: 30 })}>{event.league_team.name}</Text>
        </View>


        <Text style={tw`mt-3 text-right text-red`} onPress={onDeletePressed}>Eliminar evento</Text>
      </View>

      <Text onPress={onRequestClose} style={tw`text-white absolute right-8 bottom-8 text-lg`}>Cerrar</Text>
    </View>
  )
}

