export default {
    PRIMARY_COLOR: '#fbdb05',
    FONT_SIZE_SMALL: 12,
    FONT_SIZE_MEDIUM: 14,
    FONT_SIZE_LARGE: 16,
    FONT_WEIGHT_LIGHT: '200',
    FONT_WEIGHT_MEDIUM: '500',
    FONT_WEIGHT_BOLD: '700',
    CONTAINER_PADDING: 20,
    
    COMPONENTS_BORDER_COLOR_ERROR: "red",
    COMPONENTS_BORDER_COLOR:'#ACADAD',

    TEXT_CARD_PRIMARY:'#000000',
    TEXT_CARD_SECONDARY:'#C3C3C3',


    TEXT_SECONDARY:'#fff',

    SECONDARY_COLOR:'#000000',
    
    BACKGROUND_COLOR: '#303030', // super viejo
    // #212121 este estaba chido
    BACKGROUND_COLOR: '#151515', // nuevo!
};