import isEqual from 'lodash/isEqual';
// import { EnqueuedAction } from 'react-native-offline/types';

/**
 * Finds and returns a similar thunk or action in the actionQueue.
 * Else undefined.
 * @param action
 * @param actionQueue
 */
export default function getSimilarActionInQueue(
  action: any,
  actionQueue: any,// EnqueuedAction[],
) {
  if (typeof action === 'object') {
    return actionQueue.find(queued => isEqual(queued, action));
  }
  if (typeof action === 'function') {
    // console.log("\n---------START");
    // console.log("action.toString()")
    // console.log(action.toString())
    // console.log("action", action)
    // console.log("Meta", action.meta)
    // console.log("---------END\n\n");
    return actionQueue.find(queued => action.meta?.removeSimilar !== false && action.toString() === queued.toString());
  }
  return undefined;
}