import React, { useState, useEffect } from 'react';
import { View, Pressable, Modal, Image, ScrollView } from "react-native"


import tw from '../../styles/tailwind';
import Text from '../../Components/Text';
import { SafeAreaView } from 'react-native-safe-area-context';
import LottieView from 'lottie-react-native';


export default function GamifyBaseModal({ onClose, children, bgColor, bg }) {
  function dismissModal() {
    console.log("dismisse");
    onClose?.()
  }


  return (
    <Modal
      statusBarTranslucent={true}
      animationType={"fade"}
      onRequestClose={() => {

      }}
    >
      <SafeAreaView style={tw`bg-black flex-1`}>
        {
          bg
        }

        {/* Color fondo -> Imagen de fondo (red) -> rayos de luz (lottie) */}
        {/* bg-[#724cc3] bg-primary bg-[#d8bb04] */}
        <View style={tw.style(`absolute top-0 bottom-0 left-0 right-0`, bgColor && `bg-[${bgColor}]`)}>
          <Image
            source={require('./assets/bg_panal.png')}
            style={tw`h-full w-full`}
            resizeMode="cover"
          />

          <LottieView
            source={require('./assets/luz.json')}
            autoPlay
            loop
            style={tw`w-full absolute top-0 left-0`}
            speed={1}
          />
        </View>

        <Pressable onPress={dismissModal} style={tw`flex-1`}>
          <ScrollView style={tw`flex-1`} contentContainerStyle={tw.style(`px-10`, {flexGrow: 1})}>
            {/* Contenido */}
            {children}
          </ScrollView>
        </Pressable>
      </SafeAreaView>
    </Modal>
  )
}

