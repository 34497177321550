import React, { useState, useEffect, useMemo } from 'react';
import { Button, StyleSheet, TextInput, View, TouchableOpacity, ScrollView, useWindowDimensions } from "react-native";
import { StatusBar } from "expo-status-bar";


import tw from '../styles/tailwind'
import FMIcons from '../Components/FMIcon';
import Text from '../Components/Text'


import { TabView, SceneMap } from 'react-native-tab-view';

interface TabScreensProps{
  children: React.ReactElement[],
  onIndexChange?: (index: number) => void,
  initialTabIndex?: number,
}


export default function TabScreens(props: TabScreensProps) {
  const layout = useWindowDimensions();
  const [index, setIndex] = useState(props.initialTabIndex || 0);

  const {onIndexChange} = props


  const renderScenes = useMemo(() => {
    const scenes = {}
    props.children.forEach(child => {
      scenes[child.props.tabTitle] = () => child
    })

    return scenes
  }, []);

  const routes = useMemo(() =>
    props.children.map(child => {
      return {
        key: child.props.tabTitle,
        title: child.props.tabTitle,
        icon: child.props.tabIcon
      }
    })
  , [props.children]);


  const renderScene = SceneMap(renderScenes);


  const _renderTabBar = (props) => {
    return (
      <View style={tw.style(`flex-row flex-wrap items-center py-4`, { justifyContent: "center" })}>
        <ScrollView horizontal showsHorizontalScrollIndicator={false}>
          {props.navigationState.routes.map((route, i) => {
            const selected = index === i

            return (
              <TouchableOpacity
                key={i}
                // style={tw`flex-1`}
                style={tw.style(`flex-col items-center justify-center rounded-xl mx-2 w-[22] h-[20]`, selected && `bg-gray-500`)}
                onPress={() => {
                  setIndex(i)
                  onIndexChange && onIndexChange(i)
                }}
              >
                <FMIcons name={`${route.icon}`} size={23} style={tw.style(`mb-2 text-gray-300`, selected && `text-primary`)} />

                <Text
                  style={tw.style(`text-sm text-gray-300`, selected && `text-primary`)}
                >{route.title}</Text>
              </TouchableOpacity>
            );
          })}
        </ScrollView>
      </View>
    );
  }

  return (
    <View style={tw`flex-1`}>
      <TabView
        lazy
        swipeEnabled={false}
        navigationState={{ index, routes }}
        renderScene={renderScene}
        onIndexChange={index => {
          setIndex(index)
          onIndexChange && onIndexChange(index)
        }}
        initialLayout={{ width: layout.width }}
        renderTabBar={_renderTabBar}
      />
    </View>
  );
}
