import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, Pressable, Image, Alert, Platform } from "react-native"


import Text from '../../../../Components/Text';
import tw from '../../../../styles/tailwind';
import FMIcons from "../../../../Components/FMIcon";
import api from '../../../../utils/api';
import Button from '../../../../Components/Button';
import { useForm } from 'react-hook-form';
import {Ionicons} from "@expo/vector-icons"
import TabletModal from './TabletModal';
import * as Camera from 'expo-camera';
import { useRef } from 'react';

export default function PlayerTakePictureModal({ player, leagueId, onRequestClose, visible, onImageChanged }) {
  // const [errorMessage, setErrorMessage] = useState();
  
  const [uploadPercentage, setUploadPercentage] = useState(null);
  const [selectedImage, setSelectedImage] = useState();
  const [uploading, setUploading] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [permission, requestPermission] = Camera ? Camera.Camera?.useCameraPermissions() : useState();
  const cameraRef = useRef()


  useEffect(() => {
    // setValue("squad_number", "")
    // setShowForm(true)
  }, [player]);

  // if (!player) return null

  if (permission && !permission.granted) {
    console.log("no permisos, solicitando", permission);

    requestCameraPermissions()
  }

 
  function changePlayerImage(uploadedImage) {
    const data = {
      image_uuid: uploadedImage.uuid,
    }

    
    api.post(`leagues/${leagueId}/players/${player.uuid}`, data)
      .then(({ data }) => {
        onImageChanged?.(data.image_url)
        setUploading(false)
      })
      .catch(({ response }) => {
        console.log("el error", response?.data)
        if (response.data.message) {
          setErrorMessage(response.data.message)
        }
      })
  }

  async function requestCameraPermissions(){
    const pp = await Camera.requestCameraPermissionsAsync()

    // console.log("getCameraPermissions", pp);
  }



  async function takePicture(){
    await cameraRef.current?.resumePreview()
    const picture = await cameraRef.current?.takePictureAsync({
      base64: true,
    })

    if(picture){
      // console.log(picture);
      setSelectedImage(picture);
      setUploadPercentage(0);

      const serverImage = await uploadImage(picture);
    }

  }



  const fetchImageFromUri = async (uri) => {
    const response = await fetch(uri);
    const blob = await response.blob();
    return blob;
  };

  
  const uploadImage = async (pickerResult) => {
    const formData = new FormData();

    if (Platform.OS === "web") {
      const image = await fetchImageFromUri(pickerResult.uri)

      formData.append('file', image, 'image.jpg');
    } else {
      const localUri = Platform.OS === 'ios' ? pickerResult.uri.replace('file://', '') : pickerResult.uri
      const filename = localUri.split('/').pop();

      // Infer the type of the image
      const match = /\.(\w+)$/.exec(filename);
      const type = match ? `image/${match[1]}` : `image/jpeg`;

      formData.append('file', {
        name: "image.jpg",
        type: type,
        uri: localUri,
      });
    }

    formData.append('target', 'Player');

    const config = {
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        // console.log("uploadpercent", percentCompleted);
        
        setUploadPercentage(percentCompleted)
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
      timeout: 30000,
    }


    setUploading(true)

    return api.post("/images/upload", formData, config)
      .then((response) => {
        // console.log("Upload succeded", response.data);
        
        setImageUrl(response.data.url)
        // setUploading(false)
        setUploadPercentage(0)
        setSelectedImage(null)
        changePlayerImage(response.data)


        return response.data;
      })
      .catch((error) => {
        console.log("Upload error", error);
        setUploading(false)
      });
  };


  return (
    <TabletModal visible={visible} onRequestClose={onRequestClose} showCloseButton={true}>
      <View style={tw`items-center`}>
        {
          ! Camera &&
            <Text>Cámara no disponible</Text>
        }
        
        {
          (Camera && ! uploading) &&
            <Camera.Camera
              style={tw`w-[500px] h-[500px]`}
              type={Camera.CameraType.back}
              ref={cameraRef}
              ratio={"1:1"}
              // pictureSize={"1500x1500"}
            >
                <TouchableOpacity
                  style={tw`absolute right-2 w-14 h-14 bottom-2 rounded-full items-center justify-center border border-white`}
                  onPress={takePicture}
                >
                  <Ionicons name="camera-outline" size={24} style={tw`text-white`} />
                </TouchableOpacity>
            </Camera.Camera>
        }

        {/* ({uploadPercentage}%) */}
        {
          uploading &&
            <Text style={tw`text-center text-xl py-5`}>Actualizando fotografía...</Text>
        }
      </View>
    </TabletModal>
  )
}

