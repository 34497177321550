import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity } from "react-native"


import tw from '../../../../styles/tailwind';
import Text from '../../../../Components/Text';
import BottomSheet from '../../../../Components/BottomSheet';
import Loading from '../../../../Components/Loading';


import { Ionicons } from "@expo/vector-icons"
import FMIcons from "../../../../Components/FMIcon";
import api from '../../../../utils/api';
import { useSelector } from 'react-redux';
import useRemoteControl, {controlSignals} from './useRemoteControl';




export default function FMRemoteBottomSheet({ visible, fieldId, onRequestClose, elapsedTime, periodDuration }) {
  const { isLoading, sendSignal } = useRemoteControl(fieldId)


  // const totalSeconds = periodDuration - elapsedTime
  // const limitMinutes = Math.floor(totalSeconds / 60)
  // const limitSeconds = totalSeconds % 60
  // const limitStr = `${limitMinutes > 9 ? limitMinutes : `0${limitMinutes}`}:${limitSeconds > 9 ? limitSeconds : `0${limitSeconds}`}`


  return (
    <BottomSheet
      visible={visible}
      onRequestClose={() => {
        onRequestClose && onRequestClose()
      }}
      height={250}
      style={{ container: tw`bg-gray rounded-tl-xl rounded-tr-xl` }}
    >
      <View style={tw`row justify-around`}>
        {/* Local */}
        <View style={tw`items-center`}>
          <Text style={tw`font-bold text-xl`}>Local</Text>
          
          <TouchableOpacity onPress={() => sendSignal(controlSignals.local_up)} style={tw``}>
            <Ionicons name='add-circle-outline' color={"#000"} size={70} />
          </TouchableOpacity>


          <TouchableOpacity onPress={() => sendSignal(controlSignals.local_down)} style={tw``}>
            <Ionicons name='remove-circle-outline' color={"#000"} size={70} />
          </TouchableOpacity>


          <View style={tw`row`}>
            <TouchableOpacity onPress={() => sendSignal(controlSignals.local_foul)} style={tw`border-4 rounded-full h-14 w-14 items-center justify-center mr-2`}>
              <Text style={{fontSize: 30, fontWeight: 'bold'}}>F+</Text>
            </TouchableOpacity>

            <TouchableOpacity onPress={() => sendSignal(controlSignals.local_foul_down)} style={tw`border-4 rounded-full h-14 w-14 items-center justify-center`}>
              <Text style={{fontSize: 30, fontWeight: 'bold'}}>F-</Text>
            </TouchableOpacity>
          </View>
        </View>

        {/*  */}
        <View style={tw`items-center`}>
          {/* <Text style={tw`font-bold text-xl`}>Tablero</Text> */}

          <TouchableOpacity onPress={() => sendSignal(controlSignals.start)} style={tw``}>
            <Ionicons name='caret-forward-circle-outline' color={"#000"} size={70} />
          </TouchableOpacity>

          <TouchableOpacity onPress={() => sendSignal(controlSignals.program)} style={tw`border-4 rounded-full h-14 w-14 items-center justify-center`}>
            <Text style={{ fontSize: 30, fontWeight: 'bold' }}>P</Text>
          </TouchableOpacity>

          <View style={tw`row`}>
            <TouchableOpacity onPress={() => sendSignal(controlSignals.horn)} style={tw`border-4 mt-3 rounded-full h-14 w-14 items-center justify-center mr-2`}>
              <Ionicons name='volume-medium' color={"#000"} size={30} />
            </TouchableOpacity>

            <TouchableOpacity onPress={() => sendSignal(controlSignals.reset_board)} style={tw`border-4 mt-3 rounded-full h-14 w-14 items-center justify-center`}>
              <Ionicons name='refresh-outline' color={"#000"} size={30} />
            </TouchableOpacity>
          </View>

        </View>


        {/* Visitante */}
        <View style={tw`items-center`}>
          <Text style={tw`font-bold text-xl`}>Visitante</Text>

          <TouchableOpacity onPress={() => sendSignal(controlSignals.visit_up)} style={tw``}>
            <Ionicons name='add-circle-outline' color={"#000"} size={70} />
          </TouchableOpacity>


          <TouchableOpacity onPress={() => sendSignal(controlSignals.visit_down)} style={tw``}>
            <Ionicons name='remove-circle-outline' color={"#000"} size={70} />
          </TouchableOpacity>


          <View style={tw`row`}>
            <TouchableOpacity onPress={() => sendSignal(controlSignals.visit_foul)} style={tw`border-4 rounded-full h-14 w-14 items-center justify-center mr-2`}>
              <Text style={{ fontSize: 30, fontWeight: 'bold' }}>F+</Text>
            </TouchableOpacity>

            <TouchableOpacity onPress={() => sendSignal(controlSignals.visit_foul_down)} style={tw`border-4 rounded-full h-14 w-14 items-center justify-center`}>
              <Text style={{ fontSize: 30, fontWeight: 'bold' }}>F-</Text>
            </TouchableOpacity>
          </View>
        </View>


        {/* Crono */}
        <View style={tw`items-center`}>
          {/* <Text style={tw`font-bold text-xl`}>Visitante</Text> */}
          {/* <Text>{elapsedTime}</Text> */}
          <Text>Tiempo</Text>

          <TouchableOpacity onPress={() => sendSignal(controlSignals.add_minute)} style={tw``}>
            <Ionicons name='chevron-up-circle-outline' color={"#000"} size={70} />
          </TouchableOpacity>

          <TouchableOpacity onPress={() => sendSignal(controlSignals.substract_minute)} style={tw``}>
            <Ionicons name='chevron-down-circle-outline' color={"#000"} size={70} />
          </TouchableOpacity>
        </View>

        <Loading visible={isLoading} style={tw`absolute right-4`} />
      </View>
    </BottomSheet>
  )
}

