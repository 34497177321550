import React, { useState, useEffect } from 'react';
import { View, Pressable, TouchableOpacity, Modal as ReactModal } from "react-native"
import FMIcons from '../../../../Components/FMIcon';


import tw from '../../../../styles/tailwind';


export default function TabletModal({ children, onRequestClose, visible, showCloseButton, ...props }) {
  if (!visible) return null

  return (
    <ReactModal
      hardwareAccelerated
      statusBarTranslucent={true}
      transparent
      onRequestClose={onRequestClose}
      {...props}
    >
      <View style={tw`flex-1 absolute right-0 top-0 left-0 bottom-0 items-center justify-center`}>
        <Pressable onPress={onRequestClose} style={tw`absolute right-0 top-0 left-0 bottom-0 bg-black/85`} />

        <View style={tw.style(`bg-white rounded-3xl py-8 px-8`, props.style?.wrapper)}>
          {
            showCloseButton &&
              <TouchableOpacity onPress={onRequestClose} style={tw`absolute right-3 top-3 w-9 h-9 items-center justify-center`}>
                <FMIcons name={"cerrar"} size={14} />
              </TouchableOpacity>
          }
          {children}
        </View>
      </View>
    </ReactModal>
  )
}