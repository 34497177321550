import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity } from "react-native"


import tw from '../../../../styles/tailwind';
import Text from '../../../../Components/Text';
import FMIcons from "../../../../Components/FMIcon";
import TeamBadge from '../../../../Components/Teams/TeamBadge';


function EventIcon({event, style}){
  switch(event.type){
    case "goal":
      return  <FMIcons style={style} name={`gol`} size={20} color={'green'} />

    case "yellow_card":
      return  <FMIcons style={style} name={`tarjeta`} size={20} color={'yellow'} />

    case "red_card":
      return  <FMIcons style={style} name={`tarjeta`} size={20} color={'red'} />

    default:
      return null
      return <FMIcons style={style} name={`posicion`} size={20} color={'#fff'} />
  }
}

function TimeText({seconds, style, padMinutes=false}){
  const limitMinutes = Math.floor(seconds / 60)
  const limitSeconds = seconds % 60
  const timeStr = `${limitMinutes > 9 || ! padMinutes ? limitMinutes : `0${limitMinutes}`}:${limitSeconds > 9 ? limitSeconds : `0${limitSeconds}`}`

  return <Text style={tw.style(style)}>{timeStr}</Text>
}


function Timeline({ events, match, periodDuration, periodsCount, currentTime = 0, onEventPress, currentPeriod, reverseAssistance } : {match: Match}) {
  const matchLength = periodDuration * periodsCount
  const currentLeft = (currentTime / (matchLength * 60)) * 100

  function renderVerticalSeparators(){
    const separators = []

    for(let i=1; i < periodsCount; i++){
      separators.push(
        <View
          key={i}
          style={tw`w-[5px] bg-[#2f2f2f] -ml-[2.5px] absolute left-${(100 / periodsCount) * i}% top-2 bottom-2 rounded-full`}
        />
      )
    }

    return separators
  }

  function renderMinutesIndicators(){
    const indicators = []

    for(let i=1; i <= periodsCount; i++){
      indicators.push(
        <Text
          style={tw`w-8 text-gray-300 text-right text-lg -ml-[40px] absolute left-${(100 / periodsCount) * i}% top-0 bottom-0`}
        >{periodDuration}'</Text>
      )
    }

    return indicators
  }

  return (
    <View style={tw.style(`flex-1`)}>
      <View style={tw` h-25 mr-5 ml-5`}>

        {/* Raya medio tiempo */}
        {
          renderVerticalSeparators()
        }

        {/* Raya media horizontal */}
        <View style={tw`h-[2px] bg-[#2f2f2f] absolute top-50% left-0 right-0`}></View>

        {/* Raya elapsed y bolita elapsed*/}
        <View style={tw`h-[2px] bg-[#716967] absolute top-50% left-0 w-${currentLeft < 100 ? currentLeft : 100}%`}></View>
        <View style={tw`rounded-full h-[7px] w-[7px] bg-[#716967] absolute top-50% -mt-[3px] -mx-[3px] left-${currentLeft < 100 ? currentLeft : 100}%`}></View>


        {/* Minutos */}
        {
          renderMinutesIndicators()
        }


        {
          events.map(event => {
            const left = (event.time / (matchLength * 60)) * 100
            const isInCurrentPeriod = event.period == currentPeriod

            return (
              <TouchableOpacity
                key={event.id}
                onPress={() => onEventPress?.(event)}
                style={tw.style(
                  `absolute top-50% -mt-3 -ml-[10px] left-${left}%`,
                  (event.league_team?.uuid == match.home_team?.uuid) ? (! reverseAssistance ? `-mt-8` : `mt-3`) : ``,
                  (event.league_team?.uuid == match.away_team?.uuid) ? (!reverseAssistance ? `mt-3` : `-mt-8`) : ``,
                )}
              >
                {
                  (event.type == "yellow_card" && isInCurrentPeriod && match.settings?.yellow_card_timeout && match.settings?.yellow_card_timeout > (currentTime - event.time) ) && // y que config traiga
                    <TimeText style={tw`absolute text-white w-10 -bottom-3 -left-1`} seconds={match.settings?.yellow_card_timeout - (currentTime - event.time)} />
                }
                {
                  (event.type == "red_card" && isInCurrentPeriod && match.settings?.red_card_timeout && match.settings?.red_card_timeout > (currentTime - event.time) ) && // y que config traiga
                    <TimeText style={tw`absolute text-white w-10 -bottom-3 -left-1`} seconds={match.settings?.red_card_timeout - (currentTime - event.time)} />
                }
                <EventIcon
                  event={event}
                />
              </TouchableOpacity>
            )
          }
            
          )
        }

        {/*         
        <EventIcon event={{ type: "test" }} style={tw`absolute top-50% -mt-[10px] -ml-[10px] left-00%`} />
        <EventIcon event={{ type: "test" }} style={tw`absolute top-50% -mt-[10px] -ml-[10px] left-10%`} />
        <EventIcon event={{ type: "test" }} style={tw`absolute top-50% -mt-8 -ml-[10px] left-20%`} />
        <EventIcon event={{ type: "test" }} style={tw`absolute top-50% -mt-[10px] -ml-[10px] left-40%`} />
        <EventIcon event={{ type: "test" }} style={tw`absolute top-50% mt-3 -ml-[10px] left-60%`} />
        <EventIcon event={{ type: "test" }} style={tw`absolute top-50% -mt-[10px] -ml-[10px] left-80%`} />
        <EventIcon event={{ type: "test" }} style={tw`absolute top-50% -mt-[10px] -ml-[10px] left-100%`} />
       */}
      </View>
    </View>
  )
}

export default function MatchTimeline({ 
  events, match, currentTime, onEventPress,
  periodDuration, periodsCount, currentPeriod, reverseAssistance,
  ...props 
} : { match: Match }){

  return (
    <View style={tw`bg-[#212121] row pt-3`}>
      {/* Equipos */}
      <View style={tw.style(`ml-4 w-1/6`, reverseAssistance && {flexDirection : 'column-reverse'})}>
        {/* Home Team*/}
        <View style={tw`row py-2`}>
          <TeamBadge team={match.home_team.team} width={30} height={30} />
          <Text style={tw`text-white text-lg ml-3`}>{match.home_team.name}</Text>
        </View>

        <View style={tw`border-b border-[#2f2f2f]`}/>

        {/* Away team */}
        <View style={tw`row py-2`}>
          <TeamBadge team={match.away_team.team} width={30} height={30} />
          <Text style={tw`text-white text-lg ml-3`}>{match.away_team.name}</Text>
        </View>
      </View>

      <Timeline
        events={events}
        reverseAssistance={reverseAssistance}
        match={match}
        currentPeriod={currentPeriod}
        periodDuration={periodDuration}
        periodsCount={periodsCount}
        currentTime={currentTime}
        onEventPress={onEventPress}
      />
    </View>
  )
}
