import React from "react";
import { View, TouchableOpacity } from "react-native";


import tw from '../../../../styles/tailwind';
import Text from '../../../../Components/Text';
import { Ionicons } from "@expo/vector-icons"


export default function BottomButton({ color = "gray", onPress, icon, text, label, style, disabled }) {
  return (
    <TouchableOpacity
      style={tw.style(`bg-[${color}] rounded-lg px-5 py-4 items-center`, disabled && `opacity-50`, style?.container)}
      onPress={onPress}
      disabled={disabled}
    >
      <View style={tw`row`}>
        <Text style={tw.style(`text-white text-xl -mt-1 text-center`, { fontSize: 30 }, style?.text)}>{text}</Text>

        {
          icon &&
            typeof icons == "string" ?
            <Ionicons name={icon} size={40} color={"white"} style={tw`ml-4`} />
            :
            icon
        }
      </View>


      {
        (label) &&
        <Text style={tw`font-bold text-white text-center text-lg -mt-1`}>{label}</Text>
      }
    </TouchableOpacity>
  )
}