import React, { useEffect, useState } from "react";
import { Image, ScrollView, TouchableOpacity, View } from "react-native";

import tw from "../../styles/tailwind";

import Text from "../../Components/Text";
import FMIcons from "../../Components/FMIcon";
import TeamBadge from "../../Components/Teams/TeamBadge";


import { getHourStr, dayOfWeekStr, monthStr } from "../../utils/momento";
import { useDispatch, useSelector } from "react-redux";
import api from "../../utils/api";
import Loading from "../../Components/Loading";

import MatchFeaturesCard from "../../Components/Matches/MatchFeaturesCard";
import MatchTeams from "../Admin/Matches/MarchDetailComponents/MatchTeams";
import { loadMatch } from "../../store/Reducers/matches";
import MatchAchievementsSummary from "./MatchAchievementsSummary";
import MatchTimeline from "./MatchTimeline";



//! TODO: Abstraer un montón de cosas, esto es bien parecido a /Admin/Matches/MatchDetail


function MatchDateButton({ date, played }) {
  const createdAt = date ? new Date(date) : null
  const hourStr = getHourStr(createdAt)
  const dateStr = createdAt ? `${dayOfWeekStr(createdAt)}, ${createdAt?.getDate()} ${monthStr(createdAt)}` : null

  return (
    <View style={tw`flex-row items-center bg-gray-500 px-5 py-3 rounded-xl justify-between mb-4`}>
      {/* Fecha */}
      <Text style={tw`text-md text-gray`}>{dateStr}</Text>

      {/* Hora e ícono */}
      <View style={tw`flex-row items-center`}>
        {played && <FMIcons name={"progreso-b"} size={15} style={tw`text-primary`} />}

        <Text style={tw`text-md text-primary ml-2 mr-2`}>{createdAt ? hourStr : "Sin Fecha"}</Text>
      </View>
    </View>
  )
}


export function MatchNavBarTitle({ tintColor, match, children }){
  return (
    <Text style={tw.style(`text-lg`, { color: tintColor })}
    >
      <Text>{children}</Text>

      {
        match?.status == "played" &&
          <Text style={tw`text-lg text-green`}> - Jugado</Text>
      }

      {
        match?.status == "created" &&
          <Text style={tw`text-lg`}> - Por jugarse</Text>
      }

      {
        match?.status == "canceled" &&
          <Text style={tw`text-lg text-red`}> - Cancelado</Text>
      }

      {
        (match?.status != "played" && match?.status != "created" && match?.status != "canceled" ) &&
          <Text style={tw`text-lg`}> - {match?.short_status_str}</Text>
      }
    </Text>
  )
}




export default function MatchDetailScreen({ navigation, route }) {
  const matchId = route.params?.matchId
  const match: Match = useSelector(state => state.entities.matches[matchId])

  const dispatch = useDispatch()

  const played = match?.status == "played" || match?.status == "awarded"
  const rateToFace = rate => rate == 1 ? "feliz" : rate == 0 ? "neutro" : "disgustado"


  useEffect(() => {
    dispatch(loadMatch(matchId))

    loadMatchStats()
    loadMatchEvents()
  }, [matchId]);

  useEffect(() => {
    navigation.setOptions({
      title: match?.round ? match.round.name : '',
      headerShown: true,
      headerStyle: {},
      headerTransparent: true,
      headerTintColor: 'white',
      headerShadowVisible: false,
      headerTitle: ({ tintColor, children }) => {
        return (
          <MatchNavBarTitle match={match} tintColor={tintColor} children={children} />
        )
      },
    })

  }, [match]);




  const [matchEvents, setMatchEvents] = useState([]);
  const [matchStats, setMatchStats] = useState();

  function loadMatchStats() {
    api.get(`/matches/${matchId}/stats`)
      .then(({ data }) => {
        setMatchStats(data)
      })
      .catch((error) => {
        console.log("yepo!", error);
      })
  }


  async function loadMatchEvents() {
    try {
      const response = await api.get(`/matches/${matchId}/events`)
      setMatchEvents(response.data)
    } catch (error) {
      console.log("loadMatchEvents error", JSON.stringify(error.response?.data));
    }
  }


  if (!match) return <Loading visible={true} style={tw`mt-10`} />

  const playerLabel = player => `${player.squad_number} | ${player.name}`
  const homeGoalsPlayers = matchStats?.home?.player_stats.filter(e => e.goals > 0).map(e => { return { count: e.goals, label: playerLabel(e.player) } })
  const awayGoalsPlayers = matchStats?.away?.player_stats.filter(e => e.goals > 0).map(e => { return { count: e.goals, label: playerLabel(e.player) } })
  const homeYellowCards = matchStats?.home?.player_stats.filter(e => e.yellow_cards > 0).map(e => { return { label: playerLabel(e.player) } })
  const awayYellowCards = matchStats?.away?.player_stats.filter(e => e.yellow_cards > 0).map(e => { return { label: playerLabel(e.player) } })
  const homeRedCards = matchStats?.home?.player_stats.filter(e => e.red_cards > 0).map(e => { return { label: playerLabel(e.player) } })
  const awayRedCards = matchStats?.away?.player_stats.filter(e => e.red_cards > 0).map(e => { return { label: playerLabel(e.player) } })

  

  return (
    <ScrollView style={tw`flex-1 bg-background`}>
      {/* Imagen fondo */}
      <View style={tw`absolute top-0 left-0 right-0`}>
        <Image
          source={require('../../assets/match-bg-deg.png')}
          style={tw`w-100 h-89 self-center`}
          resizeMode="cover"
        />
      </View>


      <View style={tw`flex-1 py-5 px-3 pt-[95px]`}>


        {/* Botón torneo */}
        <TouchableOpacity
          onPress={() => navigation.navigate('League.Home', { leagueId: match.league.uuid })}
          style={tw`flex-row items-center justify-between text-white bg-gray-500 rounded-xl pl-1 pr-2 py-1 bg-opacity-80`}
        >
          <View style={tw`flex-row items-center`}>
            <Image source={{ uri: match.league.image_url }} style={tw`h-10 w-10 mr-3 rounded-xl`} />

            <Text style={tw`text-white`}>{match.league.name}</Text>
          </View>

          <FMIcons name={"chev-der"} size={12} style={tw`text-primary`} />
        </TouchableOpacity>



        {/*  */}
        <View style={tw.style(`flex-1 rounded-xl mb-2`)}>
          {/* Equipos y VS */}
          <MatchTeams
            match={match}
            style={tw``}
            played={played}
            onTeamPressed={slot => {
              navigation.navigate("LeagueTeam.Home", {
                leagueId: match.league.uuid,
                teamId: slot == "home" ? match.home_team?.team.uuid : match.away_team?.team.uuid,
                // leagueTeamId: slot == "home" ? match.home_team?.uuid : match.away_team?.uuid,
              })
            }}
          />
        </View>


        <MatchDateButton
          played={played}
          date={match.date}
        />

        {/* cancha donde se juega */}
        {
          match.field &&
            <View style={tw`flex-row items-center bg-gray-500 px-5 py-3 rounded-xl justify-between mb-4`}>
              <Text style={tw`text-md text-gray`}>{match.field.name}</Text>
            </View>
        }


        {/* Detalles del partido */}
        {
          played &&
          <View>
            <MatchFeaturesCard
              match={match}
              leftValue={match.home_score}
              rightValue={match.away_score}
              iconLabel={"Goles"}
              iconName={"gol"}
              iconColor={"green"}
              style={{ container: tw`mb-3` }}
              homeStats={homeGoalsPlayers}
              awayStats={awayGoalsPlayers}
            />

            <MatchFeaturesCard
              match={match}
              leftValue={match.stats?.home?.yellow_cards}
              rightValue={match.stats?.away?.yellow_cards}
              iconLabel={"Tarjeta A."}
              iconName={"tarjeta"}
              iconColor={"primary"}
              style={{ container: tw`mb-3` }}
              homeStats={homeYellowCards}
              awayStats={awayYellowCards}
            />

            <MatchFeaturesCard
              match={match}
              leftValue={match.stats?.home?.red_cards}
              rightValue={match.stats?.away?.red_cards}
              iconLabel={"Tarjeta R."}
              iconName={"tarjeta"}
              iconColor={"red"}
              style={{ container: tw`mb-3` }}
              homeStats={homeRedCards}
              awayStats={awayRedCards}
            />


            {/* {
                match.stats?.home?.review && 
                <View>
                  <MatchFeaturesCard
                    match={match}
                    leftValue={rateToFace(match.stats?.home?.review.puntuality)}
                    rightValue={rateToFace(match.stats?.away?.review.puntuality)}
                    iconLabel={"Puntualidad"}
                    iconName={"puntual"}
                    iconColor={"primary"}
                    style={{ container: tw`mb-3` }}
                  />

                  <MatchFeaturesCard
                    match={match}
                    leftValue={rateToFace(match.stats?.home.review.conduct)}
                    rightValue={rateToFace(match.stats?.away.review.conduct)}
                    iconLabel={"Conducta"}
                    iconName={"conducta"}
                    iconColor={"red"}
                    style={{ container: tw`mb-4` }}
                  />
                </View>
            } */}
          </View>
        }

        {
          matchEvents?.length > 0 &&
            <MatchTimeline matchEvents={matchEvents} match={match} />
        }

        
        {/* Comentarios de los árbitros */}
        {
          !!(match?.stats?.home?.review?.comments || match?.stats?.away?.review?.comments) &&
          <View style={tw`flex-col bg-gray-500 rounded-xl mb-4`}>
            <View style={tw`px-4 py-4`}>
                <View style={tw`flex-row items-center mt-3 mb-2`}>
                  <FMIcons name={'arbitro'} size={18} style={tw`text-primary mr-2`} />
                  <Text style={tw`text-gray`}>Comentarios</Text>
                </View>

                {
                  match.stats?.home?.review?.comments &&
                  <View style={tw`flex-row items-center -mx-2`}>
                    <TeamBadge team={match.home_team?.team} width={40} height={40} />
                    <Text style={tw`ml-3 text-gray-300`}>{match.stats?.home?.review?.comments}</Text>
                  </View>
                }
                {
                  match.stats?.away?.review?.comments &&
                  <View style={tw`flex-row items-center -mx-2`}>
                    <TeamBadge team={match.away_team?.team} width={40} height={40} />
                    <Text style={tw`ml-3 text-gray-300`}>{match.stats?.away?.review?.comments}</Text>
                  </View>
                }
              </View>
            </View>
        }
      </View>

      {/* route.params.showGamificationSummary */}
      {/* {
        route.params.showGamificationSummary && 
          <MatchAchievementsSummary
            match={match}
          />
      } */}
    </ScrollView>
  );
}