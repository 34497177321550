import { useState } from "react";
import { useDispatch } from "react-redux";
import { loadMatch } from "../../../../store/Reducers/matches";
import api from "../../../../utils/api";
import { differenceInSeconds } from "date-fns/esm"


export default function useMatchEvents(match, machineState, machineSend, reverseAssistance){
  const [events, setEvents] = useState([]);
  const dispatch = useDispatch()


  async function loadMatchEvents() {
    try {
      const response = await api.get(`/matches/${match.uuid}/events`)
      setEvents(response.data)

      return response.data
    } catch (error) {
      console.log("loadMatchEvents error", JSON.stringify(error.response?.data));
    }
  }


  async function _loadMatchEvents() {
    const events = await loadMatchEvents()


    const keyEventsNames = ['1st_half', '2nd_half', '1st_quarter', '2nd_quarter', '3rd_quarter', '4th_quarter', 'half_time', "match_started", "match_paused", "match_resumed", "period_finished"];
    const keyEvents = events.filter(ev => keyEventsNames.indexOf(ev.type) !== -1)
      // .sort((a,b) => a.time < b.time) // esto puede tener un bug, half_time = 1501, 2nd_half=1500
      .sort((a, b) => (new Date(a.created_at)) < (new Date(b.created_at)))


    if (keyEvents.length > 0) {
      const lastEvent = keyEvents[0]

      // let newIsRunning = false
      // let newLimit = -1
      let newMachineState = null
      let newMatchTime = -1
      let newPeriodTime = -1
      let newPeriod = -1

      console.log(`[MatchEvents] Last event [${lastEvent.type}] T:${lastEvent.time} ${lastEvent.created_at}`);

      if (lastEvent.type === "match_paused") {
        newMatchTime = lastEvent.time
        newPeriodTime = lastEvent.period_time
        newPeriod = lastEvent.period
        newMachineState = "_running_period.paused"
      } else if (lastEvent.type === "half_time" || lastEvent.type === "period_finished") {
        newMachineState = "_running_period.not_started"
        newMatchTime = lastEvent.time + 1 // TODO: arreglar cuando CB_MACHINE
        newPeriodTime = 0
        newPeriod = lastEvent.period + 1
      } else {
        const diffInSecs = differenceInSeconds(new Date, new Date(lastEvent.created_at))

        newMachineState = "_running_period.running"
        newMatchTime = lastEvent.time + diffInSecs
        newPeriodTime = lastEvent.period_time + diffInSecs
        newPeriod = lastEvent.period

        console.log(`[MatchEvents] diffInSecs ${diffInSecs}, newMatchTime ${newMatchTime}`)
      }


      if (newMatchTime >= 0) {
        console.log("[MatchEvents] El current time lo vamos a actualizar a", newMatchTime)

        const newContext = { elapsedMatchTime: newMatchTime, elapsedPeriodTime: newPeriodTime, currentPeriod: newPeriod, }
        console.log("[MatchEvents] newContext", newContext)

        if (newMachineState) {
          machineSend({
            type: newMachineState,
            context: newContext,
          })
        }
      }
    }
  }



  function newEvent(type, data = null, time = null, extraParams={}) {
    const matchTime = machineState.context.elapsedMatchTime
    const periodTime = machineState.context.elapsedPeriodTime
    const currentPeriod = machineState.context.currentPeriod

    const event = {
      type,
      match_id: match.uuid,
      time: time ?? matchTime,
      period: currentPeriod,
      period_time: periodTime,
      data,
      ...extraParams,
    }

    // console.log("[MatchEvent] ", JSON.stringify(event));

    postEvent(event)
  }


  function postEvent(event) {
    const _event = {
      ...event,
      reverse_assistance: reverseAssistance
    }

    console.log("[MatchEvents] posting event", JSON.stringify(_event))

    api.post(`/matches/${match.uuid}/events`, _event)
      .then(({ data }) => {
        // console.log("Event created - ", JSON.stringify(data));
        setEvents([...events, data])

        dispatch(loadMatch(match.uuid))

        // if (event.type === "half_time" || event.type === "match_ended" || event.type === "period_finished")
        //   loadMatchEvents() // fix al problema del half_time/match_ended que viene de un timer y setEvents borra lo anterior :S
      })
      .catch(({ response }) => {
        console.log("postEvent", { response: response.data });
      })

    // Podria meterese
    // dispatch(loadMatch(matchId))
  }


  return {
    events,
    loadMatchEvents,
    _loadMatchEvents,
    newEvent,
    postEvent,
  }
}