import React, { useEffect, useState } from "react";
import { ScrollView, View } from "react-native";
import { StatusBar } from "expo-status-bar";

import tw from "../../styles/tailwind";
import { useForm } from "react-hook-form";

import NewPlayer_Step1 from "./Player.New.Steps/NewPlayer_Step1";
import NewPlayer_Step2 from "./Player.New.Steps/NewPlayer_Step2";
import NewPlayer_Step3 from "./Player.New.Steps/NewPlayer_Step3";
import NewPlayer_Step4 from "./Player.New.Steps/NewPlayer_Step4";
import NewPlayer_Step5 from "./Player.New.Steps/NewPlayer_Step5";

export default function NewPlayerScreen({ route, navigation }) {
  const { control, handleSubmit, formState, formState: { errors }, setError, setValue, watch } = useForm();
  const [step, setStep] = useState(1);

  function createPlayer(data) {

    console.log("Creando nuevo Jugador")
  }

  useEffect(() => {
    navigation.setOptions({

      title: "Crear Perfil",
      headerStyle: {
        backgroundColor: '#ebebeb',
      },
      headerTintColor: 'black',
      background: 'red',

    })
  }, []);


  return (
    <ScrollView style={tw`flex-1 p-5 bg-gray`} >
      <StatusBar style="auto" />

      {
        step == 1 &&
        <NewPlayer_Step1
          style={{ container: tw`mb-8` }}
          onSubmit={() => {
            setStep(step + 1)
          }} />
      }

      {
        step == 2 &&
        <NewPlayer_Step2
          style={{ container: tw`mb-8` }}
          onSubmit={() => {
            setStep(step + 1)
          }}

          handleBack={() => {
            setStep(step - 1)
          }}
        />
      }

      {
        step == 3 &&
        <NewPlayer_Step3
          style={{ container: tw`mb-8` }}
          onSubmit={(data) => {
            setStep(step + 1)
          }}

          handleBack={() => {
            setStep(step - 1)
          }}
        />
      }

      {
        step == 4 &&
        <NewPlayer_Step4
          style={{ container: tw`mb-8` }}
          onSubmit={(data) => {
            setStep(step + 1)
          }}

          handleBack={() => {
            setStep(step - 1)
          }}
        />
      }

      {
        step == 5 &&
        <NewPlayer_Step5
          style={{ container: tw`mb-8` }}
          onSubmit={(data) => {
            createPlayer(data)
          }}

          handleBack={() => {
            setStep(step - 1)
          }}
        />
      }

    </ScrollView>
  );
}