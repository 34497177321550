
import React, {useEffect, useState} from 'react';
import { Pressable, View, Text } from "react-native";

import commonStyle, {theme} from "../styles/common.style";
import { useController } from "react-hook-form";



export default function Checkbox(props){
  const [checked, setChecked] = useState(props.checked);

  // if(!!props.control){
    // var { field, fieldState } = useController({
    //   control: props.control,
    //   defaultValue: '',
    //   name: props.name,
    //   rules: props.rules,
    // })
  // }

  // console.log(fieldState, props.rules);

  useEffect(() => {
    setChecked(props.checked)
  }, [props.checked]);


  return <Pressable
    style={({ pressed }) => [
      commonStyle.checkbox,
      props.style,
      {
        // backgroundColor: pressed ? props.activeColor ?? '#eee' : null,
      },
    ]}

    onPress={()=>{
      setChecked(v => !v)
      props.onChange && props.onChange(!checked)
    }}

    disabled={props.disabled}
  >
    {
      checked ? <View style={[{
        flex: 1,
        borderRadius: 2,
        backgroundColor: props.activeColor ?? 'black',
        margin: 3,
      }, props.checkStyle]}></View> : null
    }
  </Pressable>
}