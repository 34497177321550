import React, { useState, useEffect, useRef } from 'react';
import { View, TouchableOpacity } from "react-native"


import tw from '../../../../styles/tailwind';
import Text from '../../../../Components/Text';

export default function Clock({ style }) {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
   const interval = setInterval(() => {
    setDate(new Date())
   }, 1000) 

   return () => {
    clearInterval(interval)
   }
  }, []);

  return (
    <Text style={tw.style(`text-white text-md`, style)}>{ date.getHours() }:{date.getMinutes() < 10 ? "0" : ""}{ date.getMinutes() }</Text>
  )
}

