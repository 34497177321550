import React, { useState, useEffect } from "react";
import { View, StyleSheet, ScrollView } from "react-native";

import tw from "../styles/tailwind";

import Text from "./Text";
import Button from "./Button";

import { BarCodeScanner } from 'expo-barcode-scanner';


interface QRScannerProps{
  onCodeScanned: (data:string) => void,
  /**
   * El texto a mostrar en el overlay de la camara
   */
  label?: string,
}

export default function QRScanner({ onCodeScanned, label } : QRScannerProps) {
  const [hasPermission, setHasPermission] = useState(false);
  const [scanned, setScanned] = useState(false);


  const handleBarCodeScanned = ({ type, data }) => {
    console.log("[QRScanner] code scanned", data)

    onCodeScanned && onCodeScanned(data)
  };

  useEffect(() => {
    (async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync();
      setHasPermission(status === 'granted');
    })();
  }, []);


  if (! hasPermission) return null

  return (
    <View style={tw`flex-1`} >
      {
        scanned === false ?
          <BarCodeScanner
            barCodeTypes={[BarCodeScanner.Constants.BarCodeType.qr]}
            onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
            style={tw`flex-1 bg-transparent absolute top-0 right-0 bottom-0 left-0 bg-black`}
          />
        :
          <View>
            <Button label={"Escanear nuevamente"} onPress={() => setScanned(false)} />
          </View>
      }

      <Text
        style={tw.style(
          `font-black text-lg text-center absolute bottom-30 text-white text-center self-center w-[80vw]`
        )}>{label}</Text>
    </View>
  );
}
