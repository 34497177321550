import { useEffect, useState } from "react";
import api from "../../../../utils/api";
import { TeamsAssistance } from "../Match.ArbitrationTablet";


export default function useMatchAssistance(match:Match) {
  const [homeTeam, setHomeTeam] = useState();
  const [awayTeam, setAwayTeam] = useState();
  const [teamsAssistance, setTeamsAssistance] = useState<TeamsAssistance>({ home: [], away: [], home_no_show: false, away_no_show: false });


  useEffect(() => {
    async function _loadTeams() {
      const teams = await loadTeams()

      // Recargando el player assistance, esto se podría mejorar :S
      // un problema es que solo carga la primer vez, si match.stats?.home?.player_assistance cambia, esto ya no entra
      const teamsAssistance: TeamsAssistance = { home: [], away: [], home_no_show: false, away_no_show: false }


      teamsAssistance.home_no_show = match.stats?.home?.no_show
      teamsAssistance.away_no_show = match.stats?.away?.no_show


      if (match.stats?.home.player_assistance) {
        const homePlayersAssistance = match.stats?.home?.player_assistance.map(playerId => teams[0].members.find(player => player.uuid === playerId))

        teamsAssistance.home = homePlayersAssistance
      }

      if (match.stats?.away.player_assistance) {
        const awayPlayersAssistance = match.stats?.away?.player_assistance.map(playerId => teams[1].members.find(player => player.uuid === playerId))

        teamsAssistance.away = awayPlayersAssistance
      }

      setTeamsAssistance(teamsAssistance)
    }


    if (match) {
      if (!homeTeam || !awayTeam) {
        _loadTeams()
      }
    }
  }, [match?.id]);


  async function loadTeams() {
    try {
      // Esto se puede mover a getLeagueTeam?
      const [data1, data2] = await Promise.all([
        api.get(`/leagues/${match.league.uuid}/teams/${match.home_team?.team.uuid}`),
        api.get(`/leagues/${match.league.uuid}/teams/${match.away_team?.team.uuid}`)
      ])

      setHomeTeam(data1.data)
      setAwayTeam(data2.data)

      // console.log(data1, data2);
      return [data1.data, data2.data]
    } catch (err) {
      console.log(err);
    }
  }


  function saveAssistance() {
    const data = {
      home: teamsAssistance.home.map(player => player.uuid),
      away: teamsAssistance.away.map(player => player.uuid),
      home_no_show: teamsAssistance.home_no_show,
      away_no_show: teamsAssistance.away_no_show,
    }

    api.post(`/matches/${match.uuid}/assistance`, data)
      .then(({ data }) => {
        // console.log("saveAssistance", JSON.stringify(data));
      })
      .catch(error => {
        console.log("saveAssistance error", JSON.stringify(error.response?.data));
      })
  }



  return {
    homeTeam,
    awayTeam,
    teamsAssistance,
    setTeamsAssistance,
    saveAssistance,
    loadTeams,
  }
}