import merge from 'lodash/merge'


const initialEntities = {
    leagueTeams: {},
    teams: {},
    matches: {},
    leagues: {},
}

export const reducer = (state = initialEntities, action) => {
    if (action.entities) {
      if(!action.assign){
        return merge({}, state, action.entities)
      }else{
        // esto es un fix medio puerco
        // si le paso leagueTeams[xyz].members[{}]
        // y luego leagueTeams[xyz].members[]
        // el merge me deja los mismos members :()
        return {
          ...state,
          leagueTeams: {
            ...state.leagueTeams,
            ...action.entities.leagueTeams,
          }
        }
      }
    }

    return state
}
