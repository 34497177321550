import React, { useState, useRef, useEffect } from 'react';

import { ScrollView, TouchableOpacity, View } from 'react-native';


import Text from '../../../Components/Text';
import Button from '../../../Components/Button';
import BottomSheet from '../../../Components/BottomSheet';

import tw from '../../../styles/tailwind';

import badgesData from '../../../Components/Teams/badgesData';
import {BadgeSvg} from '../../../Components/Teams/TeamBadge';
import api from '../../../utils/api';




export default function TeamBadgeSelectorBottomSheet({ visible, onRequestClose, leagueTeam, onBadgeChanged }) {
  const badgesKeys = Object.keys(badgesData).filter(key => ["name", "camaleon_2", "empty"].indexOf(key) < 0)

  function changeLeagueTeamBadge(badgeKey){
    const formData = {
      badge: badgeKey,
    }

    api.post(`/league-teams/${leagueTeam.uuid}/badge`, formData)
      .then((response) => {
        // console.log("Upload succeded", response.data);
        onBadgeChanged?.()
      })
      .catch((error) => {
        // console.log("Upload error", error.response.data);
      });
  }

  return (
    <BottomSheet
      visible={visible}
      onRequestClose={() => {
        onRequestClose?.()
      }}
      height={500}
      style={{ container: tw`bg-gray rounded-tl-xl rounded-tr-xl` }}
    >
      <ScrollView>
        <Text style={tw.style(`mb-4 mt-3 font-black text-xl text-center`)}>Cambiar Escudo</Text>

        <View style={tw`row flex-wrap items-center justify-around`}>
          {
            badgesKeys.map(key => {
              const xml = badgesData[key]
              
              return (
                <TouchableOpacity style={tw`items-center py-2`} onPress={() => changeLeagueTeamBadge(key)}>
                  <BadgeSvg xml={xml} />
                  {/* <Text key={key}>{key}</Text>   */}
                </TouchableOpacity>
              )
            })
          }
        </View>
      </ScrollView>
    </BottomSheet >
  );
}
