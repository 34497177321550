import React, { useEffect, useState } from 'react';
import { View, ScrollView, TouchableOpacity, Image, Platform, Alert } from 'react-native'
import Text from '../../../../Components/Text';

import api from "../../../../utils/api"
import tw from '../../../../styles/tailwind';

import FMIcons from '../../../../Components/FMIcon';
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from 'react-native-toast-notifications';

import {Ionicons} from "@expo/vector-icons"
import { HeaderButtons, HiddenItem, Item, OverflowMenu, Divider } from 'react-navigation-header-buttons';
import NewPlayerFormScreen from './NewPlayerFormScreen';
import { loadLeagueTeam, updateLeagueTeam } from '../../../../store/Reducers/leagueTeams';
import ShareInvitationModal from './ShareInvitationModal';



// TODO: abstraer, Estos de abajo se repite en Team.Detail
function PlayerHeader({ player, style, onSendInvitationPressed }: {
  player: Player,
  style?: any,
}) {


  return (
    <View style={tw.style(`flex-1 flex-col py-6 rounded-xl bg-gray-500 mt-8`, style?.container)}>
      {/*  */}

      {/* Badge jugador */}
      <View style={tw.style(`flex-1 flex-row justify-center`)}>
        <View style={tw.style(`bg-gray-800 border border-primary rounded-2xl overflow-hidden absolute -top-[14]`)}>
          <Image source={{uri: player.image_url}} style={tw`h-20 w-20`} />
        </View>
      </View>

      {/* Info jugador */}
      <View style={tw`flex-col items-center mt-8 mx-3`}>
        <Text style={tw.style(`text-xl text-gray mb-5`)}>{player.name}</Text>

        {/*  */}
        {
          ! player.is_registered ?
            <TouchableOpacity onPress={onSendInvitationPressed} style={tw`row justify-between px-2 mt-2 w-full bg-primary py-3 rounded-lg px-4`}>
              <Text style={tw` text-md text-secondary`}>Enviar invitación FutMaster a {player.first_name}</Text>

              <Ionicons name="chevron-forward-outline" size={20} color="#444" />
            </TouchableOpacity>
          :
            <View style={tw`row px-2 mt-2 w-full`}>
              <Ionicons name="checkmark-outline" size={20} color="white" />
              <Text style={tw`text-white ml-2`}>Registrado en FutMaster</Text>
            </View>
        }
        {/* <XPIndicator style={{ container: tw`w-[100%] ` }} /> */}

        
      </View>


    </View>
  )
}




function Pill({icon, label, value}){
  return (
    <View style={tw`bg-gray-500 px-5 py-3 rounded-lg flex-row items-center justify-between mb-4`}>
      <View style={tw`flex-row items-center`}>
        <FMIcons name={icon} color={tw.color(value ? 'primary' : "gray-300")} size={20} />

        <Text style={tw`text-white ml-4 text-gray-300`}>{label}</Text>
      </View>

      <Text style={tw`text-white text-lg font-bold`}>{value ?? "-"}</Text>
    </View>
  )
}


export default function PlayerDetailScreen({ navigation, route }) {
  const { playerId, leagueTeamId, leagueId, teamId } = route.params

  const [editMode, setEditMode] = useState(false);
  const [shareInvitationModalShown, setShareInvitationModalShown] = useState(false);

  const toast = useToast()

  
  
  // TODO: USE_HOOK - REDUX - players
  const dispatch = useDispatch()
  const leagueTeam = useSelector(state => state.entities.leagueTeams[`${leagueId}-${teamId}`])
  const player:Player = leagueTeam.members.find(member => member.uuid === playerId)



  useEffect(() => {
    navigation.setOptions({
      title: "",
      headerRight: () => <HeaderButtons>
        <Item title={editMode ? "Cancelar" : "Editar"} onPress={() => setEditMode(!editMode)} buttonStyle={tw`text-white`} />

        <OverflowMenu
          style={{ marginRight: -5 }}
          OverflowIcon={({ color }) => (
            <Ionicons name="ellipsis-vertical-outline" color={"white"} size={24} />
          )}
        >
          <HiddenItem title="Eliminar Jugador" onPress={() => {
            if (Platform.OS !== "web") {
              Alert.alert(
                `¿Eliminar jugador?`,
                `Esta acción quitará a ${player?.name} del equipo.`,
                [
                  {
                    text: 'Eliminar jugador',
                    onPress: () => deleteLeaguePlayer(),
                    style: 'cancel',
                  },
                  {
                    text: 'Cancelar',
                    onPress: () => { },
                    // style: 'cancel',
                  },
                ],
                {
                  cancelable: true,
                  onDismiss: () => { }
                }
              );
            } else {
              const result = confirm(`¿Eliminar jugador?\nEsta acción quitará a ${player?.name} del equipo.`)
              if (result) deleteLeaguePlayer()
            }
          }} titleStyle={tw`text-red`} />
        </OverflowMenu>
      </HeaderButtons>,
    })
  }, [editMode]);


  function deleteLeaguePlayer() {
    api.delete(`/leagues/${leagueId}/players/${playerId}`)
      .then(({ data }) => {
        dispatch(loadLeagueTeam(leagueId, leagueTeam.team.uuid))

        navigation.navigate('Admin.TeamDetail', { teamUid: leagueTeam.team.uuid, leagueUid: leagueId })
      })
      .catch(({ error }) => {

      })
  }

  
  if(!player) return null

  return (
    <ScrollView style={tw`flex-1 px-3 pt-5`} contentContainerStyle={tw`pb-8`}>
      {
        editMode ?
          <NewPlayerFormScreen
            navigation={navigation}
            route={{
              params: {
                leagueUid: leagueId,
              }
            }}
            player={player}
            onPlayerCreated={(editedPlayer) => {
              setEditMode(false)

              // No es la mejor solución pero funciona
              // Me vuelvo a traer todo el equipo (ya con el player actualizado :P)
              // Lo ideal sería que players tuviera sus propios entities...
              dispatch(loadLeagueTeam(leagueId, teamId))
            }}
          />
        :
          <View>
            <PlayerHeader
              player={player}
              onSendInvitationPressed={() => setShareInvitationModalShown(true)}
              navigation={navigation}
            />

            {/*  */}
            <Text style={tw`text-white text-lg mb-4 mt-6`}>Datos personales</Text>

            <Pill icon={player.gender == "female" ? "feminil" : "varonil"} label={"Género"} value={player.gender == "female" ? "Femenino" : "Masculino"} />
            <Pill icon={"calendario"} label={"Año de nacimiento"} value={player.birthdate} />
            <Pill icon={"jersey"} label={"Dorsal"} value={player.squad_number} />


            {/*  */}
            {/* <Text style={tw`text-white text-lg mb-4 mt-6`}>Datos de contacto</Text>

            <Pill icon={"telefono"} label={"Teléfono"} value={null} />
            <Pill icon={"email"} label={"Correo"} value={null} /> */}


            {/*  */}
            {/* <View style={tw`flex-row justify-between mt-4 mb-3`}>
              <View>
                <Text style={tw`text-white text-lg mb-2`}>Documento</Text>
                <Text style={tw`text-gray-200`}>Autenticación de {"\n"}identidad</Text>
              </View>

              <View>
                <View style={tw`h-24 w-40 border border-gray-200 rounded-lg bg-gray-700`}></View>
              </View>
            </View> */}

            <ShareInvitationModal
              visible={shareInvitationModalShown}
              player={player}
              leagueTeamId={leagueTeamId}
              onRequestClose={() => setShareInvitationModalShown(false)}
            />
          </View>
      }
    </ScrollView>
  )
}


