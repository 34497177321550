import React, {useState, useEffect} from "react";
import { ScrollView, View } from "react-native";
import { StatusBar } from "expo-status-bar";

import tw from "../../../../styles/tailwind";
import api from "../../../../utils/api";

import Text from "../../../../Components/Text";

import Button from "../../../../Components/Button";
import TeamBadge from "../../../../Components/Teams/TeamBadge";
import LabelSwitch from "../../../../Components/LabelSwitch";
import { useForm } from "react-hook-form";


export default function NewTableScreen({route, navigation}) {
  const [teams, setTeams] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const leagueUid = route.params?.leagueUid;

  const { control, watch } = useForm();
  const rounds_count = watch("rounds_count")

  useEffect(() => {
    api.get(`/leagues/${leagueUid}/teams`)
      .then(({ data }) => {
        setTeams(data)
      })
      .catch(error => { })
  }, []);


  function createTable(){
    const data = {
      rounds_count,
    }

    setIsSubmitting(true)

    api.post(`/leagues/${leagueUid}/stages/table`, data)
      .then(({ data }) => {
        navigation.goBack();
        // TODO: conectar esto con redux
        route.params.onCreated({  })
      })
      .catch(error => {
        setIsSubmitting(false)
        console.log(error.response.data, "[NewTableScreen] createTable error")
      })
  }

  return (
    <ScrollView style={tw`py-5 px-5`} >
      <Text style={tw`text-lg mb-8 text-white`}>Se va a generar las jornadas y el pareo de estos equipos:</Text>

      <View style={tw`mb-6`}>
        {
          teams.map(team => 
            <View key={team.uuid} style={tw`mb-4 flex-row items-center`}>
              <TeamBadge team={team} width={35} height={35} />
              <Text style={tw`font-bold text-lg ml-3 text-white`}>{team.name}</Text>
            </View>
          )
        }
      </View>


      <LabelSwitch
        label={"Doble Vuelta"}
        name="rounds_count"
        control={control}
        offValue={1}
        onValue={2}
        style={{ container: tw`rounded-xl mb-12` }}
      />

      <Button
        label={"Crear Tabla"}
        disabled={isSubmitting}
        onPress={() => {
          createTable()
        }}
      />
    </ScrollView>
  );
}

