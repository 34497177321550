import React, { useState, useEffect } from "react";
import { TouchableOpacity, View } from "react-native";

import tw from "../../../styles/tailwind";

import Text from "../../../Components/Text";
import FMIcons from '../../../Components/FMIcon';

import { useNavigation } from "@react-navigation/native";
import { getHourStr, dayOfWeekStr, monthStr } from "../../../utils/momento";
import TeamBadge from "../../../Components/Teams/TeamBadge";

import MatchSelectRefereeBottomSheet from "../Matches/MarchDetailComponents/MatchSelectRefereeBottomSheet";
import GradientSeparator from "../../../Components/GradientSeparator";
import LiveIndicator from "../../../Components/LiveIndicator";
import { Ionicons } from '@expo/vector-icons'


function MatchCardTeam({ team, style }) {
  const emptyTeamBadge = { badge: { name: "empty" } }

  return (
    <View style={tw`items-center px-2 flex-2`}>
      <TeamBadge team={team?.team ?? emptyTeamBadge} height={35} width={35} style={tw`mb-1`} />

      <Text
        style={tw.style(
          `text-center mt-1 text-gray`,
          style?.name,
          ! team?.team && `text-gray-300`,
        )}
      >{team?.name ?? "Sin equipo"}</Text>
    </View>
  )
}



interface MatchRowProps{
  match: Match,
  showLeague: boolean,
  showDate: boolean,
  showTime: boolean,
  showRound: boolean,
  showStatus: boolean,
  showBottomBorder: boolean,
  onPress?: () => void,
  style?: any,
}

function matchStatusColor(status:MatchStatus){
  switch (status) {
    case 'canceled':
      return "red";
      break;
  
    case 'played':
      return "#00c252";
  
    default:
      return "#ddd";
  }
}


export default function MatchRow(
  { match, style, showLeague = true, showDate = true, showTime = true, onPress, showRound, showStatus=true, showBottomBorder=true }
: MatchRowProps) {
  const navigation = useNavigation();

  const createdAt = match.date ? new Date(match.date) : null
  const hourStr = getHourStr(createdAt)
  const dateStr = createdAt ? `${dayOfWeekStr(createdAt, true)}, ${createdAt?.getDate()} ${monthStr(createdAt, true)}` : null

  const statusColor = matchStatusColor(match.status)

  const showScore = match.status !== "created" && match.status !== "canceled" && match.status !== "postponed" && match.status !== "pending"// || match.status == "awarded"
  const isLive = showScore && match.status !== "awarded" && match.status !== "played"


  return (
    <TouchableOpacity
      // onPress={() => {
      //   // Mueve a la siguiente pantalla
      //   // navigation.navigate("Admin.MatchDetail", { matchId: match.uuid })
      // }}
      onPress={onPress}
      style={tw.style(`px-1 pb-2 pt-4`, style?.container)}
    >

      {/* Fecha y hora*/}
      {/* <View style={tw`flex-row items-center justify-between mx-5`}>
        {
          (showLeague && match.league) &&
            <Text style={tw`text-white`}>{match.league.name}</Text>
        }
        {
          showDate && 
            <Text style={tw.style(`text-gray-300 text-center`, dateStr && `text-white`)}>{dateStr ? dateStr : "Sin fecha"}</Text>
        }
        {
          showTime && 
            <View style={tw`flex-row items-center`}>
              <Text style={tw`text-primary text-md ml-1`}>{hourStr ? hourStr : ""}</Text>
            </View>
        }
      </View> */}

      {/*
        Contrincantes
      */}
      <View style={tw`flex-row justify-between`}>
        {/* Local */}
        <MatchCardTeam
          team={match.home_team}
          style={{ name: style?.teamsName }}
        />


        {/* Parte central */}
        <View style={tw`flex-3 items-center justify-center`}>
          <View style={tw`row mb-1 -mt-2`}>
            {
              match.hidden &&
                <Ionicons name={`eye-off`} size={15} color={tw.color('gray-200')} style={tw`mr-1`} />
            }

            <Text style={tw.style(`text-primary text-sm`, style?.date)}>{dateStr ?? "Sin Fecha"}</Text>

            {
              showScore &&
                <Text style={tw`text-primary ml-2 text-sm`}>{hourStr}</Text>
            }
          </View>

          {
            ! showScore ?
              <Text style={tw`text-primary font-bold text-xl`}>{hourStr ?? "Sin Hora"}</Text>
            :
              <View style={tw`row justify-around`}>
                <Text style={tw.style(`text-white font-700 text-xl mr-3 w-10 text-center`, {fontSize: 35})}>{match.home_score}</Text>
                <Text style={tw.style(`text-white font-700 text-xl`, {fontSize: 35})}>:</Text>
                <Text style={tw.style(`text-white font-700 text-xl ml-3 w-10 text-center`, {fontSize: 35})}>{match.away_score}</Text>
              </View>
          }

          {
            match.field &&
              <Text style={tw`text-primary text-sm text-center`}>{match.field.name}</Text>
          }

          {
            showStatus &&
              <View style={tw`row mb-2`}>
                {
                  isLive &&
                    <LiveIndicator />
                }
                <Text style={tw`text-sm font-700 text-[${statusColor}] text-center`}>{match.status_str}</Text>
              </View>
          }
        </View>

        {/* Visitante */}
        <MatchCardTeam
          team={match.away_team}
          style={{ name: style?.teamsName }}
        />
      </View>


      {
        !! (showRound && match.round) &&
          <View>
            <Text style={tw.style(`text-gray text-center text-primary -mt-1`, style?.roundName)}>{match.round?.name}</Text>
          </View>
      }

      {
        showBottomBorder &&
          // <View style={tw`mt-2 border-b border-primary/20 absolute bottom-0 left-0 right-0`}></View>
          <GradientSeparator style={tw`mt-2 mb-0`}/>
      }
    </TouchableOpacity>
  )
}