

import React, { useState } from "react";
import { View } from "react-native";
import tw from "../styles/tailwind";

import { LinearGradient } from "expo-linear-gradient";


export default function GradientSeparator({style}:{style?:any}) {
  return (
    <LinearGradient
      colors={['#fddc0102', "#82927c", "#fddc0102"]}
      start={{ x: 0, y: 0.5 }}
      end={{ x: 1, y: 0.5 }}
      style={tw.style(`h-[1px] my-3 mx-2`, style)}
    />
  )
}