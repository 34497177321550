import React, { useEffect, useState } from "react";
import { ScrollView, View } from "react-native";
import { StatusBar } from "expo-status-bar";

import TeamBadge from "../../../Components/Teams/TeamBadge";
import Text from '../../../Components/Text'
import tw from "../../../styles/tailwind";
import { useRoute } from "@react-navigation/native";
import api from "../../../utils/api";
import { Table, Row, Rows } from 'react-native-table-component';
import Loading from "../../../Components/Loading";


function TeamStatsColumn({ leagueTeam }: {leagueTeam:LeagueTeam}){
  return (
    <View style={tw.style(`pl-5 flex-1 flex-row  items-center w-105`, {})}>
      <Text style={tw`text-gray w-[40px]`}>{leagueTeam.stats?.played || 0}</Text>
      <Text style={tw`text-gray w-[40px]`}>{leagueTeam.stats?.win || 0}</Text>
      <Text style={tw`text-gray w-[40px]`}>{leagueTeam.stats?.draw || 0}</Text>
      <Text style={tw`text-gray w-[40px]`}>{leagueTeam.stats?.loss || 0}</Text>
      <Text style={tw`text-gray w-[40px]`}>{leagueTeam.stats?.goals_for || 0}</Text>
      <Text style={tw`text-gray w-[40px]`}>{leagueTeam.stats?.goals_against || 0}</Text>
      <Text style={tw`text-gray w-[40px]`}>{leagueTeam.stats?.goals_difference || 0}</Text>
      <Text style={tw`text-gray w-[40px]`} >{leagueTeam.stats?.points || 0}</Text>

      {
        leagueTeam.stats?.last_5?.slice(0, 5).map((e, i) =>
          <View
            key={i}
            style={tw.style(
              `h-2 w-2 rounded-full mr-1 bg-red`,
              e == "win" && `bg-green`,
              e == "draw" && `bg-gray-200`,
              e == "loss" && `bg-red`,
            )}
          />
        )
      }
    </View>
  )
}



function TeamsIndexColum({ index, bg, team, selected = false, color = "yellow-200", selectedColor = "primary", leftBorderColor = null}) {
  return (
    <View style={tw.style(`flex-row pl-3 items-center bg-${bg}`, { height: 44, }, leftBorderColor && `border-l-2 border-${leftBorderColor}`)}>
      {
        selected &&
          <LinearGradient
            colors={['#494a2cee', "#fddc0100"]}
            start={{ x: 0, y: 0.5 }}
            end={{ x: 1, y: 0.5 }}
            style={tw.style(`absolute left-0 bottom-0 top-0 w-15`)}
          />
      }
      <Text style={tw.style(` mr-3 w-[14px] text-center text-${selected ? selectedColor : color}`, leftBorderColor && `text-${leftBorderColor}`)}>{index}</Text>

      <View style={tw`w-[1px] mr-3 h-4 bg-yellow-200`}></View>

      <TeamBadge team={team?.team} height={25} width={25} />
    </View>
  )
}




export function PositionsTable({positionTable, selectedTeamId, color="gray-300", style, qualification=null}){
  if(!positionTable) return null

  const widthArr = [150, 408]

  const tableHead = [
    '',
    <View style={tw.style(`flex-1 pl-5 w-105 flex-row items-center bg-gray-500`, { height: 44, borderTopLeftRadius: 10, borderTopRightRadius: 10 })}>
      <Text style={tw`text-gray w-[40px]`}>J</Text>
      <Text style={tw`text-gray w-[40px]`}>G</Text>
      <Text style={tw`text-gray w-[40px]`}>E</Text>
      <Text style={tw`text-gray w-[40px]`}>P</Text>
      <Text style={tw`text-gray w-[40px]`}>GF</Text>
      <Text style={tw`text-gray w-[40px]`}>GC</Text>
      <Text style={tw`text-gray w-[40px]`}>DG</Text>
      <Text style={tw`text-gray w-[40px]`}>Pts</Text>
      <Text style={tw`text-gray w-[40px]`}>Últ. 5</Text>
    </View>
  ]

  const renderTeamNameColumn = (leagueTeam, selected) => (
    <View style={tw.style(`flex-1 flex-row items-center pl-3`)}>
      <Text style={tw.style(`text-${color}`, selected && `text-primary`)}>{leagueTeam?.name}</Text>
      </View>
  )

  const rowData = (leagueTeam: LeagueTeam, selected) => [
    renderTeamNameColumn(leagueTeam, selected),
    <TeamStatsColumn leagueTeam={leagueTeam} />
  ]

  return (
    <View style={tw.style(`flex-1`, style?.container)}>
      {
        qualification &&
          <View style={tw`row -mt-2 self-center mb-5 bg-gray-500 px-5 py-4 rounded-xl`}>
            <View style={tw`row`}>
              <View style={tw`h-3 w-3 rounded-full bg-yellow-300 mr-2`}></View>
              <Text style={tw`text-gray-300`}>Pase directo</Text>
              {/* ({qualification.final_series?.[0]} - {qualification.final_series?.[1]}) */}
            </View>

            <View style={tw`row ml-5`}>
              <View style={tw`h-3 w-3 rounded-full bg-green-300 mr-2`}></View>
              <Text style={tw`text-gray-300`}>Repechaje</Text>
            </View>
          </View>
      }

      <View style={tw`flex-1 flex-row`}>
        {/* Columna fija (Posición y escudo) */}
        <View style={tw.style(``)}>
          {/* blank */}
          <View style={tw`h-[44px]`} />

          <View style={tw``}>
            {
              positionTable.map((leagueTeam, index) => {
                  const position = index + 1 // cambiar por el position del data
                  const leftBorderColor = qualification ?
                    position >= qualification.final_series?.[0] && position <= qualification.final_series?.[1]
                      ? "yellow-300"
                    :
                      position >= qualification.final_series_playoffs?.[0] && position <= qualification.final_series_playoffs?.[1]
                        ? "green-400" : null
                  : null

                  return (
                    <TeamsIndexColum
                      team={leagueTeam}
                      key={index}
                      index={position}
                      bg={index % 2 == 0 ? "gray-500" : "transparent"}
                      selected={leagueTeam.uuid == selectedTeamId}
                      color={color}
                      leftBorderColor={leftBorderColor}
                    />
                  )
                }
              )
            }
          </View>
        </View>

        {/* Bloque de la tabla screolleable */}
        <ScrollView horizontal={true} style={tw.style(`flex-1`)}>
          <Table borderStyle={{}}>
            <Row widthArr={widthArr} data={tableHead} style={tw`h-[44px] flex-1`} />

            {
              positionTable?.map((leagueTeam, index) =>
                <Row
                  key={leagueTeam.uuid}
                  widthArr={widthArr}
                  data={rowData(leagueTeam, leagueTeam.uuid == selectedTeamId)}
                  style={tw.style(`h-[44px] flex-1`, index % 2 == 0 ? "bg-gray-500" : "bg-transparent")}
                />
              )
            }
          </Table>
        </ScrollView>
      </View>

      {/* Acotaciones */}
      {/* <View style={tw`mt-2 bg-gray-500 px-5 py-4 rounded-xl`}>
        <Text style={tw`text-gray-300`}>
          <Text style={tw`text-white`}>[Pts]</Text> Puntos,
          <Text style={tw`text-white`}>[J]</Text> Jugados,
          <Text style={tw`text-white`}>[G]</Text> Ganados,
          <Text style={tw`text-white`}>[P]</Text> Perdidos,
          <Text style={tw`text-white`}>[GF]</Text> Goles a favor,
          <Text style={tw`text-white`}>[GC]</Text> Goles en contra,
          {/* <Text style={tw`text-white`}>[GC]</Text> Goles en contra, * /}
          <Text style={tw`text-white`}>[TA]</Text> Tarjetas Amarillas,
          <Text style={tw`text-white`}>[TR]</Text> Tarjetas Rojas
        </Text>
      </View> */}
    </View>
  )
}

import FMIcons from '../../../Components/FMIcon';
import { LinearGradient } from "expo-linear-gradient";
import useLeague from "../../../hooks/useLeague";


export function TopScorersTable({players, style, hideTitle=false, selectedPlayerId=null }){
  if(!players) return null

  return (
    <View style={tw.style(`mt-8`, style?.container)}>
      {/* Titulo */}
      {
        ! hideTitle &&
          <View style={tw`flex-row items-center mb-4 ml-3`}>
            <FMIcons name={"gol"} color={"green"} size={26} />

            <Text style={tw`text-lg text-white ml-3`}>Goleo</Text>
          </View>
      }

      {/* Tabla */}
      <View style={tw``}>
        {
          players.map((player, i) => 
            <View
              key={player.uuid}
              style={tw.style(`h-[44px] flex-row items-center justify-between`, i % 2 == 0 && `bg-gray-500`)}
            >
              <View style={tw`row flex-1`}>
                {
                  player.uuid == selectedPlayerId &&
                    <LinearGradient
                      colors={['#494a2cee', "#fddc0100"]}
                      start={{ x: 0, y: 0.5 }}
                      end={{ x: 1, y: 0.5 }}
                      style={tw.style(`absolute left-0 bottom-0 top-0 w-15`)}
                    />
                }

                <Text style={tw`text-yellow-200 w-8 text-center`}>{player.scorer_position}</Text>

                <TeamBadge team={player.league_team.team} height={30} width={30} />

                <View style={tw`flex-1`}>
                  <Text style={tw`text-gray-200 ml-2`} numberOfLines={1}>{player.squad_number} | {player.name}</Text>
                  <Text style={tw`text-gray-300 ml-2`}>{player.league_team.name}</Text>
                </View>
              </View>

              <View style={tw`w-[44px] h-[44px] items-center justify-center bg-white/3`}>
                <Text style={tw`text-white`}>{player.stats?.goals || 0}</Text>
              </View>
            </View>
          )
        }
      </View>
    </View>
  )
}


function YellowCardsTable({players, }){
  if(!players) return null

  return (
    <View style={tw`mt-8`}>
      {/* Titulo */}
      <View style={tw`flex-row items-center mb-4 ml-3`}>
        <FMIcons name={"tarjeta"} color={"yellow"} size={26} />

        <Text style={tw`text-lg text-white ml-3`}>Tarjetas Amarillas</Text>
      </View>

      {/* Tabla */}
      <View style={tw``}>
        {
          players.map((player, i) => 
            <View
              key={player.uuid}
              style={tw.style(`h-[44px] flex-row items-center justify-between`, i % 2 == 0 && `bg-gray-500`)}
            >
              <View style={tw`flex-row items-center pl-2`}>
                <TeamBadge team={player.league_team.team} height={30} width={30} />

                <View>
                  <Text style={tw`text-gray-200 ml-2`}>{player.squad_number} | {player.name}</Text>
                  <Text style={tw`text-gray-300 ml-2`}>{player.league_team.name}</Text>
                </View>
              </View>

              <View style={tw`w-[44px] h-[44px] items-center justify-center bg-white/3`}>
                <Text style={tw`text-white`}>{player.stats?.yellow_cards || 0}</Text>
              </View>
            </View>
          )
        }
      </View>
    </View>
  )
}


function RedCardsTable({players, }){
  if(!players) return null

  return (
    <View style={tw`mt-8`}>
      {/* Titulo */}
      <View style={tw`flex-row items-center mb-4 ml-3`}>
        <FMIcons name={"tarjeta"} color={"red"} size={26} />

        <Text style={tw`text-lg text-white ml-3`}>Tarjetas Rojas</Text>
      </View>

      {/* Tabla */}
      <View style={tw``}>
        {
          players.map((player, i) => 
            <View
              key={player.uuid}
              style={tw.style(`h-[44px] flex-row items-center justify-between`, i % 2 == 0 && `bg-gray-500`)}
            >
              <View style={tw`flex-row items-center pl-2`}>
                <TeamBadge team={player.league_team.team} height={30} width={30} />

                <View>
                  <Text style={tw`text-gray-200 ml-2`}>{player.squad_number} | {player.name}</Text>
                  <Text style={tw`text-gray-300 ml-2`}>{player.league_team.name}</Text>
                </View>
              </View>

              <View style={tw`w-[44px] h-[44px] items-center justify-center bg-white/3`}>
                <Text style={tw`text-white`}>{player.stats?.red_cards || 0}</Text>
              </View>
            </View>
          )
        }
      </View>
    </View>
  )
}


export default function LeagueStats({ leagueId }) {
  const [statsTeams, setStatsTeams] = useState();
  const league = useLeague(leagueId)


  useEffect(() => {
    reloadStats()
  }, []);


  // TODO: mover a redux
  function reloadStats() {
    api.get(`/leagues/${leagueId}/stats`)
      .then(({ data }) => {
        setStatsTeams(data)
      })
      .catch(error => {
        console.log("api error", error)
      })
  }


  

  return (
    <ScrollView style={tw`flex-1`} contentContainerStyle={tw`py-5`}>
      <Loading visible={!statsTeams} />

      <PositionsTable positionTable={statsTeams?.teams_stats} qualification={league?.settings?.qualification} />
      <TopScorersTable players={statsTeams?.top_scorers} />
      <YellowCardsTable players={statsTeams?.top_yellow_cards} />
      <RedCardsTable players={statsTeams?.top_red_cards} />
    </ScrollView>
  );
}