import React, {useEffect, useState} from 'react';
import { Pressable, View, Text, TouchableOpacity } from "react-native";

import commonStyle, {theme} from "../styles/common.style";
import { useController } from "react-hook-form";


import Checkbox from './Checkbox';
import Select from './Select';
import tw from '../styles/tailwind';


export default function CheckButton({ label, onChange, control, name, style, rules, activeColor, icon }) {
  const [selected, setSelected] = useState(false);

  var { field, fieldState: { invalid, isTouched, isDirty, error } } = useController({
    control: control,
    defaultValue: selected,
    name: name,
    rules: rules,
  })

  useEffect(() => {
    setSelected(!!field.value)
  }, [field.value]);

  function onSelectChange() {
    setSelected(v => !v)
    onChange && onChange(!selected)
    field.onChange(!selected)
  }


  return (
    <>
      <TouchableOpacity 
        style={[
          commonStyle.inputWrapper,
          {paddingVertical: 15, paddingRight: 10},
          style?.container,
          invalid ? {borderColor: "red", borderWidth: 1} : {},
        ]}
        onPress={onSelectChange}
      >
        <Checkbox
          checked={!!field.value}
          style={[{ position: 'absolute', zIndex: 1, top: 15, left: 15 }, style?.checkbox]}
          checkStyle={style?.checkStyle}
          activeColor={activeColor ?? "#eee"}
          onChange={onSelectChange}
        />

        <View style={tw`row`}>
          <Text style={[{ paddingLeft: 50, color: theme.PLACEHOLDER_COLOR, fontWeight: '600' }, style?.label]}>{label}</Text>

          {
            icon && icon
          }
        </View>
      </TouchableOpacity>

      {
        !!error?.message && <Text style={[{ marginTop: 10, color: theme.COMPONENTS_BORDER_COLOR_ERROR }, style?.error]}>{error.message}</Text>
      }
    </>
  )
}