import { useEffect, useState } from "react";
import api from "../utils/api";



export default function useLeague(leagueId: string): (League | undefined) {
  const [league, setLeague] = useState<League>();

  useEffect(() => {
    api.get(`/leagues/${leagueId}`)
      .then(({ data }) => {
        setLeague(data.league)
      })
      .catch(error => { })
  }, [leagueId]);

  return league
}