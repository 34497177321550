import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, ScrollView } from "react-native"
import FMIcons from '../../../../Components/FMIcon';
import TeamBadge from '../../../../Components/Teams/TeamBadge';


import Text from '../../../../Components/Text';
import tw from '../../../../styles/tailwind';
import api from '../../../../utils/api';


function MatchFeaturesCard({ leftValue, rightValue, iconName, iconLabel, iconColor, style, match, homeStats, awayStats, tablet = false }) {

  return (
    <View style={tw.style(`bg-gray-500 rounded-xl`, style?.container)}>
      <View style={tw`flex-row items-center justify-between px-16 py-4`}>
        {
          typeof (leftValue && rightValue) == 'number' &&
          <>
            <Text style={tw.style(`text-xl text-gray font-bold`, {fontSize: 30})}>{leftValue}</Text>
            <View style={tw`flex-col items-center`}>
              <FMIcons name={`${iconName}`} size={24} style={tw`text-${iconColor} mb-1`} />
              <Text style={tw`text-lg text-gray`}>{iconLabel}</Text>
            </View>
            <Text style={tw.style(`text-xl text-gray font-bold`, {fontSize: 30})}>{rightValue}</Text>
          </>
        }

        {
          // TODO: cambiar nombre de los íconos por el parámetro que se mande desde el endpoint
          typeof (leftValue && rightValue) == 'string' &&
          <>
            <FMIcons name={`${leftValue}`} size={24}
              style={[tw`text-gray-100`,
              leftValue == "feliz" && tw`text-green`,
              leftValue == "neutro" && tw`text-gray`,
              leftValue == "disgustado" && tw`text-red`]} />

            <View style={tw`flex-col items-center`}>
              <FMIcons name={`${iconName}`} size={24} style={tw`text-${iconColor} mb-1`} />
              <Text style={tw`text-md text-gray`}>{iconLabel}</Text>
            </View>

            <FMIcons name={`${rightValue}`} size={24}
              style={[tw`text-gray-100`,
              rightValue == "feliz" && tw`text-green`,
              rightValue == "neutro" && tw`text-gray`,
              rightValue == "disgustado" && tw`text-red`]} />
          </>
        }
      </View>


      <View style={tw.style(`row justify-between items-start`)}>
        <View>
          {
            homeStats?.length > 0 &&
              <View style={tw`mb-3`}>
                {
                  homeStats?.map((el, i) =>
                    <View key={i} style={tw.style(`flex-row items-center px-4 py-1 justify-between`, (i % 2 == 0) && `bg-[#232323]`)}>
                      <Text style={tw`text-lg text-gray-100`}>{el.label}</Text>
                      <Text style={tw`text-xl ml-8 text-gray-100`}>{el.count}</Text>
                    </View>
                  )
                }
              </View>
          }
        </View>

        <View>
          {
            awayStats?.length > 0 &&
              <View style={tw`mb-3`}>
                {
                  awayStats?.map((el, i) =>
                    <View key={i} style={tw.style(`flex-row items-center px-4 py-1 justify-between`, (i % 2 == 0) && `bg-[#232323]`)}>
                      <Text style={tw`text-lg text-gray-100`}>{el.label}</Text>
                      <Text style={tw`text-xl ml-8 text-gray-100`}>{el.count}</Text>
                    </View>
                  )
                }
              </View>
          }
        </View>
      </View>
    </View>
  )
}




export default function MatchStats({ match }) {

  const [matchStats, setMatchStats] = useState();

  useEffect(() => {
    loadMatchStats()
  }, []);
  // TODO: ABstraer

  function loadMatchStats() {
    api.get(`/matches/${match.uuid}/stats`)
      .then(({ data }) => {
        setMatchStats(data)
      })
      .catch((error) => {
        console.log("yepo!", error);
      })
  }


  const homeGoalsPlayers = matchStats?.home?.player_stats.filter(e => e.goals > 0).map(e => { return { count: e.goals, label: `${e.player.name} - ${e.player.squad_number}` } })
  const awayGoalsPlayers = matchStats?.away?.player_stats.filter(e => e.goals > 0).map(e => { return { count: e.goals, label: `${e.player.name} - ${e.player.squad_number}` } })
  const homeYellowCards = matchStats?.home?.player_stats.filter(e => e.yellow_cards > 0).map(e => { return { label: `${e.player.name} - ${e.player.squad_number}` } })
  const awayYellowCards = matchStats?.away?.player_stats.filter(e => e.yellow_cards > 0).map(e => { return { label: `${e.player.name} - ${e.player.squad_number}` } })
  const homeRedCards = matchStats?.home?.player_stats.filter(e => e.red_cards > 0).map(e => { return { label: `${e.player.name} - ${e.player.squad_number}` } })
  const awayRedCards = matchStats?.away?.player_stats.filter(e => e.red_cards > 0).map(e => { return { label: `${e.player.name} - ${e.player.squad_number}` } })


  return (
    <ScrollView style={tw`flex-1`}>
      <MatchFeaturesCard
        match={match}
        leftValue={match.home_score}
        rightValue={match.away_score}
        iconLabel={"Goles"}
        iconName={"gol"}
        iconColor={"green"}
        style={{ container: tw`mb-3 mt-8` }}
        homeStats={homeGoalsPlayers}
        awayStats={awayGoalsPlayers}
      />

      <MatchFeaturesCard
        match={match}
        leftValue={match.stats?.home.yellow_cards}
        rightValue={match.stats?.away.yellow_cards}
        iconLabel={"Tarjeta A."}
        iconName={"tarjeta"}
        iconColor={"primary"}
        style={{ container: tw`mb-3` }}
        homeStats={homeYellowCards}
        awayStats={awayYellowCards}
      />

      <MatchFeaturesCard
        match={match}
        leftValue={match.stats?.home.red_cards}
        rightValue={match.stats?.away.red_cards}
        iconLabel={"Tarjeta R."}
        iconName={"tarjeta"}
        iconColor={"red"}
        style={{ container: tw`mb-3` }}
        homeStats={homeRedCards}
        awayStats={awayRedCards}
      />

      <MatchFeaturesCard
        match={match}
        leftValue={match.stats?.home.fouls}
        rightValue={match.stats?.away.fouls}
        iconLabel={"Faltas"}
        iconName={"tarjeta"}
        iconColor={"gray"}
        style={{ container: tw`mb-3` }}
        homeStats={[]}
        awayStats={[]}
      />
     
    </ScrollView>
  )
}

