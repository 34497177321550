import React, { useEffect, useState } from "react";
import { TouchableOpacity, View, Image, ScrollView, Platform, Alert } from "react-native";

import tw from "../../../../styles/tailwind";
import Text from '../../../../Components/Text';
import Button from "../../../../Components/Button";
import BottomSheet from "../../../../Components/BottomSheet";
import api from "../../../../utils/api";
import FMIcons from "../../../../Components/FMIcon"
import {Ionicons} from "@expo/vector-icons"
import { updateMatch } from "../../../../store/Reducers/matches";
import { useDispatch } from "react-redux";

interface MatchSelectRoundProps{
  visible: boolean,
  match: Match,
  onRequestClose: () => {},
  onRoundChanged?: () => {},
}


export default function MatchSelectRoundBottomSheet({ visible, onRequestClose, match, onRoundChanged } : MatchSelectRoundProps) {
  const [leagueCalendar, setLeagueCalendar] = useState();
  const [teams, setTeams] = useState<Array<Team>>([]);

  const dispatch = useDispatch()

  useEffect(() => {
    reloadCalendar()
  }, []);

  function reloadCalendar() {
    api.get(`/leagues/${match.league.uuid}/calendar`)
      .then(({ data }) => {
        setLeagueCalendar(data)
      })
      .catch(error => { })
  }

  function confirmRoundMove(round){
    if (Platform.OS !== "web") {
      Alert.alert(
        `¿Cambiar este partido a la ${round.name}?`,
        '',
        [
          {
            text: 'Cambiar',
            onPress: () => moveToRound(round),
            style: 'cancel',
          },
          {
            text: 'Cancelar',
            onPress: () => { },
            // style: 'cancel',
          },
        ],
        {
          cancelable: true,
          onDismiss: () => { }
        }
      );
    } else {
      const result = confirm(`Cambiar este partido a la ${round.name}`)
      if (result) moveToRound(round)
    }
  }

  function moveToRound(round){
    const data = {
      round_id: round.uuid,
    }

    api.post(`/matches/${match.uuid}/round`, data)
      .then(({ data }) => {
        // setLeagueCalendar(data)
        // console.log(data);
        onRoundChanged?.()
      })
      .catch(error => {
        console.log(error?.response?.data);
      })
  }

  const rounds = leagueCalendar?.stages?.[0]?.rounds

  return (
    <BottomSheet
      visible={visible}
      onRequestClose={() => {
        onRequestClose && onRequestClose()
      }}
      height={400}
      style={{ container: tw`px-3 bg-fm-background rounded-tl-xl rounded-tr-xl` }}
    >
      <ScrollView style={tw`flex-1 px-4 pt-5`} contentContainerStyle={tw`pb-14`}>
        <Text style={tw`font-bold mb-3 text-lg text-center`}>Elige la jornada</Text>

        <View style={tw`mt-2`}>
          {
            rounds?.map(round => 
              <TouchableOpacity
                key={round.uuid}
                style={tw`rounded-lg border border-gray-200 mb-3 px-4 py-2`}
                onPress={() => {
                  confirmRoundMove(round)
                }}
              >
                <Text style={tw`text-gray-300`}>{round.name}</Text>
              </TouchableOpacity>
            )
          }
          
        </View>
      </ScrollView>
    </BottomSheet>
  );
}
