import React from "react";
import { useForm } from "react-hook-form";
import { View } from "react-native";

import StepIndicator from "../../../Components/StepIndicator";
import Text from "../../../Components/Text";
import Button from "../../../Components/Button";

import tw from "../../../styles/tailwind";



export default function NewPlayer_Step5({ onSubmit, handleBack, style }) {
  const { control, handleSubmit, formState, formState: { errors }, setError } = useForm();


  const returnStep = () => {
    handleBack()
  }

  const submitStep5 = () => {
    onSubmit()
  }

  return (
    <View style={tw.style(``, style?.container)}>

      <StepIndicator
        style={{ container: tw`mb-6` }}
        currentStep={5}
      />

      <View style={tw.style(`bg-gray-100 mb-6 rounded-xl self-center p-5`)}>
        <Text style={tw`text-gray-300 text-center`}>
          El nickname de tu camiseta lo podrás cambiar después de completar
          tu perfil, recuerda que el apodo y el número de tu camiseta
          es meramente informativo.
        </Text>
      </View>


      {/* Aquí va la camiseta */}
      <View style={tw.style(`bg-gray-100 rounded-xl p-5 mb-8`)}>

        <Text style={tw`text-2xl font-bold text-gray-300 text-center`}>
          El Chema
        </Text>

        <Text style={tw`text-4xl font-bold text-gray-300 text-center`}>
          8
        </Text>

      </View>

      {/* Botones */}
      <View style={tw`flex-row justify-around`}>
        <Button
          label={"Anterior"}
          onPress={() => { returnStep() }}
          style={{ container: tw`px-8  py-3 bg-black-100`, label: tw`text-white` }}

        />

        <Button
          label={"Terminar"}
          onPress={(handleSubmit(submitStep5))}
          style={{ container: tw`px-8  py-3` }}
        />
      </View>
    </View>
  )
}