import React, { useEffect, useRef, useState } from "react";
import { Animated, Pressable, TouchableOpacity, View } from "react-native";
import { StatusBar } from "expo-status-bar";

import Text from '../../../../Components/Text';
import tw from "../../../../styles/tailwind";

import FMIcons from '../../../../Components/FMIcon';
import BottomSheet from "../../../../Components/BottomSheet";
import Button from "../../../../Components/Button";
import NewPlayerEvent, { SuspensionReasonSelector } from "../TabletComponents/NewPlayerEvent";


export type PlayerStats = {
  goals: number,
  yellow_cards: number,
  red_cards: number,
}




export function MatchPlayerFeaturesModal(props: MatchPlayerFeaturesProps){
  if(!props.visible) return null

  const [selectedReason, setSelectedReason] = useState();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [playerStats, setPlayerStats] = useState <null | PlayerStats>();

  const reasonModalWidth = useRef(new Animated.Value(0)).current;


  useEffect(() => {
    setSubmitDisabled(playerStats?.red_cards > 0 && !selectedReason)
  }, [selectedReason]);


  function onStatsChanged(stats:PlayerStats) {

    Animated.timing(reasonModalWidth, {
      toValue: stats.red_cards > 0 ? 420 : 0,
      duration: 300,
      useNativeDriver: false,
    }).start();

    if(stats.red_cards == 0){
      setSelectedReason(null)
    }

    setPlayerStats(stats)
    setSubmitDisabled(stats.red_cards > 0 && ! selectedReason)
  }

  return (
    <View style={tw`flex-1 absolute right-0 top-0 left-0 bottom-0 items-center justify-center row`}>
      <Pressable onPress={props.onRequestClose} style={tw`absolute right-0 top-0 left-0 bottom-0 bg-black/85`} />

      {/* <NewPlayerEvent
          player={props.player}
          leagueTeam={{name: "pepito"}}
          onRequestClose={props.onRequestClose}
          // onSelect={onPlayerNewEvent}
        /> */}

      <View style={tw`bg-white rounded-3xl py-5 px-5 w-[400px] h-[400px]`}>
        <MatchPlayerStatsCapture
          {...props}
          onChange={onStatsChanged}
          submitDisabled={submitDisabled}
          onSubmit={playerStats => {
            // SUBMIT
            if(selectedReason){
              playerStats.red_card_reason = selectedReason
            }

            props.onSubmit?.(playerStats)
          }}
        />
      </View>

      <Animated.View style={{ width: reasonModalWidth, overflow: 'hidden', marginLeft: 10 }}>
        <SuspensionReasonSelector setSelected={setSelectedReason} selected={selectedReason} />
      </Animated.View>
    </View>
  )
}


interface MatchPlayerFeaturesProps {
  visible: boolean,
  bigText: boolean,
  playerStats: PlayerStats,
  onSubmit: (playerStats: PlayerStats) => void,
  onChange?: (playerStats: PlayerStats) => void,
  onRequestClose: () => void,
  player: any, //  Player, <TODOOOOOO
}



function MatchPlayerStatsCapture(props: MatchPlayerFeaturesProps){
  const { player, playerStats, onSubmit, onChange, submitDisabled, bigText } = props

  const [goals, setGoals] = useState(0);
  const [counterYellowCard, setCounterYellowCard] = useState(0);
  const [counterRedCard, setCounterRedCard] = useState(0);

  useEffect(() => {
    setGoals(playerStats?.goals || 0)
    setCounterYellowCard(playerStats?.yellow_cards || 0)
    setCounterRedCard(playerStats?.red_cards || 0)
  }, [player, playerStats]);

  useEffect(() => {
    const playerStats: PlayerStats = {
      goals,
      yellow_cards: counterYellowCard,
      red_cards: counterRedCard,
    }

    onChange?.(playerStats)
  }, [goals, counterYellowCard, counterRedCard]);

  return (
    <View style={tw`flex-1`}>
      <View style={tw`flex-row items-center justify-center mt-4`}>
        <Text style={tw.style(`font-bold text-lg`, bigText && `text-xl`)}>{player?.squad_number}</Text>
        <View style={tw`border-l border-2 border-secondary h-4 mx-2`} />
        <Text style={tw.style(`font-bold text-lg`, bigText && `text-xl`)}>{player?.name}</Text>
      </View>

      <View style={tw`flex-1 flex-col mt-8`}>
        <View style={tw`flex-row items-center justify-between px-2 py-2 mb-4`}>
          <View style={tw`flex-row items-center`}>
            <FMIcons name={"gol"} size={30} color={'#39B54A'} />
            <Text style={tw.style(`text-lg ml-3`, bigText && `text-xl`)}>Goles</Text>
          </View>

          <View style={tw`flex-row items-center `}>
            <TouchableOpacity style={tw`rounded-xl w-10 h-10 bg-gray-50 items-center justify-center`}
              onPress={() => setGoals((goals - 1 > 0) ? goals - 1 : 0)}>
              <FMIcons name={'chev-izq'} size={15} color={'black'} />
            </TouchableOpacity>

            <Text style={tw`font-bold text-xl mx-4`}>{goals}</Text>

            <TouchableOpacity style={tw`rounded-xl w-10 h-10 bg-primary items-center justify-center`}
              onPress={() => setGoals(goals + 1)}>
              <FMIcons name={'chev-der'} size={15} color={'black'} />
            </TouchableOpacity>
          </View>
        </View>


        <View style={tw`flex-row items-center justify-between px-2 py-2 mb-4`}>
          <View style={tw`flex-row items-center`}>
            <FMIcons name={"tarjeta"} size={30} color={'#efc42e'} />
            <Text style={tw.style(`text-lg ml-3`, bigText && `text-xl`)}>Tarjeta A.</Text>
          </View>

          <View style={tw`flex-row items-center `}>
            <TouchableOpacity style={tw`rounded-xl w-10 h-10 bg-gray-50 items-center justify-center`}
              onPress={() => setCounterYellowCard((counterYellowCard - 1 > 0) ? counterYellowCard - 1 : 0)}>
              <FMIcons name={'chev-izq'} size={15} color={'black'} />
            </TouchableOpacity>

            <Text style={tw`font-bold text-xl mx-4`}>{counterYellowCard}</Text>

            <TouchableOpacity style={tw`rounded-xl w-10 h-10 bg-primary items-center justify-center`}
              onPress={() => setCounterYellowCard(counterYellowCard + 1)}>
              <FMIcons name={'chev-der'} size={15} color={'black'} />
            </TouchableOpacity>
          </View>
        </View>

        <View style={tw`flex-row items-center justify-between px-2 py-2 mb-12`}>
          <View style={tw`flex-row items-center`}>
            <FMIcons name={"tarjeta"} size={30} color={'#FF365B'} />
            <Text style={tw.style(`text-lg ml-3`, bigText && `text-xl`)}>Tarjeta R.</Text>
          </View>

          <View style={tw`flex-row items-center `}>
            <TouchableOpacity style={tw`rounded-xl w-10 h-10 bg-gray-50 items-center justify-center`}
              onPress={() => setCounterRedCard((counterRedCard - 1 > 0) ? counterRedCard - 1 : 0)}>
              <FMIcons name={'chev-izq'} size={15} color={'black'} />
            </TouchableOpacity>

            <Text style={tw`font-bold text-xl mx-4`}>{counterRedCard}</Text>

            <TouchableOpacity style={tw.style(`rounded-xl w-10 h-10 bg-primary items-center justify-center`, counterRedCard > 0 && `bg-gray-50`)}
              onPress={() => setCounterRedCard(1)}>
              <FMIcons name={'chev-der'} size={15} color={'black'} />
            </TouchableOpacity>
          </View>
        </View>

        <Button 
          label={"Guardar"}
          disabled={submitDisabled}
          onPress={() => {
            const playerStats: PlayerStats = {
              goals,
              yellow_cards: counterYellowCard,
              red_cards: counterRedCard,
            }
            onSubmit && onSubmit(playerStats)
          }}
          style={{
            label: bigText ? `text-xl` : ``
          }}
        />
      </View>
    </View>
  )
}


export default function MatchPlayerFeaturesBottomSheet(props: MatchPlayerFeaturesProps) {
  const { visible, onRequestClose, player, playerStats, onSubmit } = props

  return (
    <BottomSheet
      visible={visible}
      onRequestClose={() => {
        onRequestClose && onRequestClose()
      }}
      height={400}
      style={{ container: tw`bg-gray rounded-tl-xl rounded-tr-xl  lg:w-100 lg:self-center` }}
    >
      <MatchPlayerStatsCapture {...props} />
    </BottomSheet>
  );
}

