import React, { useState } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';

// import NewPlayerForm from './NewPlayerForm';

import ScanPlayerQR from '../../../../Components/QRScanner';
import Text from '../../../../Components/Text';
import BottomSheet from '../../../../Components/BottomSheet';
import Button from '../../../../Components/Button';

import tw from '../../../../styles/tailwind';
import NewPlayerFormScreen from './NewPlayerFormScreen';



export default function AddPlayerBottomSheet({
  visible, onRequestClose, leagueTeam, leagueUid, selectedRole, minimal = false,
  onPlayerCreated, gender = null, autofocus = false, title, canDuplicateSquadNumber = false, showSquadNumberLabel = false
}) {
  const navigation = useNavigation();
  const route = useRoute()
  const [selectedScreen, setSelectedScreen] = useState();

  if(! leagueTeam) return null

  const isFemaleTeam = leagueTeam.team.gender == "female"


  // leagueUid

  
  return (
    <BottomSheet
      visible={visible}
      onRequestClose={() => {
        setSelectedScreen(0)
        onRequestClose && onRequestClose()
      }}
      height={450}
      style={{ container: tw`bg-gray rounded-tl-xl rounded-tr-xl lg:w-100 lg:self-center` }}
    >
      <NewPlayerFormScreen
        navigation={navigation}
        route={{params: {
          teamUid: leagueTeam.team.uuid,
          leagueUid,
          selectedRole,
        }}}
        minimal
        gender={gender}
        onPlayerCreated={onPlayerCreated}
        autofocus={autofocus}
        title={title}
        canDuplicateSquadNumber={canDuplicateSquadNumber}
        showSquadNumberLabel={showSquadNumberLabel}
      />

      <Text style={tw`absolute right-2 top-2 px-3 py-2`} onPress={onRequestClose}>Cerrar</Text>

      {/* <Text style={tw.style(`mb-4 text-center font-black text-lg mt-4`)}>Nuev{isFemaleTeam ? 'a' : 'o'} Jugador{isFemaleTeam ? 'a' : ''}</Text>
      {
        ! selectedScreen &&
          <>
            <Button
              style={{ container: tw`flex-row items-center mb-3`, label: tw`ml-4`}} 
              label={`Crear nuev${isFemaleTeam ? 'a' : 'o'} jugador${isFemaleTeam ? 'a' : ''}`} 
              onPress={() => {
                // Mandamos a la pantalla de Nuevo Jugador
                navigation.navigate('Admin.Player.New', {
                  teamUid: leagueTeam.team.uuid,
                  leagueUid,
                  selectedRole
                })
              }} 
              icon={'perfil-nav'}
            />

            <Button
              style={{ container: tw`flex-row items-center mb-3`, label: tw`ml-4` }} 
              label={"Escanear código QR"} 
              onPress={() => {
                // Mandamos a la pantalla de escanear qr jugador
                navigation.navigate('Admin.Player.Associate', {
                  teamUid: leagueTeam.team.uuid,
                  leagueUid,
                  selectedRole
                })
              }} 
              icon={'qr'}
            />
          </>
      } */}
    </BottomSheet>
  );
}
