import React, { useState } from 'react';
import { StatusBar } from "expo-status-bar";
import { TouchableOpacity, View } from "react-native";

import Text from '../../../../Components/Text';
import BottomSheet from '../../../../Components/BottomSheet';
import Button from '../../../../Components/Button';

import tw from '../../../../styles/tailwind';

import { useForm } from 'react-hook-form';
import Input from '../../../../Components/Input';

import { Ionicons } from '@expo/vector-icons';


export default function MatchSelectRefereeBottomSheet({ visible, onRequestClose }) {
  const { control, handleSubmit, formState: { errors }, setError, setValue, getValues, register } = useForm();
  const [selectedIndex, setSelectedIndex] = useState(0)

  const referees = [
    { name: "Ruben Agruirre", uuid: 1 },
    { name: "Saúl Sandoval", uuid: 2 },
    { name: "Miguel gonzales", uuid: 3 },
    { name: "Roberto Perez", uuid: 4 },

  ]

  return (

    <BottomSheet
      visible={visible}
      onRequestClose={() => {
        onRequestClose && onRequestClose()
      }}
      height={500}
      style={{ container: tw`px-3 bg-fm-background rounded-tl-xl rounded-tr-xl` }}
    >

      <View style={tw`flex-1 py-4 bg-fm-background`} >
        <Text style={tw`font-bold text-lg text-center mb-4`}>Árbitro del partido</Text>

        <View style={tw`mb-4`}>
          {
            referees.map(referee => {

              const isSelected = (selectedIndex == referee.uuid)

              return <TouchableOpacity
                style={[tw`bg-gray-50 py-3 px-3 rounded-xl mb-3 flex-row items-center`, isSelected && tw`bg-gray-500`]}
                key={referee.uuid}
                onPress={() => { setSelectedIndex(referee.uuid) }}>

                <View style={tw`flex-1 flex-row justify-between  items-center`}>

                  <Text style={[tw`text-secondary ml-5`, isSelected && tw`text-gray`]}>{referee.name}</Text>
                  {
                    isSelected &&
                    <Ionicons name="checkmark" size={19} color="#39B54A" />
                  }
                </View>
              </TouchableOpacity>
            })
          }
        </View>

        <Text style={tw`text-gray-300 text-center mb-4`}>Todos los árbitros que agregres aparecerán en
          el listado para futuros partidos</Text>

        <Input
          name="referee_name"
          control={control}
          type={"text"}
          placeholder="Agregar Árbitro"
          style={{ subcontainer: tw`border-0`, input: tw`font-normal bg-white border border-gray-200 rounded-xl` }}
        />

        <Button
          label={"Guardar"}
          onPress={() => onRequestClose && onRequestClose()}
        />

      </View>
    </BottomSheet>
  );
}
