import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TouchableOpacity, View } from "react-native";

import StepIndicator from "../StepIndicator";
import Text from "../Text";

import tw from "../../styles/tailwind";

import FieldImage from '../../assets/half-field-2.svg'


function PositionSelectorUI({ onValueChange, position }) {

  const renderPosition = (value, style=null) => {
    return <TouchableOpacity
      onPress={() => {
        onValueChange?.(value)
      }}
      style={
        tw.style(
          `border border-gray-100 bg-black/25 rounded-full h-10 w-10`,
          position == value && `bg-primary/30 border-primary`, 
          position == value && { borderWidth: 2},
          style
        )
      }
    />
  }

  return (
    <View style={tw`items-center bg-[#1d5c34] pt-7 pb-4 w-70 h-66 rounded-xl overflow-hidden`}>
      {/* Aca va la imagen */}
      {/* .... */}
      <FieldImage style={tw`absolute left-0 top-0 right-0 h-66`} />


      <View style={tw`w-44 absolute bottom-2`}>
        <View style={tw`flex-row justify-center mb-3`}>
          {renderPosition('forward_left')}
          {renderPosition('forward_center', `-mt-8 mx-3`)}
          {renderPosition('forward_right')}
        </View>

        <View style={tw`flex-row justify-around mt-3`}>
          {renderPosition('defender_left', '-mt-3')}
          {renderPosition('sweeper', 'mx-14')}
          {renderPosition('defender_right', '-mt-3')}
        </View>

        <View style={tw`flex-row justify-center mt-8`}>
          {renderPosition('goalkeeper')}
        </View>
      </View>
    </View>
  )
}

function PositionDisplay({ position }) {
  let positionStr = ''
  let description = ''
  let abreviation = ''

  switch (position) {
    case 'goalkeeper':
      positionStr = 'Portero'
      description = 'El de hasta atrás'
      abreviation = 'PO'
      break;

    case 'forward_left':
      positionStr = 'Delantero Izquierdo'
      description = 'Atacas por la izquierda'
      abreviation = 'DI'
      break;

    case 'forward_center':
      positionStr = 'Delantero central'
      description = 'Atacas por el centro'
      abreviation = 'DC'
      break;

    case 'forward_right':
      positionStr = 'Delantero Derecho'
      description = 'Atacas por la derecha'
      abreviation = 'DD'
      break;

    case 'defender_left':
      positionStr = 'Defensa Izquierdo'
      description = 'Defiendes por la izquierda'
      abreviation = 'DFI'
      break;

    case 'defender_right':
      positionStr = 'Defensa derecho'
      description = 'Defiendes por la derecha'
      abreviation = 'DFD'
      break;

    case 'sweeper':
      positionStr = 'Defensa central'
      description = 'Defiendes central'
      abreviation = 'DFC'
      break;

    default:
      positionStr = position
      description = ''
      abreviation = ''
      break;
  }
  // {abreviation != '' ? `(${abreviation})` : ''}
  return (
    <View>
      <Text style={tw`text-center text-white text-lg bg-[#666] mt-5 rounded-xl px-5 py-3`}>
         {positionStr}
      </Text>

      {/* <Text style={tw`text-center`}>{description}</Text> */}
    </View>
  )
}

export default function PosistionSelector({ onChange, position }){
  const [selected, setSelected] = useState();

  useEffect(() => {
    setSelected(position)
  }, [position]);

  function onPositionChange(position){
    setSelected(position)
    onChange?.(position)
  }

  return (
    <View style={tw`items-center`}>
      {/* Selector de posición */}
      <PositionSelectorUI
        position={selected}
        onValueChange={onPositionChange}
      />


      {/* Texto descriptivo de posición */}
      <View style={tw.style(``)}>
        <PositionDisplay position={selected} />
      </View>
    </View>
  )
}