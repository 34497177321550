import React, { useEffect, useState } from "react";
import { View, Button, Platform } from "react-native";

import tw from "../../styles/tailwind";

//* Pruebas SVGs
/*
https://github.com/react-native-svg/react-native-svg
https://jakearchibald.github.io/svgomg/
*/


import { SvgCssUri, SvgXml, SvgUri, SvgCss } from 'react-native-svg';

import teamBadgesXmls from './badgesData'

export const BadgeSvg = ({ xml, width = 100, height = 100, style = null, fill = undefined, ...props }) => {

  const Svg = !! fill ? SvgXml : SvgCss

  // WEB_ADAPT
  if(Platform.OS === "web"){
    return <img src={`data:image/svg+xml;utf8,${encodeURIComponent(xml)}`} style={{ width, height }} />
  }

  return (
    <Svg
      xml={xml}
      width={width}
      height={height}
      style={tw.style(
        // `border border-white`, // Debug
        style
      )}
      fill={fill}
      {...props}
    />
  )
}


interface TeamBadgeProps{
  team: Team | undefined,
  height: number,
  width: number,
}

export default function TeamBadge({team, ...props}){
  const badgeName = (typeof team?.badge == "object" && team?.badge?.name) ? team?.badge?.name : "default"
  const badgeColor = (typeof team?.badge == "object" && team?.badge?.color) ? team?.badge?.color : "#fff"
  const xml = teamBadgesXmls[badgeName] ? teamBadgesXmls[badgeName] : teamBadgesXmls.default

  return <BadgeSvg xml={xml} {...props} />
}





export function EscudosShowcase(){
  const [color, setColor] = useState("0f0");

  return (
    <View>
      <View style={tw`flex-row`}>
        <TeamBadge color={"white"} fill={"pink"} />
        <TeamBadge name={"maria"} fill={`#${color}`} />
        <TeamBadge name={"dados"} fill="white" />
      </View>

      <View style={tw`flex-row items-center justify-between`}>
        <TeamBadge name={"dragon"} fill={`#${color}`} width={300} height={300} viewBox={`0 100 800 600`} preserveAspectRatio={"xTopYTop slice"} />
        <Button onPress={() => setColor(Math.floor(Math.random() * 16777215).toString(16))} title="Color" />
      </View>

      <View style={tw`flex-row`}>
        <TeamBadge fill={`#${color}`} />
        <TeamBadge name={"dados"}  fill={`#${color}`} />
        <TeamBadge name={"dragon"} fill={`#${color}`} viewBox={`0 100 800 600`} preserveAspectRatio={"xTopYTop slice"} />
      </View>

      <View style={tw`flex-row`}>
        <TeamBadge fill={`#${color}`} />
        <TeamBadge name={"camaleon_2"}  fill={`#${color}`} />
        <TeamBadge name={"maria"} fill={`#${color}`} />
      </View>

      <View style={tw`flex-row`}>
        <TeamBadge fill={`#${color}`} />
        <TeamBadge name={"dados"}  fill={`#${color}`} />
        <TeamBadge name={"dragon"} fill={`#${color}`} viewBox={`0 100 800 600`} preserveAspectRatio={"xTopYTop slice"} />
      </View>

      <View style={tw`flex-row`}>
        <TeamBadge fill={`#${color}`} />
        <TeamBadge name={"dados"}  fill={`#${color}`} />
        <TeamBadge name={"maria"} fill={`#${color}`} />
      </View>

      <View style={tw`flex-row`}>
        <TeamBadge fill={`#${color}`} />
        <TeamBadge name={"dados"}  fill={`#${color}`} />
        <TeamBadge  fill={`#${color}`} />
      </View>

      <View style={tw`flex-row`}>
        <TeamBadge fill={`#${color}`} />
        <TeamBadge name={"dados"}  fill={`#${color}`} />
        <TeamBadge  fill={`#${color}`} />
      </View>

      <View style={tw`flex-row`}>
        <TeamBadge fill={`#${color}`} />
        <TeamBadge name={"dados"}  fill={`#${color}`} />
        <TeamBadge  fill={`#${color}`} />
      </View>

      <View style={tw`flex-row`}>
        <TeamBadge fill={`#${color}`} />
        <TeamBadge name={"dados"}  fill={`#${color}`} />
        <TeamBadge  fill={`#${color}`} />
      </View>

      <View style={tw`flex-row`}>
        <TeamBadge fill={`#${color}`} />
        <TeamBadge name={"dados"}  fill={`#${color}`} />
        <TeamBadge  fill={`#${color}`} />
      </View>

      {/* <SvgCssUri
        width="100%"
        height="100%"
        uri={require('./juegosucio.svg')}
      />
      <SvgUri
        width="100%"
        height="100%"
        uri={require('./juegosucio.svg')}
      />
      <SvgUri
        width="100%"
        height="100%"
        uri="http://thenewcode.com/assets/images/thumbnails/homer-simpson.svg"
      /> */}
    </View>
  )
}