import React, { useEffect, useState } from "react";
import { StyleSheet, View, TouchableOpacity, ScrollView, Image } from "react-native";
import { StatusBar } from "expo-status-bar";
import Text from '../../../Components/Text';
import commonStyle, { theme } from '../../../styles/common.style'

import tw from '../../../styles/tailwind';
import { useNavigation, useRoute } from "@react-navigation/native";

import api from '../../../utils/api'
import AddTeamBottomSheet from "../Teams/AddTeamBottomSheet";
import Button from "../../../Components/Button";

import TeamBadge from "../../../Components/Teams/TeamBadge";
import Loading from "../../../Components/Loading";


function TeamRow({ leagueTeam, leagueUid, leagueName }){
  const navigation = useNavigation();

  return (
    <TouchableOpacity
      style={tw`bg-secondary mb-3 px-3 py-2 rounded-xl flex-row items-center`}
      onPress={() => navigation.navigate('Admin.TeamDetail', { teamUid: leagueTeam.team.uuid, leagueUid, leagueName: leagueName })}>

      <TeamBadge team={leagueTeam.team} width={40} height={40}/>

      <View style={tw`ml-3`}>
        <Text style={tw`text-gray text-lg`}>{leagueTeam.name}</Text>
        <Text style={tw`text-gray text-md`}>{leagueTeam.team.players_count} jugador{leagueTeam.team.gender == "female" ? "a" : "e"}{leagueTeam.team.players_count != 1 ? "s" : ""}</Text>
      </View>
    </TouchableOpacity>
  )
}


export default function LeagueTeams({leagueName}) {
  const [leagueTeams, setLeagueTeams] = useState([]);
  const [modalShown, setModalShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const navigation = useNavigation();
  const route = useRoute();
  const leagueUid = route.params?.leagueUid;

  useEffect(() => {
    reloadTeams()
  }, []);


  // TODO: mover a redux
  function reloadTeams(){
    setIsLoading(true)

    api.get(`/leagues/${leagueUid}/teams?with_deleted=1`)
      .then(({ data }) => {
        setLeagueTeams(data)
        setIsLoading(false)
      })
      .catch(error => {
        console.log("api error", error)
        setIsLoading(false)
      })
  }


  const deletedTeams = leagueTeams.filter((team: LeagueTeam) => !! team.deleted_at)

  return (
    <ScrollView>
      {
        <AddTeamBottomSheet
          visible={modalShown}
          leagueId={leagueUid}
          onRequestClose={() => {
            setModalShown(false)
          }}
          onTeamAssociated={(leagueTeam) => {
            setModalShown(false)
            reloadTeams()
            navigation.navigate('Admin.TeamDetail', {teamUid: leagueTeam.team.uuid, leagueUid})
          }}
          
          onTeamCreated={(team) => {
            setModalShown(false)
            reloadTeams()
            navigation.navigate('Admin.TeamDetail', {teamUid: team.uuid, leagueUid})
          }}
          
        />
      }

      <View style={tw.style(`py-5 px-3`, styles.container)}>
        <Loading visible={isLoading} />

        {/* Listado equipos */}
        <View>
          {
            leagueTeams.filter((team:LeagueTeam) => ! team.deleted_at).sort((tA, tB) => tA.name.localeCompare(tB.name)).map((leagueTeam:LeagueTeam) => {
              return <TeamRow key={leagueTeam.uuid} leagueUid={leagueUid} leagueTeam={leagueTeam} leagueName={leagueName} />
            })
          }
        </View>


        {
          deletedTeams.length > 0 &&
            <View style={tw`mt-4`}>
              <Text style={tw`text-yellow-200 text-center mb-3`}>Equipos eliminados</Text>

              {
                deletedTeams.sort((tA, tB) => tA.name.localeCompare(tB.name)).map((leagueTeam:LeagueTeam) => {
                  return <TeamRow key={leagueTeam.uuid} leagueUid={leagueUid} leagueTeam={leagueTeam} leagueName={leagueName} />
                })
              }
            </View>
        }

        <Button
          style={{container:tw`flex-row items-center py-4`, label:tw`font-bold text-md`}}
          onPress={() => {
            setModalShown(true)
          }}
          label={`Agregar equipo`}
        />
      </View>
    </ScrollView>
  );
}




const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
