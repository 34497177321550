import api from "../../utils/api";

const types = {
};


// DOING: REDUX_NORMALIZR
// mover a esquema
// import { denormalize, normalize, schema } from 'normalizr';
// export const leagueTeamSchema = new schema.Entity('league_teams', undefined, { idAttribute: "uuid" });


//! Fix puerco!! Ver entities.ts para entender lo del "assign"


export function loadLeagueTeam(leagueId, teamId, assign = true) {
  return (dispatch, getState) => {
    return api.get(`/leagues/${leagueId}/teams/${teamId}`)
      .then(({ data }) => {
        // REDUX_NORMALIZR - [WIP]
        // const normalizedData = normalize(data, leagueTeamSchema);
        // dispatch({ entities: normalizedData.entities, type: "UPDATE_ENTITIES" })
        // setMatchesResult(normalizedData.result)

        dispatch(updateLeagueTeam(leagueId, data, assign))

        return data;
      })
      .catch(error => {

      })
  }
}


export function updateLeagueTeam(leagueId, leagueTeam: LeagueTeam, assign=false) {
  return (dispatch, getState) => {
    dispatch({
      entities: {
        leagueTeams: {
          [`${leagueId}-${leagueTeam.team.uuid}`]: leagueTeam,
        }
      },
      type: "UPDATE_ENTITIES",
      assign,
    })
  }
}




const initialState = {

};



export const reducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {


    default:
      return state;
  }
};
