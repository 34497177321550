import React, { useEffect, useState } from "react";
import { ScrollView, View, TouchableOpacity, Image, Platform } from "react-native";
import Text from "../../Components/Text";

import api from '../../utils/api'

import tw from '../../styles/tailwind';
import Button from "../../Components/Button";

import { useSelector } from "react-redux";

import FMIcon from '../../Components/FMIcon';
import { useNavigation } from "@react-navigation/native";
import Loading from "../../Components/Loading";

import * as Device from 'expo-device';


import {
  HeaderButtons,
  HiddenItem,
  OverflowMenu,
  Divider,
} from 'react-navigation-header-buttons';
import { Ionicons } from "@expo/vector-icons"
import FMIcons from "../../Components/FMIcon";



function LeagueInfoBar({ league, style }) {
  const round = "10/12"// <<<<

  return (

    <View style={tw.style(`flex-row items-center rounded-br-xl rounded-bl-xl bg-secondary py-3 px-3`, style?.container)}>

      <View style={tw`flex-1 flex-row justify-center items-center`}>
        <FMIcon name="equipos-b" size={18} color={"#efc42e"} />
        <Text style={tw`text-md text-gray ml-2`}>{league.teams_count}/{league.max_teams}</Text>
      </View>


      <View style={tw`flex-1 flex-row justify-center items-center`}>
        <FMIcon name="jugadores-b" size={18} color={"#efc42e"} />
        <Text style={tw`text-md text-gray ml-2`}>{league.players_count}</Text>
      </View>


      <View style={tw`flex-1 flex-row justify-center items-center`}>
        <FMIcon name="progreso-b" size={18} color={"#efc42e"} />
        <Text style={tw`text-md text-primary ml-2`}>J<Text style={tw`text-md text-gray`}>{round}</Text></Text>
      </View>

    </View>
  )
}


function LeagueCard({league}){
  const navigation = useNavigation();

  const genderStrs = {"male": "Varonil", "female": "Femenil", "mixed": "Mixto"}

  return (
    <TouchableOpacity
      onPress={() => navigation.navigate('Admin.League.Home', { leagueUid: league.uuid })}
      style={tw`flex-1 bg-gray-500 rounded rounded-xl mb-5 pt-3`}>

      <View style={tw`flex-1 flex-row mb-3 px-3 `}>

        <Image source={{ uri: league.image_url }} style={tw`h-20 w-20 mr-5 rounded-xl`} />

        <View style={tw`flex-col flex-shrink justify-around`}>
          <Text style={tw`mb-1 text-gray text-lg`}>{league.name}</Text>
          <Text style={tw`mb-1 text-primary text-md`}>{league.match_days_str}</Text>
          <Text style={tw`text-md text-gray-300`}>{league.variation_str} - {genderStrs[league.gender]}</Text>
        </View>

      </View>

      <LeagueInfoBar league={league} />
    </TouchableOpacity>
  )
}




export default function AdminHome({ navigation, route }) {
  const [stadiums, setStadium] = useState(null);
  const [isTablet, setIsTablet] = useState(false);


  // TODO: SELECTED_STADIUM_STATE
  const selectedStadiumId = useSelector((state) => state.status?.selectedStadiumId);
  const stadium = useSelector((state) => state.status?.stadiums?.find(e => e.uuid == selectedStadiumId));

  const reload = route.params?.reload

  // Una vez que status sea cargado nos traemo la info del primer estadio
  useEffect(() => {
    if (stadium) {
      loadStadiumHome(stadium.uuid)
    }
  }, [stadium, reload]);

  function loadStadiumHome(stadiumId:string){
    api.get(`/stadiums/${stadiumId}/home`)
      .then(({ data }) => {
        setStadium(data)
      })
      .catch(error => { })
  }

  useEffect(() => {
    const getDeviceType = async () => {
      const deviceType = await Device.getDeviceTypeAsync();

      setIsTablet(deviceType == Device.DeviceType.TABLET)
    }

    getDeviceType()
  }, []);


  return (
    <ScrollView style={tw`flex-1 py-5 px-3`}>
      <View style={tw`mb-10`}>

        {/*Ligas*/}
        <View style={tw`pt-3`}>
          {/* Header */}
          <View style={tw`row justify-between mb-5`}>
            <Text style={tw.style(`text-xl text-white`)}>Mis Torneos</Text>

            <HeaderButtons>
              <OverflowMenu
                style={{ marginRight: -5 }}
                OverflowIcon={({ color }) => (
                  <Ionicons name="ellipsis-vertical-outline" color={"white"} size={24} />
                )}
              >
                <HiddenItem
                  title={"Nuevo Torneo"}
                  titleStyle={{}}
                  onPress={() => navigation.navigate("Admin.League.New", { stadiumId: stadium.uuid })}
                />
              </OverflowMenu>
            </HeaderButtons>
          </View>
          

          {/* Cargando */}
          <Loading visible={!stadiums} />

          {
            stadiums?.leagues.map(league => 
              <LeagueCard key={league.uuid} league={league} />
            )
          }
        </View>


        {/* Botón crear torneo */}
        {/* <Button
          label={"Crear Torneo"}
          onPress={() => {
            navigation.navigate("Admin.League.New", { stadiumId: stadium.uuid })
          }}
        /> */}


        {
          (Platform.OS !== "web" && isTablet) &&

          <TouchableOpacity
            onPress={() => navigation.navigate("Admin.RefereesScreen")}
            style={tw`border border-primary px-10 py-5 mb-2 rounded-lg items-center self-center mt-10`}
          >
            <Text style={tw`text-primary mb-5 `}>Pantalla árbitros</Text>
            <FMIcons name="cancha" size={50} color={tw.color('primary')} />
          </TouchableOpacity>
        }
       
      </View>
    </ScrollView>
  );
}

