import React, { useEffect, useRef } from 'react';
import { KeyboardAvoidingView, Platform } from 'react-native';


import RBSheet from "react-native-raw-bottom-sheet";
import Button from './Button';

import tw from '../styles/tailwind';

export default function BottomSheet(props) {
  const refRBSheet = useRef();

  useEffect(() => {
    if (props.visible)
      refRBSheet.current.open()
    else
      refRBSheet.current.close()
  }, [props.visible])


  return (
    <RBSheet
      ref={refRBSheet}
      height={props.height}
      openDuration={250}
      onClose={props.onRequestClose}
      customStyles={{
        container: tw.style(
          `rounded-tl-xl rounded-tr-xl`,
            {
            // justifyContent: "center",
            // alignItems: "center",
            // height: 'auto',
            // maxHeight: 600,
            // flexGrow: 1,
            // flex: 1,
            // paddingHorizontal: 30,
            ...props.style?.container,
        }),
      }}
    >
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={tw`flex-1`}
      >
      {props.children}
      </KeyboardAvoidingView>
    </RBSheet>
  )
}
