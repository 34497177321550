import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity } from "react-native"


import tw from '../../../../styles/tailwind';
import Text from '../../../../Components/Text';
import TabletModal from './TabletModal';


import { useForm } from 'react-hook-form';
import Input from '../../../../Components/Input';
import Button from '../../../../Components/Button';
import api from '../../../../utils/api';
import TeamBadge from '../../../../Components/Teams/TeamBadge';
import Loading from '../../../../Components/Loading';
import FMIcons from '../../../../Components/FMIcon';

function TeamPaymentForm({ leagueId, leagueTeam, matchId, onPaymentCreated}){
  const { control, handleSubmit, formState, formState: { errors }, setError } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  function onSubmit(data) {
    const _data = {
      ...data,
      type: "arbitration",
      match_id: matchId,
    }

    setIsSubmitting(true)

    api.post(`/leagues/${leagueId}/teams/${leagueTeam.team.uuid}/payments`, _data)
      .then(response => {
        setIsSubmitting(false)
        onPaymentCreated?.(response.data)
      })
      .catch(({ response }) => {
        setIsSubmitting(false)
        alert("Error al registrar el pago")

        console.log("Error al registrar pago", response?.data)
      })
  }

  return (
    <View>
      <Input
        name="amount"
        label={'Monto de pago'}
        autoFocus={true}
        control={control}
        keyboardType='phone-pad'
        rules={{ required: "Monto Requerido", pattern: { value: /^[0-9]+(\.[0-9]{1,2})?$/, message: "Monto inválido" } }}
        style={{ container: tw`mt-5`, label: tw`bg-transparent font-normal ml-1` }}
      />

      <Button
        onPress={handleSubmit(onSubmit)}
        label="Registrar Pago"
        disabled={isSubmitting}
        style={{container: `mb-0`}}
      />
    </View>
  )
}



export default function TeamDetailModal({
  onRequestClose, visible, leagueTeam, stadium, match
}: { leagueTeam: LeagueTeam, stadium: Stadium})
{
  const [isLoading, setIsLoading] = useState(false);
  const [teamPayments, setTeamPayments] = useState([]);
  const [showNewPaymentForm, setShowNewPaymentForm] = useState(false);

  useEffect(() => {
    setTeamPayments([])
    setShowNewPaymentForm(false)

    if (leagueTeam){
      loadPayments()
    }
  }, [leagueTeam]);


  function loadPayments() {
    setIsLoading(true)

    api.get(`/leagues/${match.league.uuid}/teams/${leagueTeam.team.uuid}/payments`, {params: {match_id: match.uuid}})
      .then(({data}) => {
        setTeamPayments(data)
        setIsLoading(false)
      })
      .catch(({ response }) => {
        setIsLoading(false)
        // alert("Error al registrar el pago")

        console.log("Error al registrar pago", response?.data)
      })
  }


  return (
    <TabletModal onRequestClose={onRequestClose} visible={visible} showCloseButton>
      <View style={tw`items-center mb-4`}>
        <View style={tw`row`}>
          <TeamBadge team={leagueTeam?.team} width={40} height={40} />

          <Text style={tw`text-xl text-center ml-3`}>{leagueTeam?.name}</Text>
        </View>
      </View>

      {
        leagueTeam?.stats &&
          <View style={tw`py-2`}>
            <Text>Posición: {leagueTeam.stats.position}</Text>
            <Text>Ganados: {leagueTeam.stats.win} / Perdidos: {leagueTeam.stats.loss} / Empatados: {leagueTeam.stats.draw} </Text>
            <Text>Goles a favor: {leagueTeam.stats.goals_for} / En contra: {leagueTeam.stats.goals_against}</Text>
          </View>
      }

      <View style={tw`mb-5 border-t mt-3 pt-3`}>
        <Text style={tw`text-lg mb-2`}>Pago arbitraje:</Text>

        {
          isLoading &&
            <View style={tw`items-center -my-6`}>
              <Loading visible={isLoading} />
            </View>
        }

        {
          ! isLoading && teamPayments.length == 0 &&
            <Text style={tw`italic text-center`}>Sin pago registrado</Text>
        }

        {
          teamPayments.map(payment =>
            <View key={payment.uuid} style={tw`row justify-between`}>
              <Text>{payment.description}</Text>
              <Text style={tw`font-bold text-lg ml-5`}>${payment.amount}</Text>
            </View>
          )
        }
      </View>


      {
        (stadium.settings?.referee_receives_payments && ! showNewPaymentForm) &&
          <TouchableOpacity
            onPress={() => setShowNewPaymentForm(true)}
            style={tw`bg-primary/30 items-center row justify-center rounded-lg border border-primary py-2 px-4`}
          >
            <FMIcons name="costo-arbitraje" size={24} />
            <Text style={tw`ml-3`}>Registrar pago arbitraje</Text>
          </TouchableOpacity>
      }

      {
        showNewPaymentForm &&
          <TeamPaymentForm
            leagueId={match.league.uuid}
            leagueTeam={leagueTeam}
            matchId={match.uuid}
            onPaymentCreated={payment => {
              setTeamPayments([
                ...teamPayments,
                payment
              ])

              setShowNewPaymentForm(false)
            }}
          />
      }

    </TabletModal>
  )
}

