import React, { useState, useEffect } from 'react';
import { View, ScrollView } from "react-native"

import tw from '../../styles/tailwind';
import Text from '../../Components/Text';

export default function PrivacyScreen({ }) {

  return (
    <ScrollView style={tw`px-3`}>
      <Text style={tw`text-white`}>OUVIO, S.A. DE C.V., mejor conocido como futmaster.app, con domicilio en calle Valle del Silencio 108, colonia Valle de San Javier, ciudad Pachuca de Soto, municipio o delegación Pachuca de Soto, c.p. 42086, en la entidad de Hidalgo, país México, y portal de internet privacidad@futmaster.app, es el responsable del uso y protección de sus datos personales, y al respecto le informamos lo siguiente:</Text>
      <Text style={tw`text-white text-lg mt-4 mb-1`}><Text style={tw`text-white`}>¿Para qué fines utilizaremos sus datos personales?</Text></Text>
      <Text style={tw`text-white`}>Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son necesarias para el servicio que solicita:</Text>
      <View>
        <Text style={tw`text-white`}>● Verificar los datos personales del usuario</Text>
        <Text style={tw`text-white`}>● Publicar los datos en futmaster.app para búsqueda de los negocios en internet</Text>
        <Text style={tw`text-white`}>● Mercadotecnia o publicitaria</Text>
        <Text style={tw`text-white`}>● Prospección comercial</Text>
      </View>
      <Text style={tw`text-white text-lg mt-4 mb-1`}>
        <Text style={tw`text-white`}>¿Qué datos personales utilizaremos para estos fines?</Text>
      </Text>
      <Text style={tw`text-white`}>Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales:</Text>
      <View>
        <Text style={tw`text-white`}>● Nombre</Text>
        <Text style={tw`text-white`}>● Fecha de nacimiento ● Correo electrónico</Text>
        <Text style={tw`text-white`}>● Edad</Text>
      </View>

      <Text style={tw`text-white text-lg mt-4 mb-1`}>
        <Text style={tw`text-white`}>¿Cómo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso?</Text>
      </Text>
      <Text style={tw`text-white`}>Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación); así como oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.</Text>
      <Text style={tw`text-white`}>Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar la solicitud respectiva a través del siguiente medio:</Text>
      <Text style={tw`text-white`}>privacidad@futmaster.app</Text>
      <Text style={tw`text-white`}>Para conocer el procedimiento y requisitos para el ejercicio de los derechos ARCO, ponemos a su disposición el siguiente medio:</Text>
      <Text style={tw`text-white`}>privacidad@futmaster.app</Text>
      <Text style={tw`text-white`}>Los datos de contacto de la persona o departamento de datos personales, que está a cargo de dar trámite a las solicitudes de derechos ARCO, son los siguientes:</Text>
      <Text style={tw`text-white`}>
        a) Nombre de la persona o departamento de datos personales: Departamento de Privacidad FutMaster{"\n"}
        b) Domicilio: Blvd Real del Valle 911, colonia Real del Valle, ciudad Pachuca de Soto, municipio o delegación Pachuca de Soto, c.p. 42086, en la entidad de Hidalgo, país México{"\n"}
        c) Correo electrónico: privacidad@futmaster.app{"\n"}
        d) Número telefónico: 771-216-2398
      </Text>
      <Text style={tw`text-white text-lg mt-4 mb-1`}>
        <Text style={tw`text-white`}>Usted puede revocar su consentimiento para el uso de sus datos personales</Text>
      </Text>
      <Text style={tw`text-white`}>Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales. Asimismo, usted deberá considerar que para ciertos fines, la revocación de su consentimiento implicará que no le podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.</Text>
      <Text style={tw`text-white`}>Para revocar su consentimiento deberá presentar su solicitud a través del siguiente medio: privacidad@futmaster.app</Text>
      <Text style={tw`text-white`}>Con relación al procedimiento y requisitos para la revocación de su consentimiento, le informamos lo siguiente:</Text>
      <Text style={tw`text-white`}>a) ¿A través de qué medios pueden acreditar su identidad el titular y, en su caso, su representante, así como la personalidad este último?{"\n"} A través de correo electrónico a privacidad@futmaster.app</Text>
      <Text style={tw`text-white`}>b) ¿Qué información y/o documentación deberá contener la solicitud?{"\n"} Nombre, teléfono y correo electrónico</Text>
      <Text style={tw`text-white`}>c) ¿En cuántos días le daremos respuesta a su solicitud?{"\n"} 2 días hábiles</Text>
      <Text style={tw`text-white`}>d) ¿Por qué medio le comunicaremos la respuesta a su solicitud?{"\n"} Correo electrónico</Text>
      <Text style={tw`text-white`}>e) Para mayor información sobre el procedimiento, ponemos a disposición los siguientes medios:{"\n"} privacidad@futmaster.app</Text>
      <Text style={tw`text-white text-lg mt-4 mb-1`}>
        <Text style={tw`text-white`}>¿Cómo puede limitar el uso o divulgación de su información personal?</Text>
      </Text>
      <Text style={tw`text-white`}>Con objeto de que usted pueda limitar el uso y divulgación de su información personal, le ofrecemos los siguientes medios:</Text>
      <Text style={tw`text-white`}>enviar un correo electrónico a privacidad@futmaster.app</Text>
      <Text style={tw`text-white text-lg mt-4 mb-1`}>
        <Text style={tw`text-white`}>El uso de tecnologías de rastreo en nuestro portal de internet</Text>
      </Text>
      <Text style={tw`text-white`}>Le informamos que en nuestra página de internet utilizamos cookies, web beacons u otras tecnologías, a través de las cuales es posible monitorear su comportamiento como usuario de internet, así como brindarle un mejor servicio y experiencia al navegar en nuestra página. Los datos personales que recabamos a través de estas tecnologías, los utilizaremos para los siguientes fines:</Text>
      <Text style={tw`text-white`}>Para identificar el lugar donde el usuario está localizado para ofrecer búsquedas locales sobre negocios y promociones que le puedan interesar.</Text>
      <Text style={tw`text-white`}>Los datos personales que obtenemos de estas tecnologías de rastreo son los siguientes:</Text>
      <Text style={tw`text-white`}>Identificadores, nombre de usuario y contraseñas de una sesión Región en la que se encuentra el usuario{"\n"} Tipo de navegador del usuario{"\n"} Tipo de sistema operativo del usuario{"\n"} Búsquedas realizadas por un usuario</Text>

      <Text style={tw`text-white text-lg mt-4 mb-1`}>
        <Text style={tw`text-white`}>¿Cómo puede conocer los cambios en este aviso de privacidad?</Text>
      </Text>
      <Text style={tw`text-white`}>El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas.</Text>
      <Text style={tw`text-white`}>Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a través de: futmaster.app.</Text>
      <Text style={tw`text-white`}>El procedimiento a través del cual se llevarán a cabo las notificaciones sobre cambios o actualizaciones al presente aviso de privacidad es el siguiente:</Text>
      <Text style={tw`text-white`}>Se comunicará por medio de correo electrónico y publicación en la página futmaster.app</Text>

    </ScrollView>
  )
}

