import React, { useEffect, useState } from 'react';
import { View, ScrollView, TextInput, TouchableOpacity } from 'react-native'
import Text from '../../../../Components/Text';

import api from "../../../../utils/api"
import tw from '../../../../styles/tailwind';
import { useForm } from "react-hook-form";
import Input from '../../../../Components/Input';
import Button from '../../../../Components/Button';
import ImagesPicker from '../../../../Components/ImagesPicker';
import Select from '../../../../Components/Select';
import CheckButton from '../../../../Components/CheckButton';
import OptionsSelect from '../../../../Components/OptionsSelect';

import FMIcons from '../../../../Components/FMIcon';

import MaskInput, { Masks } from 'react-native-mask-input';
import { isLoading } from 'expo-font';
import AddPlayerVoice from './AddPlayerVoice';
import { Ionicons } from "@expo/vector-icons"


// TODO: poder pasar el leagueTeam y determinar el sexo del compx si el team no es mixed

interface NewPlayerFormScreenProps {
  player?: Player,
  gender?: "male" | "female",
  canDuplicateSquadNumber?: boolean,
}

export default function NewPlayerFormScreen({ 
  navigation, route, minimal = false, onPlayerCreated, player, gender, autofocus = false, title, canDuplicateSquadNumber,
  showSquadNumberLabel=false
}:NewPlayerFormScreenProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { control, handleSubmit, formState, formState: { errors }, setError, setValue, reset, setFocus } = useForm();
  const [image, setImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState();
  const [showVoiceDetection, setShowVoiceDetection] = useState(false);

  const { teamUid, leagueUid, selectedRole } = route.params
  const isFemale = gender === "female"


  useEffect(() => {
    if(autofocus){
      setTimeout(() => {
        setFocus("name")
      }, 500) // < tiempo animacion bottomsheet
    }
    
    // setValue('gender', 'male')
  }, []);


  useEffect(() => {
    if(player){
      reset({
        name: player.first_name,
        last_name: player.last_name,
        second_last_name: player.second_last_name,
        squad_number: player.squad_number,
        gender: player.gender,
        // birthdate
        is_representative: player.pivot.roles?.indexOf("representative") !== -1,
      })
    }else{
      resetForm()
    }
  }, [player]);


  function resetForm(){
    // is_representative
    reset({ name: "", last_name: "", second_last_name: "", squad_number: "", birthdate: "", gender: gender,  })
  }


  function submitPlayer(data) {
    const _data = {
      ...data,
      // aqui también podemos meter el uuid de las imagenes a subir
    }


    if(player){
      _data.roles = data.is_representative ? ["representative", "player"] : [ "player"]
    }else{
      _data.roles = selectedRole == "representative" ? [selectedRole, "player"] : [selectedRole]
    }


    if(image){
      _data.image_uuid = image.uuid
    }

    if (canDuplicateSquadNumber){
      _data.can_duplicate_squad_number = true
    }

    setIsSubmitting(true)

    const url = player ? `leagues/${leagueUid}/players/${player.uuid}` : `/leagues/${leagueUid}/teams/${teamUid}/players`

    console.log("submitPlayer", _data, url)

    api.post(url, _data)
      .then(({ data }) => {
        
        //
        // WHAT? Abstraer
        // TODO: Meter a redux?
        //

        if (onPlayerCreated){
          onPlayerCreated(data)
          resetForm()
          setIsSubmitting(false)
          return
        }

        // Regresamos a la pantalla del detalle del equipo
        // Deprecated? Esto es si se usa como screen  algone
        navigation.navigate("Admin.TeamDetail", {
          teamUid,
          leagueUid,
          createdPlayerId: data.uuid,
        })
      })
      .catch(({ response }) => {
        setIsSubmitting(false)

        if(response.data.message){
          setErrorMessage(response.data.message)
        }
        
        if (response?.data?.errors) {
          Object.keys(response.data.errors).forEach(errorKey => {
            setError(errorKey, { type: "manual", message: response.data.errors[errorKey][0] })
          })
        }
      })
  }


  // PLAYER_IMAGE
  const onImageChanged = (data) => {
    if (data !== null) {
      console.log("recibiendo img data en parent", data);
      setImage(data)
    } else {
      // console.log("nohaydatos")
      setImage(null)
    }
  }

  useEffect(() => {
    // navigation.setOptions({
    //   title: "Crear Perfil",
    //   headerStyle: {
    //     backgroundColor: '#ebebeb',
    //   },
    //   headerTintColor: 'black',
    //   background: 'red',
    // })
  }, []);


  

  return (
    <ScrollView style={tw`flex-1 px-3 pt-5 bg-gray`} contentContainerStyle={{ flexGrow: 1 }}>
      <Text style={tw`text-lg font-bold text-center `}>{title ?? `${isFemale ? "Nueva Jugadora" : "Nuevo jugador"}`}</Text>

      {
        showVoiceDetection ?
          <View style={tw`flex-1 justify-center`}>
            <AddPlayerVoice
              autoStart
              onPlayerNameDetected={result => {
                setValue('name', result.name)
                setValue('last_name', result.last_name)
                setValue('second_last_name', result.second_last_name)
                setValue('squad_number', result.squad_number)

                if (result.gender) {
                  setValue('gender', result.gender)
                }

                setShowVoiceDetection(false)
              }}
              onRequestClose={() => setShowVoiceDetection(false)}
              style={tw``}
            />
          </View>

        :

        <View>
          {
            ! minimal &&
              <>
                <ImagesPicker
                  label={"Subir fotografía"}
                  onImageChanged={onImageChanged}
                  initialUrl={player?.image_url}
                  style={{ 
                    buttonWrapper: tw`bg-secondary border-primary border-solid`, 
                    label: tw`text-gray`,
                    buttonWrapperFill: tw`items-center w-26 h-26  rounded-xl bg-transparent border-dashed border-2 border-gray-300 p-1` 
                  }}
                />

                <CheckButton
                  label={"Es representante"}
                  control={control}
                  name={"is_representative"}
                  rules={{}}
                  style={{ label: tw`text-gray-500`, container: tw`border-[#999] mt-5 mb-4` }}
                  activeColor={"black"}
                />

              </>
          }

          <View>
            <Input
              name="name"
              control={control}
              rules={{ required: "El nombre es requerido" }}
              type={"text"}
              label="Nombre(s) *"
              style={{ container: tw`mt-8`, label: tw`bg-transparent font-normal ml-1` }}
            // autoFocus={autofocus}
            />

            <TouchableOpacity style={tw`absolute right-1 bottom-7 p-[6px] bg-red/0`} onPress={() => setShowVoiceDetection(true)}>
              <Ionicons name={"mic"} size={25} color={"#666"} />
            </TouchableOpacity>
          </View>
          


          <View style={tw`row`}>
            <Input
              name="last_name"
              control={control}
              rules={{ required: "Apellido Paterno Requerido" }}
              type={"text"}
              label="Apellido Paterno *"
              style={{ container: tw`flex-1 mt-5 mr-2`, label: tw`bg-transparent font-normal ml-1` }}

            />

            <Input
              name="second_last_name"
              control={control}
              type={"text"}
              label="Apellido Materno"
              placeholder=''
              style={{ container: tw`flex-1 mt-5 ml-2`, label: tw`bg-transparent font-normal ml-1` }}

            />
          </View>

        
          <View style={tw`items-center`}>
            <Input
              name="squad_number"
              control={control}
              keyboardType='phone-pad'
              maxLength={3}
              label="# Dorsal *"
              rules={{ required: "Dorsal Requerido", pattern: { value: /^[0-9]{1,3}$/, message: "El dorsal tiene que ser un número" } }}
              style={{ container: tw`mt-5 w-30`, label: tw`bg-transparent font-normal ml-1`, input: {textAlign: 'center', fontSize: 35} }}
              onSubmitEditing={({ nativeEvent }) => {
                // console.log("onSubmitEditing", nativeEvent.text)
                handleSubmit(submitPlayer)()
              }}
            />
            
            {
              showSquadNumberLabel &&
                <Text style={tw`-mt-5 mb-2 text-sm text-gray-200`}>Puede ser cambiado después</Text>
            }
          </View>

        
          {
            ! gender &&
              <View>
                <Text style={tw`ml-3 mb-1 font-bold`}>Género</Text>

                <OptionsSelect
                  style={{ container: tw`mb-5`, option: tw`flex-1 px-4 py-4`, optionsWrapper: tw`justify-between`, icon: tw`text-gray-400` }}
                  control={control}
                  name={"gender"}
                  options={[
                    {
                      icon: "feminil",
                      iconlabel: "Femenino",
                      value: "female"
                    },
                    {
                      icon: "varonil",
                      iconlabel: "Masculino",
                      value: "male"
                    },
                  ]}
                  rules={{ required: true }}
                />
              </View>
          }




          {
            !minimal &&
              <View>
                {/*
                    Información de contacto
                */}
                {/* <Text style={tw`mt-3 text-lg`}>Información de contacto</Text> */}

                {/* <Input
                  name="phone"
                  control={control}
                  keyboardType='phone-pad'
                  label="Teléfono"
                  rules={{ pattern: { value: /\d{10}/, message: "Tiene que ser de 10 digitos" } }}
                  maxLength={10}
                  style={{ container: tw`mt-5`, label: tw`bg-transparent font-normal ml-1` }}
                />

                <Input
                  name="email"
                  control={control}
                  keyboardType={"email-address"}
                  label="Correo electrónico"
                  rules={{ pattern: { value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, message: "Dirección de correo no válida" } }}
                  style={{ container: tw`mt-5`, label: tw`bg-transparent font-normal ml-1` }}
                /> */}



                {/* 
                  Información adicional
                */}
                {/* <Text style={tw`mt-3 text-lg`}>Información adicional</Text> */}
                {/* Fecha de nacimiento */}
                <Input
                  name="birthdate"
                  control={control}
                  keyboardType={'numeric'}
                  label="Fecha de nacimiento"
                  placeholder='DD/MM/YYYY'
                  style={{ container: tw`mt-5`, label: tw`bg-transparent font-normal ml-1` }}
                  rules={{ pattern: { value: /\d{1,2}\/\d{1,2}\/\d{4}/, message: "El formato tiene que ser DD/MM/YYYY" } }}
                />
                



                {/* Subir Documento [WIP] */}
                <ImagesPicker
                  label={"Subir Documento"}
                  onImageChanged={onImageChanged}
                  deletable={false}
                  changeable={true}
                  style={{
                    buttonWrapper: tw`w-full h-28 px-10 bg-transparent border-dashed border-2 border-gray-300`,
                    buttonWrapperFill: tw`w-full h-28 px-10`,
                    label: tw`text-gray-300 font-bold`,
                    container: tw`w-full mb-5`
                  }}
                  icon={"cargar"}
                  description={"Documento con fotografía"}
                />
              </View>
          }
          

          {
            errorMessage &&
              <Text style={tw`text-red text-center my-3`}>
                {errorMessage}
              </Text>
          }

          <Button
            label={!isSubmitting ? (player ? `Guardar Jugador${isFemale ? "a" : ""}` : `Crear Jugador${isFemale ? "a" : ""}`) : `Creando Jugador${isFemale ? "a" : ""}...`}
            disabled={isSubmitting}
            onPress={handleSubmit(submitPlayer)}
            style={{ container: tw`mb-8 mt-3` }}
          />
        </View>
      }
    </ScrollView>
  )
}


