import React, { useEffect, useState } from 'react';
import { View, ScrollView, TouchableOpacity, Image, Share, Platform, Alert } from 'react-native'
import Text from '../../Components/Text';

import api from "../../utils/api"
import tw from '../../styles/tailwind';

import FMIcons from '../../Components/FMIcon';
import { useSelector } from 'react-redux';
import { useToast } from 'react-native-toast-notifications';

import { Ionicons } from "@expo/vector-icons"
import NeonButton from '../../Components/NeonButton';
import * as Analytics from 'expo-firebase-analytics';


function Counter({ label, count, color }) {
  return (
    <View style={tw`flex-1 flex-col items-center`}>
      <Text style={tw.style(`text-xl font-bold text-gray`)}>{count}</Text>
      <Text style={tw.style(`text-md  text-${color}`)}>{label}</Text>
    </View>
  )
}


// TODO: abstraer, Estos dos de abajo se repiten en Team.Detail
function XPBar({ percentage }) {
  return (
    <View style={tw`border border-gray-300 h-3 rounded-xl flex-1`}>
      <View style={tw.style(`rounded-xl bg-primary flex-1`, { width: `${percentage}%` })}></View>
    </View>
  )
}

function XPIndicator({ style }) {
  return (
    <View style={tw.style(`flex-col items-center`, style?.container)}>

      <View style={tw`flex-row items-center`}>
        <XPBar percentage={0} />
      </View>

      <View style={tw`flex-row items-center mt-3`}>
        <FMIcons name={'xp-b'} size={18} color={'#efc42e'} />
        <Text style={tw.style(`text-lg text-gray ml-2 mr-3 `)}>0 XP</Text>
      </View>
    </View>

  )
}


function PlayerHeader({ player, style, onSendInvitationPressed, isTeamManager }: {
  player: Player,
  style?: any,
}) {
  return (
    <View style={tw.style(`mt-8 flex-1 flex-col py-6 rounded-xl bg-gray-500`, style?.container)}>
      {/*  */}

      {/* Badge jugador */}
      <View style={tw.style(`flex-1 flex-row justify-center`)}>
        <View style={tw.style(`bg-gray-800 border border-primary rounded-2xl overflow-hidden absolute -top-[14]`)}>
          <Image source={{ uri: player.image_url }} style={tw`h-24 w-24`} />
        </View>
      </View>

      {/* Info jugador */}
      <View style={tw`flex-col items-center mt-[15%] mx-3`}>
        <Text style={tw.style(`text-xl text-gray mb-5`)}>{player.name}</Text>

        {/*  */}
        {
          isTeamManager && 
            <View style={tw`w-full`}>
              {
                ! player.is_registered ?
                  <NeonButton style={{container: 'f'}} label='Enviar invitación a FutMaster' onPress={onSendInvitationPressed} showChevronRight />
                :
                  <View style={tw`row px-2 mt-2 w-full`}>
                    <Ionicons name="checkmark-outline" size={20} color="white" />
                    <Text style={tw`text-white ml-2`}>Registrad{player.gender == "female" ? "a" : "o"} en FutMaster</Text>
                  </View>
              }
            </View>
        }
        
        {/* <XPIndicator style={{ container: tw`w-[100%] ` }} /> */}
      </View>
    </View>
  )
}






function Pill({ icon, label, value }) {
  return (
    <View style={tw`bg-gray-500 px-5 py-3 rounded-lg flex-row items-center justify-between mb-4`}>
      <View style={tw`flex-row items-center`}>
        <FMIcons name={icon} color={tw.color(value ? 'primary' : "gray-300")} size={20} />

        <Text style={tw`text-white ml-4 text-gray-300`}>{label}</Text>
      </View>

      <Text style={tw`text-white text-lg font-bold`}>{value ?? "-"}</Text>
    </View>
  )
}


export async function generateInvitation(leagueTeamId, playerId, analyticRef="") {
  api.post(`/league-teams/${leagueTeamId}/invite-player`, { player_id: playerId })
    .then(({ data }) => {
      if (Platform.OS == "web") {
        navigator?.clipboard?.writeText(data.invitation_url)
        toast.show("Liga copiada en el portapapeles", {
          // type: "warning",
        })
      } else {
        shareUrl(data.invitation_url)
      }

      // Analytic
      Analytics.logEvent('UserInvitationGenerated', {
        player: playerId,
        leagueTeamId,
        from: analyticRef,
      });
    })
    .catch((e) => {
      toast.show("No se pudo generar la liga de invitación", {
        type: "warning",
      })
    })
}

async function shareUrl(url) {
  try {
    const result = await Share.share({
      // title: `title`,
      url: url,
      message: url,
    }, {
      // dialogTitle: Android
      // excludedActivityTypes: (ios),
      // subject: ,// - a subject to share via email (iOS)
      // tintColor: ,// 
    });
    if (result.action === Share.sharedAction) {
      if (result.activityType) {
        // shared with activity type of result.activityType
      } else {
        // shared
      }
    } else if (result.action === Share.dismissedAction) {
      // dismissed
    }
  } catch (error) {
    // alert(error.message);
  }
}


export default function PlayerDetailScreen({ navigation, route }) {
  const { playerId, leagueTeamId,
    leagueId, teamId // estos solo se usan para que cuando se borre pueda regresar a LeagueTeam.Home
  } = route.params
  const toast = useToast()
  

  // TODO mover a hook?
  // 
  const myLeagueTeams = useSelector(state => state.status.league_teams)
  const isTeamManager = myLeagueTeams.filter(leagueTeam => leagueTeam.pivot.roles?.indexOf("representative") !== -1 && leagueTeam.uuid === leagueTeamId).length > 0


  // TODO: Conexión a datos/redux
  const [player, setPlayer] = useState<Player|null>();

  useEffect(() => {
    navigation.setOptions({
      title: "",
    })
  }, []);


  // Mover a redux
  // 
  useEffect(() => {
    api.get(`/players/${playerId}`)
      .then(({ data }) => {
        setPlayer(data)
      })
      .catch(error => { })
  }, [playerId]);


  function deleteLeaguePlayer() {
    console.log(`league-teams/${leagueTeamId}/players/${playerId}`);
    
    api.delete(`league-teams/${leagueTeamId}/players/${playerId}`)
      .then(({ data }) => {
        // dispatch(loadLeagueTeam(leagueId, leagueTeam.team.uuid))
        // navigation.navigate('Admin.TeamDetail', { teamUid: leagueTeam.team.uuid, leagueUid: leagueId })

        // wenas?
        navigation.navigate("LeagueTeam.Home", { teamId, leagueId, showDeletedPlayerToast: playerId })
        // navigation.goBack()
      })
      .catch(({ response }) => {
        console.log(response);
      })
  }

  if (!player) return null

  return (
    <ScrollView style={tw`flex-1 px-3 pt-5`} contentContainerStyle={tw`pb-8`}>
      <PlayerHeader
        player={player}
        onSendInvitationPressed={() => generateInvitation(leagueTeamId, player.uuid, "Player.Home")}
        isTeamManager={isTeamManager}
      />



      {/*  */}
      <Text style={tw`text-white text-lg mt-1`}></Text>

      <Pill icon={player.gender == "female" ? "feminil" : "varonil"} label={"Género"} value={player.gender == "female" ? "Femenino" : "Masculino"} />
      {/* <Pill icon={"calendario"} label={"Año de nacimiento"} value={player.birthdate} /> */}
      <Pill icon={"jersey"} label={"Dorsal"} value={player.squad_number} />


      {
        isTeamManager &&
          <TouchableOpacity
            onPress={() => {
              if (Platform.OS !== "web") {
                Alert.alert(
                  `¿Eliminar jugador?`,
                  `Esta acción quitará a ${player?.name} del equipo.`,
                  [
                    {
                      text: 'Eliminar jugador',
                      onPress: () => deleteLeaguePlayer(),
                      style: 'cancel',
                    },
                    {
                      text: 'Cancelar',
                      onPress: () => { },
                      // style: 'cancel',
                    },
                  ],
                  {
                    cancelable: true,
                    onDismiss: () => { }
                  }
                );
              } else {
                const result = confirm(`¿Eliminar jugador?\nEsta acción quitará a ${player?.name} del equipo.`)
                if (result) deleteLeaguePlayer()
              }
            }}
          >
            <Text style={tw`text-red`}>Dar de baja del equipo</Text>
          </TouchableOpacity>
      }


    </ScrollView>
  )
}


