import React, { useState, useEffect } from 'react';
import { View, ScrollView, TouchableOpacity } from "react-native"

import tw from '../../styles/tailwind';
import Text from '../../Components/Text';
import {Ionicons} from "@expo/vector-icons"
import * as Linking from 'expo-linking';

import { useForm } from "react-hook-form";
import Input from '../../Components/Input';
import Button from '../../Components/Button';



export default function PrivacyScreen({ }) {
  const { control, handleSubmit, formState, formState: { errors }, setError, setValue, reset } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ticketSent, setTicketSent] = useState(false);


  function sendSupportTicket(data){
    setIsSubmitting(true)

    setTimeout(() => {
      setTicketSent(true)
    }, 1000)
  }

  return (
    <ScrollView style={tw`px-3`}>
      <Text style={tw`text-white`}>Estamos para ayudarte con cualquier duda o problema que tengas</Text>

      <TouchableOpacity
        onPress={() => {
          const waUrl = 'https://wa.me/5217712162398'

          Linking.openURL(waUrl);
        }}
        style={tw`row items-center justify-center px-3 py-3 border border-white rounded-lg mt-5`}
      >
        <Text style={tw`text-white mr-3`}>Escríbenos en Whatsapp</Text>
        <Ionicons name='logo-whatsapp' size={20} color={"green"} />
      </TouchableOpacity>


      {
        ! ticketSent ?
          <View style={tw`mt-10 border-t border-white/30 pt-8`}>
            <Text style={tw`text-white mb-8`}>O también puedes escribirnos en este formulario:</Text>

            <Input
              name="name"
              control={control}
              rules={{ required: "Nombre Requerido" }}
              label="Nombre"
              style={{ container: tw`flex-1 mt-5`, input: tw`text-white`, label: tw`bg-transparent font-normal ml-1 text-white` }}
            />

            <Input
              name="email"
              control={control}
              keyboardType={"email-address"}
              label="Correo electrónico"
              rules={{ pattern: { value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, message: "Dirección de correo no válida" } }}
              style={{ container: tw`flex-1 mt-5`, input: tw`text-white`, label: tw`bg-transparent font-normal ml-1 text-white` }}
            />

            <Input
              name="message"
              control={control}
              label="Tu mensaje"
              multiline
              numberOfLines={3}
              style={{ container: tw`flex-1 mt-5`, input: tw`text-white`, label: tw`bg-transparent font-normal ml-1 text-white` }}
            />

            <Button
              label={!isSubmitting ? "Crear Jugador" : "Creando Jugador..."}
              disabled={isSubmitting}
              onPress={handleSubmit(sendSupportTicket)}
              style={{ container: tw`mb-8 mt-5` }}
            />
          </View>
        :
          <Text style={tw`text-white mt-10 text-lg`}>Gracias por tu mensaje. Estaremos revisando tu mensaje y te contactaremos a la brevedad posible.</Text>
      }
    </ScrollView>
  )
}

