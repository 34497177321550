import React from "react";
import { useForm } from "react-hook-form";
import { View } from "react-native";

import StepIndicator from "../../../Components/StepIndicator";
import Text from "../../../Components/Text";
import Select from "../../../Components/Select";
import OptionsSelect from "../../../Components/OptionsSelect";
import Button from "../../../Components/Button";

import tw from "../../../styles/tailwind";
import { Ionicons } from '@expo/vector-icons';

export default function NewPlayer_Step2({ onSubmit, handleBack, style }) {
  const { control, handleSubmit, formState, formState: { errors }, setError } = useForm();


  const returnStep = () => {
    handleBack()
  }

  const submitStep2 = () => {
    onSubmit()
  }

  const birthdayDays = [
    { id: "1", label: "1" },
    { id: "2", label: "2" },
    { id: "3", label: "3" },
    { id: "4+", label: "..." },
  ]

  return (
    <View style={tw.style(``, style?.container)}>

      <StepIndicator
        style={{ container: tw`mb-6` }}
        currentStep={2}
      />


      <View style={tw.style(`bg-gray-100 flex-1 mb-8 rounded-xl self-center w-full h-[270px]`)}></View>

      <View style={tw`flex-col mb-8`}>

        <Text style={tw`mb-1`}>¿Cuándo es tu cumpleaños?</Text>

        <View style={tw`flex-row justify-between`}>

          <Select
            name="birth_day"
            items={birthdayDays}
            placeholder={{ label: "Día" }}
            control={control}
            style={{ container: tw.style(``, { flexShrink: 1, width: '29%' }) }}
          />

          <Select
            name="birth_month"
            items={birthdayDays}
            placeholder={{ label: "Mes" }}
            control={control}
            style={{ container: tw.style(``, { flexShrink: 1, width: '29%' }) }}
          />

          <Select
            name="birth_year"
            items={birthdayDays}
            placeholder={{ label: "Año" }}
            control={control}
            style={{ container: tw.style(``, { flexShrink: 1, width: '29%' }) }}
          />


        </View>

      </View>


      <OptionsSelect
        style={{ container: tw`mb-16`, option: tw`py-5 pb-3` }}
        control={control}
        name="gender"
        options={[
          {
            label: <View style={tw`items-center `}>
              <Ionicons name="male" size={35} color="white" />
              <Text style={tw.style(`text-white mt-3 text-xs`)}>Hombre</Text>
            </View>,
            value: "man"
          },
          {
            label: <View style={tw`items-center `}>
              <Ionicons name="male-female" size={35} color="white" />
              <Text style={tw.style(`text-white mt-3 text-xs`)}>Otro</Text>
            </View>, value: "other"
          },
          {
            label: <View style={tw`items-center `}>
              <Ionicons name="female" size={35} color="white" />
              <Text style={tw.style(`text-white mt-3 text-xs`)}>Mujer</Text>
            </View>, value: "woman"
          },
        ]}
      />


      {/* Botones */}
      <View style={tw`flex-row justify-around`}>
        <Button
          label={"Anterior"}
          onPress={() => { returnStep() }}
          style={{ container: tw`px-8  py-3 bg-black-100`, label: tw`text-white` }}

        />

        <Button
          label={"Siguiente"}
          onPress={(handleSubmit(submitStep2))}
          style={{ container: tw`px-8  py-3` }}
        />
      </View >

    </View >
  )
}
