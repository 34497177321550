import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, Image } from "react-native"


import tw from '../../styles/tailwind';
import Text from '../../Components/Text';
import { LinearGradient } from 'expo-linear-gradient';
import { CountUp } from 'use-count-up'

import Animated, {
  withTiming,
  useAnimatedStyle,
  useSharedValue,
  Extrapolation,
  interpolate
} from 'react-native-reanimated';


function XPBar({ current=0, highLimit, style }) {
  const [_current, set_current] = useState(current);
  const [_highLimit, set_highLimit] = useState(highLimit);
  const [isCounting, setIsCounting] = useState(false);
  const [initial, setInitial] = useState(current);
  const [animationDuration, setAnimationDuration] = useState(500);

  const percentage = (_current / highLimit) * 100
  const barWidth = useSharedValue(_current / highLimit);

  useEffect(() => {
    if (highLimit == _highLimit) {
      barWidth.value = current / highLimit
      set_current(current)
    }

    if (highLimit > _highLimit) {
      // manda a 100, espera

      barWidth.value = 100

      set_highLimit(highLimit)
      set_current(current)

      setTimeout(() => {
        // manda a 0 (imediado)
        setAnimationDuration(0)
        barWidth.value = 0

        // manda a current
        setTimeout(() => {
          setAnimationDuration(500)
          barWidth.value = current / highLimit
        }, 300)
      }, 800)
    }
  }, [current, highLimit]);


  const barWidthAnimatedStyle = useAnimatedStyle(() => {
    const interpolatedWidth = interpolate(
      barWidth.value,
      [0, 1],
      [0, 100],
      { extrapolateRight: Extrapolation.CLAMP }
    );

    return {
      width: withTiming(`${interpolatedWidth}%`, {
        duration: animationDuration,
        useNativeDriver: false,
      }),
    };
  });

  return (
    <View style={tw.style(`border border-gray-300 h-6 rounded-lg`, style?.container)}>
      <LinearGradient
        colors={['#706967', "#2E2E2E"]}
        start={{ x: 0, y: 0.5 }}
        end={{ x: 1, y: 0.5 }}
        style={tw`absolute right-0 top-0 left-0 bottom-0 rounded-lg`}
      />

      <Animated.View style={[tw`absolute top-0 bottom-0 mt-[-1px] mb-[-1px] left-0 rounded-lg bg-primary`, barWidthAnimatedStyle]} />

      {/* mt-[-2px] */}
      <View style={tw`justify-center row items-center `}>
        <Text style={tw.style(`text-white font-600`, { fontSize: 17, lineHeight: 23 })}><CountUp isCounting start={initial} end={_current} duration={4} /> </Text>
        <Text style={tw.style(`text-white font-600`, { fontSize: 17, lineHeight: 23 })}>/ {highLimit}</Text>
      </View>
    </View>
  )
}

// GAMIFY_ASSETS



export default function PlayerXPBar({ current, highLimit }){
  return (
    <View style={tw.style(`row`, {flexDirection: 'row-reverse'})}>
      <XPBar current={current} highLimit={highLimit} style={{container: tw`flex-1 -ml-3`}} />
      
      <Image source={require('./assets/players/xp_circle.png')} style={tw`w-9 h-9`} />
    </View>
  )
}
