import React, { useState } from 'react';
import { View, TouchableOpacity } from 'react-native'

import tw from '../../styles/tailwind';
import commonStyle, {theme} from '../../styles/common.style'

import Input from '../../Components/Input';
import Checkbox from '../../Components/Checkbox';
import Text from '../../Components/Text';


export default function OptionsSelector({ title, options, multiple, onChange, style, showOtherInput, showInRows }) {
  const [selectedOptions, setSelectedOptions] = useState([]);

  function onOptionPressed(option) {
    let newSelected;

    if (multiple) {
      const optionIndex = selectedOptions.findIndex(e => e.id == option.id)
      
      if (optionIndex != -1){
        newSelected = selectedOptions.filter(e => e.id != option.id)
      }else{
        newSelected = [...selectedOptions, option]
      }
    } else {
      newSelected = [option]
    }

    setSelectedOptions(newSelected)

    onChange(newSelected)
  }


  function renderRowButtons(){
    return (
      <View style={tw``}>
        {
          options.length > 0 &&
            <Text style={tw` text-center mb-4`}>Puedes elegir varias opciones</Text>
        }

        {
          options.map(option => {
            const isSelected = !!selectedOptions.find(e => e.id == option.id)

            return <View style={tw``} key={option.id}>
              <TouchableOpacity style={[commonStyle.inputWrapper, { paddingVertical: 13, marginBottom: 14 }, style]} onPress={() => onOptionPressed(option)}>
                <Checkbox
                  checked={isSelected}
                  style={{ position: 'absolute', zIndex: 1, top: 23, marginTop: -10, left: 15 }}
                  onChange={() => onOptionPressed(option)}
                />

                <Text style={tw.style(``, { paddingLeft: 50, color: theme.PLACEHOLDER_COLOR, fontWeight: '600' }, style?.label)}>{option.label}</Text>
              </TouchableOpacity>
            </View>
          })
        }

        {
          showOtherInput &&
            <Input
              uncontrolled
              label={options.length == 0 ? "" : "Otro"}
              onChangeText={ text => onOptionPressed({id: "other", value: text}) }
            />
        }
      </View>
    )
  }

  

  function renderColumnButtons(){
    return (
      <View>
        {
          multiple &&
            <Text style={tw` text-center mb-4`}>Puedes elegir varias opciones</Text>
        }

        <View style={tw`flex-row flex-wrap`}>
          {
            options.map(option => {
              const isSelected = !!selectedOptions.find(e => e.id == option.id)

              return <View style={tw`w-1/2`} key={option.id}>

                <TouchableOpacity
                  style={tw.style(
                    `border border-secondary rounded-xl mx-3 mb-3`,
                    { paddingVertical: 6 },
                    { 'bg-secondary': isSelected }
                  )}
                  onPress={() => onOptionPressed(option)}
                >
                  <Text style={tw.style(
                    `text-secondary text-center `,
                    { 'text-primary': isSelected }
                  )}
                  >{option.label}</Text>
                </TouchableOpacity>
              </View>
            })
          }
        </View>
      </View>
    )
  }



  return (
    <View style={tw`mb-7`}>
      <Text style={tw`text-center mb-3 text-lg font-worksans-black`}>{title}</Text>

      { showInRows  ? renderRowButtons() :renderColumnButtons() }
    </View>
  );
}