import { state } from 'robot3';
import { createMachine, assign } from 'xstate';

interface MatchMachineContext{
  periodsCount: number,
  currentPeriod: number,
  periodDuration: number,
  elapsedPeriodTime: number,
  elapsedMatchTime: number,
  interval: number,
  halfTimeLength: number,
}

// Sacado de acá (y agregandole el isRunning porque )
// https://stackoverflow.com/a/44337628
// 
function AdjustingInterval(workFunc, interval, errorFunc) {
  var that = this;
  var expected, timeout, isRunning;
  this.interval = interval;

  this.start = function () {
    expected = Date.now() + this.interval;
    timeout = setTimeout(step, this.interval);
    isRunning = true
  }

  this.stop = function () {
    isRunning = false
    clearTimeout(timeout);
  }

  function step() {
    var drift = Date.now() - expected;
    if (drift > that.interval) {
      // You could have some default stuff here too...
      if (errorFunc) errorFunc();
    }
    workFunc();
    expected += that.interval;

    if (isRunning){
      timeout = setTimeout(step, Math.max(0, that.interval - drift));
    }
  }
}

let ticker

const periodStates = {
  initial: "not_started",
  states: {
    not_started: {
      on: {
        PAUSE_RESUME: {
          target: "running",
          actions: ['onPeriodStart']
        },
      },
    },
    running: {
      invoke: {
        src: (context) => (cb) => {
          // const interval = setInterval(() => {
          //   console.log("Willgototick", (new Date).toISOString());
          //   cb("TICK");
          // }, 1000 * context.interval);

          const  doError = function () {
            console.warn('The drift exceeded the interval.');
          };

          ticker?.stop()

          ticker = new AdjustingInterval(() => {
              // console.log("Willgototick", (new Date).toISOString());
              cb("TICK");
          }, 1000, doError);

          ticker.start();


          return () => {
            // aunque aqui si se llamaba el .stop el timer seguia
            // por eso agregue el isRunning al ticker
            ticker?.stop();

            // y lo mismo pasaba con este, el interval seguia corriendo aunque se cleareara
            // clearInterval(interval);
          };
        },
      },
      always: {
        target: "period_finished",
        cond: (context) => {
          return context.elapsedPeriodTime >= context.periodDuration;
        },
      },
      on: {
        TICK: {
          // si el tiempo del periodo es negativo no suma al elapsedMatchTime
          actions: assign({
            elapsedPeriodTime: (context) => +(context.elapsedPeriodTime + context.interval).toFixed(2),
            elapsedMatchTime: (context) => context.elapsedPeriodTime >= 0 ? +(context.elapsedMatchTime + context.interval).toFixed(2) : context.elapsedMatchTime,
          }),
        },
        PAUSE_RESUME: {
          target: "paused",
          actions: ['onPeriodPause']
        },
      },
    },
    paused: {
      on: {
        PAUSE_RESUME: {
          target: "running",
          actions: ['onPeriodResume']
        },
      },
    },
    period_finished: {
      type: "final",
      entry: ['onPeriodFinish']
    },
  },
};

const gatorade = target => {
  return {
    target,
    actions: assign((context, event) => {
      // console.log("REHYDRATE_LA_COSA2", context, event)
      return {
        ...context,
        ...event.context,
      }
    })
  }
}

export const matchMachine = createMachine({
  key: "match",
  preserveActionOrder: true,
  initial: "not_started",
  schema: {
    context: {} as MatchMachineContext
  },
  context: {
    periodsCount: 4,
    currentPeriod: 0,
    periodDuration: 5,
    elapsedPeriodTime: 0,
    elapsedMatchTime: 0,
    halfTimeLength: 0,

    interval: 1,
  },
  states: {
    not_started: {
      on: {
        PAUSE_RESUME: {
          target: "running_period.running",
          actions: 
          [
            assign({
              currentPeriod: (context, event) => context.currentPeriod + 1,
            }),
            'onMatchStart',
            'onPeriodStart',
          ]
        },
      },
    },
    running_period: {
      ...periodStates,
      onDone: 'change_period'
    },
    change_period: {
      always: [
        {
          target: "running_period",
          cond: (context) => {
            return context.currentPeriod < context.periodsCount;
          },
          actions: assign({
            currentPeriod: (context) => context.currentPeriod + 1,
            elapsedPeriodTime: (context) => {
              // 
              if ((context.currentPeriod == context.periodsCount/2) && context.halfTimeLength > 0){
                return 0 - context.halfTimeLength
              }else{
                return 0
              }
            },
          }),
        },
        { target: "match_finished", actions: ['onMatchFinish'] },
      ],
    },
    match_finished: {
      type: "final"
    },
  },
  on:{
    REHYDRATE_LA_COSA: gatorade(null),
    '_running_period.running': gatorade('running_period.running'),
    '_running_period.not_started': gatorade('running_period.not_started'),
    '_running_period.paused': gatorade('running_period.paused'),
  }
},
  {
    actions: {
      onMatchStart: (context, event) => {
        console.log('[mach] onMatchStart');
      },
      onPeriodStart: (context, event) => {
        console.log('[mach] onPeriodStart');
      },
      onPeriodFinish: (context, event) => {
        console.log('[mach] onPeriodFinish', context);
      },
      onPeriodPause: (context, event) => {
        console.log('[mach] onPeriodPause');
      },
      onPeriodResume: (context, event) => {
        console.log('[mach] onPeriodResume');
      },
      onMatchFinish: (context, event) => {
        console.log('[mach] onMatchFinish');
      },
      ranimate: (context, event) => {
        console.log('[mach] ranimatea plis :(');
      },
    }
  }
);



/*
import { State, interpret, actions } from "xstate";

function useSuPutaMadre(){
  const service = interpret(matchMachine).start()

  return [
    service,
  ];
  // updateState
}


const lef = { "actions": [{ "type": "xstate.stop", "activity": { "id": "match.running_period.running:invocation[0]", "src": { "type": "match.running_period.running:invocation[0]" }, "type": "xstate.invoke" } }], "activities": { "match.running_period.running:invocation[0]": false }, "meta": {}, "events": [], "value": { "running_period": "paused" }, "context": { "periodsCount": 4, "currentPeriod": 1, "periodDuration": 5, "elapsedPeriodTime": 2, "interval": 1 }, "_event": { "name": "PAUSE_RESUME", "data": { "type": "PAUSE_RESUME" }, "$$type": "scxml", "type": "external" }, "_sessionid": "x:3", "event": { "type": "PAUSE_RESUME" }, "historyValue": { "current": { "running_period": "paused" }, "states": { "running_period": { "current": "paused", "states": {} } } }, "history": { "actions": [], "activities": { "match.running_period.running:invocation[0]": { "type": "xstate.start", "activity": { "id": "match.running_period.running:invocation[0]", "src": { "type": "match.running_period.running:invocation[0]" }, "type": "xstate.invoke" } } }, "meta": {}, "events": [], "value": { "running_period": "running" }, "context": { "periodsCount": 4, "currentPeriod": 1, "periodDuration": 5, "elapsedPeriodTime": 2, "interval": 1 }, "_event": { "name": "START", "data": { "type": "START" }, "$$type": "scxml", "type": "external" }, "_sessionid": "x:3", "event": { "type": "START" }, "historyValue": { "current": { "running_period": "running" }, "states": { "running_period": { "current": "running", "states": {} } } }, "children": { "match.running_period.running:invocation[0]": { "id": "match.running_period.running:invocation[0]" } }, "done": false, "changed": false, "tags": [] }, "children": {}, "done": false, "changed": true, "tags": [] }
const jefe = { "value": { "running_period": "paused" }, "context": { "periodsCount": 4, "currentPeriod": 1, "periodDuration": 5, "elapsedPeriodTime": 2, "interval": 1 }, }

function MahMachineX(){
  const [machineStatequieto, setMachineStatequieto] = useState({});
  const [state, send, service] = useMachine(matchMachine, jefe);

  // console.log(state.context);

  // useEffect(() => {
  //   matchMachine.withContext({
  //     periodsCount: 4,
  //     currentPeriod: 0,

  //     periodDuration: 15,
  //     elapsedPeriodTime: 4,

  //     interval: 1,
  //   })
  // }, []);

  useEffect(() => {
    setTimeout(() => {
      setMachineStatequieto({ state: lef })
    }, 2000)
  }, []);

  useEffect(() => {
    const subscription = service.subscribe((state) => {
      // simple state logging
      // console.log(state);
      // console.log(JSON.stringify(state));
    });

    return subscription.unsubscribe;
  }, [service]); // note: service should never change


  return (
    <>
      <BottomButton onPress={() => {
        send('START')
        send('PAUSE_RESUME')
        // setRnandom(Math.random())
      }} label={`C ${JSON.stringify(state.value)}`} />
    </>
  )
}






const [machineService, setMachineService] = useState(
  interpret(matchMachine.withConfig(machineOptions).withContext({...matchMachine.context,...machineOptions.context})).start()
);
const [state, send] = useActor(machineService);


const jj = { "actions": [], "activities": { "match.running_period.running:invocation[0]": { "type": "xstate.start", "activity": { "id": "match.running_period.running:invocation[0]", "src": { "type": "match.running_period.running:invocation[0]" }, "type": "xstate.invoke" } } }, "meta": {}, "events": [], "value": { "running_period": "running" }, "context": { "periodsCount": 4, "currentPeriod": 1, "periodDuration": 10, "elapsedPeriodTime": 4, "interval": 1 }, "_event": { "name": "TICK", "data": { "type": "TICK" }, "$$type": "scxml", "type": "external", "origin": "match.running_period.running:invocation[0]" }, "_sessionid": "x:33", "event": { "type": "TICK" }, "historyValue": { "current": { "running_period": "running" }, "states": { "running_period": { "current": "running", "states": {} } } }, "children": { "match.running_period.running:invocation[0]": { "id": "match.running_period.running:invocation[0]" } }, "done": false, "changed": true, "tags": [] }
const initial = { "actions": [], "activities": { "match.running_period.running:invocation[0]": { "type": "xstate.start", "activity": { "id": "match.running_period.running:invocation[0]", "src": { "type": "match.running_period.running:invocation[0]" }, "type": "xstate.invoke" } } }, "meta": {}, "events": [], "value": { "running_period": "running" }, "context": { "periodsCount": 4, "currentPeriod": 2, "periodDuration": 5, "elapsedPeriodTime": 2, "interval": 1 }, "_event": { "name": "TICK", "data": { "type": "TICK" }, "$$type": "scxml", "type": "external", "origin": "match.running_period.running:invocation[0]" }, "_sessionid": "x:189", "event": { "type": "TICK" }, "historyValue": { "current": { "running_period": "running" }, "states": { "running_period": { "current": "running", "states": {} } } }, "history": { "actions": [], "activities": { "match.running_period.running:invocation[0]": { "type": "xstate.start", "activity": { "id": "match.running_period.running:invocation[0]", "src": { "type": "match.running_period.running:invocation[0]" }, "type": "xstate.invoke" } } }, "meta": {}, "events": [], "value": { "running_period": "running" }, "context": { "periodsCount": 4, "currentPeriod": 2, "periodDuration": 5, "elapsedPeriodTime": 1, "interval": 1 }, "_event": { "name": "TICK", "data": { "type": "TICK" }, "$$type": "scxml", "type": "external", "origin": "match.running_period.running:invocation[0]" }, "_sessionid": "x:189", "event": { "type": "TICK" }, "historyValue": { "current": { "running_period": "running" }, "states": { "running_period": { "current": "running", "states": {} } } }, "children": { "match.running_period.running:invocation[0]": { "id": "match.running_period.running:invocation[0]" } }, "done": false, "changed": true, "tags": [] }, "children": { "match.running_period.running:invocation[0]": { "id": "match.running_period.running:invocation[0]" } }, "done": false, "changed": true, "tags": [] }
const restoredState = State.create(initial)


const newService = interpret(
  matchMachine//.withConfig(machineOptions).withContext({ ...matchMachine.context, ...machineOptions.context })
).onTransition(state => {
  console.log("onTransition ----", state.value, state.context, "\n----");
})

newService.start(restoredState)

// para que esto funcoine hay que poner en on TICK -> target: "running",
var result = newService.send({ type: 'TICK' })
console.log({result})
setMachineService(newService)
*/

/*
['running_period.not_started', 'running_period.running', 'running_period.paused', 'not_started', 'match_finished']
.map(state => {
  return {
    target: state,
    cond: (_, event) => {
      console.log("comparando", event.target, state)
      return event.target === state
    },
    actions: assign((context, event) => {
        console.log("REHYDRATE_LA_COSA", context, event)

        return {
          ...context,
          ...event.context,
        }
      })
  }
}),



---
useEffect(() => {
  setTimeout(() => {
    // send({ type: "REHYDRATE_LA_COSA", context: { periodDuration: 11}})
    // send({ type: "REHYDRATE_LA_COSA", context: { periodDuration: 22 }, target: 'running_period.running'})
    // send({ type: "_running_period.running", context: { periodDuration: 22, elapsedTime: 14, currentPeriod: 3 }})
  }, 2000);
}, []);

----

Machina de pruebas

import { useMachine } from '@xstate/react';
import { createMachine } from "xstate";
import { assign } from "xstate/lib/actions";

export const machine = createMachine({
  initial: 'toggledOff',
  context: {
    times: 0,
  },
  states: {
    toggledOff: {
      on: {
        TOGGLE: {
          target: 'toggledOn',
          actions: [
            assign({
              times: (context, event) => context.times + 1,
            }),
            // 'reportaEse',
          ]
        }
      }
    },
    toggledOn: {
      entry: ['reportaEse'],
      on: {
        TOGGLE: 'toggledOff'
      }
    }
  }
}, {
  actions: {
    reportaEse: () => {console.log("fooo") }
  }
});

function MyMachine(){
  const [counter, setCounter] = useState(0);

  const [state, send] = useMachine(machine, {
    actions: {
      reportaEse: (ctx) => {
        console.log(counter, state.context, ctx);
      }
    }
  });

  return (
    <View>
      <Button label={`c: ${counter}`} onPress={() => setCounter(counter+1)} />
      <Button label={`${JSON.stringify(state.value)} ${state.context.times}`} onPress={() => send("TOGGLE")} />
    </View>
  )
}
*/

// https://medium.com/@luisbar180492/xstate-an-alternative-to-redux-a-practical-example-d8851ee62442
// https://stackoverflow.com/questions/70202325/is-there-a-way-to-set-the-state-of-an-xstate-machine-in-a-react-component-in-use
// https://github.com/statelyai/xstate/discussions/1939#discussioncomment-381565 hmmm
// https://stackoverflow.com/a/66720857 hmm