import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity } from "react-native"


import tw from '../../../../../styles/tailwind';
import Text from '../../../../../Components/Text';
import { useSelector } from 'react-redux';
import TabletModal from '../../../Matches/TabletComponents/TabletModal';


export default function FieldSelectorModal({ visible, onRequestClose, onFieldSelected }) {

  // TODO: SELECTED_STADIUM_STATE
  const selectedStadiumId = useSelector((state) => state.status?.selectedStadiumId);
  const stadium = useSelector((state) => state.status?.stadiums?.find(e => e.uuid == selectedStadiumId));



  return (
    <TabletModal
      visible={visible}
      onRequestClose={() => {
        onRequestClose && onRequestClose()
      }}
      // style={{ container: tw`px-3 bg-fm-background rounded-tl-xl rounded-tr-xl` }}
    >
      <Text style={tw`font-bold mb-3 text-lg text-center`}>Elige la cancha</Text>

      <View style={tw`mt-2`}>
        {
          stadium.fields?.map(field =>
            <TouchableOpacity
              key={field.uuid}
              style={tw`rounded-lg border border-gray-200 mb-3 px-4 py-2`}
              onPress={() => {
                onFieldSelected?.(field)
              }}
            >
              <Text style={tw`text-gray-300`}>{field.name}</Text>
            </TouchableOpacity>
          )
        }

      </View>
    </TabletModal>
  );
}

