import React from 'react';
import { useForm } from 'react-hook-form';
import { View } from 'react-native';

import Text from '../../../Components/Text';
import Button from '../../../Components/Button';
import Input from '../../../Components/Input';
import StepIndicator from '../../../Components/StepIndicator';

import tw from '../../../styles/tailwind';



export default function NewPlayer_Step1({ onSubmit, style}) {

  const { control, handleSubmit, formState, formState: { errors }, setError } = useForm();

  const submitStep1 = () => {
    onSubmit()
  }
  return (
    <View style={tw.style(``, style?.container)}>

        <StepIndicator
          style={{container: tw`mb-6 px-6 py-2 bg-gray-100 rounded-xl`}}
          currentStep={1}
        />


        <View style={tw`bg-gray-100 px-5 py-7 flex-1 mb-16 rounded-xl`}>
          <Text style={tw`text-gray-200 text-center`}>Tus datos a llenar serán necesarios  para cuando ingreses a un torneo y para la
            visualización de tu perfil
          </Text>
        </View>

        <Input
          name="names"
          control={control}
          label={"Nombres"}
          style={{ label: tw.style(`bg-transparent`), container: tw`mb-12` }}
        />

        <Input
          name="lastname"
          control={control}
          label={"Apellido Paterno"}
          style={{ label: tw.style(`bg-transparent`), container: tw`mb-12` }}
        />

        <Input
          name="secondlastname"
          control={control}
          label={"Apellido Materno"}
          style={{ label: tw.style(`bg-transparent`), container: tw`mb-12` }}
        />

        <Input
          name="nickname"
          control={control}
          label={"Apodo"}
          style={{ label: tw.style(`bg-transparent`), container: tw`mb-12` }}
        />

        <Input
          name="squad_number"
          control={control}
          label={"Número Dorsal"}
          style={{ label: tw.style(`bg-transparent`), container: tw`mb-12` }}
        />


      <Button
        label={"Siguiente"}
        onPress={(handleSubmit(submitStep1))}
      />
    </View>
  )
}