import React, { useState } from 'react';
import { View, ScrollView, TextInput, TouchableOpacity } from 'react-native'
import Text from '../../../Components/Text';

import api from "../../../utils/api"
import tw from '../../../styles/tailwind';
import { useForm } from "react-hook-form";
import Input from '../../../Components/Input';
import Button from '../../../Components/Button';


export default function NewTeamForm({ leagueId, onTeamCreated }) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { control, handleSubmit, formState, formState: { errors }, setError } = useForm();

  function onSubmit(data) {
    setIsSubmitting(true)

    api.post(`/leagues/${leagueId}/teams`, data)
      .then(response => {
        onTeamCreated && onTeamCreated(response.data)
      })
      .catch(({ response }) => {
        setIsSubmitting(false)

        console.log("Error al crear equipo", response?.data)
        
        if (response?.data?.errors) {
          Object.keys(response.data.errors).forEach(errorKey => {
            setError(errorKey, { type: "manual", message: response.data.errors[errorKey][0] })
          })
        }
      })
  }

  return (
    <View>
      <Input
        name="name"
        label={'Nombre del equipo'}
        control={control}
        rules={{ required: "Nombre del equipo requerido" }}
        style={{ container: tw`mt-5`, label: tw`bg-transparent font-normal ml-1` }}
      />

      <Button
        onPress={handleSubmit(onSubmit)}
        label="Crear equipo"
        disabled={isSubmitting}
      />
    </View>
  );

}