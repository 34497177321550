import { applyMiddleware, combineReducers, compose } from 'redux'
import { createStore } from 'redux'
import thunk from 'redux-thunk'

// Reductores...
import {reducer as UserRedux} from './Reducers/auth';
import {reducer as StatusRedux} from './Reducers/status';
import { reducer as MatchesRedux} from './Reducers/matches';
import { reducer as EntitiesRedux} from './Reducers/entities';


// react-native-offline
import { reducer as network, createNetworkMiddleware, createReducer as createNetworkReducer } from 'react-native-offline';
import offlineComparisonFn from './offlineComparisonFn'

const networkMiddleware = createNetworkMiddleware({
  queueReleaseThrottle: 200,
});



const appReducers = combineReducers({
    auth: UserRedux,
    status: StatusRedux,
    matches: MatchesRedux,
    entities: EntitiesRedux,
    network: createNetworkReducer(offlineComparisonFn),
})


// Persist config
import { persistStore, persistReducer } from 'redux-persist'
import AsyncStorage from '@react-native-async-storage/async-storage';


const persistConfig = {
    key: 'root',
    storage: AsyncStorage,
    blacklist: ['entities', 'matches', 'network'],
}




const persistedReducer = persistReducer(persistConfig, appReducers)


// Redux Devtools
const composeEnhancers = window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


export default () => {
  let store = createStore(persistedReducer, composeEnhancers(applyMiddleware(networkMiddleware, thunk)))
  let persistor = persistStore(store)
  return { store, persistor }
}