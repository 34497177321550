import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, ScrollView, Platform } from "react-native"


import tw from '../../../../styles/tailwind';
import Text from '../../../../Components/Text';
import TabletModal from '../../Matches/TabletComponents/TabletModal';
import Button from '../../../../Components/Button';
import TeamBadge from '../../../../Components/Teams/TeamBadge';
import {Ionicons} from '@expo/vector-icons'
import api from '../../../../utils/api';
import TeamSelector from './CreateRoundModal/TeamSelector';
import MatchSelectDateBottomSheet from '../../Matches/MarchDetailComponents/MatchSelectDateBottomSheet';
import FieldSelectorModal from './CreateRoundModal/FieldSelector';
import { dayOfWeekStr, getHourStr, monthStr } from '../../../../utils/momento';

function MatchRow({ match, round, leagueId, league, deleteMatch, onMatchChanged, home, away }){
  const [homeTeam, setHomeTeam] = useState(home);
  const [awayTeam, setAwayTeam] = useState(away);
  const [teamPrevMatchesCount, setTeamPrevMatchesCount] = useState();
  const [date, setDate] = useState();
  const [field, setField] = useState();

  const [teamSelectorSlot, setTeamSelectorSlot] = useState(null); //{selectedSlot: null, rival: null}
  const [showDateModal, setShowDateModal] = useState(false);
  const [showFieldModal, setShowFieldModal] = useState(false);

  //
  const createdAt = date ? new Date(date) : null
  const hourStr = getHourStr(createdAt)
  const dateStr = createdAt ? `${dayOfWeekStr(createdAt, true)}, ${createdAt?.getDate()} ${monthStr(createdAt, true)}` : null

  useEffect(() => {
    if(homeTeam && awayTeam && date && field){
      onMatchChanged({
        ...match,
        valid: true,
        home_team: homeTeam.uuid,
        away_team: awayTeam.uuid,
        date,
        field: field.uuid,
      })
    }else{
      onMatchChanged({
        ...match,
        home_team: homeTeam?.uuid,
        away_team: awayTeam?.uuid,
        valid: false,
      })
    }
  }, [homeTeam, awayTeam, date, field]);

  function onTeamSelected(leagueTeam) {
    if(teamSelectorSlot.selectedSlot == "home"){
      setHomeTeam(leagueTeam)
    }else{
      setAwayTeam(leagueTeam)
    }

    setTeamPrevMatchesCount(leagueTeam.matches_vs_rival)

    setTeamSelectorSlot(null)
  }

  return (
    <View
      style={tw.style(`border-b border-gray-100 py-2`)}
    >
      <View style={tw`row justify-between`}>
        <View style={tw``}>
          <TouchableOpacity style={tw`px-2 py-1 mb-1 border border-gray-100 rounded`} onPress={() => setTeamSelectorSlot({selectedSlot: "home", rival: awayTeam})}>
            <Text style={tw.style(!homeTeam && `italic`)}>{homeTeam?.name ?? "Seleccionar Equipo" }</Text>
          </TouchableOpacity>

          <TouchableOpacity style={tw`px-2 py-1 border border-gray-100 rounded`} onPress={() => setTeamSelectorSlot({selectedSlot: "away", rival: homeTeam})}>
            <Text style={tw.style(!awayTeam && `italic`)}>{awayTeam?.name ?? "Seleccionar Equipo"}</Text>
          </TouchableOpacity>
        </View>

        <View style={tw`row`}>
          <View style={tw`items-end mr-2`}>
            <TouchableOpacity style={tw`px-2 py-1 mb-2 border border-gray-100 rounded`} onPress={() => setShowDateModal(true)}>
              <Text style={tw.style(`text-sm`, ! date && `italic`)}>{date ? `${dateStr} ${hourStr}` : "Sin Fecha"}</Text>
            </TouchableOpacity>

            <TouchableOpacity style={tw`px-2 py-1 border border-gray-100 rounded`} onPress={() => setShowFieldModal(true)}>
              <Text style={tw.style(`text-sm`, ! field && `italic`)}>{field?.name ?? "Sin cancha"}</Text>
            </TouchableOpacity>
          </View>

          <TouchableOpacity onPress={deleteMatch}>
            <Ionicons name="trash-outline" size={18} color={"#888"} />
          </TouchableOpacity>
        </View>
      </View>


      {
        (teamPrevMatchesCount > 0) &&
          <Text style={tw`text-xs text-orange mt-1`}>Estos equipos han jugado {teamPrevMatchesCount} ve{teamPrevMatchesCount > 1 ? "ces" : "z"} anteriormente.</Text>
      }

      {
        teamSelectorSlot &&
          <TeamSelector
            leagueId={leagueId}
            round={round}
            rivalTeam={teamSelectorSlot.rival}
            onTeamSelected={onTeamSelected}
            onRequestClose={() => setTeamSelectorSlot(null)}
          />
      }

      <MatchSelectDateBottomSheet
        visible={showDateModal}
        leagueMatchDays={league?.match_days}
        onRequestClose={() => {
          setShowDateModal(false)
        }}
        onSubmit={(date) => {
          setShowDateModal(false)
          setDate(date)
        }}
      />

      <FieldSelectorModal
        visible={showFieldModal}
        onFieldSelected={(field) => {
          setShowFieldModal(false)
          // onFieldChanged?.()
          setField(field)
        }}
        onRequestClose={() => {
          setShowFieldModal(false)
        }}
      />
    </View>
  )
}


/* TOFIX: league viene de una larga cadena de params que se puede resolver con redux :O */
export default function CreateRoundModal({ visible, onRequestClose, round, leagueId, league, onMatchesSaved }:{}) {
  const [isLoading, setIsLoading] = useState(false);
  const [generatingSuggestions, setGeneratingSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState(null);
  // const [freeTeams, setFreeTeams] = useState([]);
  const [teams, setTeams] = useState<null | []>(null);

  const [matches, setMatches] = useState([
    // {home: "Amazonas", away: "Rebeldes", field: "Cancha Rápido", date: "Mie 24 Octubre - 18:00"},
    // {home: "2.0", away: "Jagoba", field: "Cancha Rápido", date: "Mie 24 Octubre - 18:00", repeated: true},
    // {home: "LAH", away: "DIF MR", field: "Cancha Rápido", date: "Mie 24 Octubre - 18:00"},
    // {home: "Real Paris", away: "Magic", field: "Cancha Rápido", date: "Mie 24 Octubre - 18:00"},
    // {home: "Huracán", away: "Jornada Libre", field: "Cancha Rápido", date: "Mie 24 Octubre - 18:00"},
  ])

  useEffect(() => {
    if (visible){
      loadLeagueTeams()
      // getRoundSuggestions()
    }else{
      setIsLoading(false)
      setMatches([])
    }
  }, [visible]);


  function getRoundSuggestions(){
    setGeneratingSuggestions(true)
    // setFreeTeams([])

    const data = {
      // matches: hiddenMatches,
    }
    
    api.post(`/rounds/${round.uuid}/generate-suggestions`)
    .then(({data}) => {
      setGeneratingSuggestions(false)
      // console.log(JSON.stringify(data));

      const _matches = data.matches?.map(match => {
        return {
          uuid: Math.random(),
          home_team: match.home.uuid,
          home: match.home,
          away_team: match.away.uuid,
          away: match.away,
        }
      })

      setMatches(_matches)
      
      setSuggestions(data.matches)
      // setFreeTeams(data.free_teams)
    })
    .catch(({response})=> {
      setGeneratingSuggestions(false)
      console.log("CreateRoundModal error - ", JSON.stringify(response));
    })
  }


  function addEmptyMatch(){
    setMatches([
      ...matches,
      {
        uuid: Math.random(),
      },
    ])
  }

  function deleteMatch(match, index){
    setMatches([
      ...matches.filter((it, i) => it.uuid !== match.uuid)
    ])
  }

  function saveMatches(){
    // const _matches = matches.map()
    const data = {
      matches: matches,
    }

    // Sino envía el tiempo en UTC
    // const localDate = new Date(day.getTime() - (day.getTimezoneOffset() * 60000))

    console.log("saveMatches", JSON.stringify(data));
    
    setIsLoading(true)

    api.post(`/rounds/${round.uuid}/multiple-matches`, data)
    .then(({ data }) => {
      // setTeams(data)
      // onRequestClose
      onMatchesSaved?.()
    })
    .catch(({ response }) => {
      setIsLoading(false)
    })
  }




  function loadLeagueTeams() {
    const params = {
      // rival_id: rivalTeam?.uuid,
    }

    api.get(`/rounds/${round.uuid}/teams`, { params })
      .then(({ data }) => {
        setTeams(data)
      })
      .catch(({ response }) => { })
  }


  const freeTeams = teams?.filter(team => ! matches?.find(match => match.home_team == team.uuid || match.away_team == team.uuid))

  if(!visible) return null



  return (
    <TabletModal
      visible={visible}
      onRequestClose={onRequestClose}
      style={{ wrapper: tw.style(`w-90%  max-h-95%`, Platform.OS !== "web" && `px-4 pr-3`)}}
    >
      <ScrollView style={tw``} contentContainerStyle={{ flexGrow: 1 }}>
        <Text style={tw`text-lg font-bold text-center mb-3`}>Crear partidos {round.name}</Text>

        {
          matches.map((match, i) => {
            return (
              <MatchRow
                key={match.uuid}
                round={round}
                leagueId={leagueId}
                league={league}
                match={match}
                home={match.home}
                away={match.away}
                onMatchChanged={match => {
                  const _matches = [...matches]
                  _matches[i] = match

                  setMatches(_matches)
                }}
                deleteMatch={() => deleteMatch(match, i)}
              />
            )
          })
        }

        {
          (matches?.length > 0 && freeTeams?.length > 0) &&
            <View style={tw`py-3`}>
              <Text style={tw`font-bold mb-2`}>Equipos que no jugarán esta jornada:</Text>

              {
                freeTeams?.map((leagueTeam: LeagueTeam) => 
                  <View style={tw`row mb-1`}>
                    <TeamBadge team={leagueTeam.team} width={22} height={22} />
                    <Text style={tw`ml-1`}>{leagueTeam.name}</Text>
                  </View>
                )
              }
            </View>
        }
        

        {/* {
          suggestions?.map((suggestion, i) => 
            <View style={tw`border-b`} key={i}>
              <Text>{suggestion.home.name}</Text>
              <Text>{suggestion.away.name}</Text>
            </View>
          )
        } */}
        {
          generatingSuggestions &&
            <Text style={tw`py-5 text-center text-lg`}>Generando jornada automática...</Text>
        }

        <View style={tw`row justify-center mt-4 mb-3`}>
         
          {/* Botón jornada automática */}
          <TouchableOpacity
            onPress={getRoundSuggestions}
            // disabled
            style={tw`row px-3 py-2 border border-primary rounded-lg`}>
            <Ionicons name="color-wand-outline" color={"#888"} size={20} style={tw`mr-1`} />

            <Text>Jornada automática</Text>
          </TouchableOpacity>

          {/* Botón nuevo partido */}
          <TouchableOpacity onPress={addEmptyMatch} style={tw`row px-3 py-2 border border-primary rounded-lg ml-4`}>
            <Ionicons name="add-outline" color={"#888"} size={20} style={tw``} />

            <Text>Agregar Partido</Text>
          </TouchableOpacity>
        </View>
        

        <Button
          label={isLoading ? "Guardando" : "Guardar"}
          disabled={isLoading || matches?.length == 0 || !! matches.find(e => e.valid === false)}
          onPress={saveMatches}
          style={tw`mb-0`}
        />
      </ScrollView>
    </TabletModal>
  )
}

