import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, Image } from "react-native"


import tw from '../../styles/tailwind';

export default function PlayerPicture({ player, style } : {player: Player, style?:any}) {
  if(!player) return null

  return (
    <Image source={{ uri: player.image_url }} style={tw.style(`h-20 w-20 rounded-lg overflow-hidden`, style)} />
  )
}

