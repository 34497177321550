import React, { useEffect, useState } from "react";
import { View } from "react-native";

import tw from "../../styles/tailwind";

import Text from "../Text";
import FMIcons from "../FMIcon";
import TeamBadge from "../Teams/TeamBadge";


export default function MatchFeaturesCard({ leftValue, rightValue, iconName, iconLabel, iconColor, style, match, homeStats, awayStats }) {

  return (
    <View style={tw.style(`bg-gray-500 rounded-xl`, style?.container)}>
      <View style={tw`flex-row items-center justify-between px-16 py-4`}>
        {
          typeof (leftValue && rightValue) == 'number' &&
          <>
            <Text style={tw`text-xl text-gray font-bold`}>{leftValue}</Text>
            <View style={tw`flex-col items-center`}>
              <FMIcons name={`${iconName}`} size={24} style={tw`text-${iconColor} mb-1`} />
              <Text style={tw`text-md text-gray`}>{iconLabel}</Text>
            </View>
            <Text style={tw`text-xl text-gray font-bold`}>{rightValue}</Text>
          </>
        }

        {
          // TODO: cambiar nombre de los íconos por el parámetro que se mande desde el endpoint
          typeof (leftValue && rightValue) == 'string' &&
          <>
            <FMIcons name={`${leftValue}`} size={24}
              style={[tw`text-gray-300`,
              leftValue == "feliz" && tw`text-green`,
              leftValue == "neutro" && tw`text-gray`,
              leftValue == "disgustado" && tw`text-red`]} />

            <View style={tw`flex-col items-center`}>
              <FMIcons name={`${iconName}`} size={24} style={tw`text-${iconColor} mb-1`} />
              <Text style={tw`text-md text-gray`}>{iconLabel}</Text>
            </View>

            <FMIcons name={`${rightValue}`} size={24}
              style={[tw`text-gray-300`,
              rightValue == "feliz" && tw`text-green`,
              rightValue == "neutro" && tw`text-gray`,
              rightValue == "disgustado" && tw`text-red`]} />
          </>
        }
      </View>


      {
        homeStats?.length > 0 &&
        <View>
          <View style={tw`px-4 flex-row items-center`}>
            <TeamBadge team={match.home_team.team} width={40} height={40} />
            <Text style={tw`ml-3 text-white`}>{match.home_team.name}</Text>
          </View>

          <View style={tw`mb-3`}>
            {
              homeStats?.map((el, i) =>
                <View key={i} style={tw.style(`flex-row items-center px-4 py-1 justify-between`, (i % 2 == 0) && `bg-[#232323]`)}>
                  <Text style={tw`text-gray-300`}>{el.label}</Text>
                  <Text style={tw`text-gray-300`}>{el.count}</Text>
                </View>
              )
            }
          </View>
        </View>
      }

      {
        awayStats?.length > 0 &&
        <View>
          <View style={tw`px-4 flex-row items-center`}>
            <TeamBadge team={match.away_team.team} width={40} height={40} />
            <Text style={tw`ml-3 text-white`}>{match.away_team.name}</Text>
          </View>

          <View style={tw`mb-3`}>
            {
              awayStats?.map((el, i) =>
                <View key={i} style={tw.style(`flex-row items-center px-4 py-1 justify-between`, (i % 2 == 0) && `bg-[#232323]`)}>
                  <Text style={tw`text-gray-300`}>{el.label}</Text>
                  <Text style={tw`text-gray-300`}>{el.count}</Text>
                </View>
              )
            }
          </View>
        </View>
      }

    </View>
  )
}